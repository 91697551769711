import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Row, Table } from 'react-bootstrap'
import { FaUser } from "react-icons/fa";
import { GetAllLeaveRequestByMonth, GetLeaveCount } from '../../utils/proxy/LeaveRequestProxy';
import { GetAttendance } from '../../utils/proxy/AttendanceProxy';
import { GetUserDeductionByUserid } from '../../utils/proxy/DeductionMasterProxy';
import { GetUserDetails } from '../../utils/proxy/ReportsProxy';
import { GetExpenseByUserByMonth } from '../../utils/proxy/Expenseproxy';
import { GiMoneyStack } from 'react-icons/gi';


function PayoutDetails(selectedUserId,selectedMonth,selectedYear) {

    const [userinfo,setUserInfo] = useState({});
    const [userid,setUserId] = useState(selectedUserId.selectedUserId);

    const [present, setPresent] = useState();
    const [absent, setAbsent] = useState();
    const [holiday, setHoliday] = useState();
    const [days,setDays] = useState();

    const [leaves, setLeaves] = useState([]);
    const [leaveCount, setLeaveCount] = useState([]);
    const [casualLeave, setCasualLeave] = useState();
    const [sickLeave, setSickLeave] = useState()

    const [GetAllDeduction,setAllDeduction] = useState([]);
    const [userDeduction,setUserDeduction] = useState([]);

    const [userDeductionCount,setUserDeductionCount] = useState(0);
    const [userExpensePayabale,setUserExpensepayable] = useState(0);




    useEffect(() => {


        const getUserDetail = async () => {
            let res = await GetUserDetails(selectedUserId.selectedUserId);
            if (res) {
                setUserInfo(res);
            }
        }

      
        

        async function getLeaves() {
            let data = await GetAllLeaveRequestByMonth(selectedUserId.selectedMonth.value,selectedUserId.selectedYear.value,selectedUserId.selectedUserId);
            let data2 = await GetLeaveCount(selectedUserId.selectedMonth.value,selectedUserId.selectedYear.value,selectedUserId.selectedUserId);
            if (data) {
                setLeaves(data);
                setLeaveCount(data2);
                setCasualLeave(0);
                setSickLeave(0);
                data2.map(item=>{
                    if(item.name == 'casual leave'){
                        setCasualLeave(le => le + 1)
                    }else if(item.name == 'sick leave'){
                        setSickLeave(le => le + 1);
                    }
                })
 
            } else {
                alert("Unable To Fetch");
            }
        }

        async function getExp(){
            let res = await GetExpenseByUserByMonth(selectedUserId.selectedMonth.value,selectedUserId.selectedYear.value,selectedUserId.selectedUserId);

            if (res) {

                let count = 0;
                res.forEach(item => {
                    count = count + item.approvedamount
                })
                setUserExpensepayable(count);

            } else {
                
            }
        }


        const getAttendance = async()=>{
            let start = selectedUserId.selectedYear.value+'-'+selectedUserId.selectedMonth.value+'-01';
            let a,b ;
            if(selectedUserId.selectedMonth.value == 12){
                a = (parseInt(selectedUserId.selectedYear.value) + 1).toString();
                b = '01';
            }else{
                a = selectedUserId.selectedYear.value;
                let resultString = (parseInt(selectedUserId.selectedMonth.value) + 1).toString();
                b = resultString.length === 1 ? '0' + resultString : resultString;
            }
            let end = a + '-'+b+'-01';
            let d = await GetAttendance(selectedUserId.selectedUserId,start, end);

 
            if(d){
                setPresent(0);
                setAbsent(0);
                setHoliday(0);
                setDays(d.length);

                d.map(item=>{
                    if(item.working == 'Present'){
                        setPresent(present=>present+1);
                    }else if(item.working == 'Absent'){
                        setAbsent(absent=>absent+1);
                    }else if(item.working == 'Holiday'){
                        setHoliday(holiday=>holiday+1);
                    }
                })
            }
        }


        const getDeduction = async () => {
            let res = await GetUserDeductionByUserid(selectedUserId.selectedUserId);
            if (res) {
                setUserDeduction(res);
                let count = 0;
                res.forEach(item => {
                    count = count + item.amount

                })
                setUserDeductionCount(count);
            } else {
                alert('Issue While Fetching Deductions')
            }
        }

       
        if ((selectedUserId.selectedUserId !=null || selectedUserId.selectedUserId != undefined) && selectedUserId.selectedMonth.value && selectedUserId.selectedYear.value) {
            getLeaves();
            getDeduction();
            getAttendance();
            getExp();
            getUserDetail()
          }
          



        

    },[selectedUserId,selectedMonth,selectedYear])

    return (
       
<>
<Row>
        <div style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex', flexDirection: 'row', backgroundColor:'#FF8B13', borderRadius:20, padding:0,marginBottom:10, color:"white"    }}>
            <div md={4}>
                <div style={{ backgroundColor: '#f5dd4b', padding: '10px',marginRight:10, borderRadius: 50, height: 40, width: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <GiMoneyStack color='#000' size={30} />
                </div>
            </div>
            <div md={4}>
            <h6 style={{textAlign:'center', marginBottom:0}}>Payout Details</h6>
            </div>
            <div md={4}>
           
            </div>
        </div>
    </Row> 
               

                <Row className='mt-3'>
                    <Col md={12}>
                       
                        <Table className='jmn-table' size='xs' responsive>
                            <tr>
                                <td className='pr-table-heads'><b>Payable Days</b>&nbsp;</td>
                                <td className='pr-table-seprator'>:</td>
                                <td>{present!=null && holiday!=null && sickLeave!=null && casualLeave!=null ? ((present + holiday)) +' days' : '' }</td>
                            </tr>
                            <tr>
                                <td className='pr-table-heads'><b>Deductions</b>&nbsp;</td>
                                <td className='pr-table-seprator'>:</td>
                                <td>{userDeductionCount +' /-'}</td>
                            </tr>
                            <tr>
                                <td className='pr-table-heads'><b>Payable Salary</b>&nbsp;</td>
                                <td className='pr-table-seprator'>:</td>
                                <td>{present!=null && holiday!=null && sickLeave!=null && casualLeave!=null ? Math.round(((userinfo.salary/days)*(present + holiday) - userDeductionCount))+' /-': ''}</td>
                            </tr>
     
                            <tr>
                                <td className='pr-table-heads'><b>Expense Payable</b>&nbsp;</td>
                                <td className='pr-table-seprator'>:</td>
                                <td>{userExpensePayabale+" /-" }</td>
                            </tr>
                            <tr>
                                <td className='pr-table-heads'><b>Salary With Exp</b>&nbsp;</td>
                                <td className='pr-table-seprator'>:</td>
                                <td>{present!=null && holiday!=null && sickLeave!=null && casualLeave!=null ? Math.round(((userinfo.salary/days)*(present + holiday) - userDeductionCount) + userExpensePayabale)+' /-': ''}</td>
                            </tr>
                        </Table>
                        <hr />
                        <Table className='jmn-table' borderless size='xs' responsive>
                            <tr style={{fontWeight:'bold',color:'#0D6EFD'}}>
                                <td className='pr-table-heads'><b>Total Payable</b>&nbsp;</td>
                                <td className='pr-table-seprator'>:</td>
                                <td>{present!=null && holiday!=null && sickLeave!=null && casualLeave!=null ? Math.round(((userinfo.salary/days)*(present + holiday) - userDeductionCount) + userExpensePayabale)+' /-': ''}</td>
                            </tr>
                           
                        </Table>
                    </Col>
                </Row>
                </>
    )
}

export default PayoutDetails