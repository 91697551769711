let _permissions = {
    "USER_PERMISSION":false,
    "VISIBLE_MASTERS":false,
    "VISIBLE_MASTERS_DOCTOR":false,
    "VISIBLE_MASTERS_HOSPITAL":false,
    "VISIBLE_MASTERS_CLINIC":false,
    "VISIBLE_MASTERS_CIP":false,
    "VISIBLE_MASTERS_CHEMIST":false,
    "VISIBLE_MASTERS_STOCKIST":false,
    "VISIBLE_MASTERS_TERITORY":false,
    "VISIBLE_MASTERS_CURRENCY":false,
    "VISIBLE_MASTERS_EXPENSETYPE":false,
    "VISIBLE_MASTERS_HOLIDAY":false,
    "VISIBLE_MASTERS_HOLIDAYTYPE":false,
    "VISIBLE_MASTERS_TASKCATEGORY":false,
    "VISIBLE_MASTERS_MOODREPORT":false,
    "VISIBLE_MASTERS_QUOTEMASTER":false,
    "VISIBLE_QUOTE_CREATE":false,
    "VISIBLE_MASTERS_ROLE":false,
    "VISIBLE_FIELD_FORCE":false,
    "VISIBLE_FIELD_FORCE_USER_MASTER":false,
    "VISIBLE_FIELD_FORCE_MANAGERS":false,
    "VISIBLE_FIELD_FORCE_USER_ROLE":false,
    "VISIBLE_FIELD_FORCE_USER_TYPE":false,
    "VISIBLE_FIELD_FORCE_USER_DOCTORS":false,
    "VISIBLE_FIELD_FORCE_USER_HOSPITAL":false,
    "VISIBLE_FIELD_FORCE_USER_CLINIC":false,
    "VISIBLE_FIELD_FORCE_USER_CIP":false,
    "VISIBLE_FIELD_FORCE_USER_CHEMIST":false,
    "VISIBLE_FIELD_FORCE_USER_STOCKIST":false,
    "VISIBLE_FIELD_FORCE_USER_LOCATION":false,
    "VISIBLE_FIELD_FORCE_USER_TERITORY":false,
    "VISIBLE_TOUR_PROGRAM":false,
    "VISIBLE_REPORTS":false,
    "VISIBLE_REPORTS_DCR_DAILY":false,
    "VISIBLE_REPORTS_DCR_MONTHLY":false,
    "VISIBLE_REPORTS_DCR_INOUT":false,
    "VISIBLE_REPORTS_DCR_REPORTS":false,
    "VISIBLE_REPORTS_TP_VS_ACHIEVEMENT":false,
    "VISIBLE_OPTIONS":false,
    "VISIBLE_OPTIONS_DOCTOR_QUALIFICATION":false,
    "VISIBLE_OPTIONS_DOCTOR_SPECIALITY":false,
    "VISIBLE_OPTIONS_COUNTRY":false,
    "VISIBLE_OPTIONS_STATE":false,
    "VISIBLE_OPTIONS_CITY":false,
    "VISIBLE_OPTIONS_USER_DESIGNATION":false,
    "VISIBLE_OPTIONS_CIP_DEPARTMENT":false,
    "VISIBLE_OPTIONS_CIP_DESIGNATION":false,
    "VISIBLE_OPTIONS_CIP_QUALIFICATION":false,
    "VISIBLE_OPTIONS_TRANSFER_USER_DATA":false,
    "VISIBLE_HR":false,
    "VISIBLE_HR_LEAVE_TYPE":false,
    "VISIBLE_HR_DEDUCTION":false,
    "VISIBLE_HR_ATTENDANCE_SHEET":false,
    "VISIBLE_HR_PERFORMANCE":false,
    "VISIBLE_NOTICE":false,
    "VISIBLE_TASKS":false,
    "VISIBLE_EXPENSE":false,
    "VISIBLE_PROJECTS":false,
    "VISIBLE_CALENDAR":false,
    "VISIBLE_LEAVE_REQUEST":false,
    "LEAVE_REQUEST_UPDATE_STATUS" : false,
    "VISIBLE_SALES":false,
    "VISIBLE_SALES_LEADS":false,
    "VISIBLE_SALES_LEAD_SOURCE":false,
    "VISIBLE_SALES_LEAD_STATUS":false,
    "VISIBLE_PRODUCTS":false,
    "VISIBLE_PRODUCTS_CATEGORY":false,
    "VISIBLE_PRODUCTS_MASTER":false,
    "VISIBLE_COMPANY_CONTACTS":false,
    "VISIBLE_ACCOUNTS":false,
    "VISIBLE_ACCOUNTS_TYPE":false,
    "VISIBLE_INDUSTRY_MASTER":false,
    "VISIBLE_SETTINGS":false,
    "VISIBLE_TAX_OPTIONS":false,
    "VISIBLE_TAX_OPTIONS_TCS":false,
    "VISIBLE_TAX_OPTIONS_TDS":false,
    "VISIBLE_TAX_OPTIONS_RULES":false,
    "VISIBLE_TAX_OPTIONS_CONFIG":false,
}

let newOperationPermissions = {
    "MASTERS_DOCTORS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "ALLOW_IMPORT_XLSX":false,
        "ALLOW_EXPORT_XLSX":false
    },
    "MASTERS_HOSPITALS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "ALLOW_IMPORT_XLSX":false,
        "ALLOW_EXPORT_XLSX":false
    },
    "MASTERS_CLINICS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "ALLOW_IMPORT_XLSX":false,
        "ALLOW_EXPORT_XLSX":false
    },
    "MASTERS_CIP":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "ALLOW_IMPORT_XLSX":false,
        "ALLOW_EXPORT_XLSX":false
    },
    "MASTERS_CHEMISTS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "ALLOW_IMPORT_XLSX":false,
        "ALLOW_EXPORT_XLSX":false
    },
    "MASTERS_STOCKISTS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "ALLOW_IMPORT_XLSX":false,
        "ALLOW_EXPORT_XLSX":false
    },
    "MASTERS_TERRITORIES":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "ALLOW_EXPORT_XLSX":false
    },
    "MASTERS_CURRENCIES":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "MASTERS_ROLE":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "MASTERS_EXPENSETYPES":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "MASTERS_HOLIDAYTYPES":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "MASTERS_HOLIDAYS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "ALLOW_EXPORT_XLSX":false
    },
    "MASTERS_TASK_CATEGORIES":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "USERS_USER_MASTER":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "USERS_MANAGERS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "USERS_USER_ROLES":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "USERS_USER_TYPES":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "USERS_USER_DOCTORS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "USER_DROPDOWN":false
    },
    "USERS_USER_HOSPITALS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "USER_DROPDOWN":false
    },
    "USERS_USER_CLINICS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "USER_DROPDOWN":false
    },
    "USERS_USER_CIPS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "USER_DROPDOWN":false
    },
    "USERS_USER_CHEMISTS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "USER_DROPDOWN":false
    },
    "USERS_USER_STOCKISTS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "USER_DROPDOWN":false
    },
    "USERS_USER_LOCATIONS":{
        "USER_DROPDOWN":false
    },
    "USERS_USER_TERRITORIES":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "USER_DROPDOWN":false
    },
    "TOUR_PROGRAM":{
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "ALLOW_EXPORT_XLSX":false,
        "USER_DROPDOWN":false
    },
    "REPORTS_DCR_DAILY":{
        "ALLOW_EXPORT_XLSX":false,
        "USER_DROPDOWN":false
    },
    "REPORTS_DCR_MONTHLY":{
        "ALLOW_EXPORT_XLSX":false,
        "USER_DROPDOWN":false
    },
    "REPORTS_DCR_REPORTS":{
        "ALLOW_EXPORT_XLSX":false,
        "USER_DROPDOWN":false
    },
    "REPORTS_DCR_DAYINOUT":{
        "ALLOW_EXPORT_XLSX":false,
        "USER_DROPDOWN":false
    },
    "REPORTS_TP_VS_ACHIEVEMENT":{
        "ALLOW_EXPORT_XLSX":false,
        "USER_DROPDOWN":false
    },
    "OPTIONS_QUALIFICATION_MASTER":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "OPTIONS_SPECIALITY_MASTER":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "OPTIONS_COUNTRY_MASTER":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "OPTIONS_STATE_MASTER":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "OPTIONS_CITY_MASTER":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "OPTIONS_USER_DESIGNATION":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "OPTIONS_CIP_DEPARTMENT":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "OPTIONS_CIP_DESIGNATION":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "OPTIONS_CIP_QUALIFICATION":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "HR_LEAVE_TYPE":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "HR_DEDUCTION_MASTER":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "HR_PERFOMANCE":{
        "USER_DROPDOWN":false
    },
    "NOTICE":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false
    },
    "TASKS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "USER_DROPDOWN":false
    },
    "EXPENSE":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "USER_DROPDOWN":false
    },
    "PROJECTS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "ALLOW_EXPORT_XLSX":false
    },
    "CALENDAR":{
        "ALLOW_EXPORT_XLSX":false,
        "USER_DROPDOWN":false
    },
    "LEAVE_REQUEST":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "ALLOW_EXPORT_XLSX":false,
        "USER_DROPDOWN":false
    },
    "LEADS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "ALLOW_EXPORT_XLSX":false,
        "USER_DROPDOWN":false,
    },
    "PRODUCTS":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "ALLOW_EXPORT_XLSX":false,
        "USER_DROPDOWN":false
    },
    "QUOTE_MASTER":{
        "ALLOW_ADD":false,
        "ALLOW_EDIT":false,
        "ALLOW_DELETE":false,
        "CREATE_QUOTE":false,
    },
    "VISIBLE_TAX_OPTIONS_RULES":{
        "ALLOW_ADD_TAX_RULES":false,
        "ALLOW_EDIT_TAX_RULES":false,
        "ALLOW_DELETE_TAX_RULES":false
    }
    ,
    "VISIBLE_TAX_OPTIONS_CONFIG":{
        "ALLOW_ADD_TAX_CONFIG":false,
        "ALLOW_EDIT_TAX_CONFIG":false,
        "ALLOW_DELETE_TAX_CONFIG":false,
    }
    ,
    "VISIBLE_TAX_OPTIONS_TCS":{
        "ALLOW_ADD_TCS_SECTION":false,
        "ALLOW_EDIT_TCS_SECTION":false,
        "ALLOW_DELETE_TCS_SECTION":false,
        "ALLOW_ADD_TCS_TAX":false,
        "ALLOW_EDIT_TCS_TAX":false,
        "ALLOW_DELETE_TCS_TAX":false
    }
    ,
    "VISIBLE_TAX_OPTIONS_TDS":{
        "ALLOW_ADD_TDS_SECTION":false,
        "ALLOW_EDIT_TDS_SECTION":false,
        "ALLOW_DELETE_TDS_SECTION":false,
        "ALLOW_ADD_TDS_TAX":false,
        "ALLOW_EDIT_TDS_TAX":false,
        "ALLOW_DELETE_TDS_TAX":false
    }

}

export const Permissions = {
    get: function() {
  
        return _permissions;
    },
    set: function(newPermissions) {
        // Iterate over the keys of newPermissions
        Object.keys(newPermissions).forEach(key => {
            // Check if the key exists in _permissions
            if (_permissions.hasOwnProperty(key)) {
                // Update the value of the corresponding key in _permissions
                _permissions[key] = newPermissions[key];
            }
        });
    }
};

export const Op_per = {
    get: function(){
        return newOperationPermissions;
    },
    set: function(object) {
        // Iterate over the keys of newPermissions
        Object.keys(object).forEach(key => {
            // Check if the key exists in _permissions
            if (newOperationPermissions.hasOwnProperty(key)) {
                // Update the value of the corresponding key in newOperationPermissions
                newOperationPermissions[key] = object[key];
            }
        });
    }
}