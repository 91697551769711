import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"


export const GetAllUserTeritory = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/userteritory',null);
}

export const GetAllUserTeritoryByFilter = async (userid,areatype,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/userteritory/'+userid+'/'+areatype+'/'+status,null);
}

export const UpdateUserTeritory = async (userid,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/userteritory/'+userid+'/'+status,null);
}

export const DeleteUserTeritory = async (userid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/userteritory/'+userid,null);
}


