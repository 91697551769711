import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"



export const GetAllTourPlan = async (user,selectedMonth, selectedYear) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/tourplan/user/'+user+"/"+selectedMonth+"/"+selectedYear,null);
}

export const CreateTourPlan = async (user,selectedDate,activityType,areaid,doctorsid,chemistsid,stockistid,clinicid,hospitalsid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "userid":user,
        "tourdate":selectedDate,
        "activitytype":activityType,
        "teritories":areaid,
        "doctors":doctorsid,
        "chemists":chemistsid,
        "stockists":stockistid,
        "clinics":clinicid,
        "hospitals":hospitalsid
    
    });

    return await Restapi('post',REPORTING_URL+'/apiv1/tourplan',data);
}

export const UpdateTourPlan = async (id,user,selectedDate,activityType,areaid,doctorsid,chemistsid,stockistid,clinicid,hospitalsid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "userid":user,
        "tourdate":selectedDate,
        "activitytype":activityType,
        "teritories":areaid,
        "doctors":doctorsid,
        "chemists":chemistsid,
        "stockists":stockistid,
        "clinics":clinicid,
        "hospitals":hospitalsid
    
    });

    return await Restapi('put',REPORTING_URL+'/apiv1/tourplan/update/'+id,data);
}

export const UpdateStatusTourPlan = async (id,status) => {

    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/tourplan/updatestatus/'+id+'/'+status,null);
}

export const DeleteTourPlan = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/tourplan/'+id,null);
}

export const GetUserDayPlan = async (user,date) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/tourplan/dayplan/'+user+'/'+date,null);
}

export const UpdateBulkStatus = async (data) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi(
        "put",
        REPORTING_URL + "/apiv1/tourplan/bulk",
        JSON.stringify(data)
    );
}

export const StartDay = async (user) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
 
    return await Restapi('post',REPORTING_URL+'/apiv1/dailylogs/start/'+user,null);
}

export const EndDay = async (startdayid,remark) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "userid":null,
        "startday": null,
        "endday": null,
        "remark": remark
    });
    return await Restapi('post',REPORTING_URL+'/apiv1/dailylogs/end/'+startdayid,data);
}

export const IsStartDay = async (date,userid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
 
    return await Restapi('get',REPORTING_URL+'/apiv1/dailylogs/isdaystarted?date='+date+'&userid='+userid,null);
}

export const CheckDayEnded = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
 
    return await Restapi('post',REPORTING_URL+'/apiv1/dailylogs/checkendday/'+id,null);
}

export const DayStartMood = async (id,mood) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
 
    return await Restapi('put',REPORTING_URL+`/apiv1/dailylogs/startmood/${id}/${mood}`,null);
}

export const DayEndMood = async (id,mood) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
 
    return await Restapi('put',REPORTING_URL+`/apiv1/dailylogs/endmood/${id}/${mood}`,null);
}