import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

export const CreateCurrency = async (currency, symbol, value) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        'currency':currency,
        'symbol':symbol,
        'value':value
    }
    return await Restapi('post',REPORTING_URL+'/apiv1/currency',data);
}

export const GetAllCurrency = async () =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/currency',null);
}

export const DeleteCurrency = async (id) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/currency/'+id,null);
}

export const EditCurrency = async (id, currency, symbol, value) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        'currency':currency,
        'symbol':symbol,
        'value':value
    }
    return await Restapi('put',REPORTING_URL+'/apiv1/currency/'+id,data);
}