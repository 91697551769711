import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { GetAllBifurcationCounts } from '../utils/proxy/ChartsProxy';
import CardDash from './Common/CardDash';
import { EndDay, StartDay } from '../utils/proxy/Tourplanproxy';
import Swal from 'sweetalert2';
import WorkingUsers from './Includes/WorkingUsers';
import ActiveInactiveUsers from './Includes/ActiveInactiveUsers';
import PerfomanceChart from './Includes/PerfomanceChart';
import SubmissionTrendChart from './Includes/SubmissionTrendChart';
import InternalExternalChart from './Includes/InternalExternalChart';
import TaskPendingChart from './Includes/TaskPendingChart';
import { CreateTask, GetAllTaskCategory } from "../utils/proxy/Taskproxy";
import Select from "react-select";
import { AllUsers, GetAllManagerUsers } from "../utils/proxy/Authproxy";
import { GetAllProjectsByUserid } from "../utils/proxy/ProjectProxy";
import TaskStatusChart from './Includes/TaskStatusChart';
import { CreateSettings, GetAllSettings } from '../utils/proxy/SettingsProxy';
import { GetCurrency } from '../utils/proxy/Expenseproxy';
import Helper from '../utils/helperFunctions';

function AdminDashBoard() {

  const [chartData, setChartData] = useState([]);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [weatherlocation, setWeatherLocation] = useState();
  const [weatherlocationregion, setWeatherLocationRegion] = useState();
  const [currentweather, setCurrentWeather] = useState();
  const [currentweathercondition, setCurrentWeatherCondition] = useState();
  const [currentweathericon, setCurrentWeatherIcon] = useState();
  const [currentweatherwind, setCurrentWeatherWind] = useState();
  const [currentweatherhumidity, setCurrentWeatherHumidity] = useState();
  const [currentweatheruv, setCurrentWeatherUv] = useState();
  const [date, setDate] = useState(new Date());
  const [startDay, setStartDay] = useState();
  const [remark, setRemark] = useState();
  const [show, setShow] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [siteData, setSiteData] = useState([
    {
      setting_name: '',
      setting_value: ''
    },
    {
      setting_name: '',
      setting_value: ''
    },
    {
      setting_name: '',
      setting_value: ''
    }
  ]);
  const [allCurrency, setAllCurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [selectedCurrencyId, setSelectedCurrencyId] = useState();

  const [users, setusers] = useState([])
  const [allProject, setAllProject] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState();
  const [selectedUserId, setSelectedUserId] = useState();
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [showaddtask, setshowaddtask] = useState(false);
  const [reload, setReload] = useState(false);
  const [utcStartDate, setUtcStartDate] = useState('');
  const [utcEndDate, setUtcEndDate] = useState('');
  const [task, setTask] = useState({
    "id": null,
    "userid": selectedUserId,
    "task": null,
    "projectid": selectedProjectId,
    "categoryid": selectedCategoryId,
    "start": null,
    "end": null,
    "description": null,
    "createdby": localStorage.getItem('uuid'),
    "priority": null,
    "status": "new"
  });

  useEffect(() => {
    handleSearch();
  }, []);

  function remoteadd() {
    const st = new Date();
    const ed = new Date();
    ed.setHours(ed.getHours() + 1);
    setshowaddtask(true)
    setUtcStartDate(Helper.dateToIsoStringWithoutChange(st).substr(0,16))
    setUtcEndDate(Helper.dateToIsoStringWithoutChange(ed).substr(0,16))
    setTask({...task, start: new Date().toISOString(), end:new Date().toISOString(), priority:'medium'})
  }

  useEffect(() => {
    // Show the modal after 3 seconds
    const getsettings = async () => {
      let d = await GetAllSettings();
      if (d && d.length == 0) {
        const timer = setTimeout(() => {
          setIsModalOpen(true);
        }, 1000);

        // Clear the timer if the component is unmounted before the 3 seconds
        return () => clearTimeout(timer);
      }
    }
    getsettings();
  }, []);

  // useEffect(() => {
  //   if (latitude === undefined || longitude === undefined) {
  //     return;
  //   } else {
  //     getWeather();
  //   }

  // }, [latitude, longitude]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    })
  }, []);

  useEffect(() => {

    const getAllUsersData = async () => {
      if (localStorage.getItem('role') === 'Admin') {
        let allusers = await AllUsers();
        setusers(allusers);
      } else if (localStorage.getItem('role') === 'Manager') {
        let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
        setusers(allusers);
      }
    };

    const getCurrencyType = async () => {
      let curr = await GetCurrency();
      if (curr) {
        setAllCurrency(curr);
        setSelectedCurrency(curr)
      } else {
        alert("Error", "Issue While Fetching Data");
      }
    }
    getAllUsersData();
    getCurrencyType();
  }, []);

  useEffect(() => {
    async function getProjects() {
      let data = await GetAllProjectsByUserid(selectedUserId);
      if (data) {
        setAllProject(data);
      } else {
        alert('Unable to Fetch');
      }
    }

    async function getCategories() {
      let data = await GetAllTaskCategory();
      if (data) {
        setAllCategories(data);
      } else {
        alert('Unable to Fetch');
      }
    }

    getProjects();
    getCategories();
  }, [selectedUserId])




  const handleSearch = async () => {
    let response = await GetAllBifurcationCounts();
    if (response) {
      setChartData(response);
    }
  };

  const handleStartDay = async () => {
    let res = await StartDay(localStorage.getItem('uuid'));
    if (res) {
      try {
        localStorage.setItem('startdayid', res.id.toString());
        localStorage.setItem('startday', res.startday);
        setStartDay(res.startday);
        Swal.fire({
          title: "Success!",
          text: "Day Started !!",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {

      }
    }
  }

  const handleEndDay = () => {
    if (!localStorage.getItem('startday')) {
      Swal.fire({
        title: "Warning!",
        text: "First Start the Day !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else {
      setShow(true);
    }
  }

  const submitEndDay = async () => {
    let res = await EndDay(parseInt(localStorage.getItem('startdayid')), remark);
    if (res) {
      setShow(false);
      localStorage.removeItem('startday');
      localStorage.removeItem('startdayid');
      setStartDay(null);
      Swal.fire({
        title: "Success!",
        text: "Day Ended !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    }
  }

  // Function to update a specific object in the array
  const updateSiteData = (index, newData) => {
    setSiteData(prevData => [
      ...prevData.slice(0, index),
      newData,
      ...prevData.slice(index + 1)
    ]);
  };

  const handleSelectChange = (selected) => {
    setSelectedUsers(selected);
    setTask({ ...task, userid: selected.value });
    setSelectedUserId(selected.value);
  };

  const handleProjectChange = (selected) => {
    setTask({ ...task, projectid: selected.value });
    setSelectedProject(selected);
    setSelectedProjectId(selected.value);
  };

  const handleCategoryChange = (selected) => {
    setTask({ ...task, categoryid: selected.value })
    setSelectedCategory(selected);
    setSelectedCategoryId(selected.value);
  }

  const handleCurrencyChange = (selected) => {
    updateSiteData(2, {
      setting_name: 'CURRENCY_ID',
      setting_value: selected.value
    })
    setSelectedCurrency(selected)
    setSelectedCurrencyId(selected.value)
  }

  const options = [];
  const projects = [];
  const category = [];
  const currencyoptions = [];

  users && users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  allProject.map((project) => {
    projects.push({
      value: project.id,
      label: project.name
    })
  })

  allCategories.map((cat) => {
    category.push({
      value: cat.id,
      label: cat.name
    })
  })

  allCurrency.map((curr) => {
    currencyoptions.push({
      value: curr.id,
      label: curr.currency
    });
  });

  const handlesubmit = async () => {
    if (task.task == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Title !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.description == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Description !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.userid == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter AssignTo !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.priority == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Priority !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.start == null || task.end == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Date fields !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
    else {
      let d = await CreateTask(task);
      if (d.status === "success") {
        setshowaddtask(false);
        setReload(true)
        setTask({
          ...task,
          userid: selectedUserId,
          task: null,
          start: null,
          end: null,
          description: null,
          priority: null
        })
        setSelectedCategory(null);
        setSelectedCategoryId(null);
        Swal.fire({
          title: "Success!",
          text: "Task Added Successfully !!",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        alert("Error", "Issue While Adding Task");
      }
    }
  }

  const handleSettingSubmit = async () => {
    let count = 0;
    siteData.map(item => {
      if (item.setting_value == '') {
        Swal.fire({
          title: "Warning!",
          text: "Enter All Fields !!",
          icon: "warning",
          confirmButtonText: "OK",
        });
      } else {
        count++;
      }
    })

    if (count === 3) {
      let d = await CreateSettings(siteData);
      if (d.status == 'success') {
        Swal.fire({
          title: "Success!",
          text: "Settings Applied !!",
          icon: "success",
          confirmButtonText: "OK",
        });
        setIsModalOpen(false);
        setSiteData(null);
      }
    }

  }


  return (
    <Container style={{ minHeight: "80vh" }}>
      <Row>
        <Col style={{paddingLeft:0}} md={12}>
          <div style={{paddingBottom:15, paddingLeft:0}} className="page-title-box">
            <h4  className="page-title">Dashboard</h4>
          </div>
        </Col>
      </Row>

      <Row className='mr-2 mb-2 '>
        {localStorage.getItem('type') === 'External' ? (
          chartData.map((d) => (
            <Col className='pr-card-sg p-3'>
              <CardDash count={d.count} icon={"mdi mdi-account"} name={d.name.charAt(0).toUpperCase() + d.name.slice(1)} updownpercent={75} updownpercenticon={"mdi mdi-arrow-up"} progress={89} progresscolor={"bg-success"} />
            </Col>
          ))) : ("")
        }
      </Row>

      {localStorage.getItem('type') === 'External' ? (
        <Row className='mr-2 '>
          <Col style={{height:390}} className='pr-card-sg'>
            <SubmissionTrendChart />
          </Col>
          <Col style={{height:390}} className='pr-card-sg'>
            <PerfomanceChart />
          </Col>
        </Row>
      ) : ("")}

      <Row className='mt-3'>
        <Col className='pr-card-sg'>
          <TaskStatusChart />
        </Col>
        <Col className='pr-card-sg'>
          <TaskPendingChart acdgd={remoteadd} re={reload} />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div style={{paddingBottom:0, paddingLeft:0,paddingTop:12}} className="page-title-box">
            <h4  className="page-title">Summary</h4>
          </div>
        </Col>
      </Row>


      <Row className='mt-3'>
        <Col className='pr-card-sg'>
          <ActiveInactiveUsers />
        </Col>
        <Col className='pr-card-sg'>
          <InternalExternalChart />
        </Col>
        <Col className='pr-card-sg'>
          <WorkingUsers />
        </Col>
      </Row>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Remark</Form.Label>
              <Form.Control
                type="text"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                placeholder="Enter Remark"
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={submitEndDay}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showaddtask} onHide={() => setshowaddtask(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Title <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                placeholder="Enter Task Title"
                value={task.task}
                onChange={(e) => { setTask({ ...task, task: e.target.value }) }}
              />
              <Form.Label >Assign To <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor" >
                  <Select

                    options={options}
                    value={selectedUsers}
                    onChange={handleSelectChange}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Assign Project</Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select
                    options={projects}
                    value={selectedProject}
                    onChange={handleProjectChange}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  // isClearable={true}
                  />
                </div>
              </Form.Group>
              <Form.Label>Task Category <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select
                    options={category}
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  // isClearable={true}
                  />
                </div>
              </Form.Group>
              <Form.Label>Description <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                as='textarea'
                placeholder='Enter Task Description'
                value={task.description}
                onChange={(e) => { setTask({ ...task, description: e.target.value }) }} />

              <Form.Label>Start Date <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='datetime-local'
                value={utcStartDate}
                onChange={(e) => { setTask({ ...task, start: new Date(e.target.value).toISOString() });setUtcStartDate(Helper.dateToIsoStringWithoutChange(new Date(e.target.value)).substr(0,16)) }}
              />

              <Form.Label>End Date <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='datetime-local'
                value={utcEndDate}
                onChange={(e) => { setTask({ ...task, end: new Date(e.target.value).toISOString() });setUtcEndDate(Helper.dateToIsoStringWithoutChange(new Date(e.target.value)).substr(0,16)) }} />
            </Form.Group>
            <div style={{ margin: '10px' }}>
              <div>
                <Form.Label>Priority <span style={{color:'red'}}>*</span> : </Form.Label>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: 'rgb(59, 130, 246)', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="f-option" class="l-radio">
                    <input type="radio" id="f-option" name="selector" tabindex="1" value="low" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'low'}/>
                    <span style={{ margin: '5px' }}>LOW</span>
                  </label>
                </div>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#28a745', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="s-option" class="l-radio">
                    <input type="radio" id="s-option" name="selector" tabindex="2" value="medium" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'medium'}/>
                    <span style={{ margin: '5px' }}>MEDIUM</span>
                  </label>
                </div>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#ff0018', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="t-option" class="l-radio">
                    <input type="radio" id="t-option" name="selector" tabindex="3" value="high" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'high'}/>
                    <span style={{ margin: '5px' }}>HIGH</span>
                  </label>
                </div>
              </div>
            </div>

          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowaddtask(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handlesubmit}
          >
            Create Task
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        backdrop="static"
      >
        <Modal.Body>
          <Form>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>SITE_NAME   <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                placeholder="Enter Site Name"
                onChange={(e) => {
                  updateSiteData(0, {
                    setting_name: 'SITE_NAME',
                    setting_value: e.target.value
                  })
                }}
              />
              <Form.Label>SITE_DESCRIPTION    <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                placeholder="Enter Site Description"
                onChange={(e) => {
                  updateSiteData(1, {
                    setting_name: 'SITE_DESCRIPTION',
                    setting_value: e.target.value
                  })
                }}
              />
            </Form.Group>
            <Form.Label>Currency    <span style={{color:'red'}}>*</span></Form.Label>
            <Form.Group
              className="mb-3 d-flex w-100"
              style={{ gap: "15px" }}
              controlId="formBasicPassword"
            >
              <div className="selectdoctor">
                <Select
                  placeholder="Select Currency"
                  options={currencyoptions}
                  value={selectedCurrency}
                  onChange={handleCurrencyChange}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleSettingSubmit}
          >
            Set
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
}

export default AdminDashBoard