import Restapi from "../api/Restapi";
import { AUTH_URL, REPORTING_URL, constats } from "../constant";

export const GetPermissionsByUserid = async (userid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", AUTH_URL + "/apiv1/userpermissions/"+userid, null);
};

export const createUserPermission = async (userid,permission,operationPermission) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  const p ={
    userid,
    permissions:JSON.stringify(operationPermission),
    menupermissions:JSON.stringify(permission)
  }
  return await Restapi("post", AUTH_URL + "/apiv1/userpermissions", p);
};