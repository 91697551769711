import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

export const GetAllNotice = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/notice',null);
}

export const CreateNotice = async (createdby,notice,start,end) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        createdby:createdby,
        notice:notice,
        start:start,
        end:end
    }
    return await Restapi('post',REPORTING_URL+'/apiv1/notice',data);
}

export const EditNotice = async (id,createdby,notice,start,end,disabled) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        createdby:createdby,
        notice:notice,
        start:start,
        end:end,
        disabled:disabled
    }
    return await Restapi('put',REPORTING_URL+'/apiv1/notice/'+id,data);
}

export const DeleteNotice = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/notice/'+id,null);
}