import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"


export const GetAllStockistByCity = async (cityid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/stockist/city/'+cityid,null);
}

export const GetAllStockistByCities = async (cityid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/stockist/cities',cityid);
}

export const AddUserStockistData = async (userid,stockistid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "userid":userid,
        "stockistid":stockistid,
        "status":"ACTIVE"
    });
    return await Restapi('post',REPORTING_URL+'/apiv1/userstockist',data);
}

export const UpdateUserStockistStatus = async (id,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    
    return await Restapi('put',REPORTING_URL+`/apiv1/userstockist/update/${id}/${status}`,null);
}

export const DeleteUserStockistStatus = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/userstockist/'+id,null);
}


export const CreateUserStockistData = async (userid,areas,stockistname,address,countryid,stateid,cityid,pincode,phone,email) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    var arr = [areas];
    let data = JSON.stringify({
        "userid":userid,
        "status":"PENDING_FOR_ADDITION",
        "areas":arr,
        "stockistname":stockistname,
        "address":address,
        "countryid":countryid,
        "stateid":stateid,
        "cityid":cityid,
        "pincode":pincode,
        "phone":phone,
        "email":email,
        "createdby":userid
    });
    return await Restapi('post',REPORTING_URL+'/apiv1/userstockist/create',data);
}

export const GetAllStockistbyUserStatus = async (user,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/userstockist/status/'+user+'/'+status,null);
}