import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"


export const GetAllClinicByCity = async (cityid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/clinic/city/'+cityid,null);
}

export const GetAllClinicByCities = async (cityid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/clinic/cities',cityid);
}

export const AddUserClinicData = async (userid,clinicid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "userid":userid,
        "clinicid":clinicid,
        "status":"ACTIVE"
    });
    return await Restapi('post',REPORTING_URL+'/apiv1/userclinic',data);
}

export const UpdateUserClinicStatus = async (id,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    
    return await Restapi('put',REPORTING_URL+`/apiv1/userclinic/update/${id}/${status}`,null);
}

export const DeleteUserClinicStatus = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/userclinic/'+id,null);
}

export const CreateUserClinicData = async (userid,areas,clinicname,address,countryid,stateid,cityid,pincode,phone,email) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    var arr = [areas];
    let data = JSON.stringify({
        "userid":userid,
        "status":"PENDING_FOR_ADDITION",
        "areas":arr,
        "clinicname":clinicname,
        "address":address,
        "countryid":countryid,
        "stateid":stateid,
        "cityid":cityid,
        "pincode":pincode,
        "phone":phone,
        "email":email,
        "createdby":userid
    });
    return await Restapi('post',REPORTING_URL+'/apiv1/userclinic/create',data);
}

export const GetAllClinicbyUserStatus = async (user,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/userclinic/status/'+user+'/'+status,null);
}