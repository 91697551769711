import { Button, Container, Dropdown, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Form from 'react-bootstrap/Form';
import { useState } from "react";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { useEffect } from "react";
import { CreateCurrency, DeleteCurrency, EditCurrency, GetAllCurrency } from "../../utils/proxy/Currencyproxy";
import Swal from "sweetalert2";
import { HiDotsVertical } from "react-icons/hi";
import { FaPlus } from "react-icons/fa6";
import { IoHomeOutline } from "react-icons/io5";
import { Op_per } from "../../utils/Permissions";

function Currencymaster() {

    const [show, setShow] = useState(false);
    const [showedit, setShowEdit] = useState(false);
    const [data, setData] = useState();

    const [id, setId] = useState();
    const [currency, setCurrency] = useState();
    const [symbol, setSymbol] = useState();
    const [value, setValue] = useState();

    const [reload, setReload] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const getall = async () => {
            let d = await GetAllCurrency();
            if (d) {
                setData(d);
            } else {
                alert('Error', 'Issues while fetching data')
            }
        }
        getall()
        setReload(false);
    }, [reload])

    const handledelete = async (id) => {
        let d = await DeleteCurrency(id);
        if (d.status === 'success') {
            setReload(true);
            Swal.fire({
                title: "Success!",
                text: "Currency Deleted !!",
                icon: "success",
                confirmButtonText: "OK",
            });
        }
    }

    const handleEditShow = (row) => {
        setId(row.id);
        setCurrency(row.currency);
        setSymbol(row.symbol);
        setValue(row.value);
        setShowEdit(true);
    }

    const handleEditSave = async () => {
        if (currency == null || currency == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Currency !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (symbol == null || symbol == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Currency Symbol !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (value == null || value == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Currency Value !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await EditCurrency(id, currency, symbol, value);
            if (d.status === 'success') {
                setShowEdit(false);
                setReload(true);
                setId(null);
                setCurrency(null);
                setSymbol(null);
                setValue(null);
                Swal.fire({
                    title: "Success!",
                    text: "Currency Edited !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const columns = [
        // {
        //     name: "Id",
        //     selector: (row) => row.id,
        //    
        //     width:"10%",
        // },
        {
            name: "Currency",
            selector: (row) =>
            <div style={{ whiteSpace: 'normal',backgroundColor: row.currency === "" || row.currency === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.currency === "" || row.currency === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.currency === "" || row.currency === null ? "#fff": "#000", margin:"3px", padding:"5px" }}>{row.currency ? row.currency : "N/A"}</div>,
            width:"10%",
            
        },
        {
            name: "Symbol",
            selector: (row) => 
            <div style={{ whiteSpace: 'normal',backgroundColor: row.symbol === "" || row.symbol === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.symbol === "" || row.symbol === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.symbol === "" || row.symbol === null ? "#fff": "#000", margin:"3px", padding:"5px" }}>{row.symbol ? row.symbol : "N/A"}</div>
            ,width:"10%",
           
        },
        {
            name: "Value",
            selector: (row) =>
            <div style={{ whiteSpace: 'normal',backgroundColor:  row.value === "" ||  row.value === null ? "red":"#D9E1FF", borderRadius:"10px", border:  row.value === "" ||  row.value === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color: row.value === "" ||  row.value === null ? "#fff": "#000", margin:"3px", padding:"5px" }}>{ row.value ?  row.value : "N/A"}</div>
           ,width:"10%",
           
        },
    ];

    const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
    const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    if (Op_per.get().MASTERS_CURRENCIES.ALLOW_DELETE || Op_per.get().MASTERS_CURRENCIES.ALLOW_EDIT) {
        columns.push({
            name: "Actions",
            selector: (row) => (
                <div key={row.id}>
                    <AiFillDelete
                        onClick={() => handledelete(row.id)}
                        style={{ color: "red", fontSize: 20, display:Op_per.get().MASTERS_CURRENCIES.ALLOW_DELETE ? '' : 'none' }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AiTwotoneEdit
                        onClick={() => handleEditShow(row)}
                        style={{ color: "black", fontSize: 20,display:Op_per.get().MASTERS_CURRENCIES.ALLOW_EDIT ? '' : 'none' }}
                    />
                </div>
            ),
        })
    }

    const handleSave = async () => {
        if (currency == null || currency == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Currency !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (symbol == null || symbol == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Currency Symbol!!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (value == null || value == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Currency Value!!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await CreateCurrency(currency, symbol, value);
            if (d.status === 'success') {
                setShow(false);
                setReload(true);
                setCurrency(null);
                setSymbol(null);
                setValue(null);
                Swal.fire({
                    title: "Success!",
                    text: "New Currency Added !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding:0,
          backgroundColor:'#3887BE'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/currency-master"><span class="icon icon-beaker"> </span>Masters</a></li>
  <li><a href="/currency-master"><span class="icon icon-beaker"> </span>Currencies</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <Form.Control
                        
                            className="glass"
                            placeholder="Search"
                            type="text"
                            style={{ display: 'none' }}
                        //   value={searchQuery}
                        //   onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    {Op_per.get().MASTERS_CURRENCIES.ALLOW_ADD ? (
                    <div>
                        <Button style={{backgroundColor: "#28B3D2 " }} onClick={handleShow}>
                            <FaPlus />
                        </Button>
                    </div>
                    ):("")}
                </div>
                <div style={{ marginTop: 10 }}>
                    <DataTable
                        columns={columns}
                        data={data}
                        // progressPending={pending}
                        // selectableRows
                        // onSelectedRowsChange={({ selectedRows }) =>
                        // setSelectedRows(selectedRows)
                        //   }
                        pagination
                        customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
                        
                        // actions={actionsMemo}
                        style={{ marginTop: 10 }}
                        // progressPending={pending}
                        // progressComponent={<LoadingComponent/>}
                        dense
                    />
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Currency</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Currency Name <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                            style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                                type="text"
                                onChange={(e) => setCurrency(e.target.value)}
                                placeholder="Enter Expense Type Name"
                                required
                            />
                        </Form.Group>
                        <Form.Label>Symbol <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Control
                        style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                            type="text"
                            onChange={(e) => setSymbol(e.target.value)}
                            placeholder="Enter Symbol "
                        />
                        <Form.Label>Value <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Control
                        style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                            type="number"
                            onChange={(e) => setValue(e.target.value)}
                            placeholder="Enter Value"
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showedit} onHide={() => setShowEdit(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Currency</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Currency Name <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                            style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                                type="text"
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                                placeholder="Enter Expense Type Name"
                                required
                            />
                        </Form.Group>
                        <Form.Label>Symbol <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Control
                        style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                            type="text"
                            value={symbol}
                            onChange={(e) => setSymbol(e.target.value)}
                            placeholder="Enter Symbol "
                        />
                        <Form.Label>Value <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Control
                        style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                            type="number"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            placeholder="Enter Value"
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEdit(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleEditSave}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    )
}

export default Currencymaster;