import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap"
import Swal from "sweetalert2";
import { GetUserDetailsByUserid } from "../utils/proxy/Authproxy";
import LoadingComponent from "./Includes/LoadingComponent";
import { RemoveProfilePhoto, UploadOnAwsS3 } from "../utils/proxy/AccountsProxy";
import { constats } from "../utils/constant";

export const ProfilePage = ({setProfile}) => {

    const fileInputRef = useRef(null);
    const [userDetail, setUserDetail] = useState({});
    const [pic, setPic] = useState(localStorage.getItem('image') ? localStorage.getItem('image')+ `?timestamp=${new Date().getTime()}` : 'assets/images/users/400.png')
    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [reload, setReload] = useState(false);

    useEffect(()=>{
        const getUserDetail = async() =>{
            setLoading(false);
            let d = await GetUserDetailsByUserid(localStorage.getItem('uuid'));
            setPic(null)
            if (d) {
                setUserDetail(d);
                if (d.image) {
                    localStorage.setItem('image',d.image);
                    setProfile(d.image+ `?timestamp=${new Date().getTime()}`);
                    setPic(d.image+ `?timestamp=${new Date().getTime()}`);
                }else{
                    setProfile('assets/images/users/400.png');
                    setPic('assets/images/users/400.png');
                }
                
                setLoading(true)
            }else{
                Swal.fire({
                    title:'Error',
                    text:'Something Went Wrong',
                    icon:'error'
                })
            }
        }

        getUserDetail();
        setReload(false);
    },[reload])

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async(event) => {
        const file = event.target.files[0];
        if (file) {
            // const fileURL = URL.createObjectURL(file);
            // setPic(fileURL);
            const formData = new FormData();
            formData.append('file', file);
            formData.append('keyname', localStorage.getItem('uuid'));

            const requestOptions = {
                method: "POST",
                body: formData,
                redirect: "follow"
            };

            const [AUTH_URL, REPORTING_URL] = await constats();

            
            Swal.showLoading();
            let d = await fetch(REPORTING_URL+"/apiv1/fileupload", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                Swal.hideLoading();
                if (result.status == 'success') {
                    Swal.fire({
                        title: "Success!",
                        text: "Image Uploaded !!",
                        icon: "success",
                        confirmButtonText: "OK",
                    });
                    setReload(true)
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: "Something Went Wrong !!",
                        icon: "Error",
                        confirmButtonText: "OK",
                    });
                }
            })
            .catch((error) => console.error(error));
        }
    };

    const handleRemoveProfile = async() => {
        let d = await RemoveProfilePhoto({path:'https://placehold.co/400',userid:localStorage.getItem('uuid')});
        if (d.status === 'success') {
            setReload(true);
        }else if(d.status === 'error'){
            Swal.fire({
                title: "Error!",
                text: d.message,
                icon: "Error",
                confirmButtonText: "OK",
            });
        }
    }

    const handleSave = () =>{
        setIsSaving(true)
    }

    return (
        <Container fluid style={{ fontFamily: 'Times New Roman' }}>
            {true ? <Row style={{ width: "100%", padding: 0, margin: 0, marginTop:45 }}>
                <Col md="4" >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h4 style={{ marginBottom: 35, fontFamily: 'Times New Roman' }}>Profile Photo</h4>
                        <Image style={{ marginTop: 4, marginBottom: 4 }} width={180} height={180} src={pic} roundedCircle />
                        <div className="mt-4">
                        <div>Upload Your Photo</div>
                        <div className="d-flex">
                            <Button onClick={handleButtonClick} className="m-1" variant="primary" size="sm">
                                Upload
                            </Button>
                            <input
                                type="file"
                                accept="image/*"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <Button onClick={() => handleRemoveProfile()} className="m-1" variant="danger" size="sm">
                                Remove
                            </Button>
                        </div>
                        </div>
                    </div>
                </Col>
                <Col md="4" >

                    <h4 style={{ marginBottom: 35, fontFamily: 'Times New Roman' }}>Basic Information</h4>

                    <Row>
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="First name"
                                value={userDetail.firstname}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Last name"
                                value={userDetail.lastname}
                            />
                        </Form.Group>
                    </Row>

                    <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" placeholder="Username" value={userDetail.username} disabled/>
                    </Form.Group>

                    <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Role</Form.Label>
                        <Form.Control type="text" placeholder="Role" value={userDetail.role}  disabled/>
                    </Form.Group>
                </Col>
                <Col md="4" >
                    <h4 style={{ marginBottom: 35, alignItems: 'center', fontFamily: 'Times New Roman' }}>Account</h4>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="name@example.com" value={userDetail.email} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control type="password" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" />
                    </Form.Group>
                </Col>
                {/* <div className="d-flex justify-content-end">
                    <Button disabled={isSaving} onClick={() =>!isSaving ? handleSave() : null}>{isSaving ? 'Saving...' : 'Save'}</Button>
                </div> */}
            </Row> : 
            <div style={{display:'grid',placeContent:'center'}}><LoadingComponent /></div>
            }
        </Container>
    )
} 