import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

export const GetAllUserType = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/usertype',null);
}

export const CreateUserType = async (userid, type) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        userid:userid,
        type:type
    }
    return await Restapi('post',REPORTING_URL+'/apiv1/usertype',data);
}

export const UpdateUserType = async (userid, type) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        userid:userid,
        type:type
    }
    return await Restapi('put',REPORTING_URL+'/apiv1/usertype/'+userid,data);
}

export const DeleteUserType = async (userid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/usertype/'+userid,null);
}