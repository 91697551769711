import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"


// Doctro

export const TransferUserData = async (FirstUserId,SecondUserId) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "datafromuserid":FirstUserId,
        "datatouserid":SecondUserId
    });
    return await Restapi('post',REPORTING_URL+'/apiv1/usertransfer/transfer',data);
}