import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";
import { AUTH_URL } from "../constant";

export const GetAllUser = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/usermaster", null);
};

export const DeleteUser = async (userid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("delete", REPORTING_URL + "/apiv1/usermaster/user/"+userid, null);
};

export const AddUser = async (data) => {
  const [AUTH_URL, REPORTING_URL] = await constats();

  return await Restapi(
    "post",
    AUTH_URL + "/apiv1/auth/signup",
    JSON.stringify(data)
  );
};

export const EditUser = async (data) => {
  const [AUTH_URL, REPORTING_URL] = await constats();

  return await Restapi(
    "post",
    AUTH_URL + "/apiv1/auth/updateuser",
    JSON.stringify(data)
  );
};

export const AddUserMappings = async (data) => {
  const [AUTH_URL, REPORTING_URL] = await constats();

  return await Restapi(
    "post",
    REPORTING_URL + "/apiv1/usermaster/usermappings",
    JSON.stringify(data)
  );
};

export const CreateUserHrDetails = async (salary,dateofjoining,userid) => {
  const data = {
    salary,
    dateofjoining,
    userid
  }
  const [AUTH_URL, REPORTING_URL] = await constats();

  return await Restapi(
    "post",
    REPORTING_URL + "/apiv1/userhr",
    data
  );
};

export const EditUserHrDetailsByUserid = async (salary,dateofjoining,userid) => {
  const data = {
    salary,
    dateofjoining,
    userid
  }
  const [AUTH_URL, REPORTING_URL] = await constats();

  return await Restapi(
    "put",
    REPORTING_URL + "/apiv1/userhr/byuserid/"+userid,
    data
  );
};

export const CreateUserDeduction = async (deductionid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();

  return await Restapi(
    "post",
    REPORTING_URL + "/apiv1/userdeduction",
    deductionid
  );
};

export const EditUserDeduction = async (userid,deductionid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();

  return await Restapi(
    "put",
    REPORTING_URL + "/apiv1/userdeduction/"+userid,
    deductionid
  );
};