import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"


// Country Apis

export const GetAllCountries = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/country',null);
}

export const CreateCountry = async (country_code,name) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "country_code": country_code,
        "name": name
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/country',data);
}

export const UpdateCountry = async (id,country_code,name) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "country_code": country_code,
        "name": name
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/country/'+id,data);
}

export const CheckCountryAvailable = async (country_code) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/country/available/code/'+country_code,null);
}

export const CheckCountryNameAvailable = async (country_name) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/country/available/'+country_name,null);
}

export const DeleteCountry = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/country/'+id,null);
}


// State APi

export const GetAllStatesDetail = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/state/detail',null);
}

export const GetAllStatesByCountry = async (country_id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/state/states/'+country_id,null);
}

export const CheckStateNameAvailable = async (state_name) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/state/available/'+state_name,null);
}

export const DeleteState = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/state/'+id,null);
}

export const CreateState = async (country_id,name) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "country_id": country_id,
        "name": name
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/state',data);
}

export const UpdateState = async (id,country_id,name) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "country_id": country_id,
        "name": name
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/state/'+id,data);
}

// Cities

export const GetAllCitiesDetail = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/city/detail',null);
}

export const GetAllCitiesByState = async (state_id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/city/cities/'+state_id,null);
}

export const DeleteCity = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/city/'+id,null);
}

export const CheckCityNameAvailable = async (city) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/city/available/'+city,null);
}

export const CreateCity = async (state_id,name) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "state_id": state_id,
        "city": name
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/city',data);
}

export const UpdateCity = async (id,state_id,name) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "state_id": state_id,
        "city": name
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/city/'+id,data);
}

//Area

export const GetAllAreasDetail = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/area/detail',null);
}

export const GetAllAreasDetailPagination = async (page,size,search) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/area/detail/pagination?page='+page+'&size='+size+'&search='+search,null);
}

export const GetAllAreasByCity = async (cityid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/area/city/'+cityid,null);
}





export const CheckAreaNameAvailable = async (area) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/area/available/'+area,null);
}

export const CreateArea = async (city_id,area) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "city_id": city_id,
        "area": area,
        "status":"ACTIVE"
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/area',data);
}

export const UpdateArea = async (currentId,city_id,area,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "city_id": city_id,
        "area": area,
        "status":status
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/area/'+currentId,data);
}

// Bulk Update
export const AreaBulkUpdateArea = async (data) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi(
      "put",
      REPORTING_URL + "/apiv1/area/bulk",
      JSON.stringify(data)
    );
}

export const DeleteArea = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/area/'+id,null);
}

export const CreateUserTeritory = async (user,areaid,areaname,areaCode,areatype) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "userid":user,
        "city_id":areaid,
        "area":areaname,
        "areacode":areaCode,
        "areatype":areatype,
        "status":"ACTIVE"
    });
    return await Restapi('post',REPORTING_URL+'/apiv1/userteritory/create',data);
}

export const AddUserTeritory = async (user,areaid,areaCode,areatype) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "userid":user,
        "areaid":areaid,
        "areacode":areaCode,
        "areatype":areatype,
        "status":"PENDING_FOR_ADDITION"
    });
    return await Restapi('post',REPORTING_URL+'/apiv1/userteritory',data);
}

