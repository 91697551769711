import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { FaUser } from 'react-icons/fa'
import { GetUserRoiByProject, GetUserRoiByTask } from '../../utils/proxy/ReportsProxy';
import { GetAttendance } from '../../utils/proxy/AttendanceProxy';
import { TbChartInfographic } from 'react-icons/tb';

function UserRoi(selectedUserId,selectedMonth,selectedYear) {

    const [tasks,setTasks] = useState([]);
    const [taskstime,setTasksTime] = useState(0);
    const [usertaskcost,setUserTaskCost] = useState(0);

    const [present, setPresent] = useState();
    const [absent, setAbsent] = useState();
    const [holiday, setHoliday] = useState();
    const [days,setDays] = useState();

    // const [projectcount,setProjectCount] = useState(0);
    // const [projectcost,setProjectCost] = useState(0);
    // const [usercost,setuserCost] = useState(0);
    // const [totalcompletedtask,setTotalCompletedTask] = useState(0);



    useEffect(() => {
        async function getTasksRoi(userid,month,year,status) {
            let res = await GetUserRoiByTask(userid,month,year,status);
            if (res) {
                setTasks(res);
                let t = 0;
                let ut = 0;

                res.forEach(element => {
                    t = t + element.minutes;
                    ut = ut + element.usercost;
                });
                setTasksTime(t);
                setUserTaskCost(ut)
            }

            // let res2 = await GetUserRoiByProject(userid,month,year);
            // if (res2) {
            //     setProjectCount(res2.project_count);
            //     setProjectCost(res2.projectcost);
            //     setuserCost(res2.usercost);
            //     setTotalCompletedTask(res2.total_completed_tasks);
            // }
        }


        const getAttendance = async()=>{
            let start = selectedUserId.selectedYear.value+'-'+selectedUserId.selectedMonth.value+'-01';
            let a,b ;
            if(selectedUserId.selectedMonth.value == 12){
                a = (parseInt(selectedUserId.selectedYear.value) + 1).toString();
                b = '01';
            }else{
                a = selectedUserId.selectedYear.value;
                let resultString = (parseInt(selectedUserId.selectedMonth.value) + 1).toString();
                b = resultString.length === 1 ? '0' + resultString : resultString;
            }
            let end = a + '-'+b+'-01';
            let d = await GetAttendance(selectedUserId.selectedUserId,start, end);

            if(d){
                setPresent(0);
                setAbsent(0);
                setHoliday(0);
                setDays(d.length);
                d.map(item=>{
                    if(item.working == 'Present'){
                        setPresent(present=>present+1);
                    }else if(item.working == 'Absent'){
                        setAbsent(absent=>absent+1);
                    }else if(item.working == 'Holiday'){
                        setHoliday(holiday=>holiday+1);
                    }
                })
            }
        }


        if ((selectedUserId.selectedUserId !=null || selectedUserId.selectedUserId != undefined) && selectedUserId.selectedMonth.value && selectedUserId.selectedYear.value) {
          getTasksRoi(selectedUserId.selectedUserId,selectedUserId.selectedMonth.value,selectedUserId.selectedYear.value,"completed");
          getAttendance();
          }
        
    },[selectedUserId,selectedMonth,selectedYear]);


    return (
        <>
      <Row>
                    <div style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex', flexDirection: 'row', backgroundColor:'#FF8B13', borderRadius:20, padding:0,marginBottom:0,color:"white"     }}>
                        <div md={4}>
                            <div style={{ backgroundColor: '#f5dd4b', padding: '10px',marginRight:10, borderRadius: 50, height: 40, width: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <TbChartInfographic color='#000' size={30} />
                            </div>
                        </div>
                        <div md={4}>
                        <h6 style={{textAlign:'center', marginBottom:0}}>User Tasks ({selectedUserId.selectedMonth.value}-{selectedUserId.selectedYear.value})</h6>
                        </div>
                        <div md={4}>
                            {/* <Button size='sm' variant="primary">+</Button> */}
                        </div>
                    </div>
                </Row> 
                
                <Row className='mt-3'>
                    <Col md={3}>
                        <Card style={{backgroundColor:"#FC95B4"}} className='pr-card-roi'>
                            <Card.Body>
                                <p className='pr-card-roi-title' style={{fontWeight:'bold'}}>Total Task</p>
                                <p>{tasks.length}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card style={{backgroundColor:"#FFCE62"}} className='pr-card-roi'>
                            <Card.Body>
                                <p className='pr-card-roi-title' style={{fontWeight:'bold'}}>Total Task Time</p>
                                <p>{Math.floor(taskstime / 60)} / Hrs</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card style={{backgroundColor:"#D9E1FF"}} className='pr-card-roi'>
                            <Card.Body>
                                <p className='pr-card-roi-title' style={{fontWeight:'bold'}}>Avg. Task/Day</p>
                                <p>{Math.round(tasks.length /present)}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card  style={{backgroundColor:"#0BC56D"}} className='pr-card-roi'>
                            <Card.Body>
                                <p className='pr-card-roi-title' style={{fontWeight:'bold'}}>Total Task Cost</p>
                                <p>{Math.round(usertaskcost)} /-</p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
               
</>
    )
}

export default UserRoi