import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"


// Doctro

export const GetAllHospitalss = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/hospitals',null);
}

export const GetAllHospitalssPagination = async (page, size,search) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/hospitals/pagination?page='+page+'&size='+size+'&search='+search,null);
}

export const CreateHospitals = async (hospitalsname,areasid,hospitalsaddress,country,state,city,pincode,number,email) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "areas": areasid,
        "hospitalsname": hospitalsname,
        "address": hospitalsaddress,
        "countryid": country,
        "stateid": state,
        "cityid": city,
        "pincode": pincode,
        "phone": number,
        "email": email
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/hospitals',data);
}

export const UpdateHospitals = async (hospitalsid,hospitalsname,areasid,hospitalsaddress,country,state,city,pincode,number,email) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "areas": areasid,
        "hospitalsname": hospitalsname,
        "address": hospitalsaddress,
        "countryid": country,
        "stateid": state,
        "cityid": city,
        "pincode": pincode,
        "phone": number,
        "email": email
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/hospitals/'+hospitalsid,data);
}

export const UpdateHospitalsLat = async (hospitalsid,latitude,longitude) => {

    const [AUTH_URL, REPORTING_URL] = await constats();

    let data = JSON.stringify({
        "latitude": latitude,
        "longitude": longitude
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/hospitals/updatelat/'+hospitalsid,data);
}

export const HospitalBulkUpdateArea = async (data) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi(
      "put",
      REPORTING_URL + "/apiv1/hospitals/bulk",
      JSON.stringify(data)
    );
}

export const DeleteHospitals = async (hospitalsid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi('delete',REPORTING_URL+'/apiv1/hospitals/'+hospitalsid,null);
}

export const BulkDeleteHospitals = async (hospitalsid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi('delete',REPORTING_URL+'/apiv1/hospitals/bulkdelete',hospitalsid);
}

export const CreateExcelHospitals = async (jsondata) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify(jsondata);
    return await Restapi('post',REPORTING_URL+'/apiv1/hospitals/multiple/excel',data);
}


export const CheckHospitalsAvailable = async (hospitalsname) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/hospitals/available/'+hospitalsname,null);
}

export const GetHospitalDpDetails = async (userid,date) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/hospitaldp/details/${userid}/${date}`,null);
}
