import React, { useState } from 'react';
// import './FloatingActionButton.css';
import { MdOutlineSwitchAccessShortcutAdd } from "react-icons/md";
import { FaTasks } from "react-icons/fa";
import { MdOutlineAttachMoney } from "react-icons/md";
import { IoCalculator } from "react-icons/io5";
import TaskModel from './TaskModel';
import ReactDOM from 'react-dom';
import { GiNotebook } from 'react-icons/gi';
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { CreateNote } from '../../utils/proxy/NotesProxy';




const FloatingActionButton = ({ per }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  const toggleOptions = () => {
    setShowOptions(prevState => !prevState);
  };


  const openNewWindow = () => {
    const newWindow = window.open('', '_blank', 'width=335,height=515');
    ReactDOM.render(<MyComponent />, newWindow.document.body);
  };

  const handlesubmit = async () => {
    if (title == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Note Title !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (description == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Note description !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else {
      let d = await CreateNote(title, description);
      if (d.status === 'success') {
        setShowNoteModal(false);
        setTitle(null);
        setDescription(null)
        Swal.fire({
          title: "Success!",
          text: `Note Added Successfully`,
          icon: 'success',
          confirmButtonText: "OK",
        });
      } else {
        alert("Error", "Issue While Adding Note");
      }
    }
  }

  const MyComponent = () => {
    const [currentInput, setCurrentInput] = useState('');
    const [previousInput, setPreviousInput] = useState('');
    const [operation, setOperation] = useState(null);


    const appendValue = (value) => {
      if (value === '.' && currentInput.includes('.')) return;
      setCurrentInput(currentInput + value);
    };

    const handleOperation = (op) => {
      if (currentInput === '') return;
      if (previousInput !== '') {
        calculate();
      }
      setOperation(op);
      setPreviousInput(currentInput);
      setCurrentInput('');
    };

    const calculate = () => {
      let calculation;
      switch (operation) {
        case '+':
          calculation = parseFloat(previousInput) + parseFloat(currentInput);
          break;
        case '-':
          calculation = parseFloat(previousInput) - parseFloat(currentInput);
          break;
        case '*':
          calculation = parseFloat(previousInput) * parseFloat(currentInput);
          break;
        case '/':
          calculation = parseFloat(previousInput) / parseFloat(currentInput);
          break;
        default:
          return;
      }
      setCurrentInput(calculation.toString());
      setOperation(null);
      setPreviousInput('');
    };

    const clearAll = () => {
      setCurrentInput('');
      setPreviousInput('');
      setOperation(null);
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
        <div style={{ background: '#f0f0f0', padding: '20px', borderRadius: '5px' }}>
          <div style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px', minHeight: '40px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {previousInput} {operation} {currentInput}
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 60px)', gap: '10px' }}>
            {'789456123'.split('').map(digit => (
              <button key={digit} onClick={() => appendValue(digit)} style={{ padding: '20px', fontSize: '18px' }}>
                {digit}
              </button>
            ))}
            <button onClick={() => handleOperation('/')} style={{ padding: '20px', fontSize: '18px' }}>/</button>
            <button onClick={() => handleOperation('*')} style={{ padding: '20px', fontSize: '18px' }}>*</button>
            <button onClick={() => handleOperation('-')} style={{ padding: '20px', fontSize: '18px' }}>-</button>
            <button onClick={() => handleOperation('+')} style={{ padding: '20px', fontSize: '18px' }}>+</button>
            <button onClick={() => appendValue('0')} style={{ padding: '20px', fontSize: '18px' }}>0</button>
            <button onClick={() => appendValue('.')} style={{ padding: '20px', fontSize: '18px' }}>.</button>
            <button onClick={clearAll} style={{ padding: '20px', fontSize: '18px' }}>C</button>
            <button onClick={calculate} style={{ padding: '20px', fontSize: '18px', gridColumn: 'span 2' }}>=</button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="floating-button-container">
      {showOptions && (
        <div className="floating-options">
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Calculator</Tooltip>}><button onClick={openNewWindow} className="floating-option"><IoCalculator /></button></OverlayTrigger>
          {/* <button className="floating-option"><MdOutlineAttachMoney /></button> */}
          {per.VISIBLE_TASKS && <TaskModel buttontext={<FaTasks />} buttonclass={"floating-option"}  />}
          {per.VISIBLE_TASKS && <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Note</Tooltip>}><button onClick={() => setShowNoteModal(true)} className="floating-option"><GiNotebook /></button></OverlayTrigger>}
        </div>
      )}
      <button className="floating-button" onClick={toggleOptions}>
        <MdOutlineSwitchAccessShortcutAdd />
      </button>
      <Modal show={showNoteModal} onHide={() => setShowNoteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Title <span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            style={{
              height: 46,

              borderColor: '#28B3D2',
              borderWidth: 2,
              paddingHorizontal: 8,
              marginBottom: 16,
              borderTopLeftRadius: 20,
              borderBottomRightRadius: 20,
            }}
            type="text"
            placeholder="Enter Note Title"
            value={title}
            onChange={(e) => { setTitle(e.target.value) }}
          />
          <Form.Label>Description <span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            className="selectdoctor"
            as='textarea'
            placeholder='Enter Task Description'
            value={description}
            rows={3}
            onChange={(e) => { setDescription(e.target.value) }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNoteModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handlesubmit}
          >
            Add Note
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FloatingActionButton;

