import { Button, Container, Form } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { IoHomeOutline } from "react-icons/io5";
import Select from "react-select";
import Helper from "../../utils/helperFunctions";
import { useState } from "react";
import { useEffect } from "react";
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy";
import { GetAllDcrReports } from "../../utils/proxy/Dcrproxy";
import DataTable from "react-data-table-component";
import LoadingComponent from "../Includes/LoadingComponent";
import { Parser } from "@json2csv/plainjs";
import { RiFileExcel2Fill } from "react-icons/ri";
import { Op_per } from "../../utils/Permissions";

const DcrReports = () => {

    const [dcr, setDcr] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : "");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallusers = async () => {
            if (localStorage.getItem('role') === 'Admin') {
                let allusers = await AllUsers();
                setUsers(allusers);
            } else if (localStorage.getItem('role') === 'Manager') {
                let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
                setUsers(allusers);
            }
        };
        getallusers();
    }, []);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getAllDcr = async (userid, start, end) => {
            setLoading(true);
            let response = await GetAllDcrReports(
                userid,
                Helper.ConvertLocalTimeToUtc(start),
                Helper.ConvertLocalTimeToUtc(end)
            );
            if (response) {
                setDcr(response);
                setLoading(false);
                
            } else {
                alert("Error", "Issue While Fetching DCR");
            }
        };
        if (userid && startDate && endDate) {
            getAllDcr(userid, startDate, endDate);
        }
        setReload(false);
    }, [reload, userid, startDate, endDate]);

    const options = [];

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });

    const handleSelectChange = (selected) => {
        
        setSelectedUser(selected);
        setUserId(selected.value);
    };

    async function downloadCSV(data) {
        let csv = "";
        try {
            const link = document.createElement("a");
            const filename = "Reports.csv";

            if (!Array.isArray(data) || data.length === 0) {
                console.error("Data is not an array or is empty.");
                return;
            }

            csv += `Name: ${selectedUser.label}\n`;

            for (let i = 0; i < data.length; i++) {
                const dateObj = data[i];

                if (!dateObj.date) {
                    console.warn(`Date is missing for object at index ${i}. Skipping.`);
                    continue;
                }

                csv += `Date: ${dateObj.date}\n\n`;

                // Process doctors
                if (dateObj.doctors && dateObj.doctors.length > 0) {
                    const parser = new Parser();
                    csv += "Doctors\n";
                    const filteredArray = dateObj.doctors.map(subObj => {
                        const { id, userid,doctorid,createdat,signature,image, ...filteredFields } = subObj;
                        const localCreatedAt = new Date(createdat).toLocaleString();
                        return { createdat: localCreatedAt, ...filteredFields };
                    });
                    const headerDoctors = Object.keys(filteredArray[0]);
                    const optsDoctors = {
                        fields: headerDoctors,
                        transforms: [
                            // Add padding to each cell by adding extra spaces
                            (value) => {
                                return ` ${value} `.padStart(10).padEnd(10); // Add padding of 20 characters to each cell
                            }
                        ]
                    };
                    const csvDataDoctors = parser.parse(filteredArray, optsDoctors);
                    csv += csvDataDoctors + "\n\n";
                }
                // Process hospital
                if (dateObj.hospitals && dateObj.hospitals.length > 0) {
                    const parser = new Parser();
                    csv += "Hospitals\n";
                    const filteredArray = dateObj.hospitals.map(subObj => {
                        const { id, userid,hospitalid,cipid,createdat,signature,image, ...filteredFields } = subObj;
                        const localCreatedAt = new Date(createdat).toLocaleString();
                        return { createdat: localCreatedAt, ...filteredFields };
                    });
                    const headerhospitals = Object.keys(filteredArray[0]);
                    const optshospitals = {
                        fields: headerhospitals,
                        transforms: [
                            // Add padding to each cell by adding extra spaces
                            (value) => {
                                return ` ${value} `.padStart(10).padEnd(20); // Add padding of 20 characters to each cell
                            }
                        ]
                    };
                    const csvDatahospitals = parser.parse(filteredArray, optshospitals);
                    csv += csvDatahospitals + "\n\n";
                }
                // Process clinic
                if (dateObj.clinics && dateObj.clinics.length > 0) {
                    const parser = new Parser();
                    csv += "Clinics\n";
                    const filteredArray = dateObj.clinics.map(subObj => {
                        const { id, userid,clinicid,createdat,signature,image, ...filteredFields } = subObj;
                        const localCreatedAt = new Date(createdat).toLocaleString();
                        return { createdat: localCreatedAt, ...filteredFields };
                    });
                    const headerClinics = Object.keys(filteredArray[0]);
                    const optsClinics = {
                        fields: headerClinics,
                        transforms: [
                            // Add padding to each cell by adding extra spaces
                            (value) => {
                                return ` ${value} `.padStart(10).padEnd(20); // Add padding of 20 characters to each cell
                            }
                        ]
                    };
                    const csvDataClinics = parser.parse(filteredArray, optsClinics);
                    csv += csvDataClinics + "\n\n";
                }
                // Process chemist
                if (dateObj.chemists && dateObj.chemists.length > 0) {
                    const parser = new Parser();
                    csv += "Chemists\n";
                    const filteredArray = dateObj.chemists.map(subObj => {
                        const { id, userid,chemistid,createdat,signature,image, ...filteredFields } = subObj;
                        const localCreatedAt = new Date(createdat).toLocaleString();
                        return { createdat: localCreatedAt, ...filteredFields };
                    });
                    const headerchemists = Object.keys(filteredArray[0]);
                    const optschemists = {
                        fields: headerchemists,
                        transforms: [
                            // Add padding to each cell by adding extra spaces
                            (value) => {
                                return ` ${value} `.padStart(10).padEnd(20); // Add padding of 20 characters to each cell
                            }
                        ]
                    };
                    const csvDatachemists = parser.parse(filteredArray, optschemists);
                    csv += csvDatachemists + "\n\n";
                }
                // Process stockist
                if (dateObj.stockists && dateObj.stockists.length > 0) {
                    const parser = new Parser();
                    csv += "Stockists\n";
                    const filteredArray = dateObj.stockists.map(subObj => {
                        const { id, userid,stockistid,createdat,signature,image, ...filteredFields } = subObj;
                        const localCreatedAt = new Date(createdat).toLocaleString();
                        return { createdat: localCreatedAt, ...filteredFields };
                    });
                    const headerstockists = Object.keys(filteredArray[0]);
                    const optsstockists = {
                        fields: headerstockists,
                        transforms: [
                            // Add padding to each cell by adding extra spaces
                            (value) => {
                                return ` ${value} `.padStart(10).padEnd(20); // Add padding of 20 characters to each cell
                            }
                        ]
                    };
                    const csvDatastockists = parser.parse(filteredArray, optsstockists);
                    csv += csvDatastockists + "\n\n";
                }

                // Add extra newline between dates
                csv += "\n\n\n\n";
            }

            if (!csv) {
                console.error("No valid data to download.");
                return;
            }

            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`;
            }

            link.setAttribute("href", encodeURI(csv));
            link.setAttribute("download", filename);
            link.click();
        } catch (err) {
            console.error(err);
        }
    }


    const doctorColumns = [
        // {
        //   name: "Id",
        //   selector: (row) => row.id,
        //   sortable: true,
        //   margin: 30,
        //   width: "5%",
        // },
        {
            name: "Location",
            selector: (row) => <a target="_blank" href={`https://maps.google.com/?q=${row.latitude},${row.longitutde}`}>View On Map</a>,
            width: "8%",
        },
        {
            name: "Doctor Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.doctorname === "" || row.doctorname === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.doctorname === "" || row.doctorname === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.doctorname === "" || row.doctorname === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.doctorname ? row.doctorname : "N/A"}</div>,
            width: "15%",
        },
        {
            name: "Planned",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.notplanned ? "No" : "Yes" ? row.notplanned ? "No" : "Yes" : "N/A"}</div>,
            width: "10%",
        },
        {
            name: "Join Work With",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.jointworkwithusername ? row.jointworkwithusername : "N/A"}</div>,
            width: "10%",

        },
        {
            name: "Remark",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.remark === "" || row.remark === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.remark === "" || row.remark === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.remark === "" || row.remark === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.remark ? row.remark : "N/A"}</div>,
            width: "10%",
        },

        {
            name: "Created At",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "red" : "#D9E1FF", borderRadius: "10px", border: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#fff" : "#2457C5", margin: "3px", }}>{Helper.ConvertUtcToLocalTime(row.createdat) ? Helper.ConvertUtcToLocalTime(row.createdat) : "N/A"}</div>,
            width: "10%",
        },
        {
            name: "Signature",
            selector: (row) => row.signature != '' ? <img src={row.signature} alt="signature" width={50} height={50} /> : '',
            width: "10%",
        },
        {
            name: "Image",
            selector: (row) => row.image != '' ? <img src={"data:image/png;base64," + row.image} alt="image" width={50} height={50} /> : "",
            width: "10%",
        },
    ];
    const clinicColumns = [
        // {
        //   name: "Id",
        //   selector: (row) => row.id,
        //   sortable: true,
        //   margin: 30,
        //   width: "5%",
        // },
        {
            name: "Location",
            selector: (row) => <a target="_blank" href={`https://maps.google.com/?q=${row.latitude},${row.longitutde}`}>View On Map</a>,
            width: "10%",
        },
        {
            name: "Clinic Name",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.clinicname === "" || row.clinicname === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.clinicname === "" || row.clinicname === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.clinicname === "" || row.clinicname === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.clinicname ? row.clinicname : "N/A"}</div>
            ,
            width: "15%",
        },
        {
            name: "Planned",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.notplanned ? "No" : "Yes" ? row.notplanned ? "No" : "Yes" : "N/A"}</div>,
            width: "10%",
        },
        {
            name: "Join Work With",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.jointworkwithusername ? row.jointworkwithusername : "N/A"}</div>,
            width: "10%",
        },
        {
            name: "Remark",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.remark === "" || row.remark === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.remark === "" || row.remark === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.remark === "" || row.remark === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.remark ? row.remark : "N/A"}</div>,
            width: "10%",
        },

        {
            name: "Created At",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "red" : "#D9E1FF", borderRadius: "10px", border: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#fff" : "#2457C5", margin: "3px", }}>{Helper.ConvertUtcToLocalTime(row.createdat) ? Helper.ConvertUtcToLocalTime(row.createdat) : "N/A"}</div>,
            width: "10%",
        },
        {
            name: "Signature",
            selector: (row) => row.signature != '' ? <img src={row.signature} alt="signature" width={50} height={50} /> : '',
            width: "10%",
        },
        {
            name: "Image",
            selector: (row) => row.image != '' ? <img src={"data:image/png;base64," + row.image} alt="image" width={50} height={50} /> : '',
            width: "10%",
        },
    ];
    const chemistColumns = [
        // {
        //   name: "Id",
        //   selector: (row) => row.id,
        //   sortable: true,
        //   margin: 30,
        //   width: "5%",
        // },
        {
            name: "Location",
            selector: (row) => <a target="_blank" href={`https://maps.google.com/?q=${row.latitude},${row.longitutde}`}>View On Map</a>,
            width: "10%",
        },
        {
            name: "Chemist Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.chemistname === "" || row.chemistname === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.chemistname === "" || row.chemistname === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.chemistname === "" || row.chemistname === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.chemistname ? row.chemistname : "N/A"}</div>,
            width: "15%",
        },
        {
            name: "Planned",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.notplanned ? "No" : "Yes" ? row.notplanned ? "No" : "Yes" : "N/A"}</div>,
            width: "10%",
        },
        {
            name: "Join Work With",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.jointworkwithusername ? row.jointworkwithusername : "N/A"}</div>,
            width: "10%",
        },
        {
            name: "Remark",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.remark === "" || row.remark === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.remark === "" || row.remark === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.remark === "" || row.remark === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.remark ? row.remark : "N/A"}</div>,
            width: "10%",
        },

        {
            name: "Created At",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "red" : "#D9E1FF", borderRadius: "10px", border: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#fff" : "#2457C5", margin: "3px", }}>{Helper.ConvertUtcToLocalTime(row.createdat) ? Helper.ConvertUtcToLocalTime(row.createdat) : "N/A"}</div>,
            width: "10%",
        },
        {
            name: "Signature",
            selector: (row) => row.signature != '' ? <img src={row.signature} alt="signature" width={50} height={50} /> : '',
            width: "10%",
        },
        {
            name: "Image",
            selector: (row) => row.image != '' ? <img src={"data:image/png;base64," + row.image} alt="image" width={50} height={50} /> : '',
            width: "10%",
        },
    ];
    const stockistColumns = [
        // {
        //   name: "Id",
        //   selector: (row) => row.id,
        //   sortable: true,
        //   margin: 30,
        //   width: "5%",
        // },
        {
            name: "Location",
            selector: (row) => <a target="_blank" href={`https://maps.google.com/?q=${row.latitude},${row.longitutde}`}>View On Map</a>,
            width: "10%",
        },
        {
            name: "Stockist Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.stockistname === "" || row.stockistname === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.stockistname === "" || row.stockistname === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.stockistname === "" || row.stockistname === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.stockistname ? row.stockistname : "N/A"}</div>,
            width: "15%",
        },
        {
            name: "Planned",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.notplanned ? "No" : "Yes" ? row.notplanned ? "No" : "Yes" : "N/A"}</div>,
            width: "10%",
        },
        {
            name: "Join Work With",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.jointworkwithusername ? row.jointworkwithusername : "N/A"}</div>,
            width: "10%",
        },
        {
            name: "Remark",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.remark === "" || row.remark === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.remark === "" || row.remark === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.remark === "" || row.remark === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.remark ? row.remark : "N/A"}</div>,
            width: "10%",
        },

        {
            name: "Created At",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "red" : "#D9E1FF", borderRadius: "10px", border: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#fff" : "#2457C5", margin: "3px", }}>{Helper.ConvertUtcToLocalTime(row.createdat) ? Helper.ConvertUtcToLocalTime(row.createdat) : "N/A"}</div>,
            width: "10%",
        },
        {
            name: "Signature",
            selector: (row) => row.signature != '' ? <img src={row.signature} alt="signature" width={50} height={50} /> : '',
            width: "10%",
        },
        {
            name: "Image",
            selector: (row) => row.image != '' ? <img src={"data:image/png;base64," + row.image} alt="image" width={50} height={50} /> : '',
            width: "10%",
        },
    ];
    const hospitalColumns = [
        // {
        //   name: "Id",
        //   selector: (row) => row.id,
        //   sortable: true,
        //   margin: 30,
        //   width: "5%",
        // },
        {
            name: "Location",
            selector: (row) => <a target="_blank" href={`https://maps.google.com/?q=${row.latitude},${row.longitutde}`}>View On Map</a>,
            width: "10%",
        },
        {
            name: "Hospital Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.hospitalname === "" || row.hospitalname === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.hospitalname === "" || row.hospitalname === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.hospitalname === "" || row.hospitalname === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.hospitalname ? row.hospitalname : "N/A"}</div>,
            width: "15%",
        },
        {
            name: "Stockist Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.stockistname === "" || row.stockistname === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.stockistname === "" || row.stockistname === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.stockistname === "" || row.stockistname === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.stockistname ? row.stockistname : "N/A"}</div>,
            width: "15%",
        },
        {
            name: "Planned",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.notplanned ? "No" : "Yes" ? row.notplanned ? "No" : "Yes" : "N/A"}</div>,
            width: "10%",
        },
        {
            name: "Join Work With",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.jointworkwithusername ? row.jointworkwithusername : "N/A"}</div>,
            width: "10%",
        },
        {
          name: "Cip Name",
          selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.cipname === "" || row.cipname === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.cipname === "" || row.cipname === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.cipname === "" || row.cipname === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.cipname ? row.cipname : "N/A"}</div>,
          width: "10%",
        },
        {
            name: "Remark",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.remark === "" || row.remark === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.remark === "" || row.remark === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.remark === "" || row.remark === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.remark ? row.remark : "N/A"}</div>,
            width: "10%",
        },

        {
            name: "Created At",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "red" : "#D9E1FF", borderRadius: "10px", border: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#fff" : "#2457C5", margin: "3px", }}>{Helper.ConvertUtcToLocalTime(row.createdat) ? Helper.ConvertUtcToLocalTime(row.createdat) : "N/A"}</div>,
            width: "10%",
        },
        {
            name: "Signature",
            selector: (row) => row.signature != '' ? <img src={row.signature} alt="signature" width={50} height={50} /> : '',
            width: "10%",
        },
        {
            name: "Image",
            selector: (row) => row.image != '' ? <img src={"data:image/png;base64," + row.image} alt="image" width={50} height={50} /> : '',
            width: "10%",
        },
    ];

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                            
                        /></a></li>
                        <li><a href="/dailycallrecord"><span class="icon icon-beaker"> </span>Reports</a></li>

                        <li><a href="#"><span class="icon icon-double-angle-right"></span>DCR (Reports)</a></li>

                    </ul>

                </div>

            </div>

            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                        }}
                    >
                        <div style={{ width: "350px", display: Op_per.get().REPORTS_DCR_REPORTS.USER_DROPDOWN ? '' : 'none' }}>
                            <Form.Group
                                className="mb-3 d-flex w-100"
                                style={{ gap: "15px" }}
                                controlId="formBasicPassword"
                            >
                                <Form.Label className="mt-2">Users</Form.Label>
                                <div className="w-75">
                                    <Select
                                        className="selectdoctor"
                                        options={options}
                                        value={selectedUser}
                                        onChange={handleSelectChange}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div style={{ width: "500px" }}>
                            <Form.Group
                                className="mb-3 d-flex w-100"
                                style={{ gap: "15px" }}
                                controlId="formBasicPassword"
                            >
                                <Form.Label className="mt-2">Start Date</Form.Label>
                                <div className="mt-2">
                                    <DatePicker
                                        clearIcon={null}
                                        onChange={(date) => { setStartDate(Helper.AdjustTimezone(date)) }}
                                        value={startDate}
                                        format="dd-MM-yy"
                                    />
                                </div>
                                <Form.Label className="mt-2">End Date</Form.Label>
                                <div className="mt-2">
                                    <DatePicker
                                        clearIcon={null}
                                        onChange={(date) => setEndDate(Helper.AdjustTimezone(date))}
                                        value={endDate}
                                        format="dd-MM-yy"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div>
                            <Button style={{ position: 'absolute', right: 40, backgroundColor: "#28B3D2 ", display:Op_per.get().REPORTS_DCR_REPORTS.ALLOW_EXPORT_XLSX ? '' : 'none' }} onClick={() => downloadCSV(dcr)} disabled={!(userid && startDate && endDate)}>
                                <RiFileExcel2Fill />
                            </Button>
                        </div>
                    </div>
                </div>
                {loading ? <div style={{ display: 'grid', placeContent: 'center' }}><LoadingComponent /></div> :
                    <div>
                        {dcr.map((data) => (
                            <>
                                <b>{data.date}</b>
                                <div className="pt-3">
                                    <h>Doctors</h>
                                    <DataTable
                                        columns={doctorColumns}
                                        data={data.doctors}
                                        pagination
                                        style={{ marginTop: 10 }}
                                        dense
                                    />
                                </div>
                                <div className="pt-3">
                                    <h>Clinics</h>
                                    <DataTable
                                        columns={clinicColumns}
                                        data={data.clinics}
                                        pagination
                                        style={{ marginTop: 10 }}
                                        dense
                                    />
                                </div>
                                <div className="pt-3">
                                    <h>Chemists</h>
                                    <DataTable
                                        columns={chemistColumns}
                                        data={data.chemists}
                                        pagination
                                        style={{ marginTop: 10 }}
                                        dense
                                    />
                                </div>
                                <div className="pt-3">
                                    <h>Stockists</h>
                                    <DataTable
                                        columns={stockistColumns}
                                        data={data.stockists}
                                        pagination
                                        style={{ marginTop: 10 }}
                                        dense
                                    />
                                </div>
                                <div className="pt-3">
                                    <h>Hospitals</h>
                                    <DataTable
                                        so
                                        columns={hospitalColumns}
                                        data={data.hospitals}
                                        pagination
                                        style={{ marginTop: 10 }}
                                        dense
                                    />
                                </div>
                                <hr />
                            </>
                        ))}
                    </div>}
            </div>
        </Container>
    )
}

export default DcrReports;