import Restapi from "../api/Restapi";
import { constats } from "../constant";

export const createQuote = async (data) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/quote',data);
}

export const UpdateQuote = async (id,data) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/quote/'+id,data);
}

export const DeleteQuote = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/quote/'+id,null);
}

export const GetAllQuotes = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/quote',null);
}

export const GetQuoteByOwnerid = async (ownerid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/quote/owner/'+ownerid,null);
}