import React, { useRef, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { GetAllUserLocation, GetUserDCRLocation } from "../../utils/proxy/UserLocationProxy";
import Helper from "../../utils/helperFunctions";
import { IoHomeOutline } from "react-icons/io5";
import { Op_per } from "../../utils/Permissions";
import { GOOGLE_MAPS_API_KEY } from "../../utils/constant";

const Userlocation = () => {
  const [selectedUser, setSelectedUser] = useState(localStorage.getItem('role')==='User' ? {value:localStorage.getItem('uuid'),label:localStorage.getItem('firstname')+" "+localStorage.getItem('lastname')+" (Self)"} : null);
  const [users, setUsers] = useState([]);
  const [date, setDate] = useState(new Date());
  const [markerPositions, setMarkerPositions] = useState([]);
  const [userdcrloc,setUserDcrLoc] = useState([]);
  const mapRef = useRef(null); // Ref to hold the map element

  

  // Array of marker colors (in this example, represented by URLs to marker icons)
  const markerColors = [];

  useEffect(() => {
    const initMap = () => {
      // Create a new Google Map instance
      const map = new window.google.maps.Map(mapRef.current, {
        center:
          markerPositions.length > 0
            ? markerPositions[0]
            : { lat: 37.7749, lng: -122.4194 }, // Initial map center
        zoom: 17, // Initial zoom level
      });


      

      // Loop through the marker positions and create markers for each position with custom icon and label
      const markers = markerPositions.map((position, index) => {
        const { doctorname, dcrtype } = userdcrloc[index];

        return new window.google.maps.Marker({
          position: position, // Marker position
          map: map, // Attach the marker to the map
          icon: {
            labelOrigin: new window.google.maps.Point(11, 50),
            url: 'data:image/svg+xml;charset=UTF-8,' +
              '<svg width="150" height="70" xmlns="http://www.w3.org/2000/svg">' +
              '<rect width="100%" height="100%" fill="yellow"/>' +
              '<text x="50%" y="25%" font-size="12" font-weight="bold" text-anchor="middle" alignment-baseline="middle" fill="black">' +
              doctorname +
              '</text>' +
              '<text x="50%" y="50%" font-size="10" text-anchor="middle" alignment-baseline="middle" fill="black">' +
              dcrtype +
              '</text>' +
              '<text x="50%" y="75%" font-size="10" text-anchor="middle" alignment-baseline="middle" fill="black">' +
              index +
              '</text>' +
              '</svg>',
          },
        });
      });

      // Create a new polyline to join the marker positions
      const path = new window.google.maps.Polyline({
        path: markerPositions, // Array of marker positions
        geodesic: true, // Indicates if the polyline should be geodesic (curved following the curvature of the Earth)
        strokeColor: "#FF0000", // Color of the polyline
        strokeOpacity: 0.3, // Opacity of the polyline
        strokeWeight: 4, // Width of the polyline
      });

      path.setMap(map); // Attach the polyline to the map
    };
    // Load the Google Maps JavaScript API
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=initMap`;
    script.async = true;
    window.initMap = initMap; // Attach a callback function to window for when the API is loaded
    document.head.appendChild(script);

    return () => {
      // Clean up: remove the script and the callback function from window
      document.head.removeChild(script);
      delete window.initMap;
    };
  }, [markerPositions]);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getAllUserLocation = async (selectedUser, date) => {
      let response = await GetUserDCRLocation(
        selectedUser.value,
        Helper.ConvertLocalTimeToUtc(date).slice(0, 11) + "00:00:00.000Z"
      );
      if (response) {
        let markers = [];
        response
          .map((res) => {
            let option = {
              lat: parseFloat(res.lattitude),
              lng: parseFloat(res.longitude),
            };
            markers.push(option);
            setUserDcrLoc(response);
            return true;
          });
        setMarkerPositions(markers);
        
      } else {
        alert("Error", "Issue While Fetching Data");
      }
    };
    if (selectedUser && date) {
      getAllUserLocation(selectedUser, date);
    }
  }, [selectedUser, date]);


  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {
      var allusers;
      if(localStorage.getItem('role') === 'Admin'){
        allusers = await AllUsers();
      }else if(localStorage.getItem('role') === 'Manager'){
        allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
      }
      if (allusers) {
        setUsers(allusers);
      } else {
        alert("Error", "Issue While Fetching Data");
      }
    };
    if(localStorage.getItem('role') != 'User'){
      getallusers();
      }
  }, []);

  const options = [];

  users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  const handleSelectChange = (selected) => {
    setSelectedUser(selected);
  };

  return (
    <Container className="mt-2 mb-2">

<div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding:0,
          backgroundColor:'#3887BE'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/user-location"><span class="icon icon-beaker"> </span>Users</a></li>
  <li><a href="/user-location"><span class="icon icon-beaker"> </span> User Locations</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
      </div>

      <div className="d-flex">
        <div style={{ width: "350px" }}>
        {Op_per.get().USERS_USER_LOCATIONS.USER_DROPDOWN ? <Form.Group
            className="mb-3 d-flex w-100"
            style={{ gap: "15px" }}
            controlId="formBasicPassword"
          >
            <Form.Label className="mt-2">Users</Form.Label>
            <div className="w-75">
              <Select
              className="selectdoctor"
                options={options}
                value={selectedUser}
                onChange={handleSelectChange}
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </div>
          </Form.Group> : ""}
        </div>
        <div style={{ width: "350px" }}>
          <Form.Group
            className="mb-3 d-flex w-100"
            style={{ gap: "15px" }}
            controlId="formBasicPassword"
          >
            <Form.Label className="mt-2">Date</Form.Label>
            <div className="mt-2">
              <DatePicker style={{backgroundColor:"#28B3D2"}}
                onChange={(date) => setDate(Helper.AdjustTimezone(date))}
                value={date}
                format="MM-dd-yy"
              />
            </div>
          </Form.Group>
        </div>
      </div>
      <div
       
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <div style={{ height: "80vh", width: "100%" }}>
          <div ref={mapRef} style={{ height: "100%", width: "100%" }}></div>
        </div>
      </div>
    </Container>
  );
};

export default Userlocation;
