import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import {
  GetAllCips,
  GetAllCipsQualification,
  CheckDocQualificationAvailable,
  CreateDocQualification,
  UpdateDocQualification,
  DeleteDocQualification,
} from "../../utils/proxy/Cipproxy";
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { Parser } from "@json2csv/plainjs";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { HiDotsVertical } from "react-icons/hi";
import { IoHomeOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { Op_per } from "../../utils/Permissions";

function Cipqualification() {
  const [cipqualification, setCipQualification] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [show, setShow] = useState(false);
  const [city_id, setCityId] = useState();
  const [docqualification, setDocQualification] = useState("");
  const [isdocqualification, setIsDocQualification] = useState();
  const [docdesc, setDocDesc] = useState("");
  const [reload, SetReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [currentId, setCurrentId] = useState();
  const [editShow, setEditShow] = useState(false);

  const filteredData = cipqualification.filter((row) =>
    row.qualification.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getalldocqualification = async () => {
      let cipqualificationdata = await GetAllCipsQualification();
      if (cipqualificationdata) {
        setCipQualification(cipqualificationdata);
      } else {
        alert("Error", "Issue While Fetching CIP Qualification");
      }
    };
    getalldocqualification();
    SetReload(false);
  }, [reload]);

  useEffect(() => {
    const getallcountries = async (docqualification) => {
      if (docqualification.length > 0) {
        let statedata = await CheckDocQualificationAvailable(docqualification);
        if (statedata) {
          setIsDocQualification(true);
        } else {
          setIsDocQualification(false);
        }
      }
    };
    getallcountries(docqualification);
  }, [docqualification]);

  // Function to handle page change
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // Pagination configuration
  const itemsPerPage = 10; // Number of items per page
  const pageCount = Math.ceil(filteredData.length / itemsPerPage); // Total number of pages

  // Calculate the current page's items
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageItems = filteredData.slice(startIndex, endIndex);

  const handleClose = () => {setShow(false);setIsDocQualification(false)}
  const handleShow = () => setShow(true);

  const handleEditShow = (row) => {
    setDocQualification(row.qualification);
    setDocDesc(row.description);
    setCurrentId(row.id);
    setEditShow(true);
  };

  const handleEditClose = () => {setEditShow(false);setIsDocQualification(false)}

  const handleQualificationChange = (event) => {
    setDocQualification(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDocDesc(event.target.value);
  };

  let createDocQualification = async (docqualification, docdesc) => {
    let statedata = await CreateDocQualification(docqualification, docdesc);
    if (statedata) {
      SetReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: "CIP Qualification Added Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Adding CIP Qualification");
    }
  };

  let handleSave = (event) => {
    if (isdocqualification == false && docdesc.length > 0) {
      createDocQualification(docqualification, docdesc);
    }
  };

  let updateDegree = async (id, docqualification, description) => {
    let degreedata = await UpdateDocQualification(
      id,
      docqualification,
      description
    );
    if (degreedata) {
      SetReload(true);
      setEditShow(false);

      Swal.fire({
        title: "Success!",
        text: "CIP Qualification Update Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
      setCurrentId(null);
      setDocQualification('');
      setDocDesc('');
      setIsDocQualification(false)
    } else {
      alert("Error", "Issue While Updating CIP Qualification");
    }
  };

  let handleedit = (event) => {
    updateDegree(currentId, docqualification, docdesc);
  };

  let deleteDegree = async (id) => {
    let degreedelete = await DeleteDocQualification(id);

    if (degreedelete) {
      SetReload(true);

      Swal.fire({
        title: "Success!",
        text: "CIP Qualification Deleted Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Deleting CIP Qualification");
    }
  };

  let handledelete = (id) => {
    deleteDegree(id);
  };

  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   width:"5%",
    // },
    {
      name: "Qualification",
      selector: (row) =>
      <div style={{ whiteSpace: 'normal',backgroundColor: row.qualification === "" || row.qualification === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.qualification === "" || row.qualification === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.qualification === "" || row.qualification === null ? "#fff": "#000", margin:"3px", }}>{row.qualification ? row.qualification : "N/A"}</div> ,
        sortable: true,
        width:"10%",    
      
    },
    {
      name: "Description",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor:  row.description === "" ||  row.description === null ? "red":"#D9E1FF", borderRadius:"10px", border:  row.description === "" ||  row.description === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color: row.description === "" ||  row.description === null ? "#fff": "#000", margin:"3px", }}>{ row.description ?  row.description : "N/A"}</div>,
      width:"30%",
    },
//     {
//       name: "Actions",
//       selector: (row) => (
//         <div key={row.id}>

// <AiFillDelete
//             onClick={() => handledelete(row.id)}
//             style={{ color: "red", fontSize: 20 }}
//           />
//            &nbsp;&nbsp;&nbsp;
//           <AiTwotoneEdit
//             style={{ color: "black", fontSize: 20 }}
//             onClick={() => handleEditShow(row)}
//           />
         
          
//         </div>
//       ),
//     },
  ];

  if(Op_per.get().OPTIONS_CIP_QUALIFICATION.ALLOW_DELETE || Op_per.get().OPTIONS_CIP_QUALIFICATION.ALLOW_EDIT){
    columns.push({
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>

<AiFillDelete
            onClick={() => handledelete(row.id)}
            style={{ color: "red", fontSize: 20, display:Op_per.get().OPTIONS_CIP_QUALIFICATION.ALLOW_DELETE ? '' : 'none'}}
          />
           &nbsp;&nbsp;&nbsp;
          <AiTwotoneEdit
            style={{ color: "black", fontSize: 20, display:Op_per.get().OPTIONS_CIP_QUALIFICATION.ALLOW_EDIT ? '' : 'none' }}
            onClick={() => handleEditShow(row)}
          />
         
          
        </div>
      ),
    })
  }

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  async function downloadCSV(cipqualification) {
    let csv;
    try {
      const opts = {
        fields: ["id", "qualification", "description"],
      };
      const parser = new Parser(opts);
      csv = parser.parse(cipqualification);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(cipqualification)} />,
    [cipqualification]
  );
  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding:0,
          backgroundColor:'#3887BE'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
          /></a></li>
  <li><a href="/cip-qualification"><span class="icon icon-beaker"> </span>Options</a></li>
  <li><a href="/cip-qualification"><span class="icon icon-beaker"> </span>CIP Qualification Master</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
        
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Form.Control
             style={{height: 46,
            width:250,
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
              className="glass"
              placeholder="Search Qualification"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div>
            <Button style={{backgroundColor: "#28B3D2 ", display:Op_per.get().OPTIONS_CIP_QUALIFICATION.ALLOW_ADD ? '' : 'none' }} onClick={handleShow}>
            <FaPlus />
            </Button>
          </div>
        </div>

        <div className="pt-3">
          <DataTable
            columns={columns}
            data={filteredData}
            // selectableRows
            // onSelectedRowsChange={handleChange}
            // clearSelectedRows={toggledClearRows}
            //  expandableRows
            //  expandableRowsComponent={ExpandedComponent}
            // actions={actionsMemo}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
            style={{ marginTop: 10 }}
            dense
          />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add CIP Qualification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Qualification <span style={{color:"red"}}>*</span></Form.Label>

              <Form.Control
                style={{
                  backgroundColor: isdocqualification ? "red" : "",
                  color: isdocqualification ? "#fff" : "",
                  height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,
                }}
                type="text"
                onChange={handleQualificationChange}
                placeholder="Enter Qualification"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Description <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
               style={{height: 46,
            
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                type="text"
                onChange={handleDescriptionChange}
                placeholder="Enter Description"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={isdocqualification ? true : false}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={editShow} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Cip Qualification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Qualification <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
               style={{height: 46,
            
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                type="text"
                onChange={handleQualificationChange}
                placeholder="Enter Qualification"
                value={docqualification}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Description <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
               style={{height: 46,
            
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                type="text"
                value={docdesc}
                onChange={handleDescriptionChange}
                placeholder="Enter Description"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleedit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Cipqualification;
