import React, { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Table } from 'react-bootstrap';


const PdfGenerator = (({data}) => {

    function calculateDiscount(originalPrice, discountPercentage) {

        const discountAmount = (originalPrice * discountPercentage) / 100;
        const finalPrice = originalPrice - discountAmount;
        return finalPrice;
    }

  return (
    <div>
      <div style={{ padding: '20px', backgroundColor: '#fff', margin:'20px' }}>
        <div style={{margin:'20px 20px 40px 20px'}}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <img src='images/nav.png' width={270} height={100} alt="Team Insights" />
                </div>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <div>
                        <h2>Quotation</h2>
                        <div>Date Created : {new Date(data.createdat).toLocaleDateString('en-IN')}</div>
                        <div>Valid Till : {new Date(data.duedate).toLocaleDateString('en-IN')}</div>
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <div style={{margin:'20px 20px 40px 20px'}}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <div style={{maxWidth:320}}>
                    <div>To :</div>
                    <div style={{fontWeight:'bold'}}>{`${data.salutation} ${data.firstname} ${data.lastname}, ${data.title}`} ,</div>
                    <div>{data.company} ,</div>
                    <div>{`${data.street} ${data.city} ${data.state} ${data.country}`} ,</div>
                    <div>Tax Id </div>
                </div>
                <div style={{maxWidth:300}}>
                    <div>From :</div>
                    <div style={{fontWeight:'bold'}}>Company Name </div>
                    <div>Address Company Name here Company Name here Company Name here </div>
                    <div>Email</div>
                    <div>Telephone No</div>
                </div>
            </div>
        </div>

        <div style={{margin:'20px 20px 40px 20px'}}>
        <div>
            <h3>Products : </h3>
        </div>
        <table style={{width:'100%',borderCollapse:'collapse',margin:'20px 0',fontSize:'16px',textAlign:'left'}}>
                <thead>
                    <tr>
                        <th style={{padding:'12px',borderBottom:'1px solid #ddd',background:'#f2f2f2',fontWeight:'bold'}}>Product Name</th>
                        <th style={{padding:'12px',borderBottom:'1px solid #ddd',background:'#f2f2f2',fontWeight:'bold'}}>Qty</th>
                        <th style={{padding:'12px',borderBottom:'1px solid #ddd',background:'#f2f2f2',fontWeight:'bold'}}>Price</th>
                        <th style={{padding:'12px',borderBottom:'1px solid #ddd',background:'#f2f2f2',fontWeight:'bold'}}>Discount</th>
                        <th style={{padding:'12px',borderBottom:'1px solid #ddd',background:'#f2f2f2',fontWeight:'bold'}}>Total</th>
                        <th style={{padding:'12px',borderBottom:'1px solid #ddd',background:'#f2f2f2',fontWeight:'bold'}}>Tax Price</th>
                    </tr>
                </thead>
                <tbody>
                {data.products.map((d, index) => (
                                <tr key={index}>
                                    <td style={{padding:'12px',borderBottom:'1px solid #ddd',background:index%2==0 ? '#f9f9f9':'#fff'}}>{d.productname}</td>
                                    <td style={{padding:'12px',borderBottom:'1px solid #ddd',background:index%2==0 ? '#f9f9f9':'#fff'}}>{d.quantity}</td>
                                    <td style={{padding:'12px',borderBottom:'1px solid #ddd',background:index%2==0 ? '#f9f9f9':'#fff'}}>{d.price}</td>
                                    <td style={{padding:'12px',borderBottom:'1px solid #ddd',background:index%2==0 ? '#f9f9f9':'#fff'}}>{d.discount}</td>
                                    <td style={{padding:'12px',borderBottom:'1px solid #ddd',background:index%2==0 ? '#f9f9f9':'#fff'}}>{d.total}</td>
                                    <td style={{padding:'12px',borderBottom:'1px solid #ddd',background:index%2==0 ? '#f9f9f9':'#fff'}}>{d.taxprice}</td>
                                </tr>
                            ))}
                <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{padding:'12px',borderBottom:'1px solid #ddd'}} colSpan="2">Grand Total - {calculateDiscount(data.products.reduce((sum, item) => sum + parseFloat(item.total || 0), 0),data.products.reduce((sum, item) => sum + parseFloat(item.discount || 0), 0))}</td>
                            </tr>

                </tbody>
            </table>
        </div>

        <div style={{margin:'20px 20px 40px 20px'}}>
            <div>
                Notes: In case of any query feel free to revert back.
            </div>
        </div>

        <div style={{margin:'20px 20px 40px 20px'}}>
            <div>Bank Details:</div>
            <div>Account Name: Webzworld || Bank Name: HDFC Bank || Current Account : 50200057805777</div>
            <div>Branch: Charni Road || IFSC: HDFC0000356</div>
        </div>
      </div>
    </div>
  );
});

export default PdfGenerator;
