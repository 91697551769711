import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { GetAllCountries, CheckCountryAvailable, CheckCountryNameAvailable, CreateCountry, DeleteCountry, UpdateCountry } from '../../utils/proxy/Countryproxy'
import ReactPaginate from 'react-paginate';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { Parser } from "@json2csv/plainjs";
import { HiDotsVertical } from "react-icons/hi";
import { IoHomeOutline } from 'react-icons/io5';
import { FaPlus } from 'react-icons/fa6';
import { Op_per } from '../../utils/Permissions';


function Countrymaster() {

    const [countries, setCountries] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [countrycode, setCountryCode] = useState('');
    const [countryname, setCountryName] = useState('');
    const [iscountrycode, setIsCountryCode] = useState();
    const [iscountryname, setIsCountryName] = useState();
    const [reload,SetReload] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [id, setId] = useState();

    const filteredData = countries.filter((row) =>
    row.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallcountries = async () => {
            let countriesdata = await GetAllCountries();
            if (countriesdata) {
                setCountries(countriesdata);
            } else {
                alert('Error', 'Issue While Fetching Countries');
            }
        }
        getallcountries();
        SetReload(false);

    }, [reload]);

    useEffect(() => {

        const getallcountries = async (countrycode) => {
            if (countrycode.length > 0) {
                let countriesdata = await CheckCountryAvailable(countrycode);
                if (countriesdata) {
                    setIsCountryCode(true);
                } else {
                    setIsCountryCode(false);
                }
            }

        }
        getallcountries(countrycode);


    }, [countrycode]);

    useEffect(() => {
        const getallcountries = async (countryname) => {
            if (countryname.length > 0) {
                let countriesdata = await CheckCountryNameAvailable(countryname);
                if (countriesdata) {
                    setIsCountryName(true);
                } else {
                    setIsCountryName(false);
                }
            }

        }
        getallcountries(countryname);
       

    }, [countryname]);


    // Function to handle page change
    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    // Pagination configuration
    const itemsPerPage = 10; // Number of items per page
    const pageCount = Math.ceil(filteredData.length / itemsPerPage); // Total number of pages

    // Calculate the current page's items
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageItems = filteredData.slice(startIndex, endIndex);

    const handleClose = () => {setShow(false);setIsCountryName(false);setIsCountryCode(false)}
    const handleShow = () => setShow(true);

    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
    };

    const handleCountryNameChange = (event) => {
        setCountryName(event.target.value);
    };

    let createCountry = async (countrycode, countryname) => {
        let countriesdata = await CreateCountry(countrycode, countryname);
        if (countriesdata) {
            
            
            setShow(false);
            
            SetReload(true);
           
            Swal.fire({
                title: 'Success!',
                text: 'Country Added Successfully !!',
                icon: 'success',
                confirmButtonText: 'OK'
              });
        } else {
            alert('Error', 'Issue While Adding Country');
        }
    }

    const handledelete = async(id) =>{
      let d = await DeleteCountry(id);
      if(d.status === 'success'){
        SetReload(true);
        Swal.fire({
          title: 'Success!',
          text: 'Country Deleted Successfully !!',
          icon: 'success',
          confirmButtonText: 'OK'
        });
      }else{
        Swal.fire({
          title: 'Warning!',
          text: 'Something Went Wrong !!',
          icon: 'warning',
          confirmButtonText: "OK",
        });
      }
    }

    const handleEdit = async() =>{
      let d = await UpdateCountry(id,countrycode,countryname);
      if(d){
        Swal.fire({
          title: 'Success!',
          text: 'Country Updated Successfully !!',
          icon: 'success',
          confirmButtonText: 'OK'
        });
        SetReload(true)
        setId(null);
        setCountryCode(null);
        setCountryName(null);
        setIsCountryCode(false);
        setIsCountryName(false);
        setShowEdit(false)
      }else{
        Swal.fire({
          title: "Warning!",
          text: "Something Went Wrong !!",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    }

    const handleEditShow = (row) =>{
      setId(row.id);
      setCountryCode(row.country_code);
      setCountryName(row.name);
      setShowEdit(true)
    }

    let handleSave = (event) => {
        if (iscountrycode == false && iscountryname == false && countrycode.length > 0 && countryname.length > 0) {
           
            createCountry(countrycode, countryname);

        }
    }

     const columns = [
      //  {
      //    name: "Id",
      //    selector: (row) => row.id,
      //  
      //    width:"5%",
      //  },
       {
         name: "Country Code",
         selector: (row) =><div style={{ whiteSpace: 'normal',backgroundColor:  row.country_code === "" ||  row.country_code === null ? "red":"#D9E1FF", borderRadius:"10px", border:  row.country_code === "" ||  row.country_code === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color: row.country_code === "" ||  row.country_code === null ? "#fff": "#000", margin:"3px", }}>{ row.country_code ?  row.country_code : "N/A"}</div> ,
       
         width:"10%",
   
       },
       {
         name: "Name",
         selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.name === "" || row.name === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.name === "" || row.name === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.name === "" || row.name === null ? "#fff": "#000", margin:"3px", }}>{row.name ? row.name : "N/A"}</div> ,
       
         width:"15%",
       },
      //  {
      //    name: "Actions",
      //    selector: (row) => (
      //      <div key={row.id}>
      //        <AiFillDelete
      //          onClick={() => handledelete(row.id)}
      //          style={{ color: "red", fontSize: 20 }}
      //        />
      //        &nbsp;&nbsp;&nbsp;
      //        <AiTwotoneEdit
      //          onClick={() => handleEditShow(row)}
      //          style={{ color: "black", fontSize: 20 }}
      //        />
      //      </div>
      //    ),
      //  },
     ];

     if(Op_per.get().OPTIONS_COUNTRY_MASTER.ALLOW_DELETE || Op_per.get().OPTIONS_COUNTRY_MASTER.ALLOW_EDIT){
      columns.push({
        name: "Actions",
        selector: (row) => (
          <div key={row.id}>
            <AiFillDelete
              onClick={() => handledelete(row.id)}
              style={{ color: "red", fontSize: 20, display:Op_per.get().OPTIONS_COUNTRY_MASTER.ALLOW_DELETE ? '' : 'none' }}
            />
            &nbsp;&nbsp;&nbsp;
            <AiTwotoneEdit
              onClick={() => handleEditShow(row)}
              style={{ color: "black", fontSize: 20, display:Op_per.get().OPTIONS_COUNTRY_MASTER.ALLOW_EDIT ? '' : 'none' }}
            />
          </div>
        ),
      })
    }

     const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
     const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

     async function downloadCSV(countries) {
       let csv;
       try {
         const opts = {
           fields: ["id", "country_code", "name"],
         };
         const parser = new Parser(opts);
         csv = parser.parse(countries);
       } catch (err) {
         console.error(err);
       }

       const link = document.createElement("a");

       if (csv == null) return;

       const filename = "export.csv";

       if (!csv.match(/^data:text\/csv/i)) {
         csv = `data:text/csv;charset=utf-8,${csv}`;
       }

       link.setAttribute("href", encodeURI(csv));
       link.setAttribute("download", filename);
       link.click();
     }

     const Export = ({ onExport }) => (
       <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
     );

     const actionsMemo = React.useMemo(
       () => <Export onExport={() => downloadCSV(countries)} />,
       [countries]
     );


    return (
      <Container fluid style={{ minHeight: "80vh" }}>
        <div
          className="glass-body"
          style={{
            margin: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding:0,
          backgroundColor:'#3887BE'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/country-master"><span class="icon icon-beaker"> </span>Options</a></li>
  <li><a href="/country-master"><span class="icon icon-beaker"> </span>Country Master</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
         
        </div>

        <div className="glass-body" style={{ margin: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Form.Control
              style={{height: 46,
            width:250,
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                className="glass"
                placeholder="Search"
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div>
              <Button
                style={{backgroundColor: "#28B3D2 ", display:Op_per.get().OPTIONS_COUNTRY_MASTER.ALLOW_ADD ? '' : 'none' }}
                onClick={handleShow}
              >
                <FaPlus />
              </Button>
            </div>
          </div>

          <div style={{ marginTop: 10 }}>
            <DataTable
              columns={columns}
              data={filteredData}
              // progressPending={pending}
              pagination
              customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
              // actions={actionsMemo}
              style={{ marginTop: 10 }}
              dense
            />
          </div>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Country</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Country Code <span style={{color:"red"}} >*</span></Form.Label>
                <Form.Control
                  style={{
                    backgroundColor: iscountrycode ? "red" : "",
                    color: iscountrycode ? "#fff" : "",
                    height: 46,
            
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,
                  }}
                  type="text"
                  onChange={handleCountryCodeChange}
                  placeholder="Enter Country Code"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Country Name <span style={{color:"red"}} >*</span></Form.Label>
                <Form.Control
                  style={{
                    backgroundColor: iscountryname ? "red" : "",
                    color: iscountryname ? "#fff" : "",
                    height: 46,
           
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,
                  }}
                  type="text"
                  onChange={handleCountryNameChange}
                  placeholder="Enter Country Name"
                  required
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={handleSave}
              disabled={iscountryname || iscountrycode ? true : false}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showEdit} onHide={()=>{setShowEdit(false);setIsCountryCode(false);setIsCountryName(false)}}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Country</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Country Code <span style={{color:"red"}} >*</span></Form.Label>
                <Form.Control
                  style={{
                    backgroundColor: "",
                    color: "",
                    height: 46,
            
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,
                  }}
                  type="text"
                  value={countrycode}
                  onChange={(e)=>setCountryCode(e.target.value)}
                  placeholder="Enter Country Code"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Country Name <span style={{color:"red"}} >*</span></Form.Label>
                <Form.Control
                  style={{
                    backgroundColor: "",
                    color: "",
                    height: 46,
           
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,
                  }}
                  type="text"
                  value={countryname}
                  onChange={(e) => setCountryName(e.target.value)}
                  placeholder="Enter Country Name"
                  required
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{setShowEdit(false);setIsCountryCode(false);setIsCountryName(false)}}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={handleEdit}
            >
              Edit
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
}

export default Countrymaster
