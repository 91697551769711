import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { GetAllCitiesDetail,GetAllStatesDetail,CheckCityNameAvailable,CreateCity, DeleteCity, UpdateCity } from '../../utils/proxy/Countryproxy'
import ReactPaginate from 'react-paginate';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { Parser } from "@json2csv/plainjs";
import { HiDotsVertical } from 'react-icons/hi';
import { IoHomeOutline } from 'react-icons/io5';
import { FaPlus } from 'react-icons/fa6';
import { Op_per } from '../../utils/Permissions';

function Citymaster() {

    const [citys, setCitys] = useState([]);
    const [states, setStates] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [state_id, setStateId] = useState();
    const [cityname, setCityName] = useState('');
    const [iscityname, setIsCityName] = useState();
    const [reload, SetReload] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [id, setId] = useState();

    const filteredData = citys.filter((row) =>
    row.cityname.toLowerCase().includes(searchQuery.toLowerCase())
  );


      useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallcitys = async () => {
            let citysdata = await GetAllCitiesDetail();
            if(citysdata){    
                setCitys(citysdata);
            }else{
              alert('Error', 'Issue While Fetching Cities');
            }
          }
          getallcitys();
          SetReload(false);
      }, [reload]); 

      useEffect(() => {
        if (show || showEdit) {
        // Fetch data from an API when the component mounts
        const getallstates = async () => {
            let statesdata = await GetAllStatesDetail();
            if (statesdata) {
                setStates(statesdata);
            } else {
                alert('Error', 'Issue While Fetching States');
            }
        }
        getallstates();
    }
    }, [show,showEdit]);

    useEffect(() => {
        const getallcountries = async (cityname) => {
            if (cityname.length > 0) {
                let statedata = await CheckCityNameAvailable(cityname);
                if (statedata) {
                    setIsCityName(true);
                } else {
                    setIsCityName(false);
                }
            }

        }
        getallcountries(cityname);
       

    }, [cityname]);


      // Function to handle page change
    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    // Pagination configuration
  const itemsPerPage = 10; // Number of items per page
  const pageCount = Math.ceil(filteredData.length / itemsPerPage); // Total number of pages

  // Calculate the current page's items
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageItems = filteredData.slice(startIndex, endIndex);

  const handleClose = () => {setShow(false);setIsCityName(false)}
    const handleShow = () => setShow(true);


    const handleCountryCodeChange = (event) => {
        setStateId(event.target.value);
    };

    const handleCountryNameChange = (event) => {
        setCityName(event.target.value);
    };

    let createState = async (state_id, cityname) => {
        let statedata = await CreateCity(state_id, cityname);;
        if (statedata) {
            SetReload(true);
            setShow(false);
            

            Swal.fire({
                title: 'Success!',
                text: 'City Added Successfully !!',
                icon: 'success',
                confirmButtonText: 'OK'
            });
        } else {
            alert('Error', 'Issue While Adding City');
        }
    }

    const handledelete = async(id) =>{
      let d = await DeleteCity(id);
      if(d.status === 'success'){
        Swal.fire({
          title: 'Success!',
          text: 'City Deleted Successfully !!',
          icon: 'success',
          confirmButtonText: 'OK'
      });
        SetReload(true);
      }else{
        Swal.fire({
          title: 'Warning!',
          text: 'Something Went Wrong !!',
          icon: 'warning',
          confirmButtonText: "OK",
        });
      }
    }

    const handleEdit = async() =>{
      let d = await UpdateCity(id,state_id, cityname);
    if(d){
      Swal.fire({
        title: "Success!",
        text: "City Updated Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
      SetReload(true);
      setShowEdit(false);
      setId();
      setStateId(null);
      setCityName('');
      setIsCityName(false);
    }else{
      Swal.fire({
        title: "Warning!",
        text: "Something Went Wrong !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
    }

    const handleEditShow = (row) =>{
      setShowEdit(true);
      setId(row.id);
      setCityName(row.cityname);
      setStateId(row.state_id)
    }

    let handleSave = (event) => {
        if ( iscityname == false &&  cityname.length > 0) {

            createState(state_id, cityname);

        }
    }

    const columns = [
      // {
      //   name: "Id",
      //   selector: (row) => row.id,
      //   sortable: true,
      //   width:"5%",
      // },
      {
        name: "Country Code",
        selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor:  row.countrycode === "" ||  row.countrycode === null ? "red":"#D9E1FF", borderRadius:"10px", border:  row.countrycode === "" ||  row.countrycode === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color: row.countrycode === "" ||  row.countrycode === null ? "#fff": "#000", margin:"3px", }}>{ row.countrycode ?  row.countrycode : "N/A"}</div> ,
    
        width:"10%",
      },
      {
        name: "Country Name",
        selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor:  row.countryname === "" ||  row.countryname === null ? "red":"#D9E1FF", borderRadius:"10px", border:  row.countryname === "" ||  row.countryname === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color: row.countryname === "" ||  row.countryname === null ? "#fff": "#000", margin:"3px", }}>{ row.countryname ?  row.countryname : "N/A"}</div> ,
    
        width:"10%",
      },
      {
        name: "State Name",
        selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor:   row.statename === "" ||   row.statename === null ? "red":"#D9E1FF", borderRadius:"10px", border:   row.statename === "" ||   row.statename === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:  row.statename === "" ||   row.statename === null ? "#fff": "#000", margin:"3px", }}>{  row.statename ?   row.statename : "N/A"}</div> ,
    
        width:"20%",
      },
      {
        name: "City Name",
        selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor:   row.cityname === "" ||   row.cityname === null ? "red":"#D9E1FF", borderRadius:"10px", border:   row.cityname === "" ||   row.cityname === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:  row.cityname === "" ||   row.cityname === null ? "#fff": "#000", margin:"3px", }}>{  row.cityname ?   row.cityname : "N/A"}</div> ,
    
        width:"20%",
      },
      // {
      //   name: "Actions",
      //   selector: (row) => (
      //     <div key={row.id}>
      //       <AiFillDelete
      //         onClick={() => handledelete(row.id)}
      //         style={{ color: "red", fontSize: 20 }}
      //       />
      //       &nbsp;&nbsp;&nbsp;
      //       <AiTwotoneEdit
      //         onClick={() => handleEditShow(row)}
      //         style={{ color: "black", fontSize: 20 }}
      //       />
      //     </div>
      //   ),
      // },
    ];

    if(Op_per.get().OPTIONS_CITY_MASTER.ALLOW_DELETE || Op_per.get().OPTIONS_CITY_MASTER.ALLOW_EDIT){
      columns.push({
        name: "Actions",
        selector: (row) => (
          <div key={row.id}>
            <AiFillDelete
              onClick={() => handledelete(row.id)}
              style={{ color: "red", fontSize: 20, display:Op_per.get().OPTIONS_CITY_MASTER.ALLOW_DELETE ? '' : 'none' }}
            />
            &nbsp;&nbsp;&nbsp;
            <AiTwotoneEdit
              onClick={() => handleEditShow(row)}
              style={{ color: "black", fontSize: 20, display:Op_per.get().OPTIONS_CITY_MASTER.ALLOW_EDIT ? '' : 'none' }}
            />
          </div>
        ),
      })
    }

    const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
    const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    async function downloadCSV(citys) {
      let csv;
      try {
        const opts = {
          fields: ["id", "countrycode", "countryname", "statename", "cityname"],
        };
        const parser = new Parser(opts);
        csv = parser.parse(citys);
      } catch (err) {
        console.error(err);
      }

      const link = document.createElement("a");

      if (csv == null) return;

      const filename = "export.csv";

      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }

      link.setAttribute("href", encodeURI(csv));
      link.setAttribute("download", filename);
      link.click();
    }

    const Export = ({ onExport }) => (
      <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
    );

    const actionsMemo = React.useMemo(
      () => <Export onExport={() => downloadCSV(citys)} />,
      [citys]
    );


  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding:0,
          backgroundColor:'#3887BE'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/city-master"><span class="icon icon-beaker"> </span>Options</a></li>
  <li><a href="/city-master"><span class="icon icon-beaker"> </span>City Master</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
        
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Form.Control
            style={{height: 46,
            width:250,
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
              className="glass"
              placeholder="Search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div>
            <Button style={{backgroundColor: "#28B3D2 ", display:Op_per.get().OPTIONS_CITY_MASTER.ALLOW_ADD ? '' : 'none' }} onClick={handleShow}>
            <FaPlus />
            </Button>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <DataTable
            columns={columns}
            data={filteredData}
            // progressPending={pending}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
            // actions={actionsMemo}
            style={{ marginTop: 10 }}
            dense
          />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add City</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>State <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={handleCountryCodeChange} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select State---</option>
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.statename}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>City Name <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
                style={{
                  backgroundColor: iscityname ? "red" : "",
                  color: iscityname ? "#fff" : "",
                  height: 46,
           
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,
                }}
                type="text"
                onChange={handleCountryNameChange}
                placeholder="Enter City Name"
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={iscityname ? true : false}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEdit} onHide={()=>{setShowEdit(false);setIsCityName(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Edit City</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>State <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={handleCountryCodeChange} value={state_id} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option disabled>---Select State---</option>
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.statename}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>City Name <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
                style={{
                  backgroundColor: "",
                  color: "",
                  height: 46,
           
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,
                }}
                type="text"
                value={cityname}
                onChange={handleCountryNameChange}
                placeholder="Enter City Name"
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setShowEdit(false);setIsCityName(false)}}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleEdit}
          >
            Edit
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Citymaster