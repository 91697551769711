import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { GetAllBifurcationCounts, GetAllBifurcationCountsUser } from '../utils/proxy/ChartsProxy';
import CardDash from './Common/CardDash';
import { EndDay, StartDay } from '../utils/proxy/Tourplanproxy';
import Swal from 'sweetalert2';
import PerfomanceChart from './Includes/PerfomanceChart';
import SubmissionTrendChart from './Includes/SubmissionTrendChart';
import InternalExternalChart from './Includes/InternalExternalChart';
import TaskStatusChart from './Includes/TaskStatusChart';
import TaskPendingChart from './Includes/TaskPendingChart';
import Select from "react-select";
import { AllUsers, GetAllManagerUsers } from '../utils/proxy/Authproxy';
import { GetAllProjectsByUserid } from '../utils/proxy/ProjectProxy';
import { CreateTask, GetAllTaskCategory } from '../utils/proxy/Taskproxy';
import Helper from '../utils/helperFunctions';

function UserDashboard() {

  const [chartData, setChartData] = useState([]);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [weatherlocation, setWeatherLocation] = useState();
  const [weatherlocationregion, setWeatherLocationRegion] = useState();
  const [currentweather, setCurrentWeather] = useState();
  const [currentweathercondition, setCurrentWeatherCondition] = useState();
  const [currentweathericon, setCurrentWeatherIcon] = useState();
  const [currentweatherwind, setCurrentWeatherWind] = useState();
  const [currentweatherhumidity, setCurrentWeatherHumidity] = useState();
  const [currentweatheruv, setCurrentWeatherUv] = useState();
  const [date, setDate] = useState(new Date());
  const [startDay, setStartDay] = useState();
  const [remark, setRemark] = useState();
  const [show, setShow] = useState(false);

  const [users, setusers] = useState([])
  const [allProject, setAllProject] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(localStorage.getItem('role')==='User' ? {value:localStorage.getItem('uuid'),label:localStorage.getItem('firstname')+" "+localStorage.getItem('lastname')+" (Self)"} : null);
  const [selectedUserId, setSelectedUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [showaddtask, setshowaddtask] = useState(false);
  const [reload,setReload] = useState(false);
  const [utcStartDate, setUtcStartDate] = useState('');
  const [utcEndDate, setUtcEndDate] = useState('');
  const [task, setTask] = useState({
    "id": null,
    "userid": selectedUserId,
    "task": null,
    "projectid": selectedProjectId,
    "categoryid": selectedCategoryId,
    "start": null,
    "end": null,
    "description": null,
    "createdby": localStorage.getItem('uuid'),
    "priority": null,
    "status": "new"
  });

  useEffect(() => {
    handleSearch();
  }, []);


  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    })
  }, []);

  useEffect(() => {

    const getAllUsersData = async () => {
      if (localStorage.getItem('role') === 'Admin') {
        let allusers = await AllUsers();
        setusers(allusers);
      } else if (localStorage.getItem('role') === 'Manager') {
        let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
        setusers(allusers);
      }
    };
    getAllUsersData();
  }, []);

  useEffect(() => {
    async function getProjects() {
      let data = await GetAllProjectsByUserid(selectedUserId);
      if (data) {
        setAllProject(data);
      } else {
        alert('Unable to Fetch');
      }
    }

    async function getCategories() {
      let data = await GetAllTaskCategory();
      if (data) {
        setAllCategories(data);
      } else {
        alert('Unable to Fetch');
      }
    }

    getProjects();
    getCategories();
  }, [selectedUserId])







  const handleSearch = async () => {
    let response = await GetAllBifurcationCountsUser(localStorage.getItem('uuid'));
    if (response) {
      setChartData(response);
    }
  };

  function remoteadd() {
    const st = new Date();
    const ed = new Date();
    ed.setHours(ed.getHours() + 1);
    setshowaddtask(true)
    setUtcStartDate(Helper.dateToIsoStringWithoutChange(st).substr(0,16))
    setUtcEndDate(Helper.dateToIsoStringWithoutChange(ed).substr(0,16))
    setTask({...task, start: new Date().toISOString(), end:new Date().toISOString(), priority:'medium'})
  }

  const handleSelectChange = (selected) => {
    setSelectedUsers(selected);
    setTask({ ...task, userid: selected.value });
    setSelectedUserId(selected.value);
  };

  const handleProjectChange = (selected) => {
    setTask({ ...task, projectid: selected.value });
    setSelectedProject(selected);
    setSelectedProjectId(selected.value);
  };

  const handleCategoryChange = (selected) => {
    setTask({ ...task, categoryid: selected.value })
    setSelectedCategory(selected);
    setSelectedCategoryId(selected.value);
  }

  const options = [];
  const projects = [];
  const category = [];

  users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  allProject.map((project) => {
    projects.push({
      value: project.id,
      label: project.name
    })
  })

  allCategories.map((cat) => {
    category.push({
      value: cat.id,
      label: cat.name
    })
  })

  const handlesubmit = async () => {
    if (task.task == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Title !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.description == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Description !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.userid == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter AssignTo !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.priority == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Priority !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.start == null || task.end == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Date fields !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
    else {
      let d = await CreateTask(task);
      if (d.status === "success") {
        setshowaddtask(false);
        setReload(true)
        setTask({
          ...task,
          userid: selectedUserId,
          task: null,
          start: null,
          end: null,
          description: null,
          priority: null
        })
        setSelectedCategory(null);
        setSelectedCategoryId(null);
        Swal.fire({
          title: "Success!",
          text: "Task Added Successfully !!",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        alert("Error", "Issue While Adding Task");
      }
    }
  }


  return (
    <Container style={{ minHeight: "80vh" }}>

      {/* <Row>
        <Col md={12}>
          <div className="page-title-box">
            <h4 className="page-title">Dashboard</h4>
          </div>
        </Col>
      </Row> */}

      {/* <div style={{ marginHorizontal: 15, backgroundColor: "#D9E1FF",  borderRadius: 10, border:"solid #28B3D2 ", height:80,  display: 'flex',
          flexDirection: 'row', width:700,
          justifyContent:"space-between"}}>
<div style={{
          // marginVertical: 10,
          borderRadius: 10,
          // padding: 10, 
          // alignItems: 'center',
          // shadowColor: '#000',
          // shadowOffset: { width: 5, height: 6 },
          // shadowOpacity: 0.50,
          // shadowRadius: 3.84,
          // elevation: 8,
          display: 'flex',
          flexDirection: 'row',
          justifyContent:"flex-start"
        }}>
     <img  style={{ height: 53, width: 55, backgroundColor: 'black', borderRadius: 50, margin: 8, }} src='images/favicon.png' />
<h6 style={{margin: 10, color:"#2457C5"}} > Hello, {localStorage.getItem('firstname') + localStorage.getItem('lastname')} <br/> {localStorage.getItem('role')} <br/> <span style={{fontSize:15}} >hello@{localStorage.getItem('username')}</span></h6> <br/>

</div>
        <div style={{justifyContent:"flex-end", backgroundColor: '#2457C5', marginHorizontal: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderRadius: 20, marginBottom: 10,margin: 8, height:50}} >
<div style={{ padding: 10, }}><p style={{ backgroundColor: startDay?'#D9E1FF':'#2457C5', padding: 5, borderRadius: startDay ? 15:0,overflow:'hidden' , height: 33, width: 100, color: !startDay ? 'white':'black', textAlign: 'center', }}>Start Day </p></div>

<div style={{ padding: 10, }}><p style={{ backgroundColor: !startDay?'#D9E1FF':'#2457C5', borderRadius: !startDay ? 15:0,overflow:'hidden' ,textAlign: 'center', color: !startDay ? 'black':'white', height: 33, width: 100, padding: 5, }}  >End Day</p></div>

        </div>
      </div> */}

      <Row className='mt-2 mb-2' style={{ justifyContent: 'space-between' }}>
        {localStorage.getItem('type') === 'External' ? (
          chartData.map((d) => (
            <Col  className='pr-card-sg p-3'>
              <CardDash count={d.count} icon={"mdi mdi-account"} name={d.name.charAt(0).toUpperCase() + d.name.slice(1)} updownpercent={75} updownpercenticon={"mdi mdi-arrow-up"} progress={89} progresscolor={"bg-success"} />
            </Col>
          ))) : ("")
        }

      </Row>
      {localStorage.getItem('type') === 'External' ? (
        <Row>
          <Col  className='pr-card-sg' style={{ height: 400 }}>
            <SubmissionTrendChart />
          </Col>
        </Row>
      ) : ("")}

      <Row className='mt-3'>
      <Col  className='pr-card-sg'>
          <TaskStatusChart />
        </Col>
        <Col  className='pr-card-sg'>
          <TaskPendingChart acdgd={remoteadd} re={reload} />
        </Col>
      </Row>

      {/* <Row className='mt-3'>
        <Col md={4}>
            <ActiveInactiveUsers />
        </Col>
      </Row> */}

      <Modal show={showaddtask} onHide={() => setshowaddtask(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Title <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                placeholder="Enter Task Title"
                value={task.task}
                onChange={(e) => { setTask({ ...task, task: e.target.value }) }}
              />
              <Form.Label >Assign To <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor" >
                  <Select

                    options={options}
                    value={selectedUsers}
                    onChange={handleSelectChange}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Assign Project</Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select
                    options={projects}
                    value={selectedProject}
                    onChange={handleProjectChange}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  // isClearable={true}
                  />
                </div>
              </Form.Group>
              <Form.Label>Task Category <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select
                    options={category}
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  // isClearable={true}
                  />
                </div>
              </Form.Group>
              <Form.Label>Description <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                as='textarea'
                placeholder='Enter Task Description'
                value={task.description}
                onChange={(e) => { setTask({ ...task, description: e.target.value }) }} />

              <Form.Label>Start Date <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='datetime-local'
                value={utcStartDate}
                onChange={(e) => { setTask({ ...task, start: new Date(e.target.value).toISOString() });setUtcStartDate(Helper.dateToIsoStringWithoutChange(new Date(e.target.value)).substr(0,16)) }}
              />

              <Form.Label>End Date <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='datetime-local'
                value={utcEndDate}
                onChange={(e) => { setTask({ ...task, end: new Date(e.target.value).toISOString() });;setUtcEndDate(Helper.dateToIsoStringWithoutChange(new Date(e.target.value)).substr(0,16)) }} />
            </Form.Group>
            <div style={{ margin: '10px' }}>
              <div>
                <Form.Label>Priority <span style={{color:'red'}}>*</span> : </Form.Label>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: 'rgb(59, 130, 246)', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="f-option" class="l-radio">
                    <input type="radio" id="f-option" name="selector" tabindex="1" value="low" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'low'}/>
                    <span style={{ margin: '5px' }}>LOW</span>
                  </label>
                </div>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#28a745', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="s-option" class="l-radio">
                    <input type="radio" id="s-option" name="selector" tabindex="2" value="medium" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'medium'}/>
                    <span style={{ margin: '5px' }}>MEDIUM</span>
                  </label>
                </div>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#ff0018', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="t-option" class="l-radio">
                    <input type="radio" id="t-option" name="selector" tabindex="3" value="high" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'high'}/>
                    <span style={{ margin: '5px' }}>HIGH</span>
                  </label>
                </div>
              </div>
            </div>

          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowaddtask(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handlesubmit}
          >
            Create Task
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
}

export default UserDashboard;