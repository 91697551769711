import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"


// Doctro

export const GetAllClinics = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/clinic',null);
}

export const GetAllClinicsPagination = async (page, size,searchQuery) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/clinic/pagination?page='+page+'&size='+size+'&search='+searchQuery,null);
}

export const CreateClinic = async (clinicname,areasid,clinicaddress,country,state,city,pincode,number,email) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "areas": areasid,
        "clinicname": clinicname,
        "address": clinicaddress,
        "countryid": country,
        "stateid": state,
        "cityid": city,
        "pincode": pincode,
        "phone": number,
        "email": email
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/clinic',data);
}

export const UpdateClinic = async (clinicid,clinicname,areasid,clinicaddress,country,state,city,pincode,number,email) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "areas": areasid,
        "clinicname": clinicname,
        "address": clinicaddress,
        "countryid": country,
        "stateid": state,
        "cityid": city,
        "pincode": pincode,
        "phone": number,
        "email": email
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/clinic/'+clinicid,data);
}

export const UpdateClinicLat = async (clinicid,latitude,longitude) => {

    const [AUTH_URL, REPORTING_URL] = await constats();

    let data = JSON.stringify({
        "latitude": latitude,
        "longitude": longitude
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/clinic/updatelat/'+clinicid,data);
}

export const ClinicBulkUpdateArea = async (data) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi(
      "put",
      REPORTING_URL + "/apiv1/clinic/bulk",
      JSON.stringify(data)
    );
}

export const DeleteClinic = async (clinicid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi('delete',REPORTING_URL+'/apiv1/clinic/'+clinicid,null);
}

export const BulkDeleteClinic = async (clinicid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/clinic/bulkdelete',clinicid);
}



export const CreateExcelClinic = async (jsondata) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify(jsondata);
    return await Restapi('post',REPORTING_URL+'/apiv1/clinic/multiple/excel',data);
}


export const CheckClinicAvailable = async (clinicname) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/clinic/available/'+clinicname,null);
}

export const GetClinicDpDetails = async (userid,date) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/clinicdp/details/${userid}/${date}`,null);
}