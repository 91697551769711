import Restapi from "../api/Restapi";
import {constats} from "../constant"


// Doctor

export const GetAllDoctors = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/doctor',null);
}

export const GetAllDoctorsPagination = async (page,size,searchQuery) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/doctor/pagination?page='+page+'&size='+size+'&search='+searchQuery,null);
}

export const CheckDoctorNameAvailable = async (doctorname) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/doctor/available/doctorname/'+doctorname);
}

export const CheckDoctorCodeAvailable = async (doctorcode) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/doctor/available/doctorcode/'+doctorcode);
}

export const CreateDoctor = async (areasid,doctorcode,doctorname,doctoraadhar,degreeid,specializationid,doctoraddress,city,pincode,cipid,clinicsid,hospitalid,number,email) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "areas": areasid,
        "doctorcode": doctorcode,
        "doctorname":doctorname,
        "doctoraadhar":doctoraadhar,
        "degrees":degreeid,
        "specializations":specializationid,
        "address":doctoraddress,
        "city":city,
        "pincode":pincode,
        "dateofbirth":null,
        "aniversarydate":null,
        "cips":cipid,
        "clinics":clinicsid,
        "hospitals":hospitalid,
        "phone":number,
        "email":email,
        "focusedproducts":[],
        "deleted":false,
        "disabled":false,
        "createdby":""

      });
    return await Restapi('post',REPORTING_URL+'/apiv1/doctor',data);
}

export const UpdateDoctor = async (editdocid,areasid,doctorcode,doctorname,doctoraadhar,degreeid,specializationid,doctoraddress,city,pincode,cipid,clinicsid,hospitalid,number,email) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        
        "areas": areasid,
        "doctorcode": doctorcode,
        "doctorname":doctorname,
        "doctoraadhar":doctoraadhar,
        "degrees":degreeid,
        "specializations":specializationid,
        "address":doctoraddress,
        "city":city,
        "pincode":pincode,
        "dateofbirth":null,
        "aniversarydate":null,
        "cips":cipid,
        "clinics":clinicsid,
        "hospitals":hospitalid,
        "phone":number,
        "email":email,
        "focusedproducts":[],
        "deleted":false,
        "disabled":false,
        "createdby":""

      });
    return await Restapi('put',REPORTING_URL+'/apiv1/doctor/'+editdocid,data);
}





export const CreateExcelDoctor = async (jsondata) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify(jsondata);
    return await Restapi('post',REPORTING_URL+'/apiv1/doctor/multiple/excel',data);
}


export const DeleteDoctor = async (editdocid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
   
    return await Restapi('delete',REPORTING_URL+'/apiv1/doctor/'+editdocid,null);
}

export const BulkDeleteDoctor = async (ids) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
   
    return await Restapi('delete',REPORTING_URL+'/apiv1/doctor/bulkdelete',ids);
}


// Doctor Degree
export const GetAllDoctorsDegree = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/doctordegree',null);
}

export const CheckDocDegreeAvailable = async (degree) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/doctordegree/available/'+degree,null);
}

export const CreateDocDegree = async (degree,description)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "degree": degree,
        "description": description
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/doctordegree',data);
}

// Doctor Special
export const GetAllDoctorsSpecial = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/doctorspecialization',null);
}

export const CheckDocSpecialAvailable = async (degree) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/doctorspecialization/available/'+degree,null);
}

export const CreateDocSpecial = async (specialization,description)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "specialization": specialization,
        "description": description
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/doctorspecialization',data);
}

export const UpdateDocSpecial = async (id,specialization,description)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "specialization": specialization,
        "description": description
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/doctorspecialization/'+id,data);
}

export const DeleteDocSpecial = async (id)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
 
    return await Restapi('delete',REPORTING_URL+'/apiv1/doctorspecialization/'+id,null);
}




// Doctor Degree proxy

export const UpdateDoctorDegree = async (id,degree,description) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "degree":degree,
        "description":description
    });

    return await Restapi('put',REPORTING_URL+'/apiv1/doctordegree/'+id,data);
}

export const DeleteDoctorDegree = async (id,degree,description) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
   

    return await Restapi('delete',REPORTING_URL+'/apiv1/doctordegree/'+id,null);
}

// Bulk Update
export const DoctorBulkUpdateArea = async (data) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi(
      "put",
      REPORTING_URL + "/apiv1/doctor/bulk",
      JSON.stringify(data)
    );
}

export const GetDoctorDpDetails = async (userid,date) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/doctordp/details/${userid}/${date}`,null);
}

