import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

// DCR

export const GetAllDayInOut = async (userId,selectedMonth,selectedYear) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi(
    "get",
    REPORTING_URL + `/apiv1/dailylogs/${userId}?month=${selectedMonth}&year=${selectedYear}`,
    null
  );
};

export const GetMoodsByUserId = async (userId, monthyear,page, size) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi(
    "get",
    REPORTING_URL + `/apiv1/dailylogs/moods?userid=${userId}&monthyear=${monthyear}&page=${page}&size=${size}`,
    null
  );
};

export const GetMoodsCountByMonth = async (monthyear) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi(
    "get",
    REPORTING_URL + `/apiv1/dailylogs/moods/count?monthyear=${monthyear}`,
    null
  );
};

