import { Container } from "react-bootstrap"
import Chart from 'react-apexcharts';
import { GrFormPrevious } from 'react-icons/gr';
import { MdNavigateNext } from 'react-icons/md';
import { useEffect, useState } from "react";
import { getExpenseByMonth } from "../../utils/proxy/ReportsProxy";

export const ExpenseChart = ({setExpensedur,setTotalExpense}) => {

    const months = {
        1:'Jan',
        2:'Feb',
        3:'Mar',
        4:'Apr',
        5:'May',
        6:'Jun',
        7:'Jul',
        8:'Aug',
        9:'Sep',
        10:'Oct',
        11:'Nov',
        12:'Dec',
    }

    const [currdate, setCurrdate] = useState(new Date());
    const [seriesData, setSeriesData] = useState([]);
    const [loading, setLoading] = useState(false);

    setExpensedur(months[currdate.getMonth()+1] +" "+ currdate.getFullYear());

    useEffect(()=>{
        const getExpense = async() =>{
            setLoading(true);
            let d = await getExpenseByMonth(currdate.toISOString().split('T')[0]);
            setSeriesData([])
            if(d){
                d.map(val=>{
                    setSeriesData(pre =>[
                        ...pre, {name:val.type,data:[val.expense]}
                    ])
                })
                const totalexpense = d.reduce((prev,curr)=> prev + (curr.expense || 0),0)
                setTotalExpense(totalexpense);
                setLoading(false);
            }
        }

        getExpense();
    },[currdate])

    function subtractMonths(date, months) {
        const d = new Date(date);
        d.setMonth(d.getMonth() - months);
    
        // Handle edge case where subtracting months might affect the day
        if (d.getDate() !== date.getDate()) {
            d.setDate(0);
        }
    
        setCurrdate(d);
    }
    
    // Function to add months to a given date
    function addMonths(date, months) {
        const d = new Date(date);
        d.setMonth(d.getMonth() + months);
    
        // Handle edge case where adding months might affect the day
        if (d.getDate() !== date.getDate()) {
            d.setDate(0);
        }
    
        setCurrdate(d);
    }

    const options = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: false,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        tooltip: {
            x: {
                show: false  // This removes the header
              },
            // other tooltip options
          },
        // title: {
        //     text: `Expenses Of ${months[currdate.getMonth()+1]} - ${currdate.getFullYear()}`,
        //     style: {
        //         fontSize: '13px',
        //     }
        // },
        noData: {
            text: loading ? 'Loading...' : 'No Expense'
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    show:true,
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: true,
                borderRadius: 10,
                borderRadiusApplication: 'end', // 'around', 'end'
                borderRadiusWhenStacked: 'last', // 'all', 'last'
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        dataLabels: {
            enabled: true,
            style: {
              colors: ['black'] // Change the color to your desired color
            },
            formatter: function (val) {
              return val;
            },
            // offsetY: -20,
          },
        yaxis: {
            show:false,
            // categories: ['2024-01'],
            // type: 'month'
        },
        // legend: {
        //     position: 'right',
        //     offsetY: 40
        // },
        fill: {
            opacity: 1
        }
    };

    const series = seriesData;

    return (
        <Container fluid>
            <div className="donut">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    <GrFormPrevious size={30} onClick={()=>subtractMonths(currdate,1)} />

                    <div style={{ flexGrow: 1 }}>
                        <Chart options={options} series={series} type="bar" height={350} />
                    </div>

                    <MdNavigateNext size={30} onClick={()=>addMonths(currdate,1)} />

                </div>
            </div>
        </Container>
    )
}