import { useEffect } from "react";
import { useState } from "react";
import { Badge, Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy";
import { CreateExpense, DeleteExpense, EditExpense, GetCurrency, GetExpenseByUser, GetExpenseByUserCopy, GetExpenseTypeMaster, approveExpense } from "../../utils/proxy/Expenseproxy";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { IoIosClose, IoMdCheckmarkCircle } from "react-icons/io";
import { FaPlus, FaRegRectangleXmark } from "react-icons/fa6";
import Helper from "../../utils/helperFunctions";
import { IoHomeOutline } from "react-icons/io5";
import { GetCurrencyFromSettings } from "../../utils/proxy/SettingsProxy";
import { Op_per } from "../../utils/Permissions";
import DatePicker from "react-date-picker";

function Expense() {
    const [data, setdata] = useState([])
    const [users, setusers] = useState([])

    const [currentId, setCurrentId] = useState();
    const [status, setStatus] = useState();
    const [date, setDate] = useState('')
    const [allExpense, setAllExpense] = useState([]);
    const [allCurrency, setAllCurrency] = useState([]);
    const [approvedby, setApprovedby] = useState();

    const [selectedExpense, setSelectedExpense] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState();

    const [selectedExpenseId, setSelectedExpenseId] = useState(1);
    const [selectedCurrencyId, setSelectedCurrencyId] = useState();

    const [remark, setRemark] = useState();
    const [amount, setAmount] = useState();
    const [approveamount, setApproveamount] = useState();
    const [description, setDescription] = useState()
    const [selectedExpenseType, setSelectedExpenseType] = useState()
    const [selectedUsers, setSelectedUsers] = useState(localStorage.getItem('role')==='User' ? {value:localStorage.getItem('uuid'),label:localStorage.getItem('firstname')+" "+localStorage.getItem('lastname')+" (Self)"} : null);
    const [reload, setReload] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(localStorage.getItem('role')==='User' ? localStorage.getItem('uuid') : '');
    const [showaddexpense, setshowaddexpense] = useState(false);
    const [showeditexpense, setshoweditexpense] = useState(false);
    const [showapprovalmodal, setShowapprovalmodal] = useState(false);
    const [showrejectmodal, setShowrejectmodal] = useState(false);
    const [changestatus, setchangestatus] = useState('');
    const [localDate, setLocalDate] = useState('');

    const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [totalRows,setTotalRows] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [filterDate, setFilterDate] = useState('');

    const filteredData = changestatus === "" ? data :
        data.filter((row) => {

            if (row.status.toLowerCase().includes(changestatus)) {
                return true;
            }

            return false;
        })

    useEffect(() => {
        async function name() {
            let managerid = localStorage.getItem('role') === "Manager" ? localStorage.getItem('uuid') : ''
            let data = await GetExpenseByUserCopy(selectedUserId,page,size,changestatus,managerid,filterDate);
            if (data) {
                setdata(data.expense);
                setTotalPages(data.totalpages)
                setTotalRows(data.totalrows)
                setCurrentPage(data.currentpage)

            } else {
                alert("Unable To Fetch ");
            }
        }

        name();
        setReload(false);
    }, [selectedUserId, reload,page,size,changestatus,filterDate]);

    useEffect(() => {

        const getAllUsersData = async () => {
            if(localStorage.getItem('role') === 'Admin'){
                let allusers = await AllUsers();
                allusers.push({firstname:localStorage.getItem('firstname'),lastname:localStorage.getItem('lastname'),userid:localStorage.getItem('uuid'),username:localStorage.getItem('username')})
                setusers(allusers);
              }else if(localStorage.getItem('role') === 'Manager'){
                let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
                setusers(allusers);
              }
        };
        const getExpenseType = async () => {
            let type = await GetExpenseTypeMaster();
            if (type) {
                setAllExpense(type);
                // setSelectedExpenseType(type)
            } else {
                alert("Error", "Issue While Fetching Data");
            }
        }
        const getCurrencyType = async () => {
            let curr = await GetCurrency();
            let setcurr = await GetCurrencyFromSettings();
            if (curr) {
                setAllCurrency(curr);
                if(setcurr){
                    const b = curr.find(cur => cur.id == setcurr)
                    setSelectedCurrency({value:b.id, label:b.currency})
                    setSelectedCurrencyId(b.id);
                    }
            } else {
                alert("Error", "Issue While Fetching Data");
            }
        }
        getAllUsersData();
        getExpenseType();
        getCurrencyType();
    }, [reload]);


    const edithandle = (row) => {

        setCurrentId(row.id);
        setStatus(row.status);
        setAmount(row.amount)
        setDate(row.date)
        setApprovedby(row.approvedby);
        setSelectedExpense({ value: row.expensetypeid, label: row.expensetype })
        setSelectedExpenseId(row.expensetypeid)
        setSelectedCurrency({ value: row.currencyid, label: row.currency })
        setSelectedCurrencyId(row.currencyid)
        setDescription(row.description)
    }

    const handledelete = async (id) => {
        let d = await DeleteExpense(id);
        if (d.status === "success") {
            setReload(true)
            Swal.fire({
                title: "Deleted!",
                text: "Expense Deleted Successfully !!",
                icon: "success",
                confirmButtonText: "OK",
            });
        }
    }

    const handleApprove = async (id, status, userid, approveamount, remark, amt) => {
        if (approveamount === null || approveamount === '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Approved Amount!!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (remark === null || remark === '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Remark !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (parseInt(approveamount) > amt) {
            Swal.fire({
                title: "Warning!",
                text: "Approved Amount is Greater than Expense !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {

            let d = await approveExpense(id, status, userid, approveamount, remark);
            if (d.status === "success") {
                setReload(true)
                setShowapprovalmodal(false);
                setShowrejectmodal(false);
                setAmount(null);
                setRemark(null);
                setApproveamount(null);
                Swal.fire({
                    title: status + " !!",
                    text: "Expense " + status,
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const options = [];
    const typeoptions = [];
    const currencyoptions = [];

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });

    allExpense.map((type) => {
        typeoptions.push({
            value: type.id,
            label: type.name
        });
    });

    allCurrency.map((curr) => {
        currencyoptions.push({
            value: curr.id,
            label: curr.currency
        });
    });


    function handletypechange(params) {
        setSelectedExpenseType(params);
    }

    const columns = [
        // {
        //   name: "Id",
        //   selector: (row) => row.id,
        //   sortable: true,
        // },
        {
            name: "Username",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.username === "" || row.username === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.username === "" || row.username === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.username === "" || row.username === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.username ? row.username : "N/A"}</div>,
        
            width: "8%",

        },
        {
            name: "Expense Type",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.expensetype === "" || row.expensetype === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.expensetype === "" || row.expensetype === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.expensetype === "" || row.expensetype === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.expensetype ? row.expensetype : "N/A"}</div>,
        
            width: "7%",

        },
        {
            name: "Amount",
            selector: (row) =>

                <div>{row.symbol} {row.amount}</div>,
            width: "6%",
        },
        {
            name: "Description",
            selector: (row) => <div style={{ whiteSpace: "normal", overflow: "visible" }}>{row.description}</div>,
            width: "8%",
        },
        {
            name: "Created By",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.createdby === "" || row.createdby === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.createdby === "" || row.createdby === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.createdby === "" || row.createdby === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.createdby ? row.createdby : "N/A"}</div>,
        
            width: "7%",
        },
        {
            name: "Approved By",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.approvedby === "" || row.approvedby === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.approvedby === "" || row.approvedby === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.approvedby === "" || row.approvedby === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.approvedby ? row.approvedby : "N/A"}</div>,
        
            width: "7%",
        },
        {
            name: "Status",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.status === "" || row.status === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.status === "" || row.status === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.status === "" || row.status === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.status ? row.status : "N/A"}</div>,
        
            width: "7%",
        },
        // {
        //     name: "Currency",
        //     selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.currency === "" || row.currency === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.currency === "" || row.currency === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.currency === "" || row.currency === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.currency ? row.currency : "N/A"}</div>,
        // 
        //     width: "6%",
        // },
        {
            name: "Date",
            selector: (row) => <div style={{ whiteSpace: "normal", overflow: "visible" }} title={Helper.ConvertUtcToLocalTime(row.date)}>{Helper.ConvertUtcToLocalTime(row.date)}</div>,
            width: "9%",
        },
        {
            name: "Approved Amount",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.approvedamount === "" || row.approvedamount === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.approvedamount === "" || row.approvedamount === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.approvedamount === "" || row.approvedamount === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.approvedamount ? row.approvedamount : "N/A"}</div>,
        
            width: "8%",
        },
        {
            name: "Remark",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.remark === "" || row.remark === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.remark === "" || row.remark === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.remark === "" || row.remark === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.remark ? row.remark : "N/A"}</div>,

            width: "8%",
        },
        (localStorage.getItem('role')==='Admin' || localStorage.getItem('role')==='Manager' ?
        {
            name: "Approval",
            selector: (row) => {
                if (row.status === 'pending') {
                    return (<div key={row.id} style={{ overflow: 'visible' }}>
                        <IoMdCheckmarkCircle style={{ fontSize: '25px', margin: '2px' }} title="Approve" onClick={() => { setShowapprovalmodal(true); setCurrentId(row.id); setApproveamount(row.amount);  }} />
                        <FaRegRectangleXmark style={{ fontSize: '25px', margin: '2px' }} title="Reject" onClick={() => { setShowrejectmodal(true); setCurrentId(row.id); setApproveamount(row.amount);  }} />
                    </div>)
                } else {
                    return null;
                }
            },
            width: "8%",
        } : ""),
        // {
        //     name: "Actions",
        //     selector: (row) => (
        //         <div key={row.id}>
        //             <AiTwotoneEdit
        //                 style={{ color: "black", fontSize: 20 }}
        //                 onClick={() => { setshoweditexpense(true); edithandle(row) }}
        //             />
        //             &nbsp;&nbsp;&nbsp;
        //             <AiFillDelete
        //                 style={{ color: "red", fontSize: 20 }}
        //                 onClick={() => handledelete(row.id)}
        //                 title="Delete"
        //             />
        //         </div>
        //     ),
        // },
    ];

    if(Op_per.get().EXPENSE.ALLOW_DELETE || Op_per.get().EXPENSE.ALLOW_EDIT){
        columns.push({
            name: "Actions",
            selector: (row) => (
                <div key={row.id}>
                    <AiTwotoneEdit
                        style={{ color: "black", fontSize: 20, display:Op_per.get().EXPENSE.ALLOW_EDIT ? '' : 'none' }}
                        onClick={() => { setshoweditexpense(true); edithandle(row) }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AiFillDelete
                        style={{ color: "red", fontSize: 20, display:Op_per.get().EXPENSE.ALLOW_DELETE ? '' : 'none' }}
                        onClick={() => handledelete(row.id)}
                        title="Delete"
                    />
                </div>
            ),
        })
      }

    const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
    const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    const handleSelectChange = (selected) => {
        if (selected == null) {
            setSelectedUsers(null);
            setSelectedUserId('')
            setReload(true)
            return;
          }
        setSelectedUsers(selected);
        setSelectedUserId(selected.value);
        setchangestatus('');
        setPage(0);
        setSize(10);
    };

    const handleSelectExpenseType = (row) => {

        setSelectedExpense(row);
        setSelectedExpenseId(row.value)
    }

    const handleCurrencyChange = (selected) => {

        setSelectedCurrency(selected)
        setSelectedCurrencyId(selected.value)
    }

    const handlePageChangeTable = (page) =>{
        setPage(page-1);
      }
  
      const handlePerRowChange = (newPerPage, page) =>{
        setSize(newPerPage);
        setPage(page-1);
      }

      function dateToIsoStringWithoutChange(istDate) {
        if (!(istDate instanceof Date)) {
          throw new Error("Input must be a Date object");
        }
    
        istDate.setMinutes(istDate.getMinutes() - istDate.getTimezoneOffset());
    
        const isoString = istDate.toISOString();
    
        return isoString;
      }

      const handleDateFilter = (value) => {
        if (value == null ) {
          setFilterDate('');
          setReload(true);
          return;
        }
        setFilterDate(dateToIsoStringWithoutChange(value).split('T')[0]);
      };
      
      const clearDate = () => {
        setFilterDate('');
      };

    const addhandlesubmit = async () => {
        if (selectedUserId == null) {
            Swal.fire({
                title: "Warning!",
                text: "Select User !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (selectedExpenseId == null) {
            Swal.fire({
                title: "Warning!",
                text: "Select Expense Type !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (amount == null) {
            Swal.fire({
                title: "Warning!",
                text: "Enter Amount !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (description == null) {
            Swal.fire({
                title: "Warning!",
                text: "Enter description !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await CreateExpense(selectedUserId, selectedExpenseId, amount, description, localStorage.getItem('uuid'), null, 'pending', selectedCurrencyId, date);

            if (d.status === "success") {
                setshowaddexpense(false);
                setReload(true)
                setSelectedExpense(null);
                setSelectedExpenseId(null);
                setAmount(null);
                Swal.fire({
                    title: "Success!",
                    text: "Expense Created Successfully !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            } else {
                alert("Error", "Issue while Adding Expense")
            }
        }
    }

    const edithandlesubmit = async () => {
        if (selectedUserId == null) {
            Swal.fire({
                title: "Warning!",
                text: "Select User !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (selectedExpenseId == null) {
            Swal.fire({
                title: "Warning!",
                text: "Select Expense Type !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (amount == null) {
            Swal.fire({
                title: "Warning!",
                text: "Enter Amount !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (description == null) {
            Swal.fire({
                title: "Warning!",
                text: "Enter description !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await EditExpense(currentId, selectedUserId, selectedExpenseId, amount, description, localStorage.getItem('uuid'), approvedby, status, selectedCurrencyId, date);
            if (d.status === 'success') {
                setshoweditexpense(false);
                setReload(true);
                setSelectedExpense(null);
                setSelectedExpenseId(null);
                setApprovedby(null);
                setAmount(null);
                Swal.fire({
                    title: "Success!",
                    text: "Expense Updated Successfully !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            } else {
                alert("Error", "Issue while Updating Expense")
            }
        }
    }

    return (
        <>

<div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding:0,
          backgroundColor:'#3887BE'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/user-doctor-master"><span class="icon icon-beaker"> </span>Expense</a></li>
  {/* <li><a href="/user-doctor-master"><span class="icon icon-beaker"> </span> User Doctor Master</a></li> */}
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
               
            </div>

            <div style={{ overflow: 'visible', display: 'flex', flexDirection: 'row', justifyContent: localStorage.getItem('role') === 'User' ? 'flex-end' : 'space-between', alignItems: 'center',margin: '20px' }}>
            <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap:5
            }}
          >
                <div style={{ width: '300px',display:Op_per.get().EXPENSE.USER_DROPDOWN ? '' : 'none' }}>
                    <Form.Group
                        className="mb-3 d-flex w-100 "
                        style={{ gap: "15px" }}
                        controlId="formBasicPassword"
                    >
                        <Form.Label className="mt-2 ">Users </Form.Label>
                        <div className="selectdoctor">
                            <Select

                                options={options}
                                value={selectedUsers}
                                onChange={handleSelectChange}
                                isClearable
                                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                            />
                        </div>
                    </Form.Group>

                </div>

                <div style={{ width: "350px" }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Date</Form.Label>
                <div className="mt-2">
                  <DatePicker
                    clearIcon={<IoIosClose onClick={()=>clearDate()} />}
                    onChange={(date) => handleDateFilter(date)}
                    value={filterDate}
                    format="dd-MM-yy"
                  />
                </div>
              </Form.Group>
            </div>
        </div>

                <div>
                    <Button style={{ backgroundColor: "#28B3D2", display:Op_per.get().EXPENSE.ALLOW_ADD ? '' : 'none' }} onClick={() => {setshowaddexpense(true);setLocalDate(Helper.dateToIsoStringWithoutChange(new Date()).substr(0,16));setDate(new Date().toISOString())}}>
                        <FaPlus />
                    </Button>
                </div>

                {/* <div style={{padding:5}}>
                    <div style={{backgroundColor:"#28B3D2", borderTopLeftRadius:20, borderBottomRightRadius:20,color:"#fff", borderColor:"none", width:118}} className="add-button" onClick={() => setshowaddexpense(true)} > Add Expense</div>
                </div> */}
            </div>

            <hr />

            <div style={{ marginBottom: 20 }}>
                <Badge onClick={() => { setchangestatus('') }} style={{ margin: 3, padding: 6, cursor: 'pointer', boxShadow: changestatus === 'all' ? 'black 1px 1px 1px 1px' : 'black 0px 0px 0px 0px' }} bg="secondary">All</Badge>
                <Badge onClick={() => { setchangestatus('approved'); }} style={{ margin: 3, padding: 6, cursor: 'pointer', boxShadow: changestatus === 'approved' ? 'black 1px 1px 1px 1px' : 'black 0px 0px 0px 0px' }} bg="primary">APPROVED</Badge>
                <Badge onClick={() => { setchangestatus('rejected'); }} style={{ margin: 3, padding: 6, cursor: 'pointer', boxShadow: changestatus === 'rejected' ? 'black 1px 1px 1px 1px' : 'black 0px 0px 0px 0px' }} bg="warning">REJECTED</Badge>
                <Badge onClick={() => { setchangestatus('pending'); }} style={{ margin: 3, padding: 6, cursor: 'pointer', boxShadow: changestatus === 'pending' ? 'black 1px 1px 1px 1px' : 'black 0px 0px 0px 0px' }} bg="info">PENDING</Badge>
            </div>

            <div className="pt-3">

                <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
                    style={{ marginTop: 10 }}
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowChange}
                    onChangePage={handlePageChangeTable}
                    dense
                />
            </div>


            <Modal show={showaddexpense} onHide={() => setshowaddexpense(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Expense</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>User <span style={{color:'red'}}>*</span></Form.Label>
                            <Form.Group
                                className="mb-3 d-flex w-100"
                                style={{ gap: "15px" }}
                                controlId="formBasicPassword"
                            >
                                <div className="selectdoctor">
                                    <Select

                                        options={options}
                                        value={selectedUsers}
                                        onChange={handleSelectChange}
                                        styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Label>Date <span style={{color:'red'}}>*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 46,

                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                type='datetime-local'
                                placeholder="Enter Date"
                                value={localDate}
                                onChange={(e) => { setDate(new Date(e.target.value).toISOString()); setLocalDate(Helper.dateToIsoStringWithoutChange(new Date(e.target.value)).substr(0,16)) }}
                            />
                            <Form.Label>Expense Type <span style={{color:'red'}}>*</span></Form.Label>
                            <Form.Group
                                className="mb-3 d-flex w-100"
                                style={{ gap: "15px" }}
                                controlId="formBasicPassword"
                            >
                                <div className="selectdoctor">
                                    <Select

                                        options={typeoptions}
                                        value={selectedExpense}
                                        onChange={handleSelectExpenseType}
                                        styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Label>Currency <span style={{color:'red'}}>*</span></Form.Label>
                            <Form.Group
                                className="mb-3 d-flex w-100"
                                style={{ gap: "15px" }}
                                controlId="formBasicPassword"
                            >
                                <div className="selectdoctor">
                                    <Select

                                        options={currencyoptions}
                                        value={selectedCurrency}
                                        onChange={handleCurrencyChange}
                                        styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Label>Amount <span style={{color:'red'}}>*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 46,

                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                type="number"
                                placeholder="Enter Amount"
                                onChange={(e) => { setAmount(e.target.value) }}
                            />
                            <Form.Label>Description <span style={{color:'red'}}>*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 80,

                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                as="textarea"
                                placeholder="Enter Description"
                                onChange={(e) => { setDescription(e.target.value) }}
                            />
                        </Form.Group>

                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setshowaddexpense(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={addhandlesubmit}
                    >
                        Create Expense
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showeditexpense} onHide={() => setshoweditexpense(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Expense</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>User <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Group
                                className="mb-3 d-flex w-100"
                                style={{ gap: "15px" }}
                                controlId="formBasicPassword"
                            >
                                <div className="selectdoctor">
                                    <Select

                                        options={options}
                                        value={selectedUsers}
                                        onChange={handleSelectChange}
                                        styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Label>Date <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 46,

                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                type='datetime-local'
                                placeholder="Enter Date"
                                value={date.split(':')[0] + ":" + date.split(':')[1]}
                                onChange={(e) => { setDate(new Date(e.target.value).toISOString()) }}
                            />
                            <Form.Label>Expense Type <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Group
                                className="mb-3 d-flex w-100"
                                style={{ gap: "15px" }}
                                controlId="formBasicPassword"
                            >
                                <div className="selectdoctor">
                                    <Select

                                        options={typeoptions}
                                        value={selectedExpense}
                                        onChange={handleSelectExpenseType}
                                        styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Label>Currency <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Group
                                className="mb-3 d-flex w-100"
                                style={{ gap: "15px" }}
                                controlId="formBasicPassword"
                            >
                                <div className="selectdoctor">
                                    <Select

                                        options={currencyoptions}
                                        value={selectedCurrency}
                                        onChange={handleCurrencyChange}
                                        styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Label>Amount <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 46,

                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                type="number"
                                placeholder="Enter Amount"
                                value={amount}
                                onChange={(e) => { setAmount(e.target.value) }}
                            />
                            <Form.Label>Description <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 80,

                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                as="textarea"
                                placeholder="Enter Description"
                                value={description}
                                onChange={(e) => { setDescription(e.target.value) }}
                            />
                        </Form.Group>

                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setshoweditexpense(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={edithandlesubmit}
                    >
                        Edit Expense
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showapprovalmodal} onHide={() => setShowapprovalmodal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Approval Remark </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Amount To Approve <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 46,

                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                type="number"
                                placeholder="Enter Approved Amount"
                                onChange={(e) => setAmount(e.target.value)}
                            />
                            <Form.Label>Remark <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 46,

                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                as="textarea"
                                placeholder="Enter Remark"
                                onChange={(e) => setRemark(e.target.value)}
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowapprovalmodal(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => handleApprove(currentId, 'approved', localStorage.getItem('uuid'), amount, remark, approveamount)}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showrejectmodal} onHide={() => setShowrejectmodal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Reject Remark</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Remark <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 46,
                                    width: 250,
                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                as="textarea"
                                placeholder="Enter Remark"
                                onChange={(e) => setRemark(e.target.value)}
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowrejectmodal(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => handleApprove(currentId, 'rejected', localStorage.getItem('uuid'), 0, remark, approveamount)}
                    >
                        submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default Expense;