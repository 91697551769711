import { Button, Card, Col, Container, Row } from "react-bootstrap"
import { FaTasks } from "react-icons/fa"
import { AiOutlineUserAdd } from "react-icons/ai";
import { MdOutlineLeaderboard } from "react-icons/md";
import CardDash from "./Common/CardDash";
import { DemoChart1 } from "./Includes/DemoChart1";
import { DonutDemo } from "./Includes/DonutDemo";
import { LineBarDemochart } from "./Includes/LineBarDemochart";
import { HorizontalBarChart } from "./Includes/HorizontalBarChart";
import Calendar from 'react-calendar';
import { useNavigate } from "react-router-dom";
import { ExpenseChart } from "./Includes/ExpenseChart";
import TaskPendingChart from "./Includes/TaskPendingChart";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { CardDash2 } from "./Common/CardDash2";
import { GetDataForCardDash } from "../utils/proxy/DashboardProxy";
import UserNotes from "./Common/UserNotes";
import MoodChart from "./Common/MoodChart";

export const UserDashboard2 = () => {

    const navigate = useNavigate();
    const [cardData, setCardData] = useState([]);

    const [expensedur,setExpensedur] = useState();
    const [taskof,setTaskof] = useState();
    const [moodMonth, setMoodMonth] = useState();

    const [totalExpense, setTotalExpense] = useState(0);

    useEffect(()=>{
      const getCardData = async() =>{
        let d = await GetDataForCardDash(localStorage.getItem('uuid'),localStorage.getItem("role"));
        if (d) {
          setCardData(d);
        }else{
          Swal.fire({
            title:'Error',
            text:'Something Went Wrong',
            icon:'error'
          })
        }
      }

      getCardData();
    },[])

    const iconComponents = {
        FaTasks: FaTasks, // Map the icon names to the imported components
        AiOutlineUserAdd: AiOutlineUserAdd,
        MdOutlineLeaderboard: MdOutlineLeaderboard
        // Add other icon components here if needed
    };

    const cardArray = [
        { title: 'Create Task', icon: 'FaTasks' },
        { title: 'Create Lead', icon: 'MdOutlineLeaderboard' },
        { title: 'Add User', icon: 'AiOutlineUserAdd' },
    ]

    const chartData = [
        { count: 21, name: 'Completed Tasks', icon:'mdi mdi-checkbox-marked-circle' },
        { count: 17, name: 'In-Progress Tasks', icon:'mdi mdi-account' },
        { count: 9, name: 'Leads', icon:'mdi mdi-account' },
        { count: 10, name: 'Products', icon:'mdi mdi-account' },
    ]

    const tileContent = ({ date, view }) => {
        // Check if it's the desired view (for example, 'month')
        if (view === 'month') {
          // Check if it's the desired date(s) to display dot(s)
          if (date.getDate() === 15 || date.getDate() === 20) {
            return <div className="d-flex"><div style={{width:6,height:6,backgroundColor:'red',borderRadius:'50%'}} onClick={(event)=>{event.stopPropagation();alert("Dot Clicked")}}></div><div style={{width:6,height:6,backgroundColor:'green',borderRadius:'50%'}}></div></div>;
          }
        }
        return null;
      };

      const handleClick = (date) =>{
        const d = new Date(date).toDateString();
        navigate('/attendance',{ state: { dateProp: date } })
      }

    return (
 
        <>
        <div class="row">
                                <div class="col-sm-12">
                                    <div class="page-title-box">
                                        <h4 class="page-title">Dashboard</h4>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
  <div className="row">
    <div className="col-xl-8" >
      <div className="row">
        {cardData.map((item)=>(
          <CardDash2 count={item.count} title={item.title} />
        ))}
       
      </div>
      <div className="card">
      <Card.Header>📒 <b>Tasks Of {taskof}</b></Card.Header>
        <div className="card-body" >
          {/* <h4 className="mt-0 header-title">Every Day Revenue</h4>
          <p className="text-muted mb-4 font-14" />
          <div id="morris-bar-stacked" className="morris-chart" /> */}
          <LineBarDemochart setTaskof={setTaskof} />
        </div>
      </div>
    </div>
    <div className="col-xl-4" >
    <div className="card" style={{height:"200px"}} >
    <Card.Header>Ⓜ <b>Daily Mood of {moodMonth}</b></Card.Header>
    <div className="card-body overflow-hidden">
      <MoodChart setMoodMonth={setMoodMonth}/>
    </div>
      </div>
      <div className="card" style={{height:"302px"}} >
        <Card.Header>📒 <b>My Notes</b></Card.Header>
      <div className="card-body" style={{overflow:'scroll'}}>
        <UserNotes/>
        </div>
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-xl-4 ">
      <div className="card">
      <Card.Header>📈 <b>Lead Sources</b></Card.Header>
        <div className="card-body">
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <div>
          <DonutDemo />
          </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-xl-4">
      <div className="card">
      <Card.Header> <div style={{display:'flex',justifyContent:'space-between',fontWeight:'bold'}}><div>💵 Expense {expensedur} </div><div>{totalExpense} /-</div></div></Card.Header>
        <div className="card-body">
          <ExpenseChart setExpensedur={setExpensedur} setTotalExpense={setTotalExpense}/>
        </div>
      </div>
    </div>
    <div className="col-xl-4">
      <div className="card">
      <Card.Header>📅 <b>Calendar</b></Card.Header>
        <div className="card-body">
          <Calendar 
                     className='react-calendar-dashboard react-calendar__navigation__label__labelText_bold'
                     style={{width:'100%', height:'100%'}} 
                     value={new Date()} 
                    //  tileContent={tileContent}
                     onClickDay={handleClick} />
        </div>
      </div>
    </div>
  </div>
</>

    )
}