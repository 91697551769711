import Restapi from "../api/Restapi";
import { constats } from "../constant";

export const GetAllLeadLog = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/leadlog',null);
}

export const GetAllLeadLogById = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/leadlog/'+id,null);
}

export const CreateLeadLog = async (leadid,opttype,start,end,description) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        leadid,
        opttype,
        start,
        end,
        description
    }

    return await Restapi('post',REPORTING_URL+'/apiv1/leadlog',data);
}

export const UpdateLeadLog = async (id,leadid,opttype,start,end,description) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        leadid,
        opttype,
        start,
        end,
        description
    }

    return await Restapi('post',REPORTING_URL+'/apiv1/leadlog/'+id,data);
}


export const DeleteLeadLog = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();


    return await Restapi('post',REPORTING_URL+'/apiv1/leadlog/'+id,null);
}