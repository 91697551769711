import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"


// Doctro

export const GetAllStockists = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/stockist',null);
}

export const GetAllStockistsPagination = async (page,size,search) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/stockist/pagination?page='+page+'&size='+size+'&search='+search,null);
}

export const CreateStockist = async (stockistname,areasid,stockistaddress,country,state,city,pincode,number,email) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "areas": areasid,
        "stockistname": stockistname,
        "address": stockistaddress,
        "countryid": country,
        "stateid": state,
        "cityid": city,
        "pincode": pincode,
        "phone": number,
        "email": email
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/stockist',data);
}

export const UpdateStockist = async (stockistid,stockistname,areasid,stockistaddress,country,state,city,pincode,number,email) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "areas": areasid,
        "stockistname": stockistname,
        "address": stockistaddress,
        "countryid": country,
        "stateid": state,
        "cityid": city,
        "pincode": pincode,
        "phone": number,
        "email": email
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/stockist/'+stockistid,data);
}


export const UpdateStockistLat = async (stockistid,latitude,longitude) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "latitude": latitude,
        "longitude": longitude
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/stockist/updatelat/'+stockistid,data);
}

// Bulk Update
export const StockistBulkUpdateArea = async (data) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi(
      "put",
      REPORTING_URL + "/apiv1/stockist/bulk",
      JSON.stringify(data)
    );
}

export const DeleteStockist = async (stockistid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi('delete',REPORTING_URL+'/apiv1/stockist/'+stockistid,null);
}


export const BulkDeleteStockist = async (stockistid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi('delete',REPORTING_URL+'/apiv1/stockist/bulkdelete',stockistid);
}



export const CreateExcelStockist = async (jsondata) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify(jsondata);
    return await Restapi('post',REPORTING_URL+'/apiv1/stockist/multiple/excel',data);
}


export const CheckStockistAvailable = async (stockistname) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/stockist/available/'+stockistname,null);
}

export const GetStockistDpDetails = async (userid,date) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/stockistdp/details/${userid}/${date}`,null);
}