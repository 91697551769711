import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"


export const GetAllCipByCity = async (cityid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/cip/city/'+cityid,null);
}

export const GetAllCipByCities = async (cityid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/cip/cities',cityid);
}

export const UpdateUserCipStatus = async (id,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    
    return await Restapi('put',REPORTING_URL+`/apiv1/usercip/update/${id}/${status}`,null);
}

export const DeleteUserCipStatus = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/usercip/'+id,null);
}

export const AddUserCipData = async (userid,cipid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "userid":userid,
        "cipid":cipid,
        "status":"ACTIVE"
    });
    return await Restapi('post',REPORTING_URL+'/apiv1/usercip',data);
}


export const CreateUserCipData = async (userid,areas,cipname,address,countryid,stateid,cityid,pincode,phone,email) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    var arr = [areas];
    let data = JSON.stringify({
        "userid":userid,
        "status":"PENDING_FOR_ADDITION",
        "areas":arr,
        "cipname":cipname,
        "address":address,
        "countryid":countryid,
        "stateid":stateid,
        "cityid":cityid,
        "pincode":pincode,
        "phone":phone,
        "email":email,
        "createdby":userid
    });
    return await Restapi('post',REPORTING_URL+'/apiv1/usercip/create',data);
}

export const GetAllCipbyUserStatus = async (user,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/usercip/status/'+user+'/'+status,null);
}