import logo from './logo.svg';
import './App.css';
import './index.css';
import Appstart from './components/Appstart';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Appstart/>
  );
}

export default App;
