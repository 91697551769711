import React,{ useEffect, useState } from 'react'
import Login from './Auth/Login'
import Dashboard from './Dashboard';
import Main from './Main';
import Footer from './Includes/Footer';
import Cookies from 'js-cookie';
import { GetPermissionsByUserid } from '../utils/proxy/UserPermissionsProxy';
import { Op_per, Permissions } from '../utils/Permissions';
import Login2 from './Auth/Login2';
import { Toast, ToastContainer } from 'react-bootstrap';

function Appstart() {

  const [isloggedin, setIsloggedin] = useState(false);
  const [per,setPer] = useState({});
  
  const [toastShow, setToastShow] = useState(false);

  useEffect(() => {
    const loggedIn = localStorage.getItem("accessToken");
    if (loggedIn != null) {
      setIsloggedin(true);
    }
    
  }, []);

  const getPermission = async () => {
    let res = await GetPermissionsByUserid(localStorage.getItem('uuid'));
    if (res) {
      try {
        let per = JSON.parse(res.menupermissions);
        let operationper = JSON.parse(res.permissions);
        Permissions.set(per);
        Op_per.set(operationper);
        setPer(Permissions.get());
      } catch (e) {
      }
    }else{
      setToastShow(true);
      logoutUser();
    }
  }


  function loginUser() {
    setIsloggedin(true)
  }

  function logoutUser() {
  localStorage.clear();
    // Cookies.remove('loggedIn');
    setIsloggedin(false)
  }


  useEffect(() => {
    if (isloggedin) {
      getPermission();
    }
    
  }, [isloggedin])

  if (isloggedin) {
    return (
      <div>
        <Main per={per} setIsloggedin={logoutUser} />
        <Footer per={per} />
      </div>
    );
  } else {
    return (
      <>
      <Login2 setIsloggedin={loginUser} />
      <ToastContainer
          className="p-3"
          position={'top-end'}
          style={{ zIndex: 10 }}
        >
      <Toast bg='danger' onClose={() => setToastShow(false)} show={toastShow}>
      <Toast.Header>
      <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto">Logged Out</strong>
      </Toast.Header>
      <Toast.Body>Session Ended</Toast.Body>
    </Toast>
    </ToastContainer>
    </>
     )
  }
  
}

export default Appstart
