import React from 'react';
import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { GetDisabledUsers } from '../../utils/proxy/DashboardProxy';
import { useState } from 'react';
import { Row } from 'react-bootstrap';
import { FaChartPie } from "react-icons/fa";

const ActiveInactiveUsers = () => {

  const [disabled, setDisabled] = useState({});

  useEffect(() => {
    const getdisabled = async () => {
      let d = await GetDisabledUsers();

      setDisabled(d);
    }
    getdisabled();
  }, [])

  const chartOptions = {
    chart: {
      type: 'donut',
      toolbar: {
        show: true,
      }
    },
    labels: ['In-Active', 'Active'],
    dataLabels: {
      enabled: false
    },
    pie: {
      donut: {
        labels: {
          show: true,
        },
      },
    },
    colors: ['#ff0000', '#006400'],
    // title: {
    //   text: 'Active/In-Active Agent Summary',
    //   style: {
    //     fontSize: '13px',
    //   }
    // },
    legend: {
      show: true,
      position: 'bottom',
      markers: {
        width: 12,
        height: 12,
        radius: 2 // To make the legend marker square, set radius to 0
      }
    }
  };

  let series = [];
  if(disabled){
    series = [disabled.inactive, disabled.active];
  }

  return (

    <>
        <Row>
    <div style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex', flexDirection: 'row', backgroundColor:'#FF8B13', borderRadius:20, padding:0,marginBottom:10, color:"white"    }}>
        <div md={4}>
            <div style={{ backgroundColor: '#f5dd4b', padding: '10px',marginRight:10, borderRadius: 50, height: 40, width: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <FaChartPie color='#000' size={30} />
            </div>
        </div>
        <div md={4}>
        <h6 style={{textAlign:'center', marginBottom:0}}>Active/In-Active Agent</h6>
        </div>
        <div md={4}>
      
        </div>
        
    </div>
</Row> 
              <div style={{ padding: 10 }}>
          <Chart options={chartOptions} series={series} type="pie" width='100%' height={238} />
        </div>
        </>
        
    
       
  );
};

export default ActiveInactiveUsers;
