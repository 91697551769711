import { FaPlus } from "react-icons/fa6"
import { IoHomeOutline } from "react-icons/io5"
import { Op_per } from "../../utils/Permissions"
import { Badge, Button, Card, Col, Dropdown, Form, Modal, OverlayTrigger, Placeholder, Popover, Row } from "react-bootstrap"
import Select from "react-select"
import { useState } from "react"
import { useEffect } from "react"
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy"
import { ChangeNoteStatus, CreateNote, DeleteNote, GetNotesByUserid, UpdateNote } from "../../utils/proxy/NotesProxy"
import Swal from "sweetalert2"
import Helper from "../../utils/helperFunctions"
import { GetAllProjectsByUserid } from "../../utils/proxy/ProjectProxy"
import { CreateTask, GetAllTaskCategory } from "../../utils/proxy/Taskproxy"

export const NotesPage = () => {

    const [data, setData] = useState([]);
    const [users, setusers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState(localStorage.getItem('role') === 'User' ? { value: localStorage.getItem('uuid'), label: localStorage.getItem('firstname') + " " + localStorage.getItem('lastname') + " (Self)" } : null);
    const [selectedUserId, setSelectedUserId] = useState(localStorage.getItem('uuid'));
    const [showadd, setshowAdd] = useState(false);
    const [id, setId] = useState();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [status, setStatus] = useState();
    const [reload, setReload] = useState();
    const [operation, setOperation] = useState('Add');
    const [showaddtask, setshowaddtask] = useState(false);
    const [task, setTask] = useState();
    const [desc, setDesc] = useState();
    const [startDate, setStartDate] = useState('');
    const [localStartDate, setLocalStartDate] = useState('');
    const [endDate, setEndDate] = useState('')
    const [localEndDate, setLocalEndDate] = useState('');
    const [allProject, setAllProject] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});
    const [selectedProjectId, setSelectedProjectId] = useState();
    const [allCategories, setAllCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [selectedCategoryId, setSelectedCategoryId] = useState();
    const [priority, setPriority] = useState('medium');
    const [isLoading, setIsLoading] = useState(false);

    const ModalOpen = () => { setshowAdd(true) }

    const ModalClose = () => {
        setId(null);
        setTitle(null)
        setDescription(null)
        setStatus(null)
        setshowAdd(false)
        setOperation('Add')
    }

    const newNote = data.filter(note => note.status === 'new');
    const inprogressNote = data.filter(note => note.status === 'inprogress');
    const completedNote = data.filter(note => note.status === 'completed');

    useEffect(() => {
        const getNotes = async () => {
            setIsLoading(true)
            let allnotes = await GetNotesByUserid(localStorage.getItem('uuid'));
            if (allnotes) {
                setData(allnotes);
                setIsLoading(false);
            }
        }
        getNotes();
        setReload(false);
    }, [reload])

    useEffect(() => {

        const getAllUsersData = async () => {
            if (localStorage.getItem('role') === 'Admin') {
                let allusers = await AllUsers();
                allusers.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
                setusers(allusers);
            } else if (localStorage.getItem('role') === 'Manager') {
                let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
                setusers(allusers);
            }
        };
        getAllUsersData();
    }, []);

    useEffect(() => {
        async function getProjects() {
            let data = await GetAllProjectsByUserid(selectedUserId);
            if (data) {
                setAllProject(data);
            } else {
                alert('Unable to Fetch');
            }
        }

        async function getCategories() {
            let data = await GetAllTaskCategory();
            if (data) {
                setAllCategories(data);
            } else {
                alert('Unable to Fetch');
            }
        }

        getProjects();
        getCategories();
    }, [selectedUserId])

    const handlesubmit = async () => {
        if (title == null) {
            Swal.fire({
                title: "Warning!",
                text: "Enter Note Title !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (description == null) {
            Swal.fire({
                title: "Warning!",
                text: "Enter Note description !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d;
            if (operation === 'Add') {
                d = await CreateNote(title, description);
            } else if (operation === 'Update') {
                d = await UpdateNote(id, title, description, status);
            }
            if (d.status === 'success') {
                setshowAdd(false);
                setReload(true);
                setId(null);
                setTitle(null);
                setDescription(null);
                setOperation('Add')
                Swal.fire({
                    title: "Success!",
                    text: `Note ${operation} Successfully`,
                    icon: 'success',
                    confirmButtonText: "OK",
                });
            } else {
                alert("Error", "Issue While Adding Note");
            }
        }
    }

    const handletasksubmit = async () => {
        if (task == null) {
            Swal.fire({
                title: "Warning!",
                text: "Enter Task Title !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (desc == null) {
            Swal.fire({
                title: "Warning!",
                text: "Enter Task Description !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (selectedUserId == null) {
            Swal.fire({
                title: "Warning!",
                text: "Enter AssignTo !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (priority == null) {
            Swal.fire({
                title: "Warning!",
                text: "Enter Task Priority !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (startDate == null || endDate == null || startDate == '' || endDate == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Date fields !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (startDate > endDate) {
            Swal.fire({
                title: "Warning!",
                text: "Start Date is Greater Than End Date !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await CreateTask({ task, "userid": selectedUserId, "projectid": selectedProjectId, "categoryid": selectedCategoryId, "start": startDate, "end": endDate, "description": desc, "createdby": localStorage.getItem('uuid'), "priority": priority, "status": "new" });
            if (d.status === "success") {
                await DeleteNote(id);
                setshowaddtask(false);
                setReload(true)
                setTask(null)
                setDesc(null)
                setStartDate(null);
                setLocalStartDate(null);
                setEndDate(null);
                setLocalEndDate(null);
                setPriority('medium')
                setSelectedUserId(null);
                setSelectedUsers(null);
                setSelectedProject(null)
                setSelectedProjectId(null)
                setSelectedCategory(null)
                setSelectedCategoryId(null)
                setId(null);
                Swal.fire({
                    title: "Success!",
                    text: "Converted To Task Successfully !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            } else {
                alert("Error", "Issue While Adding Task");
            }
        }
    }

    const handleConvertToTask = (row) => {
        setId(row.id);
        setTask(row.title);
        setDesc(row.description);
        setshowaddtask(true);
    }

    const options = [];
    const projects = [];
    const category = [];

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });

    allProject.map((project) => {
        projects.push({
            value: project.id,
            label: project.name
        })
    })

    allCategories.map((cat) => {
        category.push({
            value: cat.id,
            label: cat.name
        })
    })

    const handleSelectChange = (selected) => {
        setSelectedUsers(selected);
        setSelectedUserId(selected.value);
    };

    const handleProjectChange = (selected) => {
        if (selected === null) {
            setSelectedProject();
            setSelectedProjectId();
        } else {
            setSelectedProject(selected);
            setSelectedProjectId(selected.value);
        }

    };

    const handleCategoryChange = (selected) => {
        if (selected === null) {
            setSelectedCategory();
            setSelectedCategoryId();
        } else {
            setSelectedCategory(selected);
            setSelectedCategoryId(selected.value);
        }

    }

    const handlenotedelete = async (id) => {
        let d = await DeleteNote(id);
        if (d.status === 'success') {
            setReload(true);
            Swal.fire({
                title: "Success!",
                text: "Task Deleted Successfully !!",
                icon: "success",
                confirmButtonText: "OK",
            })
        } else {
            alert("Something Went Wrong")
        }
    }

    const handleNoteUpdate = async (row) => {
        setOperation('Update');
        setId(row.id);
        setTitle(row.title);
        setDescription(row.description);
        setStatus(row.status);
        setshowAdd(true)
    }

    const handleDragStart = (e) => {
        const crt = e.target.cloneNode(true);
        crt.style.position = "absolute";
        crt.style.left = "-1000px"; // Move off-screen
        crt.style.overflow = "hidden";
        crt.style.width = "20%";
        document.body.appendChild(crt);
        e.dataTransfer.setData("text", e.target.id);
        e.dataTransfer.setData("teaminsights-website", "true");
        e.dataTransfer.setDragImage(crt, 0, 0);

        // Remove the clone after dragging ends
        // e.target.addEventListener("dragend", () => {
        //     crt.parentNode.removeChild(crt);
        // }, { once: true });
    };

    const drop = async (e, status) => {
        e.preventDefault();
        if (e.dataTransfer.getData("teaminsights-website")) {
            var data = e.dataTransfer.getData("text");
            Swal.fire({
                title: "Updating!",
                text: "Updating Note Status !!",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                didOpen: async () => {
                    Swal.showLoading()
                    let d = await ChangeNoteStatus(data, status);
                    if (d.status === 'success') {
                        setReload(true);
                        Swal.hideLoading()
                        Swal.close()
                    }

                }
            });
        }

    }

    const handleDragEnd = (e) => {
        e.target.style.opacity = '1'; // Restore opacity after dragging ends
    };

    return (
        <>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                        /></a></li>
                        <li><a href="/notes"><span class="icon icon-beaker"> </span>Notes</a></li>
                        {/* <li><a href="/user-doctor-master"><span class="icon icon-beaker"> </span> User Doctor Master</a></li> */}
                        {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

                    </ul>

                </div>

            </div>
            <div style={{ overflow: 'visible', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', margin: '20px' }}>
                <div style={{ width: '300px', display: 'none' }}>
                    <Form.Group
                        className="mb-3 d-flex w-100"
                        style={{ gap: "15px" }}
                        controlId="formBasicPassword"
                    >
                        <Form.Label className="mt-2">Users</Form.Label>
                        <div className="selectdoctor">
                            <Select

                                options={options}
                                value={selectedUsers}
                                styles={{

                                    dropdownIndicator: (provided) => ({
                                        ...provided,
                                        color: '#2457C5' // Change color of the arrow here
                                    })
                                }}
                            />
                        </div>
                    </Form.Group>

                </div>

                <div style={{ float: 'right' }}>
                    <Button style={{ backgroundColor: "#28B3D2" }} onClick={ModalOpen}>
                        <FaPlus />
                    </Button>
                </div>
            </div>
            <hr />

            <div>
                <Row style={{ padding: 15 }}>
                    <Col style={{ background: '#eff3f6', marginRight: '10px', overflow: 'auto', borderRadius: '12px 12px 6px 6px', height: 600, border: '2px solid #00000094' }}>
                        <div className="note-head" style={{ background: 'rgb(0, 134, 255)' }}>New</div>
                        {isLoading ? <><Card style={{ borderRadius: 20, height: '150px', overflow: 'hidden' }}>
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                            </Card.Body>
                        </Card><Card style={{ borderRadius: 20, height: '150px', overflow: 'hidden' }}>
                                <Card.Body>
                                    <Placeholder as={Card.Title} animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>
                                    <Placeholder as={Card.Text} animation="glow">
                                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                        <Placeholder xs={6} /> <Placeholder xs={8} />
                                    </Placeholder>
                                </Card.Body>
                            </Card></> : <>
                            {newNote.map((task, index) => (
                                <div id={task.id} key={task.id} draggable={true} onDragStart={(e) => handleDragStart(e)} onDragEnd={(e) => handleDragEnd(e)}>
                                    <OverlayTrigger
                                        trigger="hover"
                                        key={task.id}
                                        placement={'bottom'}
                                        overlay={
                                            <Popover id={`popover-positioned`}>
                                                <Popover.Header as="h3">{task.title}</Popover.Header>
                                                <Popover.Body>
                                                    {task.description}
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <Card style={{ borderRadius: 20, backgroundColor: task.status == 'new' ? '#ADEAFE' : task.status == 'inprogress' ? '#FDE767' : '#6DEE7A', height: '150px', overflow: 'hidden' }}>
                                            <Dropdown >
                                                <Dropdown.Toggle style={{ backgroundColor: task.status == 'new' ? '#0086ff' : task.status == 'inprogress' ? '#ffbd00' : '#00b212', border: "none" }} className='text-right position-absolute top-0 end-0 pr-3 pt-2'></Dropdown.Toggle>
                                                <Dropdown.Menu style={{ WebkitAppearance: 'none' }}>
                                                    <Dropdown.Item style={{ display: Op_per.get().TASKS.ALLOW_EDIT ? '' : 'none' }} onClick={() => { handleConvertToTask(task) }}>
                                                        Convert To Task
                                                    </Dropdown.Item>
                                                    <Dropdown.Item style={{ display: Op_per.get().TASKS.ALLOW_EDIT ? '' : 'none' }} onClick={() => { handleNoteUpdate(task) }}>
                                                        Update
                                                    </Dropdown.Item>
                                                    <Dropdown.Item style={{ display: Op_per.get().TASKS.ALLOW_DELETE ? '' : 'none' }} onClick={() => handlenotedelete(task.id)}>
                                                        Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Card.Body>
                                                <Card.Title className='card-title' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} >{task.title}</Card.Title>
                                                <Card.Text>
                                                    <div style={{ height: "50px", overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }} >{task.description}</div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card></OverlayTrigger>
                                </div>
                            ))}</>}
                    </Col>
                    <Col onDrop={e => drop(e, 'inprogress')} onDragOver={e => { e.preventDefault() }} style={{ background: '#eff3f6', marginRight: '10px', overflow: 'auto', borderRadius: '12px 12px 6px 6px', height: 600, border: '2px solid #00000094' }}>
                        <div className="note-head" style={{ background: 'rgb(255, 189, 0)' }}>In-Progress</div>
                        {isLoading ? <><Card style={{ borderRadius: 20, height: '150px', overflow: 'hidden' }}>
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                            </Card.Body>
                        </Card><Card style={{ borderRadius: 20, height: '150px', overflow: 'hidden' }}>
                                <Card.Body>
                                    <Placeholder as={Card.Title} animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>
                                    <Placeholder as={Card.Text} animation="glow">
                                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                        <Placeholder xs={6} /> <Placeholder xs={8} />
                                    </Placeholder>
                                </Card.Body>
                            </Card></> : <>{inprogressNote.map((task, index) => (
                                <div id={task.id} key={task.id} draggable={true} onDragStart={(e) => handleDragStart(e)} onDragEnd={(e) => handleDragEnd(e)}>
                                    <OverlayTrigger
                                        trigger="hover"
                                        key={task.id}
                                        placement={'left-start'}
                                        overlay={
                                            <Popover id={`popover-positioned`}>
                                                <Popover.Header as="h3">{task.title}</Popover.Header>
                                                <Popover.Body>
                                                    {task.description}
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <Card style={{ borderRadius: 20, backgroundColor: task.status == 'new' ? '#ADEAFE' : task.status == 'inprogress' ? '#FDE767' : '#6DEE7A', height: '150px', overflow: 'hidden' }}>
                                            <Dropdown >
                                                <Dropdown.Toggle style={{ backgroundColor: task.status == 'new' ? '#0086ff' : task.status == 'inprogress' ? '#ffbd00' : '#00b212', border: "none" }} className='text-right position-absolute top-0 end-0 pr-3 pt-2'></Dropdown.Toggle>
                                                <Dropdown.Menu style={{ WebkitAppearance: 'none' }}>
                                                    <Dropdown.Item style={{ display: Op_per.get().TASKS.ALLOW_EDIT ? '' : 'none' }} onClick={() => { handleConvertToTask(task) }}>
                                                        Convert To Task
                                                    </Dropdown.Item>
                                                    <Dropdown.Item style={{ display: Op_per.get().TASKS.ALLOW_EDIT ? '' : 'none' }} onClick={() => { handleNoteUpdate(task) }}>
                                                        Update
                                                    </Dropdown.Item>
                                                    <Dropdown.Item style={{ display: Op_per.get().TASKS.ALLOW_DELETE ? '' : 'none' }} onClick={() => handlenotedelete(task.id)}>
                                                        Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Card.Body>
                                                <Card.Title className='card-title' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} >{task.title}</Card.Title>
                                                <Card.Text>
                                                    <div style={{ height: "50px", overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }} >{task.description}</div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card></OverlayTrigger>
                                </div>
                            ))}</>}
                    </Col>
                    <Col onDrop={e => drop(e, 'completed')} onDragOver={e => { e.preventDefault() }} style={{ background: '#eff3f6', marginRight: '10px', overflow: 'auto', borderRadius: '12px 12px 6px 6px', height: 600, border: '2px solid #00000094' }}>
                        <div className="note-head" style={{ background: 'rgb(0, 178, 18)' }}>Completed</div>
                        {isLoading ? <><Card style={{ borderRadius: 20, height: '150px', overflow: 'hidden' }}>
                            <Card.Body>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                    <Placeholder xs={6} /> <Placeholder xs={8} />
                                </Placeholder>
                            </Card.Body>
                        </Card><Card style={{ borderRadius: 20, height: '150px', overflow: 'hidden' }}>
                                <Card.Body>
                                    <Placeholder as={Card.Title} animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>
                                    <Placeholder as={Card.Text} animation="glow">
                                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                        <Placeholder xs={6} /> <Placeholder xs={8} />
                                    </Placeholder>
                                </Card.Body>
                            </Card></> : <>{completedNote.map((task, index) => (
                                <div id={task.id} key={task.id}>
                                    <OverlayTrigger
                                        trigger="hover"
                                        key={task.id}
                                        placement={'left-start'}
                                        overlay={
                                            <Popover id={`popover-positioned`}>
                                                <Popover.Header as="h3">{task.title}</Popover.Header>
                                                <Popover.Body>
                                                    {task.description}
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <Card style={{ borderRadius: 20, backgroundColor: task.status == 'new' ? '#ADEAFE' : task.status == 'inprogress' ? '#FDE767' : '#6DEE7A', height: '150px', overflow: 'hidden' }}>
                                            <Dropdown >
                                                <Dropdown.Toggle style={{ backgroundColor: task.status == 'new' ? '#0086ff' : task.status == 'inprogress' ? '#ffbd00' : '#00b212', border: "none" }} className='text-right position-absolute top-0 end-0 pr-3 pt-2'></Dropdown.Toggle>
                                                <Dropdown.Menu style={{ WebkitAppearance: 'none' }}>
                                                    <Dropdown.Item style={{ display: Op_per.get().TASKS.ALLOW_EDIT ? '' : 'none' }} onClick={() => { handleConvertToTask(task) }}>
                                                        Convert To Task
                                                    </Dropdown.Item>
                                                    <Dropdown.Item style={{ display: Op_per.get().TASKS.ALLOW_EDIT ? '' : 'none' }} onClick={() => { handleNoteUpdate(task) }}>
                                                        Update
                                                    </Dropdown.Item>
                                                    <Dropdown.Item style={{ display: Op_per.get().TASKS.ALLOW_DELETE ? '' : 'none' }} onClick={() => handlenotedelete(task.id)}>
                                                        Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Card.Body>
                                                <Card.Title className='card-title' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} >{task.title}</Card.Title>
                                                <Card.Text>
                                                    <div style={{ height: "50px", overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }} >{task.description}</div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card></OverlayTrigger>
                                </div>
                            ))}</>}
                    </Col>
                </Row>
            </div>

            <Modal show={showadd} onHide={() => ModalClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>{operation} Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Title <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control
                        style={{
                            height: 46,

                            borderColor: '#28B3D2',
                            borderWidth: 2,
                            paddingHorizontal: 8,
                            marginBottom: 16,
                            borderTopLeftRadius: 20,
                            borderBottomRightRadius: 20,
                        }}
                        type="text"
                        placeholder="Enter Note Title"
                        value={title}
                        onChange={(e) => { setTitle(e.target.value) }}
                    />
                    <Form.Label>Description <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control
                        className="selectdoctor"
                        // style={{
                        //     height: 46,

                        //     borderColor: '#28B3D2',
                        //     borderWidth: 2,
                        //     paddingHorizontal: 8,
                        //     marginBottom: 16,
                        //     borderTopLeftRadius: 20,
                        //     borderBottomRightRadius: 20,
                        // }}
                        as='textarea'
                        rows={3}
                        placeholder='Enter Task Description'
                        value={description}
                        onChange={(e) => { setDescription(e.target.value) }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => ModalClose()}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handlesubmit}
                    >
                        {operation} Note
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showaddtask} onHide={() => setshowaddtask(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Title <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 46,

                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                type="text"
                                placeholder="Enter Task Title"
                                value={task}
                                onChange={(e) => { setTask(e.target.value ) }}
                            />
                            <Form.Label >Assign To <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Group
                                className="mb-3 d-flex"
                                style={{ gap: "15px" }}
                                controlId="formBasicPassword"
                            >
                                <div className="selectdoctor" >
                                    <Select

                                        options={options}
                                        value={selectedUsers}
                                        onChange={handleSelectChange}
                                        styles={{

                                            dropdownIndicator: (provided) => ({
                                                ...provided,
                                                color: '#2457C5' // Change color of the arrow here
                                            })
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Label>Assign Project</Form.Label>
                            <Form.Group
                                className="mb-3 d-flex w-100"
                                style={{ gap: "15px" }}
                                controlId="formBasicPassword"
                            >
                                <div className="selectdoctor">
                                    <Select
                                        options={projects}
                                        value={selectedProject}
                                        onChange={handleProjectChange}
                                        isClearable={true}
                                        styles={{

                                            dropdownIndicator: (provided) => ({
                                                ...provided,
                                                color: '#2457C5' // Change color of the arrow here
                                            })
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Label>Task Category <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Group
                                className="mb-3 d-flex w-100"
                                style={{ gap: "15px" }}
                                controlId="formBasicPassword"
                            >
                                <div className="selectdoctor">
                                    <Select
                                        options={category}
                                        value={selectedCategory}
                                        onChange={handleCategoryChange}
                                        isClearable={true}
                                        styles={{

                                            dropdownIndicator: (provided) => ({
                                                ...provided,
                                                color: '#2457C5' // Change color of the arrow here
                                            })
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Label>Description <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 46,

                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                as='textarea'
                                placeholder='Enter Task Description'
                                value={desc}
                                onChange={(e) => { setDesc(e.target.value) }} />
                            <Form.Label>Start Date <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 46,

                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                type='datetime-local'
                                value={localStartDate}
                                onChange={(e) => { setStartDate(new Date(e.target.value).toISOString()); setLocalStartDate(Helper.dateToIsoStringWithoutChange(new Date(e.target.value)).substr(0, 16)) }}
                            />
                            <Form.Label>End Date <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 46,

                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                type='datetime-local'
                                value={localEndDate}
                                onChange={(e) => { setEndDate(new Date(e.target.value).toISOString()); setLocalEndDate(Helper.dateToIsoStringWithoutChange(new Date(e.target.value)).substr(0, 16)) }} />
                        </Form.Group>
                        <div style={{ margin: '10px' }}>
                            <div>
                                <Form.Label>Priority <span style={{ color: 'red' }}>*</span>: </Form.Label>
                                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: 'rgb(59, 130, 246)', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                                    <label for="f-option" class="l-radio">
                                        <input type="radio" id="f-option" name="selector" tabindex="1" value="low" onChange={(e) => { setPriority(e.target.value) }} checked={priority == 'low'} />
                                        <span style={{ margin: '5px' }}>LOW</span>
                                    </label>
                                </div>
                                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#28a745', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                                    <label for="s-option" class="l-radio">
                                        <input type="radio" id="s-option" name="selector" tabindex="2" value="medium" onChange={(e) => { setPriority(e.target.value) }} checked={priority == 'medium'} />
                                        <span style={{ margin: '5px' }}>MEDIUM</span>
                                    </label>
                                </div>
                                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#ff0018', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                                    <label for="t-option" class="l-radio">
                                        <input type="radio" id="t-option" name="selector" tabindex="3" value="high" onChange={(e) => { setPriority(e.target.value) }} checked={priority == 'high'} />
                                        <span style={{ margin: '5px' }}>HIGH</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setshowaddtask(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handletasksubmit}
                    >
                        Create Task
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}