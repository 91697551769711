import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

export const GetAllDeduction = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/deduction',null);
}

export const CreateDeduction = async (name,amount,description) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        name:name,
        amount:amount,
        description:description
    }
    return await Restapi('post',REPORTING_URL+'/apiv1/deduction',data);
}

export const EditDeduction = async (id,name,amount,description) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        name:name,
        amount:amount,
        description:description
    }
    return await Restapi('put',REPORTING_URL+'/apiv1/deduction/'+id,data);
}

export const DeleteDeduction = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/deduction/'+id,null);
}

export const GetUserDeductionByUserid = async (userid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/userdeduction/'+userid,null);
}

export const CreateUserDeduction = async (deductionid,userid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        deductionid,
        userid
    }
    return await Restapi('post',REPORTING_URL+'/apiv1/userdeduction',data);
}