import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

export const GetAllLeaveType = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/leavetype',null);
}

export const CreateLeaveType = async (name,description) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        name:name,
        description:description
    }
    return await Restapi('post',REPORTING_URL+'/apiv1/leavetype',data);
}

export const EditLeaveType = async (id,name, description) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data ={
      name : name,
      description : description
    }
    return await Restapi('put',REPORTING_URL+'/apiv1/leavetype/'+id,data);
}

export const DeleteLeaveType = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/leavetype/'+id,null);
}
