import { Button, Container, Dropdown, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Form from 'react-bootstrap/Form';
import { useState } from "react";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { useEffect } from "react";
import { CreateExpenseType, DeleteExpenseType, EditExpenseType, GetAllExpenseType } from "../../utils/proxy/Expensetypemasterproxy";
import Swal from "sweetalert2";
import { HiDotsVertical } from "react-icons/hi";
import { FaPlus } from "react-icons/fa6";
import { IoHomeOutline } from "react-icons/io5";
import { Op_per } from "../../utils/Permissions";

function Expensetypemaster() {

    const [show, setShow] = useState(false);
    const [showedit, setShowedit] = useState(false);
    const [reload, SetReload] = useState(false);
    const [data, setData] = useState();

    const [id, setId] = useState();
    const [name, setName] = useState();
    const [description, setDescription] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const getall = async () => {
            let d = await GetAllExpenseType();
            if (d) {
                setData(d);
            } else {
                alert('Error', 'Issues while fetching data')
            }
        }
        getall()
        SetReload(false);
    }, [reload])

    const handleSave = async () => {

        if (name == null || name == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Expense Type !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await CreateExpenseType(name, description);
            if (d.status === 'success') {
                setShow(false);
                SetReload(true);
                setName(null);
                setDescription(null);
                Swal.fire({
                    title: "Success!",
                    text: "New Expense Type Added !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const handledelete = async (id) => {
        let d = await DeleteExpenseType(id);
        if (d.status === 'success') {
            SetReload(true);
            Swal.fire({
                title: "Success!",
                text: "Expense Type Deleted !!",
                icon: "success",
                confirmButtonText: "OK",
            });
        }
    }

    const handleEditShow = (row) => {

        setShowedit(true);
        setId(row.id);
        setName(row.name);
        setDescription(row.description);
    }

    const handleEditSave = async () => {
        if (name == null || name == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Expense Type !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await EditExpenseType(id, name, description);
            if (d.status === 'success') {
                setShowedit(false);
                SetReload(true);
                setId(null);
                setName(null);
                setDescription(null);
                Swal.fire({
                    title: "Success!",
                    text: "Expense Type Edited !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const columns = [
        // {
        //     name: "Id",
        //     selector: (row) => row.id,
        //    
        //     width:"10%",
        // },
        {
            name: "Expense Type",
            selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.name === "" || row.name === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.name === "" || row.name === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.name === "" || row.name === null ? "#fff": "#000", margin:"3px", padding:"5px" }}>{row.name ? row.name : "N/A"}</div>,
           
            width:"15%",
        },
        {
            name: "Description",
            selector: (row) => 
            <div style={{ whiteSpace: 'normal',backgroundColor: row.description === "" || row.description === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.description === "" || row.description === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.description === "" || row.description === null ? "#fff": "#000", margin:"3px", padding:"5px" }}>{row.description ? row.description : "N/A"}</div>
           ,
           
            width:"20%",
        },
    ];

    const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
    const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    if (Op_per.get().MASTERS_EXPENSETYPES.ALLOW_DELETE || Op_per.get().MASTERS_EXPENSETYPES.ALLOW_EDIT) {
        columns.push({
            name: "Actions",
            selector: (row) => (
                <div key={row.id}>
                    <AiFillDelete
                        onClick={() => handledelete(row.id)}
                        style={{ color: "red", fontSize: 20,display:Op_per.get().MASTERS_EXPENSETYPES.ALLOW_DELETE ? '' : 'none' }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AiTwotoneEdit
                        onClick={() => handleEditShow(row)}
                        style={{ color: "black", fontSize: 20,display:Op_per.get().MASTERS_EXPENSETYPES.ALLOW_EDIT ? '' : 'none' }}
                    />
                </div>
            ),
        })
    }

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding:0,
          backgroundColor:'#3887BE'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/expensetype-master"><span class="icon icon-beaker"> </span>Masters</a></li>
  <li><a href="/expensetype-master"><span class="icon icon-beaker"> </span>Expense Types </a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <Form.Control
                         style={{height: 46,
            width:250,
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                            className="glass"
                            placeholder="Search"
                            type="text"
                           
                        //   value={searchQuery}
                        //   onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div>
                        {Op_per.get().MASTERS_EXPENSETYPES.ALLOW_ADD ? (
                            <Button style={{backgroundColor: "#28B3D2 " }} onClick={handleShow}>
                                <FaPlus />
                            </Button>
                        ) : ("")}
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <DataTable
                        columns={columns}
                        data={data}
                        // progressPending={pending}
                        // selectableRows
                        // onSelectedRowsChange={({ selectedRows }) =>
                        // setSelectedRows(selectedRows)
                        //   }
                        pagination
                         customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
                        // actions={actionsMemo}
                        style={{ marginTop: 10 }}
                        // progressPending={pending}
                        // progressComponent={<LoadingComponent/>}
                        dense
                    />
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Expense Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Expense Type <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                            style={{height: 46,
          
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter Expense Type Name"
                                required
                            />
                        </Form.Group>
                        <Form.Label>Description <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Control
                        style={{height: 80,
           
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                            type="text"
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter Description"
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showedit} onHide={() => setShowedit(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Expense Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Expense Type <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                            style={{height: 46,
           
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter Expense Type Name"
                                required
                            />
                        </Form.Group>
                        <Form.Label>Description <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Control
                        style={{height: 80,
          
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter Description"
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowedit(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleEditSave}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    )
}

export default Expensetypemaster;