import { Button, Container, Form, OverlayTrigger, Table, Tooltip, InputGroup } from "react-bootstrap"
import { IoHomeOutline } from "react-icons/io5"
import { Op_per } from "../../../utils/Permissions"
import { FaPlus, FaRegFilePdf } from "react-icons/fa"
import DataTable from "react-data-table-component"
import { useEffect, useRef } from "react"
import { useState } from "react"
import { GetAllQuotes, GetQuoteByOwnerid } from "../../../utils/proxy/QuoteProxy"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { AiTwotoneEdit } from "react-icons/ai"
import PdfGenerator from "../../Common/PdfGenerator"
import ReactDomServer from 'react-dom/server'
import { Navigate } from "react-big-calendar"
import { Navigation } from "react-calendar"
import Swal from "sweetalert2"

export const QuoteMaster = () => {
    function redirect() {
        window.location.href = "/quotecr"
    }

    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [borderColor, setBorderColor] = useState(false);

    let fullName =  searchQuery.split(" ");
    
    
    const filteredData = data.filter((k) =>
        k.company.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (fullName[0] && k.firstname.toLowerCase().includes(fullName[0].toLowerCase())) ||
        (fullName[1] && k.lastname.toLowerCase().includes(fullName[1].toLowerCase()))
    );

  
    
    const handleSearchQuery = (e) => {
        let rex = /^[a-zA-Z]+$/;
        let n = rex.test(e);
        console.log(n);
        if(e != ""){
            setBorderColor(!n);
        } else if(n == ""){
            setBorderColor(n);
        }
        setSearchQuery(e);
    }
    
    useEffect(() => {
        const getall = async () => {
            let d ;
            if(localStorage.getItem('role') == 'Admin' || localStorage.getItem('role') == 'Manager'){
                d = await GetAllQuotes();
            }else{
                d = await GetQuoteByOwnerid(localStorage.getItem('uuid'));
            }

            if (d) {
                setData(d);
            } else {
                alert('Error', 'Issues while fetching data')
            }
        }
        getall();
    }, [])

    const generatePDF = (products) => {
          const pdf = new jsPDF({
            orientation: 'portrait', // or 'landscape'
            unit: 'px', // units: 'mm', 'in', 'px'
            format: 'a4' // or a custom size [width, height]
          });
          
          pdf.html(ReactDomServer.renderToStaticMarkup(<PdfGenerator data={products} />), {
            callback: function (pdf) {
              pdf.save('download.pdf');
            },
            margin: [10, 10, 10, 10],
            autoPaging: 'text',
            // x: 0,
            // y: 0,
            width: 450, //target width in the PDF document
            windowWidth: 1024
          });
      };

    function calculateDiscount(originalPrice, discountPercentage) {

        const discountAmount = (originalPrice * discountPercentage) / 100;
        const finalPrice = originalPrice - discountAmount;
        return finalPrice;
    }

    const CustomHeader = ({ children }) => (<div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    
    const columns = [
        
        {
            name: "Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.firstname === "" || row.firstname === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.firstname === "" || row.firstname === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.firstname === "" || row.firstname === null ? "#fff" : "#2457C5", margin: "3px", }}>{(row.firstname && row.lastname) ? row.firstname + ' ' + row.lastname : row.firstname ? row.firstname : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Company",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.company === "" || row.company === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.company === "" || row.company === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.company === "" || row.company === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.company ? row.company : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Title",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.title === "" || row.title === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.title === "" || row.title === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.title === "" || row.title === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.title ? row.title : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Email",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.email === "" || row.email === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.email === "" || row.email === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.email === "" || row.email === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.email ? row.email : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Phone",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.phone === "" || row.phone === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.phone === "" || row.phone === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.phone === "" || row.phone === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.phone ? row.phone : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Validity",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.duedate === "" || row.duedate === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.duedate === "" || row.duedate === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.duedate === "" || row.duedate === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.duedate ? row.duedate.split('T')[0] : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Actions",
            selector: (row) => (
                <div key={row.id}>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Generate Quote</Tooltip>}>
                    <div>
                    <FaRegFilePdf
                        onClick={() => generatePDF(row)}
                        style={{ color: "black", fontSize: 20, display: Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_EDIT ? '' : 'none' }}
                    />
                    </div>
                    </OverlayTrigger>
                </div>
            )
        }
    ];

    const ExpandedComponent = ({ data }) => (
        <div style={{
            backgroundColor: data.disabled ? "red" : "#D9E1FF",
            color: "#fff",
            padding: 10,
            fontSize: 12,
            margin: 20,
            borderRadius: 20,
            border: "2px solid #28B3D2",
            width: "95%"

        }}>
            <Table bordered responsive='md' className="mt-3">
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>Discount</th>
                        <th>Total</th>
                        <th>Tax Price</th>
                    </tr>
                </thead>
                <tbody>
                    {data.products.length > 0 ? (
                        <>
                            {data.products.map((d, index) => (
                                <tr key={index}>
                                    <td>{d.productname}</td>
                                    <td>{d.quantity}</td>
                                    <td>{d.price}</td>
                                    <td>{d.discount}</td>
                                    <td>{d.total}</td>
                                    <td>{d.taxprice}</td>
                                </tr>
                            ))}
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td colSpan="2">Grand Total - {calculateDiscount(data.products.reduce((sum, item) => sum + parseFloat(item.total || 0), 0),data.products.reduce((sum, item) => sum + parseFloat(item.discount || 0), 0))}</td>
                            </tr>
                        </>
                    ) : (
                        <tr>
                            <td style={{paddingLeft:'45%',paddingTop:'3%',fontWeight:'bold' }} colSpan="6">No Products</td>
                        </tr>
                    )}

                </tbody>
            </Table>
        </div>
    )

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}

                        /></a></li>
                        <li><a href="/quote-master"><span class="icon icon-beaker"> </span>Master</a></li>
                        <li><a href="/quote-master"><span class="icon icon-beaker"> </span>Quote</a></li>
                       

                    </ul>

                </div>
            </div>

            <div id="content" className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                    <InputGroup className="">
                        <Form.Control
                            placeholder="Search Quotes"
                            value= {searchQuery}
                            onChange={(e) => {handleSearchQuery(e.target.value)}}
                            
                        />
                    </InputGroup>
                        {borderColor ? <span style={{fontSize:"small", color:"red"}}>Special characters not allowed</span> : ""}
                    </div>
                    
                    
                    <div>
                    <Button style={{ backgroundColor: "#28B3D2" }} onClick={redirect}>
                    <FaPlus />
                    </Button>
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        customStyles={{ headRow: { style: { backgroundColor: '#E5E1DA', padding: 7 }, } }}
                        CustomHeader={<CustomHeader />}
                        style={{ marginTop: 10 }}
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        dense
                    />
                </div>
            </div>
        </Container>
    )
}