import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

export const GetAllRole = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/userrole", null);
};

export const AddRole = async (data) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi(
    "post",
    REPORTING_URL + "/apiv1/userrole",
    JSON.stringify(data)
  );
};

export const EditRole = async (data) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi(
    "put",
    REPORTING_URL + "/apiv1/userrole",
    JSON.stringify(data)
  );
};

export const DeleteRole = async (roleId) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi(
    "delete",
    REPORTING_URL + `/apiv1/userrole/${roleId}`,null
  );
};
