import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

export const CreateExpenseType = async (expensetypename, description) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        'name':expensetypename,
        'description':description
    }
    return await Restapi('post',REPORTING_URL+'/apiv1/expensetype',data);
}

export const GetAllExpenseType = async () =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/expensetype',null);
}

export const DeleteExpenseType = async (id) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/expensetype/'+id,null);
}

export const EditExpenseType = async (id, expensetypename, description) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        'name':expensetypename,
        'description':description
    }
    return await Restapi('put',REPORTING_URL+'/apiv1/expensetype/'+id,data);
}