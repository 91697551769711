import React, { useEffect, useState } from "react";
import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import {
  GetAllCountries,
  GetAllCitiesDetail,
  GetAllAreasDetail,
  GetAllStatesDetail,
} from "../../utils/proxy/Countryproxy";
import {
  GetAllClinics,
  CreateClinic,
  UpdateClinic,
  CheckClinicAvailable,
  CreateExcelClinic,
  DeleteClinic,
  ClinicBulkUpdateArea,
  BulkDeleteClinic,
  GetAllClinicsPagination,
  UpdateClinicLat,
} from "../../utils/proxy/Clinicproxy";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import Select from "react-select";
import * as XLSX from "xlsx";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import Dropdown from "react-bootstrap/Dropdown";
import { Parser } from "@json2csv/plainjs";
import { unwind, flatten } from "@json2csv/transforms";
import Helper from "../../utils/helperFunctions";
import { AllUsers } from "../../utils/proxy/Authproxy";
import LoadingComponent from "../Includes/LoadingComponent";
import { HiDotsVertical } from "react-icons/hi";
import { SiDatabricks } from "react-icons/si";
import { IoHomeOutline } from "react-icons/io5";
import { Op_per } from "../../utils/Permissions";
import { FaMapMarked } from "react-icons/fa";
import { LuMapPinOff } from "react-icons/lu";

function ClinicMaster() {
  const [clinics, setClinics] = useState([]);
  const [areas, setAreas] = useState([]);
  const [citys, setCitys] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [clinicname, setClinicName] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [areasid, setAreasid] = useState([]);
  const [clinicaddress, setClinicAddress] = useState("");
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [pincode, setPincode] = useState();
  const [number, setNumber] = useState();
  const [email, setEmail] = useState();
  const [clinicid, setClinicId] = useState();
  const [pending, setPending] = React.useState(true);
  const [show, setShow] = useState(false);
  const [showuploader, setShowUploader] = useState(false);
  const [showedit, setShowEdit] = useState(false);
  const [isclinicname, setIsClinicName] = useState();
  const [reload, SetReload] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const [editdata, setEditdata] = useState();

  const [showbulkareaedit, setShowBulkAreaEdit] = useState(false);
  const [bulkSourceData, setBulkSourceData] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [users, setUsers] = useState([]);

  const [perPage, setPerage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState();

  const [excelData, setExcelData] = useState([]);
  const [columnTitles, setColumnTitles] = useState({
    title1: excelData.length > 0 ? Object.keys(excelData[0])[0] : "",
    title2: excelData.length > 0 ? Object.keys(excelData[0])[1] : "",
    title3: excelData.length > 0 ? Object.keys(excelData[0])[2] : "",
    title4: excelData.length > 0 ? Object.keys(excelData[0])[3] : "",
    title5: excelData.length > 0 ? Object.keys(excelData[0])[4] : "",
  });
  const [fileUploaded, setFileUploaded] = useState(false);


  const getallclinics = async (page) => {
    let clinicsdata = await GetAllClinicsPagination(page, perPage, searchQuery);
    if (clinicsdata) {
      setClinics(clinicsdata[0].clinics);
      setTotalRows(clinicsdata[0].totalrows);
      setCurrentPage(clinicsdata[0].currentpage);
      setTotalPages(clinicsdata[0].totalpages);
    } else {
      alert("Error", "Issue While Fetching Clinics");
    }
  };

  useEffect(() => {
    // Fetch data from an API when the component mounts
    getallclinics(currentPage == undefined ? 0 : currentPage);
    setPending(false);
    SetReload(false);
  }, [reload, searchQuery]);

  const handlePageChange = page => {

    getallclinics(page - 1);

  }

  const handlePerRowChange = async (newPerPage, page) => {
    setPerage(newPerPage);
    const areasdata = await GetAllClinicsPagination(page - 1, newPerPage, searchQuery);
    if (areasdata) {
      setClinics(areasdata[0].clinics);
      setTotalRows(areasdata[0].totalrows)
      setTotalPages(areasdata[0].totalpages);
      setPending(false);
    } else {
      alert("Error", "Issue While Fetching Doctors");
    };
  }

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {
      let allusers = await AllUsers();
      if (allusers) {
        setUsers(allusers);
      } else {
        alert("Error", "Issue While Fetching Users");
      }
    };
    getallusers();
  }, []);

  const userOptions = [];

  users.map((user) => {
    userOptions.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallcitys = async () => {
      let citysdata = await GetAllCitiesDetail();
      if (citysdata) {
        setCitys(citysdata);
      } else {
        alert("Error", "Issue While Fetching City");
      }
    };
    getallcitys();

    const getallareas = async () => {
      let areas = await GetAllAreasDetail();
      if (areas) {
        setAreas(areas);
      } else {
        alert("Error", "Issue While Fetching Territories");
      }
    };
    getallareas();

    const getallcountries = async () => {
      let countriesdata = await GetAllCountries();
      if (countriesdata) {
        setCountries(countriesdata);
      } else {
        alert("Error", "Issue While Fetching Countries");
      }
    };
    getallcountries();

    const getallstates = async () => {
      let statesdata = await GetAllStatesDetail();
      if (statesdata) {
        setStates(statesdata);
      } else {
        alert("Error", "Issue While Fetching State");
      }
    };
    getallstates();
  }, [show]);

  // const filteredData = clinics.filter((row) => {
  //   if (row.clinicname.toLowerCase().includes(searchQuery.toLowerCase())) {
  //     return true;
  //   }

  //   // If there are users associated with the doctor, loop through them.
  //   if (row.users && row.users.length > 0) {
  //     for (let user of row.users) {
  //       if (user.username.toLowerCase().includes(searchQuery.toLowerCase())) {
  //         return true;
  //       }
  //     }
  //   }

  //   return false;
  // }
  // );

  const options = [];

  areas.map((area) => {
    options.push({ value: area.id, label: area.areaname });
  });



  useEffect(() => {
    const checkClinicNameAvailable = async (clinicname) => {
      if (clinicname.length > 0) {
        let statedata = await CheckClinicAvailable(clinicname);
        if (statedata) {
          setIsClinicName(true);
        } else {
          setIsClinicName(false);
        }
      }
    };
    checkClinicNameAvailable(clinicname);
  }, [clinicname]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setIsClinicName(false);
    setShow(true);
  };

  const handleUploaderShow = () => setShowUploader(true);
  const handleUploaderClose = () => {
    setExcelData([]);
    setColumnTitles({
      title1: "",
      title2: "",
      title3: "",
    });
    setFileUploaded(false);
    setShowUploader(false);
  };

  const handleEditShow = (row) => {
    setClinicName(row.clinicname);
    let ar = [];
    let arid = [];
    row.areas.map((area) => {
      ar.push({ value: area.id, label: area.areaname });
      arid.push(area.id);
      return true;
    });
    setSelectedAreas(ar);
    setAreasid(arid);
    setClinicAddress(row.address);
    setState(row.stateid);
    setCity(row.cityid);
    setPincode(row.pincode);
    setCountry(row.countryid);
    setNumber(row.phone);
    setEmail(row.email);
    setClinicId(row.id);
    setShowEdit(true);
  };
  const handleEditClose = () => {
    setClinicName("");
    setSelectedAreas([]);
    setAreasid([]);
    setClinicAddress("");
    setState("");
    setCity("");
    setPincode("");
    setCountry("");
    setNumber("");
    setEmail("");
    setShowEdit(false);
  };

  const handleCountryCodeChange = (event) => {
    setCountry(event.target.value);
  };

  const handleClinicNameChange = (event) => {
    setClinicName(event.target.value);
  };

  const handleSelectChange = (selected) => {
    const values = selected.map((option) => option.value);
    setSelectedAreas(selected);
    setAreasid(values);
  };

  const handleClinicAddressChange = (event) => {
    setClinicAddress(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handlePincodeChange = (event) => {
    setPincode(event.target.value);
  };

  const handleNumberChange = (event) => {
    setNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  let createClinic = async (
    clinicname,
    areasid,
    clinicaddress,
    country,
    state,
    city,
    pincode,
    number,
    email
  ) => {
    let statedata = await CreateClinic(
      clinicname,
      areasid,
      clinicaddress,
      country,
      state,
      city,
      pincode,
      number,
      email
    );
    if (statedata) {
      SetReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: "Clinics Added Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Adding Clinics");
    }
  };

  let updateClinic = async (
    clinicid,
    clinicname,
    areasid,
    clinicaddress,
    country,
    state,
    city,
    pincode,
    number,
    email
  ) => {
    let statedata = await UpdateClinic(
      clinicid,
      clinicname,
      areasid,
      clinicaddress,
      country,
      state,
      city,
      pincode,
      number,
      email
    );
    if (statedata) {
      SetReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: "Clinic Updated Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Updating Clinics");
    }
  };

  let deleteClinic = async (clinicid) => {
    let statedata = await DeleteClinic(clinicid);
    if (statedata) {
      SetReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: "Clinic Deleted !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Deleting Clinics");
    }
  };

  let handleSave = (event) => {
    if (isclinicname === false) {
      createClinic(
        clinicname,
        areasid,
        clinicaddress,
        country,
        state,
        city,
        pincode,
        number,
        email
      );
    }
  };

  let handleUpdate = (event) => {
    updateClinic(
      clinicid,
      clinicname,
      areasid,
      clinicaddress,
      country,
      state,
      city,
      pincode,
      number,
      email
    );
  };

  let handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete the Clinic?",

      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteClinic(id);
      }
    });
  };

  let handleLocDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete the Location?",

      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
       // deleteLocation(id);
       UpdateClinicLat(id, "", "");
      }
    });
  };

  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => <span >{row.id}</span>,
    //   width: '3%',
    //   fontSize: 8,
    //   sortable: true,
    // },
    {
      name: "Clinics",
      selector: (row) =>
      <div style={{ whiteSpace: 'normal',backgroundColor: row.clinicname === "" || row.clinicname === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.clinicname === "" || row.clinicname === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.clinicname === "" || row.clinicname === null ? "#fff": "#000", margin:"3px", }}>{row.clinicname ? row.clinicname : "N/A"}</div>,
      
      width: '10%',
      sortable: true,
      fontSize: 10,
      fontWeight:'bold'
    },
    {
      name: "Address",
      selector: (row) => <div style={{ whiteSpace: 'normal' }}>{row.address}</div>,
      sortable: true,
      width: '15%',
    },
    {
      name: "Location",
      selector: (row) => <div style={{ whiteSpace: 'normal',textAlign:'center' }}>{row.latitude && <a target="_blank" href={`http://maps.google.com/maps?q=${row.latitude},${row.longitude}`}><FaMapMarked size={20} />
      </a>}</div>,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) =>  <div style={{ whiteSpace: 'normal',backgroundColor: row.phone === "" || row.phone === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.phone === "" || row.phone === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.phone === "" || row.phone === null ? "#fff": "#000", margin:"3px", }}>{row.phone ? row.phone : "N/A"}</div>,
      sortable: true,
      width: '8%',
      paddingLeft: 10
      
    },
    {
      name: "Territories",
      selector: (row) =>
      <div style={{ whiteSpace: 'normal' }}>{row.areas.map((hobby) => (
        <Button style={{padding:1,fontSize:'11px',fontWeight:'bold',margin:3,padding:2,backgroundColor:"#D9E1FF",borderRadius:"10px",border: "2px solid #28B3D2",color:'#000'}} key={hobby.id}>
        <Badge style={{color:"#fff", borderRadius:"5px",backgroundColor:"#2457C5",padding:"3px",}} >{hobby.id}</Badge> {hobby.areaname}
    </Button>
     ))}</div>,
      sortable: true,
      width: "17%"
    },
    {
      name: "Users",
      selector: (row) => row.users != [] && row.users.length > 0 ?
        <div style={{ whiteSpace: 'normal' }}>{
          row.users.map((hobby) => (
            <OverlayTrigger
              key={hobby.id}
              placement="top"
              overlay={
                <Tooltip style={{ maxWidth: "300px", fontSize: 10 }}>
                  <p>Username : {hobby.username} </p>
                  <p>Email : {hobby.email} </p>
                  <p>Firstname : {hobby.firstname} </p>
                  <p>Lastname : {hobby.lastname} </p>
                  <p>Address1 : {hobby.address1} </p>
                  <p>Address2 : {hobby.address2} </p>
                  <p>Role : {hobby.role}</p>
                </Tooltip>
              }
            >
             <Button style={{padding:1,fontSize:'11px',fontWeight:'bold',margin:3,padding:2,backgroundColor:"#D9E1FF",borderRadius:"10px",border: "2px solid #28B3D2",color:'#000'}} >{hobby.username}
        
        </Button>
            </OverlayTrigger>
          ) )}</div>: <div style={{backgroundColor:"red", borderRadius:"10px", border: "2px solid red",padding:"3px", display:"flex", flexDirection:"row",color:"#fff", margin:"3px" }} > 
          <div >
         
          N/A
          </div> 
        </div>,
      sortable: true,
      width: "200px"
    },
    // {
    //   name: "Created By",
    //   selector: (row) => 
    //   <div style={{ whiteSpace: 'normal',backgroundColor: row.createdbyuser === "" || row.createdbyuser === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.createdbyuser === "" || row.createdbyuser === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.createdbyuser === "" || row.createdbyuser === null ? "#fff": "#000", margin:"3px", }}>{row.createdbyuser ? row.createdbyuser : "N/A"}</div>
    
    // ,
    //   sortable: true,
    //   width: "200px"
    // },

  ];

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);


  if (Op_per.get().MASTERS_CLINICS.ALLOW_DELETE || Op_per.get().MASTERS_CLINICS.ALLOW_EDIT) {
    columns.push({
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>
          <AiFillDelete
            onClick={() => handleDelete(row.id)}
            style={{ color: "red", fontSize: 20,display:Op_per.get().MASTERS_CLINICS.ALLOW_DELETE ? '' : 'none' }}
          />
          &nbsp;&nbsp;&nbsp;
          <LuMapPinOff 
            onClick={() => handleLocDelete(row.id)}
            style={{ color: "red", fontSize: 20,display:Op_per.get().MASTERS_CLINICS.ALLOW_DELETE ? '' : 'none' }}
            />
            &nbsp;&nbsp;&nbsp;
          <AiTwotoneEdit
            style={{ color: "black", fontSize: 20,display:Op_per.get().MASTERS_CLINICS.ALLOW_EDIT ? '' : 'none' }}
            onClick={() => handleEditShow(row)}
          />
        </div>
      ),
    })
  }

  const ExpandedComponent = ({ data }) => (
    <div style={{
      backgroundColor: data.disabled ? "red" : "#D9E1FF",
      color: "#fff",
      padding: 10,
      fontSize: 12,
      margin:20,
      borderRadius:20,
      border:"2px solid #28B3D2",
      width:"95%"
      
    }}>
      <Table
        // striped
        // hover
        size="sm"
        // style={{
        //   backgroundColor: data.disabled ? "red" : "#0096FF",
        //   color: "#fff",
        //   padding: 10,
        // }}
      >

<tr>
        <th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >Clinics</th>
        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}> <span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}>{data.clinicname}</span> </td>
                        <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%" }}>Territories</th>
                        <td style={{ width:"10%"}}>:</td>
                        <td style={{ padding:10,width:"30%" }}>{data.areas != null ? (
                  data.areas.map((are) => (
                    <span
                       style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold",margin:"3px"
                       }}
                    >
                      {are.areaname}
                    </span>
                  ))
                ) : (
                  <span
                   style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold",margin:"3px"
                       }}
                  >
                    N/A
                  </span>
                )}</td>
    </tr>
    <tr>
        <th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >Address   </th>
        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}> <span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}>{data.address === "" || data.address === null
                ? "N/A"
                : data.address}</span> </td>
                        <th  style={{fontWeight:"bold", color:"#000" , padding:10, width:"10%"}}>Pincode </th>
                        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}><span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}> {data.pincode === "" || data.pincode === null
                ? "N/A"
                : data.pincode}</span></td>
    </tr>
    <tr>
        <th style={{fontWeight:"bold", color:"#000", padding:10, width:"10%"}} >City   </th>
        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10, width:"30%" }}> <span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}> {data.cityname === "" || data.cityname === null
                ? "N/A"
                : data.cityname}</span> </td>
                        <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%" }}>Email </th>
                        <td style={{ width:"10%"}}>:</td>
                        <td style={{ padding:10, width:"10%" }}> <span
                     style={{
                     backgroundColor: data.email === "" || data.email === null || data.email === "N/A" ? "red":"#2457C5", borderRadius:"10px", border: data.email === "" || data.email === null || data.email === "N/A" ? "2px solid red": "2px solid #28B3D2",padding:"5px",margin:"3px",color:"#fff", fontWeight:"bold"
                       }}
                    >{data.email === "" || data.email === null ? "N/A" : data.email}</span></td>
    </tr>
    <tr>
        <th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >Phone   </th>
        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}><span
                     style={{
                     backgroundColor: data.phone === "" || data.phone === null ? "red":"#2457C5", borderRadius:"10px", border: data.phone === "" || data.phone === null ? "2px solid red": "2px solid #28B3D2",padding:"5px",margin:"3px",color:"#fff", fontWeight:"bold"
                       }}
                    > {data.phone === "" || data.phone === null ? "N/A" : data.phone}</span></td>
                        <th  style={{fontWeight:"bold", color:"#000" , padding:10, }}>State </th>
                        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10 }}><span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}> {data.statename === "" || data.statename === null
                ? "N/A"
                : data.statename}</span></td>
    </tr>
    <tr>
        <th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >Country   </th>
        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}> <span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}> {data.country === "" || data.country === null
                ? "N/A"
                : data.countryname}</span> </td>
                        <th  style={{fontWeight:"bold", color:"#000" , padding:10, width:"10%"}}>Created By </th>
                        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}><span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}> {data.createdbyuser === "" || data.createdbyuser === null
                ? "N/A"
                : data.createdbyuser}</span></td>
    </tr>
        {/* <tbody>
          <tr>
          <th style={{fontWeight:"bold", color:"#000", padding:10}} >Clinic Name : </th>
        <td style={{ padding:10 }}> <span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}>{data.clinicname}</span> </td>
           
            
            <th  style={{fontWeight:"bold", color:"#000" , padding:10}}>Teritories :</th>
        <td style={{ padding:10 }}>{data.areas != null ? (
                  data.areas.map((are) => (
                    <span
                       style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}
                    >
                     {are.areaname}
                    </span>
                  ))
                ) : (
                  <span
                   style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}
                  >
                    N/A
                  </span>
                )}</td>
         </tr>
            <tr>
            <td style={{ color: "#000", fontWeight: "bold" }}>Address</td>
            <td style={{ color: "#000" }}>:</td>
            <td style={{ color: "#fff" }}>
              {data.address === "" || data.address === null
                ? "N/A"
                : data.address}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Pincode</td>
            <td style={{ color: "#000" }}>:</td>
            <td style={{ color: "#fff" }}>
              {data.pincode === "" || data.pincode === null
                ? "N/A"
                : data.pincode}
            </td>
          </tr>
          <tr>
            <td style={{ color: "#000", fontWeight: "bold" }}>City</td>
            <td style={{ color: "#000" }}>:</td>
            <td style={{ color: "#fff" }}>
              {data.cityname === "" || data.cityname === null
                ? "N/A"
                : data.cityname}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Email</td>
            <td style={{ color: "#000" }}>:</td>
            <td style={{ color: "#fff" }}>
              {data.email === "" || data.email === null ? "N/A" : data.email}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Phone</td>
            <td style={{ color: "#000" }}>:</td>
            <td style={{ color: "#fff" }}>
              {data.phone === "" || data.phone === null ? "N/A" : data.phone}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>State</td>
            <td style={{ color: "#000" }}>:</td>
            <td style={{ color: "#fff" }}>
              {data.statename === "" || data.statename === null
                ? "N/A"
                : data.statename}
            </td>
          </tr>
          <tr>
            <td style={{ color: "#000", fontWeight: "bold" }}>Country</td>
            <td style={{ color: "#000" }}>:</td>
            <td style={{ color: "#fff" }}>
              {data.country === "" || data.country === null
                ? "N/A"
                : data.countryname}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Created By</td>
            <td style={{ color: "#000" }}>:</td>
            <td style={{ color: "#fff" }}>
              {data.createdby === "" || data.createdby === null
                ? "N/A"
                : data.createdby}
            </td>
          </tr>
        </tbody> */}
      </Table>
    </div>
  );

  //upload excels
  let createExcelClinic = async (jsonData) => {
    let statedata = await CreateExcelClinic(jsonData);
    if (statedata) {
      SetReload(true);
      setShowUploader(false);

      Swal.fire({
        title: "Success!",
        text: "Cinics Added Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Adding CLinics");
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      setExcelData(sheetData);
      setFileUploaded(true);
    };

    reader.readAsArrayBuffer(file);
  };

  // export excel
  const handleExcelExport = () => {
    Swal.fire({
      title: "Exporting!",
      text: "Exporting Excel !!",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading()
        let d = await GetAllClinics();
        if (d) {
          Swal.hideLoading()
          Swal.close()
          downloadCSV(d);
        }

      }
    });
  }

  const handleTitleChange = (event) => {
    const { name, value } = event.target;
    setColumnTitles({ ...columnTitles, [name]: value });
  };

  const handleAddData = () => {
    const newData = [];
    excelData.forEach((row) => {
      const newRow = {
        clinicname: row[columnTitles.title1],
        address: row[columnTitles.title2],
        pincode: row[columnTitles.title3],
        phone: row[columnTitles.title4],
        email: row[columnTitles.title5],
      };
      newData.push(newRow);
    });
    createExcelClinic(newData);
  };

  async function downloadCSV(clinics) {
    let csv;
    try {
      const opts = {
        fields: [
          "id",
          "doctorname",
          "areas_areaname",
          "doctorcode",
          "doctoraadhar",
          "degrees_degree",
          "specializations_specialization",
          "address",
          "city",
          "pincode",
          "dateofbirth",
          "anniversary",
          "cips",
          "hospitals_hospitalname",
          "clinics",
          "phone",
          "email",
          "focusedproducts",
          "deleted",
          "disabled",
          "createdby",
          "createdat",
          "updatedat",
        ],
        transforms: [
          unwind({
            paths: ["areas", "degrees", "hospitals", "specializations"],
          }),
          flatten({ object: false, array: true, separator: "_" }),
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(clinics);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(clinics)} />,
    [clinics]
  );

  const handleBulkUpdateSourceData = () => {
    let newArr = [];
    selectedRows.map((obj) => {
      let option = {

        // ...obj,
        id: obj.id,
        clinicname: obj.clinicname,
        areas: Helper.SetTableDataInDropdown(obj.areas, "id", "areaname"),
        users: Helper.SetTableDataInDropdown(obj.users, "id", "username"),
        address: obj.address,
        country: { id: obj.countryid, name: obj.countryname },
        state: { id: obj.stateid, statename: obj.statename },
        city: { id: obj.cityid, cityname: obj.cityname },
        pincode: obj.pincode,
        phone: obj.phone,
        email: obj.email,
      };
      newArr.push(option);
      return true;
    });
    setBulkSourceData(newArr);
    setShowBulkAreaEdit(true);
  };

  let handleBulkAreaUpdate = async () => {
    let newData = bulkSourceData.map((data) => {
      return {
        id: data.id,
        areas: Helper.GetIdsFromObj(data.areas),
        clinicname: data.clinicname,
        users: Helper.GetIdsFromObj(data.users),
        address: data.address,
        countryid: data.country.id,
        stateid: data.state.id,
        cityid: data.city.id,
        pincode: data.pincode,
        phone: data.phone,
        email: data.email,
      };
    });

    let response = await ClinicBulkUpdateArea(newData);
    if (response) {
      SetReload(true);
      setShowBulkAreaEdit(false);

      Swal.fire({
        title: "Success!",
        text: "Clinic Bulk Updated !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Updating Clinics");
    }
  };

  const handleBulkDataDelete = async () => {

    let newIds = [];

    selectedRows.map((obj) => {
      newIds.push(obj.id)
    })

    let response = await BulkDeleteClinic(newIds);
    if (response) {
      SetReload(true);

      Swal.fire({
        title: "Success!",
        text: "Clinics Deleted !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Deleting Clinics");
    }
  }

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",padding:0,
          backgroundColor:'#3887BE'
        }}
      >
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/clinic-master"><span class="icon icon-beaker"> </span>Masters</a></li>
  <li><a href="/clinic-master"><span class="icon icon-beaker"> </span>Clinics</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Form.Control
            style={{height: 46,
              width:250,
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
              className="glass"
              placeholder="Search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            {selectedRows.length > 0 && Op_per.get().MASTERS_CLINICS.ALLOW_EDIT ? (
              <div class="dropdown" style={{ marginRight: 10 }}>
                <button
                  class="btn btn-secondary"
                  type="button"
                  variant="warning"
                  title="Bulk Menu"
                  id="dropdown-basic"
                  style={{ backgroundColor: " #FFCA2C" }}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                <SiDatabricks color="#000" />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={handleBulkUpdateSourceData}>
                    <a class="dropdown-item"> Update</a>
                  </li>
                  <li onClick={handleBulkDataDelete}>
                    <a class="dropdown-item"> Delete</a>
                  </li>
                 
                </ul>
              </div>
            ) : (
              ""
            )}
            {Op_per.get().MASTERS_CLINICS.ALLOW_ADD || Op_per.get().MASTERS_CLINICS.ALLOW_IMPORT_XLSX || Op_per.get().MASTERS_CLINICS.ALLOW_EXPORT_XLSX ? (
              <div class="dropdown">
                <button
                  class="btn btn-secondary"
                  type="button"
                  style={{ backgroundColor: "#28B3D2" }}
                  title="Options"
                  id="dropdown-basic"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <HiDotsVertical />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={handleShow} style={{display:Op_per.get().MASTERS_CLINICS.ALLOW_ADD ? '' : 'none'}}>
                    <a class="dropdown-item">Add</a>
                  </li>
                  <li onClick={handleUploaderShow}>
                    <a class="dropdown-item" style={{display:Op_per.get().MASTERS_CLINICS.ALLOW_IMPORT_XLSX ? '' : 'none'}}>Import XLSX</a>
                  </li>
                  <li onClick={handleExcelExport}>
                    <a class="dropdown-item" style={{display:Op_per.get().MASTERS_CLINICS.ALLOW_EXPORT_XLSX ? '' : 'none'}}>Export XLSX</a>
                  </li>
                </ul>
              </div>
            ) : ("")}
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            columns={columns}
            data={clinics}
            selectableRows
            onSelectedRowsChange={({ selectedRows }) =>
              setSelectedRows(selectedRows)
            }
            highlightOnHover
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            progressPending={pending}
            progressComponent={<LoadingComponent />}
            // actions={actionsMemo}
            style={{ marginTop: 10 }}
            dense
          />
        </div>
      </div>
      <Modal show={show} backdrop="static" size="md" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Clnic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Clinics <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  backgroundColor: isclinicname ? "red" : "",
                  color: isclinicname ? "#fff" : "",
                  height: 46,
           
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,
                }}
                type="text"
                onChange={handleClinicNameChange}
                placeholder="Enter Clinic"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Territories <span style={{color:"red"}}>*</span></Form.Label>
              <Select
               className="selectdoctor"
                closeMenuOnSelect={false}
                options={options}
                value={selectedAreas}
                onChange={handleSelectChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Address <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="text"
                onChange={handleClinicAddressChange}
                placeholder="Enter Address"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Country <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select  className="selectdoctor" onChange={handleCountryCodeChange} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select Country---</option>
                {countries.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>State <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select  className="selectdoctor" onChange={handleStateChange} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select State---</option>
                {states.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.statename}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select  className="selectdoctor" onChange={handleCityChange} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select City---</option>
                {citys.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.cityname}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Pincode <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="text"
                onChange={handlePincodeChange}
                placeholder="Enter Pincode"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Phone <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="number"
                onChange={handleNumberChange}
                placeholder="Enter Phone"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Email <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="email"
                onChange={handleEmailChange}
                placeholder="Enter Email"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={isclinicname ? true : false}
          >
            Add Clinic
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showuploader} onHide={handleUploaderClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
            />
          </div>

          {fileUploaded && (
            <div>
              <hr />
              <div>
                <label htmlFor="title1">Clinics <span style={{color:"red"}}>*</span></label>
                <Form.Select
                 className="selectdoctor"
                  id="title1"
                  name="title1"
                  value={columnTitles.title1}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label htmlFor="title2">Address <span style={{color:"red"}}>*</span></label>
                <Form.Select
                 className="selectdoctor"
                  id="title2"
                  name="title2"
                  value={columnTitles.title2}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label htmlFor="title3">Pincode <span style={{color:"red"}}>*</span></label>
                <Form.Select
                 className="selectdoctor"
                  id="title3"
                  name="title3"
                  value={columnTitles.title3}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label htmlFor="title4">Phone <span style={{color:"red"}}>*</span></label>
                <Form.Select
                 className="selectdoctor"
                  id="title4"
                  name="title4"
                  value={columnTitles.title4}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label htmlFor="title5">Email <span style={{color:"red"}}>*</span></label>
                <Form.Select
                 className="selectdoctor"
                  id="title5"
                  name="title5"
                  value={columnTitles.title5}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUploaderClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddData}>
            Add Data
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showedit} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Clinic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Clinics <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="text"
                onChange={handleClinicNameChange}
                placeholder="Enter Clinic Name"
                value={clinicname}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Areas <span style={{color:"red"}}>*</span></Form.Label>
              <Select
              className="selectdoctor"
                closeMenuOnSelect={false}
                options={options}
                value={selectedAreas}
                onChange={handleSelectChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Address <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="text"
                onChange={handleClinicAddressChange}
                placeholder="Enter Address"
                required
                value={clinicaddress}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Country <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select
              className="selectdoctor"
                onChange={handleCountryCodeChange}
                required
                value={country}
                style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
              >
                <option>---Select Country---</option>
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>State <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={handleStateChange} required value={state}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select State---</option>
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.statename}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={handleCityChange} required value={city}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select City---</option>
                {citys.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.cityname}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Pincode <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="text"
                onChange={handlePincodeChange}
                placeholder="Enter Pincode"
                required
                value={pincode}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Phone <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="number"
                onChange={handleNumberChange}
                placeholder="Enter Phone"
                required
                value={number}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Email <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="email"
                onChange={handleEmailChange}
                placeholder="Enter Email"
                value={email}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update Clinic
          </Button>
        </Modal.Footer>
      </Modal>
      {showbulkareaedit && (
        <Modal
          fullscreen={true}
          size="lg"
          show={true}
          onHide={() => setShowBulkAreaEdit(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Bulk Update Clinic</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="scrollable-div">
              <Table
                style={{ tableLayout: "auto", width: "100%" }}
                className="blazetable"
                striped
                bordered
                hover
                size="sm"
              >
                <thead className="sticky">
                  <tr style={{ width: "auto", whiteSpace: "nowrap" }}>
                    {Object.keys(bulkSourceData[0]).map((row, index) => {
                      return <th className={index === 0 ? "sticky-col1" : index === 1 ? "sticky-col2" : ""}>{row}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {bulkSourceData.map((row, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        {Object.keys(row).map((cell, cellIndex) => {
                          return (
                            <td
                              style={{ width: "auto", whiteSpace: "nowrap" }}
                              key={cellIndex}
                              className={cellIndex === 0 ? "sticky-col1" : cellIndex === 1 ? "sticky-col2" : ""}
                            >
                              {cell === "areas" || cell === "users" || cell === "country" || cell == "state" || cell == "city" ? (
                                <div style={{ minWidth: "200px" }}>
                                  <Select
                                    closeMenuOnSelect={false}
                                    options={
                                      cell === "areas" ? options : cell === "country" ? countries : cell == "state" ? states : cell === "city" ? citys : userOptions
                                    }
                                    value={bulkSourceData[rowIndex][cell]}
                                    getOptionLabel={(option) => cell === "country" ? option.name : cell === "state" ? option.statename : cell === "city" ? option.cityname : option.label}
                                    getOptionValue={(option) => cell === "country" || cell == "state" || cell == "city" ? option.id : option.value}
                                    onChange={(selectedOption) => {
                                      const newValue = selectedOption;
                                      setBulkSourceData((prevData) => {
                                        const newData = [...prevData];
                                        newData[rowIndex][cell] = newValue;
                                        return newData;
                                      });
                                    }}
                                    isMulti={cell !== 'country' && cell !== 'state' && cell !== 'city'}
                                  />
                                </div>
                              ) : (
                                <input
                                  type="text"
                                  name={cell}
                                  value={
                                    bulkSourceData[rowIndex][cell] !== null &&
                                      bulkSourceData[rowIndex][cell] !== undefined
                                      ? bulkSourceData[rowIndex][cell]
                                      : ""
                                  }
                                  disabled={cell === "id"}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setBulkSourceData((prevData) => {
                                      const newData = [...prevData];
                                      newData[rowIndex][cell] = newValue;
                                      return newData;
                                    });
                                  }}
                                />
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowBulkAreaEdit(false)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleBulkAreaUpdate}>
              Update Clinics
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
}

export default ClinicMaster;
