import Swal from "sweetalert2";
import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";
import { isDayStarted } from "./Taskproxy";

export const GetAllProjects = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/project/all',null);
}

export const GetAllProjectsByUserid = async (userid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/project/byuserid?userid='+userid,null);
}

//TODO 
// Add By Userid ANd Status

export const GetProjectsByMonth = async (month,year,userid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/project/getbymonth?month=${month}&year=${year}&userid=${userid}`,null);
}

export const CreateProjects = async (name,users,startdate,enddate,cost,currencyid,description,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    if(isDayStarted()){
        const data = {
            name:name,
            users:users,
            startdate:startdate,
            enddate:enddate,
            cost:cost,
            currencyid:currencyid,
            description:description,
            status:status,
        }
        return await Restapi('post',REPORTING_URL+'/apiv1/project',data);
    }else{
        Swal.fire({
            title: "Warning!",
            text: "First Start The Day !!",
            icon: "warning",
            confirmButtonText: "OK",
          });
    }
}

export const DeleteProjects = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/project/'+id,null);
}

export const UpdateProjects = async (id,name,users,startdate,enddate,cost,currencyid,description,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        name:name,
        users:users,
        startdate:startdate,
        enddate:enddate,
        cost:cost,
        currencyid:currencyid,
        description:description,
        status:status,
    }
    return await Restapi('put',REPORTING_URL+'/apiv1/project/'+id,data);
}