import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

export const GetAllSettings = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/settings',null);
}

export const CreateSettings = async (settingData) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/settings',settingData);
}

export const UpdateSettings = async (id,setting_name,setting_value) => {
    const settingData = {
        setting_name,
        setting_value
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/settings/'+id,settingData);
}

export const UpdateSMTPSettings = async (host,port,security,username,password,fromemail) => {
    const settingData = {
        host,
        port,
        security,
        username,
        password,
        fromemail
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/settings/smtp',settingData);
}
export const UpdateSiteSettings = async (sitename,sitedescription,currency,company,logo) => {
    const settingData = {
        sitename,
        sitedescription,
        currency,
        company,
        logo
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/settings/sitedetails',settingData);
}

export const GetCurrencyFromSettings = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/settings/currency',null);
}