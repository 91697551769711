import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Carousel, Col, Dropdown, Form, Modal, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { AllUsers, GetAllManagerUsers } from '../../utils/proxy/Authproxy';
import { GetAllProjectsByUserid } from '../../utils/proxy/ProjectProxy';
import { CreateTask, GetAllTaskCategory } from '../../utils/proxy/Taskproxy';
import Select from "react-select";
import Swal from 'sweetalert2';
import { GetAllLeads } from '../../utils/proxy/LeadsProxy';

function TaskModel({buttontext, buttonclass,leadid}) {
    const [show, setShow] = useState(false);
    const [users, setusers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState(localStorage.getItem('role') === 'User' ? { value: localStorage.getItem('uuid'), label: localStorage.getItem('firstname') + " " + localStorage.getItem('lastname') + " (Self)" } : null);
    const [selectedUserId, setSelectedUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);

    const [allProject, setAllProject] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});
    const [selectedProjectId, setSelectedProjectId] = useState();

    const [allCategories, setAllCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [selectedCategoryId, setSelectedCategoryId] = useState();

    const [currentmodaldata, setCurrentModaldata] = useState({ start: '', end: '' });

    const [changestatus, setchangestatus] = useState('all');

    const [localStartDate, setLocalStartDate] = useState('');
    const [localEndDate, setLocalEndDate] = useState('');

    const [showaddtask, setshowaddtask] = useState(false);
    const [reload, setReload] = useState(false);

    const [leads,setLeads] = useState([]);
    const [selectedLead,setSelectedLead] = useState();
    const [selectedLeadId,setSelectedLeadId] = useState(leadid);


    const [task, setTask] = useState({
        "id": null,
        "userid": selectedUserId,
        "task": null,
        "projectid": selectedProjectId,
        "categoryid": selectedCategoryId,
        "leadid":selectedLeadId,
        "start": null,
        "end": null,
        "description": null,
        "createdby": localStorage.getItem('uuid'),
        "priority": 'medium',
        "status": "new"
      });


      useEffect(() => {

        const getAllUsersData = async () => {
          if (localStorage.getItem('role') === 'Admin') {
            let allusers = await AllUsers();
            allusers.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
            setusers(allusers);
          } else if (localStorage.getItem('role') === 'Manager') {
            let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
            setusers(allusers);
          }
        };
        getAllUsersData();
        getLeads();
      }, []);

      async function getLeads() {
        let res = await GetAllLeads();

        if (res) {
            setLeads(res);
        }

        res.map((lead) => {
          if (lead.id === leadid) {
            setSelectedLead({
              value:lead.id,
              label:`${lead.salutation} ${lead.firstname} ${lead.lastname} (${lead.company})`
            })
          }
        })
    }

      useEffect(() => {
        async function getProjects() {
          let data = await GetAllProjectsByUserid(selectedUserId);
          if (data) {
            setAllProject(data);
          } else {
            alert('Unable to Fetch');
          }
        }
    
        async function getCategories() {
          let data = await GetAllTaskCategory();
          if (data) {
            setAllCategories(data);
          } else {
            alert('Unable to Fetch');
          }
        }
    
        getProjects();
        getCategories();
      }, [selectedUserId])

      const handleSelectChange = (selected) => {
        setSelectedUsers(selected);
        setTask({ ...task, userid: selected.value });
        setCurrentModaldata({ ...currentmodaldata, userid: selected.value });
        setSelectedUserId(selected.value);
        setchangestatus('all');
        setSelectedProject(null);
      };
    
      const handleProjectChange = (selected) => {
        if (selected === null) {
          setTask({ ...task, projectid: null });
          setCurrentModaldata({ ...currentmodaldata, projectid: null })
          setSelectedProject();
          setSelectedProjectId();
        } else {
          setTask({ ...task, projectid: selected.value });
          setCurrentModaldata({ ...currentmodaldata, projectid: selected.value })
          setSelectedProject(selected);
          setSelectedProjectId(selected.value);
        }
    
      };

      const handleLeadChange = (selected) => {
        if (selected === null) {
          setTask({ ...task, leadid: null });
          setCurrentModaldata({ ...currentmodaldata, leadid: null })
          setSelectedLead();
          setSelectedLeadId();
        } else {
          setTask({ ...task, leadid: selected.value });
          setCurrentModaldata({ ...currentmodaldata, leadid: selected.value })
          setSelectedLead(selected);
          setSelectedLeadId(selected.value);
        }
    
      };
    
      const handleCategoryChange = (selected) => {
        if (selected === null) {
          setTask({ ...task, categoryid: null })
        setCurrentModaldata({ ...currentmodaldata, categoryid: null })
        setSelectedCategory();
        setSelectedCategoryId();
        } else {
          setTask({ ...task, categoryid: selected.value })
        setCurrentModaldata({ ...currentmodaldata, categoryid: selected.value })
        setSelectedCategory(selected);
        setSelectedCategoryId(selected.value);
        }
        
      }


      function dateToIsoStringWithoutChange(istDate) {
        if (!(istDate instanceof Date)) {
          throw new Error("Input must be a Date object");
        }
    
        istDate.setMinutes(istDate.getMinutes() - istDate.getTimezoneOffset());
    
        const isoString = istDate.toISOString();
    
        return isoString;
      }

      const handlesubmit = async () => {
        if (task.task == null) {
          Swal.fire({
            title: "Warning!",
            text: "Enter Task Title !!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (task.description == null) {
          Swal.fire({
            title: "Warning!",
            text: "Enter Task Description !!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (task.userid == null) {
          Swal.fire({
            title: "Warning!",
            text: "Enter AssignTo !!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (task.priority == null) {
          Swal.fire({
            title: "Warning!",
            text: "Enter Task Priority !!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (task.start == null || task.end == null) {
          Swal.fire({
            title: "Warning!",
            text: "Enter Date fields !!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (task.start > task.end) {
          Swal.fire({
            title: "Warning!",
            text: "Start Date is Greater Than End Date !!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          let d = await CreateTask(task);
          
          if (d.status === "success") {
            setShow(false);
            setReload(true)
            setTask({
              ...task,
              userid: selectedUserId,
              task: null,
              start: null,
              end: null,
              description: null,
              priority: null
            })
            setSelectedProject(null)
            setSelectedProjectId(null)
            setSelectedCategory(null)
            setSelectedCategoryId(null)
            Swal.fire({
              title: "Success!",
              text: "Task Added Successfully !!",
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            alert("Error", "Issue While Adding Task");
          }
        }
      }

    const handleClose = () => setShow(false);
    const handleShow = () =>{ 
        let st = new Date();
    let ed = new Date();
    ed.setHours(ed.getHours() + 1);
    setTask({...task, start:st.toISOString(),end:ed.toISOString(),priority:'medium' })

        setLocalStartDate(dateToIsoStringWithoutChange(st).substr(0,16));
        setLocalEndDate(dateToIsoStringWithoutChange(ed).substr(0,16))
        setShow(true)};


    const options = [];
    const projects = [];
    const category = [];
    const leadsoption = [];


    users.map((user) => {
        options.push({
          value: user.userid,
          label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
      });
    
      allProject.map((project) => {
        projects.push({
          value: project.id,
          label: project.name
        })
      })
    
      allCategories.map((cat) => {
        category.push({
          value: cat.id,
          label: cat.name
        })
      })



      leads.map((lead) => {
        leadsoption.push({
          value: lead.id,
          label: `${lead.salutation} ${lead.firstname} ${lead.lastname} (${lead.company})`
        })
     
      })

  return (
    <>
      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Task</Tooltip>}><Button className={buttonclass} variant="primary" onClick={handleShow}>
        {buttontext}
      </Button></OverlayTrigger>

      <Modal show={show} onHide={handleClose} style={{backgroundColor:'#0000008f'}}>
      <Modal.Header closeButton>
          <Modal.Title>Create Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Title <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                placeholder="Enter Task Title"
                value={task.task}
                onChange={(e) => { setTask({ ...task, task: e.target.value }) }}
              />
              <Form.Label >Assign To <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor" >
                  <Select

                    options={options}
                    value={selectedUsers}
                    onChange={handleSelectChange}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Assign Project</Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select
                    options={projects}
                    value={selectedProject}
                    onChange={handleProjectChange}
                    isClearable={true}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Assign Lead</Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select
                    options={leadsoption}
                    value={selectedLead}
                    onChange={handleLeadChange}
                    isClearable={true}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Task Category <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select
                    options={category}
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  isClearable={true}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Description <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                as='textarea'
                placeholder='Enter Task Description'
                value={task.description}
                onChange={(e) => { setTask({ ...task, description: e.target.value }) }} />

              <Form.Label>Start Date <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='datetime-local'
                value={localStartDate}
                onChange={(e) => { setTask({ ...task, start: new Date(e.target.value).toISOString() });setLocalStartDate(dateToIsoStringWithoutChange(new Date(e.target.value)).substr(0,16)) }}
              />

              <Form.Label>End Date <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='datetime-local'
                value={localEndDate}
                onChange={(e) => { setTask({ ...task, end: new Date(e.target.value).toISOString() });setLocalEndDate(dateToIsoStringWithoutChange(new Date(e.target.value)).substr(0,16)) }} />
            </Form.Group>
            <div style={{ margin: '10px' }}>
              <div>
                <Form.Label>Priority <span style={{color:'red'}}>*</span>: </Form.Label>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: 'rgb(59, 130, 246)', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="f-option" class="l-radio">
                    <input type="radio" id="f-option" name="selector" tabindex="1" value="low" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'low'}/>
                    <span style={{ margin: '5px' }}>LOW</span>
                  </label>
                </div>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#28a745', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="s-option" class="l-radio">
                    <input type="radio" id="s-option" name="selector" tabindex="2" value="medium" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'medium'}/>
                    <span style={{ margin: '5px' }}>MEDIUM</span>
                  </label>
                </div>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#ff0018', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="t-option" class="l-radio">
                    <input type="radio" id="t-option" name="selector" tabindex="3" value="high" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'high'}/>
                    <span style={{ margin: '5px' }}>HIGH</span>
                  </label>
                </div>
              </div>
            </div>

          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlesubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TaskModel