import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"


// Doctro

export const GetAllCips = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/cip',null);
}

export const GetAllCipsPagination = async (page,size,search) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/cip/pagination?page='+page+'&size='+size+'&search='+search,null);
}

export const CreateCip = async (cipname,areasid,cipaddress,country,state,city,pincode,number,email,cipdepartmentid,
    cipdesignationid,
    cipqualificationid,hospitalsid) => {
        const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "areas": areasid,
        "cipname": cipname,
        "address": cipaddress,
        "countryid": country,
        "stateid": state,
        "cityid": city,
        "pincode": pincode,
        "phone": number,
        "email": email,
        "departmentid":cipdepartmentid,
        "designationid":cipdesignationid,
        "qualificationid":cipqualificationid,
        "hospitals":hospitalsid
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/cip',data);
}

export const UpdateCip = async (cipid,cipname,areasid,cipaddress,country,state,city,pincode,number,email,cipdepartmentid,
    cipdesignationid,
    cipqualificationid,hospitalsid) => {
        const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "areas": areasid,
        "cipname": cipname,
        "address": cipaddress,
        "countryid": country,
        "stateid": state,
        "cityid": city,
        "pincode": pincode,
        "phone": number,
        "email": email,
        "departmentid":cipdepartmentid,
        "designationid":cipdesignationid,
        "qualificationid":cipqualificationid,
        "hospitals":hospitalsid
      });

    return await Restapi('put',REPORTING_URL+'/apiv1/cip/'+cipid,data);
}

export const CipBulkUpdateArea = async (data) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi(
      "put",
      REPORTING_URL + "/apiv1/cip/bulk",
      JSON.stringify(data)
    );
}

export const DeleteCip = async (cipid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/cip/'+cipid,null);
}



export const CreateExcelCip = async (jsondata) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify(jsondata);
    return await Restapi('post',REPORTING_URL+'/apiv1/cip/multiple/excel',data);
}


export const CheckCipAvailable = async (cipname) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/cip/available/'+cipname,null);
}
// Department
export const GetAllCipsDepartment = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/cipdepartment',null);
}

export const CheckDocDepartmentAvailable = async (department) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/cipdepartment/available/'+department,null);
}

export const CreateDocDepartment = async (department,description)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "department": department,
        "description": description
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/cipdepartment',data);
}

export const UpdateDocDepartment = async (id,docdepartment,description)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "department": docdepartment,
        "description": description
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/cipdepartment/'+id,data);
}

export const DeleteDocDepartment = async (id)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/cipdepartment/'+id,null);
}

// Designation
export const GetAllCipsDesignation = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/cipdesignation',null);
}

export const CheckDocDesignationAvailable = async (designation) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/cipdesignation/available/'+designation,null);
}

export const CreateDocDesignation = async (designation,description)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "designation": designation,
        "description": description
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/cipdesignation',data);
}

export const UpdateDocDesignation = async (id,designation,description)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "designation": designation,
        "description": description
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/cipdesignation/'+id,data);
}

export const DeleteDocDesignation = async (id)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/cipdesignation/'+id,null);
}

// Qualification
export const GetAllCipsQualification = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/cipqualification',null);
}

export const CheckDocQualificationAvailable = async (qualification) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/cipqualification/available/'+qualification,null);
}

export const CreateDocQualification = async (qualification,description)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "qualification": qualification,
        "description": description
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/cipqualification',data);
}

export const UpdateDocQualification = async (id,qualification,description)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "qualification": qualification,
        "description": description
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/cipqualification/'+id,data);
}

export const DeleteDocQualification = async (id)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/cipqualification/'+id,null);
}