import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { createtdsSection, createtdstaxe, getAllTdsSection, updatetdsSection, updatetdstaxe } from '../../../utils/proxy/TaxProxy';
import Swal from 'sweetalert2';

function TdsTaxModel({action,data,show,setShow,setReload}) {

    const [tax,setTax] = useState(data ? data.taxname : "" );
    const [rate,setRate] = useState(data ? data.rate : 0);
    const [sections,setSections] = useState([]);
    const [sectionid,setSectionId] = useState(data ? data.sectionid : 0);

  const handleClose = () => setShow(false);




  useState(() => {
    getSections();
  },[])


  async function getSections() {
    let res = await getAllTdsSection();
    if (res) {
        setSections(res);
    }
  }

const handleSave = async () => {
    if (tax && rate) {
        if (action === 'create') {
            let res = await createtdstaxe(tax,rate,sectionid);
            if (res) {
                setShow(false)
                setReload(true)
                Swal.fire({
                    icon: res.status,
                    text: res.message,
                  });
            }
        }else{
            let res = await updatetdstaxe(data.id,tax,rate,sectionid);
            if (res) {
                setShow(false)
                setReload(true)
                Swal.fire({
                    icon: res.status,
                    text: res.message,
                  });
                }
        }
    }

}
  

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{action === 'create' ? 'Add Tax' : 'Edit Tax'}</Modal.Title>
        </Modal.Header>
        <div style={{margin:10}}>
        <Form>
            <Form.Group className="mb-3">
            <Form.Label>Section</Form.Label>
            <Form.Select value={sectionid} onChange={(e) => setSectionId(e.target.value)} aria-label="Default select example">
      <option>Open this select menu</option>
      {sections && sections.map((sec) => (
        <option value={sec.id}>{sec.section} - {sec.desc}</option>
      ))}
      
    </Form.Select>
            </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Tax Name</Form.Label>
        <Form.Control type="text" value={tax} onChange={(e) => setTax(e.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Rate</Form.Label>
        <Form.Control type="number" value={rate} onChange={(e) => setRate(e.target.value)} />
      </Form.Group>
     
    </Form>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
          {action === 'create' ? 'Save' : 'Update'} Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TdsTaxModel