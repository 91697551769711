import Restapi from "../api/Restapi";
import { constats } from "../constant";

export const GetAllRoleMaster = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',AUTH_URL+'/apiv1/role',null);
}

export const CreateRoleMaster = async (rolename,description) => {
    const data = {
        rolename,
        description
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',AUTH_URL+'/apiv1/role',data);
}

export const EditRoleMaster = async (id,rolename,description) => {
    const data = {
        rolename,
        description
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',AUTH_URL+'/apiv1/role/'+id,data);
}

export const DeleteRoleMaster = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',AUTH_URL+'/apiv1/role/'+id,null);
}