import { Col, Form, Row } from 'react-bootstrap';
import { AllUsers } from "../utils/proxy/Authproxy";
import Select from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import { GetPermissionsByUserid, createUserPermission } from '../utils/proxy/UserPermissionsProxy';
import Swal from 'sweetalert2';
import { Op_per, Permissions } from '../utils/Permissions';

const UserPermission = () => {
    const [users, setusers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState(localStorage.getItem('role') === 'User' ? { value: localStorage.getItem('uuid'), label: localStorage.getItem('firstname') + " " + localStorage.getItem('lastname') + " (Self)" } : null);
    const [selectedUserId, setSelectedUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);

    const [userPermission, setUserPermission] = useState({});
    const [operationPermission, setOperationPermission] = useState({});

    const [reload, setReload] = useState(false);

    useEffect(() => {

        const getAllUsersData = async () => {

            let allusers = await AllUsers();
            allusers.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
            setusers(allusers);
        };
        getAllUsersData();
    }, []);

    useEffect(() => {
        const getPermission = async () => {
            let res = await GetPermissionsByUserid(selectedUserId);
            
            if (res) {
                try {
                    let pers = JSON.parse(res.menupermissions);
                    let optsm = JSON.parse(res.permissions)
                    
                    let permi = Object.assign({}, Permissions.get());
                    let optPer = Object.assign({}, Op_per.get());

                    Object.keys(pers).forEach(key => {
                        if (permi.hasOwnProperty(key)) {
                            permi[key] = pers[key];
                        }
                    });

                    Object.keys(optsm).forEach(key => {
                        if (optPer.hasOwnProperty(key)) {
                            optPer[key] = optsm[key];
                        }
                    });
                    
                    setUserPermission(permi)
                    setOperationPermission(optPer);

                } catch (e) {
                 
                }
            }
        }

        if(selectedUserId){
        getPermission();
        setReload(false);
        }
    }, [selectedUserId,reload])

    const handleSelectChange = (selected) => {
        setSelectedUsers(selected);
        setSelectedUserId(selected.value);
    };

    const changePermission = (key,value) =>{
        setUserPermission((prevState) => ({
            ...prevState,
            [key]: !value,
        }));
    }

    const changeOperationPermission = (module, permission, value) => {
        setOperationPermission(prevState => ({
            ...prevState,
            [module]: {
                ...prevState[module],
                [permission]: !value
            }
        }));
    };
    

    const handlePermission = async() =>{
        let d = await createUserPermission(selectedUserId,userPermission, operationPermission);
        if(d.status === 'success'){
            Swal.fire({
                title: "Success!",
                text: "User Permission Updated !!",
                icon: "success",
                confirmButtonText: "OK",
            });   
            setReload(true);
        }
    }

    const show = () => {
        const tableRows = [];

        // Iterate over the entries of userPermission
        Object.entries(userPermission).forEach(([key, value]) => {

            // Create JSX for each table row and push it into the array
            tableRows.push(
                <tr key={key}>
                    <td>{key}</td>
                    <tr>
                        <label className='switch noti-icon'>
                            <input type='checkbox' onClick={() =>changePermission(key,value)} checked={value} />
                            <span className='slider round1'></span>
                        </label>
                    </tr>
                </tr>
            );
        });

        // Return the array of JSX elements wrapped in a parent element
        return <><table>{tableRows} <button style={{display:selectedUserId ? '' : 'none'}} onClick={handlePermission}>Submit</button></table></>;
    };

    const showOperationPermission = () => {
        const tableRows = [];
    
        // Iterate over the entries of userPermission
        Object.entries(operationPermission).forEach(([module, permissions]) => {
    
            // Iterate over the permissions within each module
            Object.entries(permissions).forEach(([permission, value]) => {
    
                // Create JSX for each table row and push it into the array
                tableRows.push(
                    <tr key={`${module}_${permission}`}>
                        <td>{`${module} - ${permission}`}</td>
                        <td>
                            <label className='switch noti-icon'>
                                <input type='checkbox' onClick={() =>changeOperationPermission(module, permission, value)} checked={value} />
                                <span className='slider round1'></span>
                            </label>
                        </td>
                    </tr>
                );
            });
        });
    
        // Return the array of JSX elements wrapped in a parent element
        return (
            <>
                <table>
                    {tableRows}
                <button style={{display:selectedUserId ? '' : 'none'}} onClick={handlePermission}>Submit</button>
                </table> 
            </>
        );
    }
    


    const options = [];

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });

    return (
        <>
            <div style={{ overflow: 'visible', display: 'flex', flexDirection: 'row', justifyContent: localStorage.getItem('role') === 'User' ? 'flex-end' : 'space-between', alignItems: 'center', margin: '20px' }}>
                <div style={{ width: '300px', display: localStorage.getItem('role') === 'User' ? 'none' : '' }}>
                    <Form.Group
                        className="mb-3 d-flex w-100"
                        style={{ gap: "15px" }}
                        controlId="formBasicPassword"
                    >
                        <Form.Label className="mt-2">Users</Form.Label>
                        <div className="selectdoctor">
                            <Select

                                options={options}
                                value={selectedUsers}
                                onChange={handleSelectChange}
                            />
                        </div>
                    </Form.Group>

                </div>
            </div>

            <Row>
                <Col>
                <h4>Menu Permissions</h4>
                <hr/>
                {show()}
                </Col>
                <Col>
                <h4>Operations Permissions</h4>
                <hr/>
                {showOperationPermission()}
                </Col>
            </Row>

            

        </>
    )
}

export default UserPermission;