import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"


// Doctro

export const GetAllChemists = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/chemist',null);
}

export const GetAllChemistsPagination = async (page,size,search) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/chemist/pagination?page='+page+'&size='+size+'&search='+search,null);
}

export const CreateChemist = async (chemistname,areasid,chemistaddress,country,state,city,pincode,number,email) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "areas": areasid,
        "chemistname": chemistname,
        "address": chemistaddress,
        "countryid": country,
        "stateid": state,
        "cityid": city,
        "pincode": pincode,
        "phone": number,
        "email": email
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/chemist',data);
}

export const UpdateChemist = async (chemistid,chemistname,areasid,chemistaddress,country,state,city,pincode,number,email) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "areas": areasid,
        "chemistname": chemistname,
        "address": chemistaddress,
        "countryid": country,
        "stateid": state,
        "cityid": city,
        "pincode": pincode,
        "phone": number,
        "email": email
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/chemist/'+chemistid,data);
}

export const UpdateChemistLat = async (chemistid,latitude,longitude) => {

    const [AUTH_URL, REPORTING_URL] = await constats();

    let data = JSON.stringify({
        "latitude": latitude,
        "longitude": longitude
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/chemist/updatelat/'+chemistid,data);
}

export const ChemistBulkUpdateArea = async (data) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi(
      "put",
      REPORTING_URL + "/apiv1/chemist/bulk",
      JSON.stringify(data)
    );
}

export const DeleteChemist = async (chemistid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi('delete',REPORTING_URL+'/apiv1/chemist/'+chemistid,null);
}

export const BulkDeleteChemist = async (chemistid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi('delete',REPORTING_URL+'/apiv1/chemist/bulkdelete',chemistid);
}



export const CreateExcelChemist = async (jsondata) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify(jsondata);
    return await Restapi('post',REPORTING_URL+'/apiv1/chemist/multiple/excel',data);
}


export const CheckChemistAvailable = async (chemistname) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/chemist/available/'+chemistname,null);
}

export const GetChemistDpDetails = async (userid,date) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/chemistdp/details/${userid}/${date}`,null);
}