import { Button, Container, Form } from "react-bootstrap"
import { IoHomeOutline } from "react-icons/io5"
import { Op_per } from "../../utils/Permissions"
import Select from "react-select"
import React,{ useEffect, useState } from "react"
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy"
import DataTable from "react-data-table-component"
import LoadingComponent from "../Includes/LoadingComponent"
import { GetDoctorDegreeOrSpeciality } from "../../utils/proxy/ChartsProxy"
import { Parser } from "@json2csv/plainjs"
import { RiFileExcel2Fill } from "react-icons/ri"

export const ListedDoctorCategory = () =>{

    const [data, setData] = useState([]);
    const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);
    const [selectedUser, setSelectedUser] = useState();
    const [users, setUsers] = useState([]);
    const [reload, setReload] = useState(false);
    const [pending, setPending] = useState(false);
    const [selectedType, setSelectedType] = useState({ value: 'degree', label: 'Qualification' });
    const [type, setType] = useState('degree');

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallusers = async () => {
            if (localStorage.getItem('role') === 'Admin') {
                let allusers = await AllUsers();
                allusers.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
                setUsers(allusers);
            } else if (localStorage.getItem('role') === 'Manager') {
                let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
                setUsers(allusers);
            }
        };
        getallusers();
    }, []);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallteritories = async (userid, type) => {
            let userteritories = await GetDoctorDegreeOrSpeciality(
                userid,
                type
            );

            if (userteritories) {
                setData(userteritories);
                setPending(false);
            } else {
                alert("Error", "Issue While Fetching Tour Plan");
            }
        };
        if (userid && type) {
            setPending(true);
            getallteritories(userid, type);
        }
        setReload(false);
    }, [reload, userid, type]);

    const handleSelectChange = (selected) => {
        setSelectedUser(selected);
        setUserId(selected.value);
    };

    const handleTypeChange = (selected) => {
        setSelectedType(selected);
        setType(selected.value);
    }

    const options = [];
    const typeoptions = [
        { value: 'degree', label: 'Qualification' },
        { value: 'speciality', label: 'Speciality' },
    ];

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });

    const columns = [
        {
            name:"Category",
            selector:(row)=>row.category,
            sortable:true
        },
        {
            name:"Count",
            selector:(row)=>row.count,
            sortable:true
        }
    ]

    // const columns = data.map((row) => ({
    //     name: row.category,
    //     selector: () => row.count,
    //     sortable: true
    // }));

    async function downloadCSV(plans) {
        let csv;
        try {
            const opts = {
                fields: [
                    { label: "Category", value: "category" },
                    { label: "Count", value: "count" },
                ],
            };
            const parser = new Parser(opts);
            csv = parser.parse(plans);
        } catch (err) {
            console.error(err);
        }

        const link = document.createElement("a");

        if (csv == null) return;

        const filename = `ListedDoctor-(${type}).csv`;

        csv = `Listed Doctor (${type}) under ${selectedUser.label} \n\n\n${csv}`;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    }

    const Export = ({ onExport }) => (
        <Button style={{ position: 'absolute', right: 40, backgroundColor: "#28B3D2 ",display:Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.ALLOW_EXPORT_XLSX ? '' : 'none' }} onClick={(e) => onExport(e.target.value)} disabled={!(userid && type)}><RiFileExcel2Fill /></Button>
    );

    const actionsMemo = React.useMemo(
        () => <Export onExport={() => downloadCSV(data)} />,
        [data]
    );

    return(
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                        /></a></li>
                        <li><a href="/doctor-category"><span class="icon icon-beaker"> </span>Reports</a></li>

                        <li><a href="#"><span class="icon icon-double-angle-right"></span>Listed Doctor Category</a></li>

                    </ul>

                </div>

            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                    }}
                >
                    <div style={{ width: "320px", display: Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.USER_DROPDOWN ? '' : 'none' }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Users</Form.Label>
                            <div className="w-75">
                                <Select
                                    className="selectdoctor"
                                    options={options}
                                    value={selectedUser}
                                    onChange={handleSelectChange}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    
                    <div style={{ width: "320px" }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Type</Form.Label>
                            <div className="w-75">
                                <Select
                                    className="selectdoctor"
                                    options={typeoptions}
                                    value={selectedType}
                                    onChange={handleTypeChange}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="pt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        actions={actionsMemo}
                        pagination
                        customStyles={{ headRow: { style: { backgroundColor: '#E5E1DA', padding: 7 }, } }}
                        style={{ margin: '10px', padding: 10 }}
                        progressPending={pending}
                        progressComponent={<LoadingComponent />}
                        dense
                    />
                </div>
            </div>
        </Container>
    )
}