import { Button, Container, Form, Table } from "react-bootstrap"
import { IoHomeOutline } from "react-icons/io5"
import { Op_per } from "../../utils/Permissions"
import { FaPlus } from "react-icons/fa"
import DataTable from "react-data-table-component"
import { useState, useEffect } from "react"
import { DeleteAccount, GetAllAccounts } from "../../utils/proxy/AccountsProxy"
import AccountModal from "./AccountModal"
import { AiFillDelete } from "react-icons/ai"
import Swal from "sweetalert2"

export const Accounts = () => {

    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [accountid, setAccountId] = useState();
    const [opttype, setOptType] = useState();

    useEffect(() => {
        const getall = async () => {
            let d = await GetAllAccounts();
            if (d) {
                setData(d);
            } else {
                alert('Error', 'Issues while fetching data')
            }
        }
        getall()
        setReload(false);
    }, [reload])

    const handleShowCreate = () => {
        setOptType('create');
        setFullscreen(true);
        setShow(true)
    }

    function handleShow(id) {
        setOptType('update');
        setAccountId(id);
        setFullscreen(true);
        setShow(true);
    }

    const handledelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                let d = await DeleteAccount(id)
                if (d) {
                    setReload(true)
                    Swal.fire({
                        title: "Deleted!",
                        text: "Account has been deleted.",
                        icon: "success"
                    });
                }
            }
        });
    }

    const CustomHeader = ({ children }) => (<div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    const columns = [
        {
            name: "Account Name",
            selector: (row) => <div onClick={() => handleShow(row.id)}><u style={{ color: 'blue', cursor: "pointer" }}>{row.accountname}</u></div>,

            // width: "12%",

            fontSize: 10,
            fontWeight: 'bold'
        },
        {
            name: "Account Type",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.typename === "" || row.typename === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.typename === "" || row.typename === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.typename === "" || row.typename === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.typename ? row.typename : "N/A"}</div>,

            // width: "12%",

            fontSize: 10,
            fontWeight: 'bold'
        },
        {
            name: "Industry",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.industryname === "" || row.industryname === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.industryname === "" || row.industryname === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.industryname === "" || row.industryname === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.industryname ? row.industryname : "N/A"}</div>,

            // width: "15%",

            fontSize: 10,
            fontWeight: 'bold'
        },
        {
            name: "Phone",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.phone === "" || row.phone === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.phone === "" || row.phone === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.phone === "" || row.phone === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.phone ? row.phone : "N/A"}</div>,

            // width: "15%",

            fontSize: 10,
            fontWeight: 'bold'
        },
        {
            name: "Website",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.website === "" || row.website === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.website === "" || row.website === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.website === "" || row.website === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.website ? row.website : "N/A"}</div>,

            // width: "15%",

            fontSize: 10,
            fontWeight: 'bold'
        },
        {
            name: "Description",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.description === "" || row.description === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.description === "" || row.description === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.description === "" || row.description === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.description ? row.description : "N/A"}</div>,

            // width: "15%",

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Employees",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.employees === "" || row.employees === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.employees === "" || row.employees === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.employees === "" || row.employees === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.employees ? row.employees : "N/A"}</div>,

            // width: "10%",

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Actions",
            selector: (row) => (
                <div key={row.id}>
                    <AiFillDelete
                        onClick={() => handledelete(row.id)}
                        style={{ color: "red", fontSize: 20, display: Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_DELETE ? '' : 'none' }}
                    />
                </div>
            ),
        }
    ];

    const ExpandedComponent = ({ data }) => (
        <div style={{
            backgroundColor: data.disabled ? "red" : "#D9E1FF",
            color: "#fff",
            padding: 10,
            fontSize: 12,
            margin: 20,
            borderRadius: 20,
            border: "2px solid #28B3D2",
            width: "95%"

        }}>
            <Table
                size="sm"
            >
                <tr>
                    <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }} >Billing Address Street</th>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ padding: 10, width: "30%" }}> <span style={{
                        backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold"
                    }}>{data.billing_address_street}</span> </td>
                    <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }} >Shipping Address Street</th>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ padding: 10, width: "30%" }}> <span style={{
                        backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold"
                    }}>{data.shipping_address_street}</span> </td>
                </tr>

                <tr>

                    <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Billing Address City</th>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ padding: 10, width: "30%" }} > <span style={{
                        backgroundColor: data.billing_address_cityname === "" || data.billing_address_cityname === null ? "red" : "#2457C5", borderRadius: "10px", border: data.billing_address_cityname === "" || data.billing_address_cityname === null ? "2px solid red" : "2px solid #28B3D2", padding: "5px", margin: "3px", color: "#fff", fontWeight: "bold"
                    }}> {data.billing_address_cityname === "" || data.billing_address_cityname === null
                        ? "N/A"
                        : data.billing_address_cityname + " (" + data.billing_address_zip + ")"}</span></td>
                    <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Shipping Address City</th>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ padding: 10, width: "30%" }} > <span style={{
                        backgroundColor: data.shipping_address_cityname === "" || data.shipping_address_cityname === null ? "red" : "#2457C5", borderRadius: "10px", border: data.shipping_address_cityname === "" || data.shipping_address_cityname === null ? "2px solid red" : "2px solid #28B3D2", padding: "5px", margin: "3px", color: "#fff", fontWeight: "bold"
                    }}> {data.shipping_address_cityname === "" || data.shipping_address_cityname === null
                        ? "N/A"
                        : data.shipping_address_cityname + " (" + data.shipping_address_zip + ")"}</span></td>
                </tr>

                <tr>
                    <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Billing Address State</th>

                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ padding: 10, width: "30%" }}> <span style={{
                        backgroundColor: data.billing_address_statename === "" || data.billing_address_statename === null ? "red" : "#2457C5", borderRadius: "10px", border: data.billing_address_statename === "" || data.billing_address_statename === null ? "2px solid red" : "2px solid #28B3D2", padding: "5px", margin: "3px", color: "#fff", fontWeight: "bold"
                    }}> {data.billing_address_statename === "" || data.billing_address_statename === null
                        ? "N/A"
                        : data.billing_address_statename} </span></td>

                    <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Shipping Address State</th>

                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ padding: 10, width: "30%" }}> <span style={{
                        backgroundColor: data.shipping_address_statename === "" || data.shipping_address_statename === null ? "red" : "#2457C5", borderRadius: "10px", border: data.shipping_address_statename === "" || data.shipping_address_statename === null ? "2px solid red" : "2px solid #28B3D2", padding: "5px", margin: "3px", color: "#fff", fontWeight: "bold"
                    }}> {data.shipping_address_statename === "" || data.shipping_address_statename === null
                        ? "N/A"
                        : data.shipping_address_statename} </span></td>
                </tr>

                <tr>

                    <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Billing Address Country </th>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ padding: 10, width: "30%" }}> <span style={{
                        backgroundColor: data.billing_address_countryname === "" || data.billing_address_countryname === null ? "red" : "#2457C5", borderRadius: "10px", border: data.billing_address_countryname === "" || data.billing_address_countryname === null ? "2px solid red" : "2px solid #28B3D2", padding: "5px", margin: "3px", color: "#fff", fontWeight: "bold"
                    }}>{data.billing_address_countryname === "" || data.billing_address_countryname === null
                        ? "N/A"
                        : data.billing_address_countryname} </span></td>

                    <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Shipping Address Country </th>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ padding: 10, width: "30%" }}> <span style={{
                        backgroundColor: "#2457C5" === "" || data.shipping_address_countryname === null ? "red" : "#2457C5", borderRadius: "10px", border: data.shipping_address_countryname === "" || data.shipping_address_countryname === null ? "2px solid red" : "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold"
                    }}> {data.shipping_address_countryname === "" || data.shipping_address_countryname === null ? "N/A" : data.shipping_address_countryname} </span></td>
                </tr>
            </Table>
        </div>
    );

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                            
                        /></a></li>
                        <li><a href="/accounts"><span class="icon icon-beaker"> </span>Products</a></li>
                        <li><a href="/accounts"><span class="icon icon-beaker"> </span>Accounts</a></li>
                        {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

                    </ul>

                </div>
            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <Form.Control
                            className="glass"
                            placeholder="Search"
                            type="text"
                            style={{ display: 'none' }}
                        //   value={searchQuery}
                        //   onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div>
                        {Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_ADD ? (
                            <Button style={{ backgroundColor: "#28B3D2 " }} onClick={handleShowCreate}>
                                <FaPlus />
                            </Button>
                        ) : ("")}
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        customStyles={{ headRow: { style: { backgroundColor: '#E5E1DA', padding: 7 }, } }}
                        CustomHeader={<CustomHeader />}
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        style={{ marginTop: 10 }}
                        dense
                    />
                </div>
                {show ? <AccountModal fullscreen={fullscreen} show={show} setShow={setShow} accountid={accountid} setReload={setReload} opttype={opttype} /> : ""}
            </div>
        </Container>
    )
}