import { Button, Container, Form, Modal } from "react-bootstrap"
import DataTable from "react-data-table-component"
import { IoHomeOutline } from "react-icons/io5"
import { Op_per } from "../../../utils/Permissions"
import { FaPlus } from "react-icons/fa6"
import { useState } from "react"
import { useEffect } from "react"
import { CreateLeadSource, DeleteLeadSource,  EditLeadSource, GetAllLeadSource } from "../../../utils/proxy/LeadsProxy"
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai"
import Swal from "sweetalert2"

export const LeadSource = () => {

    const [data, setData] = useState();
    const [id, setId] = useState();
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [reload, SetReload] = useState(false);
    const [show, setShow] = useState(false);
    const [showedit, setShowedit] = useState(false);

    useEffect(() => {
        const getall = async () => {
            let d = await GetAllLeadSource();
            if (d) {
                setData(d);
            } else {
                alert('Error', 'Issues while fetching data')
            }
        }
        getall()
        SetReload(false);
    }, [reload])

    const handleShow = () => {
        setShow(true);
    }

    const handledelete = async (id) => {
        let d = await DeleteLeadSource(id);
        if (d.status === 'Success') {
            SetReload(true);
            Swal.fire({
                title: "Success!",
                text: "Lead Source Deleted !!",
                icon: "success",
                confirmButtonText: "OK",
            });
        }
    }

    const handleEditShow = (row) => {
        setShowedit(true);
        setId(row.id);
        setName(row.name);
        setDescription(row.description);
    }

    const handleClose = () => {
        setShow(false)
    }

    const handleSave = async () => {
        if (name == null || name == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Lead Source !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await CreateLeadSource(name, description);
            if (d.status === 'Success') {
                setShow(false);
                SetReload(true);
                setName(null);
                setDescription(null);
                Swal.fire({
                    title: "Success!",
                    text: "New Lead Source Added !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const handleEditSave = async() =>{
        if (name == null || name == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Lead Source !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await EditLeadSource(id, name, description);
            if (d.status === 'Success') {
                setShowedit(false);
                SetReload(true);
                setId(null);
                setName(null);
                setDescription(null);
                Swal.fire({
                    title: "Success!",
                    text: "Lead Source Edited !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const columns = [
        // {
        //     name: "Id",
        //     selector: (row) => row.id,
        //   
        //     width:"5%",
        // },
        {
            name: "Lead Source",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.name === "" || row.name === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.name === "" || row.name === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.name === "" || row.name === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.name ? row.name : "N/A"}</div>,

            width: "15%",

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Description",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.description === "" || row.description === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.description === "" || row.description === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.description === "" || row.description === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.description ? row.description : "N/A"}</div>,

            width: "15%",

            fontSize: 10,
            fontWeight: 'bold',
        },
    ];

    const CustomHeader = ({ children }) => (<div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    if (Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_DELETE || Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_EDIT) {
        columns.push({
            name: "Actions",
            selector: (row) => (
                <div key={row.id}>
                    <AiFillDelete
                        onClick={() => handledelete(row.id)}
                        style={{ color: "red", fontSize: 20, display: Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_DELETE ? '' : 'none' }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AiTwotoneEdit
                        onClick={() => handleEditShow(row)}
                        style={{ color: "black", fontSize: 20, display: Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_EDIT ? '' : 'none' }}
                    />
                </div>
            ),
        })
    }

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                            
                        /></a></li>
                        <li><a href="/leadsource-master"><span class="icon icon-beaker"> </span>Sales</a></li>
                        <li><a href="/leadsource-master"><span class="icon icon-beaker"> </span>Lead Source</a></li>
                        {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

                    </ul>

                </div>
            </div>

            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <Form.Control
                            className="glass"
                            placeholder="Search"
                            type="text"
                            style={{ display: 'none' }}
                        //   value={searchQuery}
                        //   onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div>
                        {Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_ADD ? (
                            <Button style={{ backgroundColor: "#28B3D2 " }} onClick={handleShow}>
                                <FaPlus />
                            </Button>
                        ) : ("")}
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <DataTable
                        columns={columns}
                        data={data}
                        // progressPending={pending}
                        // selectableRows
                        // onSelectedRowsChange={({ selectedRows }) =>
                        // setSelectedRows(selectedRows)
                        //   }
                        pagination
                        customStyles={{ headRow: { style: { backgroundColor: '#E5E1DA', padding: 7 }, } }}
                        CustomHeader={<CustomHeader />}
                        // actions={actionsMemo}
                        style={{ marginTop: 10 }}
                        // progressPending={pending}
                        // progressComponent={<LoadingComponent/>}
                        dense
                    />
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Lead Source</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Lead Source <span style={{ color: "red" }} >*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 46,

                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter Lead Source"
                                required
                            />
                        </Form.Group>
                        <Form.Label>Description </Form.Label>
                        <Form.Control
                            style={{
                                height: 46,

                                borderColor: '#28B3D2',
                                borderWidth: 2,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                borderTopLeftRadius: 20,
                                borderBottomRightRadius: 20,
                            }}
                            type="text"
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter Description"
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showedit} onHide={() => setShowedit(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Lead Source</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Lead Source <span style={{ color: "red" }} >*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 46,

                                    borderColor: '#28B3D2',
                                    borderWidth: 2,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    borderTopLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter Lead Source"
                                required
                            />
                        </Form.Group>
                        <Form.Label>Description </Form.Label>
                        <Form.Control
                            style={{
                                height: 46,

                                borderColor: '#28B3D2',
                                borderWidth: 2,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                borderTopLeftRadius: 20,
                                borderBottomRightRadius: 20,
                            }}
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter Description"
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowedit(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleEditSave}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}