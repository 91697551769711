import { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap"
import { IoHomeOutline } from "react-icons/io5"
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy";
import Select from "react-select";
import { Op_per } from "../../utils/Permissions";
import DataTable from "react-data-table-component";
import LoadingComponent from "../Includes/LoadingComponent";
import { GetVisitSummary } from "../../utils/proxy/ChartsProxy";
import { Parser } from "@json2csv/plainjs";
import { RiFileExcel2Fill } from "react-icons/ri";

export const VisitAnalysisReport = () => {

    const months = {
        '01':'Jan',
        '02':'Feb',
        '03':'Mar',
        '04':'Apr',
        '05':'May',
        '06':'Jun',
        '07':'Jul',
        '08':'Aug',
        '09':'Sep',
        '10':'Oct',
        '11':'Nov',
        '12':'Dec',

    }

    const [data, setData] = useState([]);
    const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);
    const [selectedUser, setSelectedUser] = useState();
    const [selectedType, setSelectedType] = useState({ value: 'doctor', label: 'Doctor' });
    const [type, setType] = useState('doctor');
    const [users, setUsers] = useState([]);
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [reload, setReload] = useState(false);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallusers = async () => {
            if (localStorage.getItem('role') === 'Admin') {
                let allusers = await AllUsers();
                allusers.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
                setUsers(allusers);
            } else if (localStorage.getItem('role') === 'Manager') {
                let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
                setUsers(allusers);
            }
        };
        getallusers();
    }, []);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallteritories = async (userid, from, to, type) => {
            let userteritories = await GetVisitSummary(
                userid,
                from,
                to,
                type
            );

            if (userteritories) {
                setData(userteritories);
                setPending(false);
            } else {
                alert("Error", "Issue While Fetching Tour Plan");
            }
        };
        if (userid && start && end) {
            setPending(true);
            getallteritories(userid, start, end, type);
        }
        setReload(false);
    }, [reload, userid, start, end, type]);

    const handleSelectChange = (selected) => {

        setUserId(selected.value);
    };

    const handleStartMonth = (value) => {
        setStart(value);
    }              

    const handleTypeChange = (selected) => {
        setSelectedType(selected);
        setType(selected.value);
    }

    const options = [];
    const typeoptions = [
        { value: 'doctor', label: 'Doctor' },
        { value: 'hospital', label: 'Hospital' },
        { value: 'clinic', label: 'Clinic' },
        { value: 'chemist', label: 'Chemist' },
        { value: 'stockist', label: 'Stockist' },
    ];

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });

    async function downloadCSV(plans) {
        let csv;
        try {
            const opts = {
                fields: [
                    "User Name", 
                    "Headquator", 
                    "Designation", 
                    "Date of Joining", 
                    "Month", 
                    "Met", 
                    "Morning Calls", 
                    "Evening Calls", 
                    "Met 1 Time", 
                    "Met 2 Time", 
                    "Met Above 3 Time", 
                    "Worked Days", 
                    "Leaves", 
                    "Holidays",                
                ],
            };
            const parser = new Parser(opts);
            csv = parser.parse(plans);
            plans.forEach(plan => {
                plan.analysis.forEach(analysis => {
                    csv += `\n${plan.username},${plan.headquator},${plan.designation},${plan.doj},${analysis.month.split('-')[0]+'-'+months[analysis.month.split('-')[1]]},${analysis.met},${analysis.morningcalls},${analysis.eveningcalls},${analysis.met1time},${analysis.met2time},${analysis.metabove3time},${analysis.workeddays},${analysis.leaves},${analysis.holidays}`;
                });
            });
        } catch (err) {
            console.error(err);
        }
    
        const link = document.createElement("a");
    
        if (csv == null) return;
    
        // Modify the filename
        const filename = `VisitAnalysis(${type})-${start}to${end}.csv`;
    
        // Insert placeholder values for colspan/rowspan
        csv = `VisitAnalysis (${type}) ${start} to ${end} \n\n\n${csv}`;
    
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
    
        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    }
    

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                        /></a></li>
                        <li><a href="/visit-analysis"><span class="icon icon-beaker"> </span>Reports</a></li>

                        <li><a href="#"><span class="icon icon-double-angle-right"></span>Visit Analysis</a></li>

                    </ul>

                </div>

            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                    }}
                >
                    <div style={{ width: "320px", display: Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.USER_DROPDOWN ? '' : 'none' }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Users</Form.Label>
                            <div className="w-75">
                                <Select
                                    className="selectdoctor"
                                    options={options}
                                    value={selectedUser}
                                    onChange={handleSelectChange}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ width: "350px" }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">From</Form.Label>
                            <div className="w-75">
                                <input className="selectdoctor" type="month" id="start" name="start" onChange={(e) => handleStartMonth(e.target.value)} value={start} />
                            </div>
                        </Form.Group>

                    </div>
                    <div style={{ width: "450px" }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">To</Form.Label>
                            <div className="w-75">
                                <input className="selectdoctor" type="month" id="start" name="end" onChange={(e) => setEnd(e.target.value)} value={end} />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ width: "320px" }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Type</Form.Label>
                            <div className="w-75">
                                <Select
                                    className="selectdoctor"
                                    options={typeoptions}
                                    value={selectedType}
                                    onChange={handleTypeChange}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div><Button onClick={() => downloadCSV(data)}><RiFileExcel2Fill /></Button></div>
                </div>
                {pending ? (<div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}><LoadingComponent /></div>) : (<div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                    {data.length > 0 ?
                        (<div className="pt-3">
                            <table class="tg">
                                <thead>
                                    <tr>
                                        <th class="tg-0pky" rowspan="2">Field Force Name</th>
                                        <th class="tg-0pky" rowspan="2">Designation</th>
                                        <th class="tg-0pky" rowspan="2">Hq</th>
                                        <th class="tg-0pky" rowspan="2">DOJ</th>
                                        <th class="tg-0pky" rowspan="2">Month</th>
                                        <th class="tg-0pky" rowspan="2">Dcr Met</th>
                                        <th class="tg-0pky" colspan="2">Total Calls</th>
                                        <th class="tg-0pky" colspan="3">Call Details</th>
                                        <th class="tg-0pky" colspan="3">Day Wise Detail</th>
                                    </tr>
                                    <tr>
                                        <th class="tg-0pky">Morning </th>
                                        <th class="tg-0pky">Evening</th>
                                        <th class="tg-0pky"> Met 1 Time</th>
                                        <th class="tg-0pky"> Met 2 Times</th>
                                        <th class="tg-0pky"> Met Above 3 Times</th>
                                        <th class="tg-0pky">Work Days</th>
                                        <th class="tg-0pky">Leaves</th>
                                        <th class="tg-0lax">Holidays</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(dt => (
                                        <>
                                            <tr key={dt.username}>
                                                <td class="tg-0pky" rowspan={dt.analysis.length + 1}>{dt.username}</td>
                                                <td class="tg-0pky" rowspan={dt.analysis.length + 1}>{dt.designation}</td>
                                                <td class="tg-0pky" rowspan={dt.analysis.length + 1}>{dt.headquator}</td>
                                                <td class="tg-0pky" rowspan={dt.analysis.length + 1}>{dt.doj}</td>
                                            </tr>
                                            {dt.analysis.map((an, index) => (
                                                <tr key={index}>
                                                    <td className="tg-0lax">{an.month.split('-')[0]+'-'+months[an.month.split('-')[1]]}</td>
                                                    <td className="tg-0lax">{an.met}</td>
                                                    <td className="tg-0lax">{an.morningcalls}</td>
                                                    <td className="tg-0lax">{an.eveningcalls}</td>
                                                    <td className="tg-0lax">{an.met1time}</td>
                                                    <td className="tg-0lax">{an.met2time}</td>
                                                    <td className="tg-0lax">{an.metabove3time}</td>
                                                    <td className="tg-0lax">{an.workeddays}</td>
                                                    <td className="tg-0lax">{an.leaves}</td>
                                                    <td className="tg-0lax">{an.holidays}</td>
                                                </tr>
                                            ))}
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>) : (<div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>No Record To Show</div>)}
                </div>)}
            </div>
        </Container>
    )
}