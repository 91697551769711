import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import {
  GetAllCountries,
  GetAllCitiesDetail,
  GetAllAreasDetail,
  GetAllStatesDetail,
} from "../../utils/proxy/Countryproxy";
import {
  GetAllCips,
  CreateCip,
  UpdateCip,
  CheckCipAvailable,
  CreateExcelCip,
  DeleteCip,
  CipBulkUpdateArea,
  GetAllCipsDepartment,
  GetAllCipsDesignation,
  GetAllCipsQualification,
  GetAllCipsPagination,
} from "../../utils/proxy/Cipproxy";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import Select from "react-select";
import * as XLSX from "xlsx";
import ProgressBar from "react-bootstrap/ProgressBar";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import Dropdown from "react-bootstrap/Dropdown";
import { Parser } from "@json2csv/plainjs";
import { unwind, flatten } from "@json2csv/transforms";
import Helper from "../../utils/helperFunctions";
import { AllUsers } from "../../utils/proxy/Authproxy";
import { ClinicBulkUpdateArea } from "../../utils/proxy/Clinicproxy";
import { GetAllHospitalss } from "../../utils/proxy/Hospitalsproxy";
import LoadingComponent from "../Includes/LoadingComponent";
import { HiDotsVertical } from "react-icons/hi";
import { SiDatabricks } from "react-icons/si";
import { IoHomeOutline } from "react-icons/io5";
import { Op_per } from "../../utils/Permissions";

function CipMaster() {
  const [cipdepartment, setCipDepartment] = useState([]);
  const [cipdepartmentid, setCipDepartmentId] = useState();
  const [cipdesignation, setCipDesignation] = useState([]);
  const [cipdesignationid, setCipDesignationId] = useState();
  const [cipqualification, setCipQualification] = useState([]);
  const [cipqualificationid, setCipQualificationId] = useState();
  const [hospitalss, setHospitalss] = useState([]);
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [hospitalsid, sethospitalsid] = useState([]);

  const [cips, setCips] = useState([]);
  const [areas, setAreas] = useState([]);
  const [citys, setCitys] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cipname, setCipName] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [areasid, setAreasid] = useState([]);
  const [cipaddress, setCipAddress] = useState("");
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [pincode, setPincode] = useState();
  const [number, setNumber] = useState();
  const [email, setEmail] = useState();
  const [cipid, setCipId] = useState();

  const [show, setShow] = useState(false);
  const [showuploader, setShowUploader] = useState(false);
  const [showedit, setShowEdit] = useState(false);
  const [iscipname, setIsCipName] = useState();
  const [reload, SetReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [editdata, setEditdata] = useState();
  const [pending, setPending] = useState(true);

  const [showbulkareaedit, setShowBulkAreaEdit] = useState(false);
  const [bulkSourceData, setBulkSourceData] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [users, setUsers] = useState([]);

  const [perPage, setPerage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState();

  const [excelData, setExcelData] = useState([]);
  const [columnTitles, setColumnTitles] = useState({
    title1: excelData.length > 0 ? Object.keys(excelData[0])[0] : "",
    title2: excelData.length > 0 ? Object.keys(excelData[0])[1] : "",
    title3: excelData.length > 0 ? Object.keys(excelData[0])[2] : "",
    title4: excelData.length > 0 ? Object.keys(excelData[0])[3] : "",
    title5: excelData.length > 0 ? Object.keys(excelData[0])[4] : "",
  });
  const [fileUploaded, setFileUploaded] = useState(false);

  const filteredData = cips.filter((row) =>
    row.cipname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const options = [];

  areas.map((area) => {
    options.push({ value: area.id, label: area.areaname });
  });

  const hospitalsoptions = [];

  hospitalss.map((area) => {
    hospitalsoptions.push({ value: area.id, label: area.hospitalsname });
  });

  const getallcips = async (page) => {
    let cipsdata = await GetAllCipsPagination(page, perPage,searchQuery);
    if (cipsdata) {
      setCips(cipsdata[0].cips);
      setTotalRows(cipsdata[0].totalrows);
      setCurrentPage(cipsdata[0].currentpage);
      setTotalPages(cipsdata[0].totalpages);
      setPending(false);
    } else {
      alert("Error", "Issue While Fetching CIP's");
    }
  };

  useEffect(() => {
    // Fetch data from an API when the component mounts
    getallcips(currentPage == undefined ? 0 : currentPage);
    SetReload(false);
  }, [reload,searchQuery]);

  const handlePageChange = page => {

    getallcips(page - 1);

  }

  const handlePerRowChange = async (newPerPage, page) => {
    setPerage(newPerPage);
    const areasdata = await GetAllCipsPagination(page - 1, newPerPage,searchQuery);
    if (areasdata) {
      setCips(areasdata[0].cips);
      setTotalRows(areasdata[0].totalrows)
      setTotalPages(areasdata[0].totalpages);
      setPending(false);
    } else {
      alert("Error", "Issue While Fetching Doctors");
    };
  }

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {
      let allusers = await AllUsers();
      if (allusers) {
        setUsers(allusers);
      } else {
        alert("Error", "Issue While Fetching Users");
      }
    };
    getallusers();
  }, []);

  const userOptions = [];

  users.map((user) => {
    userOptions.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallcitys = async () => {
      let citysdata = await GetAllCitiesDetail();
      if (citysdata) {
        setCitys(citysdata);
      } else {
        alert("Error", "Issue While Fetching City");
      }
    };
    getallcitys();

    const getalldocdepartment = async () => {
      let cipdepartmentdata = await GetAllCipsDepartment();
      if (cipdepartmentdata) {
        setCipDepartment(cipdepartmentdata);
      } else {
        alert('Error', 'Issue While Fetching CIP Department');
      }
    }
    getalldocdepartment();

    const getalldocdesignation = async () => {
      let cipdesignationdata = await GetAllCipsDesignation();
      if (cipdesignationdata) {
        setCipDesignation(cipdesignationdata);
      } else {
        alert("Error", "Issue While Fetching CIP Designation");
      }
    };
    getalldocdesignation();

    const getalldocqualification = async () => {
      let cipqualificationdata = await GetAllCipsQualification();
      if (cipqualificationdata) {
        setCipQualification(cipqualificationdata);
      } else {
        alert("Error", "Issue While Fetching CIP Qualification");
      }
    };
    getalldocqualification();

    const getallhospitalss = async () => {
      let hospitalssdata = await GetAllHospitalss();
      if (hospitalssdata) {
        setHospitalss(hospitalssdata);

      } else {
        alert("Error", "Issue While Fetching Hospitals");
      }
    };
    getallhospitalss();

    const getallareas = async () => {
      let areas = await GetAllAreasDetail();
      if (areas) {
        setAreas(areas);
      } else {
        alert("Error", "Issue While Fetching Territories");
      }
    };
    getallareas();

    const getallcountries = async () => {
      let countriesdata = await GetAllCountries();
      if (countriesdata) {
        setCountries(countriesdata);
      } else {
        alert("Error", "Issue While Fetching Countries");
      }
    };
    getallcountries();

    const getallstates = async () => {
      let statesdata = await GetAllStatesDetail();
      if (statesdata) {
        setStates(statesdata);
      } else {
        alert("Error", "Issue While Fetching State");
      }
    };
    getallstates();
  }, [show]);

  useEffect(() => {
    const checkCipNameAvailable = async (cipname) => {
      if (cipname.length > 0) {
        let statedata = await CheckCipAvailable(cipname);
        if (statedata) {
          setIsCipName(true);
        } else {
          setIsCipName(false);
        }
      }
    };
    checkCipNameAvailable(cipname);
  }, [cipname]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setIsCipName(false);
    setShow(true);
  };

  const handleUploaderShow = () => setShowUploader(true);
  const handleUploaderClose = () => {
    setExcelData([]);
    setColumnTitles({
      title1: "",
      title2: "",
      title3: "",
    });
    setFileUploaded(false);
    setShowUploader(false);
  };

  const handleEditShow = (row) => {
    setCipName(row.cipname);
    let ar = [];
    let arid = [];
    row.areas.map((area) => {
      ar.push({ value: area.id, label: area.areaname });
      arid.push(area.id);
      return true;
    });
    setSelectedAreas(ar);
    setAreasid(arid);
    setCipAddress(row.address);
    setState(row.stateid);
    setCity(row.cityid);
    setPincode(row.pincode);
    setCountry(row.countryid);
    setNumber(row.phone);
    setEmail(row.email);
    setCipId(row.id);
    setCipDepartmentId(row.departmentid);
    setCipDesignationId(row.designationid);
    setCipQualificationId(row.qualificationid);
    let hr = [];
    let hrid = [];
    row.hospitals.map((area) => {
      hr.push({ value: area.id, label: area.hospitalname });
      hrid.push(area.id);
      return true;
    });
    setSelectedHospitals(hr);
    sethospitalsid(hrid);
    setShowEdit(true);
  };
  const handleEditClose = () => {
    setCipName("");
    setSelectedAreas([]);
    setAreasid([]);
    setCipAddress("");
    setState("");
    setCity("");
    setPincode("");
    setCountry("");
    setNumber("");
    setEmail("");
    setShowEdit(false);
  };

  const handleCountryCodeChange = (event) => {
    setCountry(event.target.value);
  };

  const handleCipNameChange = (event) => {
    setCipName(event.target.value);
  };

  const handleSelectChange = (selected) => {
    const values = selected.map((option) => option.value);
    setSelectedAreas(selected);
    setAreasid(values);
  };

  const handleSelectHospitalsChange = (selected) => {
    const values = selected.map((option) => option.value);
    setSelectedHospitals(selected);
    sethospitalsid(values);
  };

  const handleCipAddressChange = (event) => {
    setCipAddress(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleDepartmentChange = (event) => {
    setCipDepartmentId(event.target.value);
  };

  const handleDesignationChange = (event) => {
    setCipDesignationId(event.target.value);
  };

  const handleQualificationChange = (event) => {
    setCipQualificationId(event.target.value);
  };

  const handlePincodeChange = (event) => {
    setPincode(event.target.value);
  };

  const handleNumberChange = (event) => {
    setNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  let createCip = async (
    cipname,
    areasid,
    cipaddress,
    country,
    state,
    city,
    pincode,
    number,
    email,
    cipdepartmentid,
    cipdesignationid,
    cipqualificationid,
    hospitalsid
  ) => {
    let statedata = await CreateCip(
      cipname,
      areasid,
      cipaddress,
      country,
      state,
      city,
      pincode,
      number,
      email,
      cipdepartmentid,
      cipdesignationid,
      cipqualificationid,
      hospitalsid
    );
    if (statedata) {
      SetReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: "CIP Added Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Adding CIP");
    }
  };

  let updateCip = async (
    cipid,
    cipname,
    areasid,
    cipaddress,
    country,
    state,
    city,
    pincode,
    number,
    email,
    cipdepartmentid,
    cipdesignationid,
    cipqualificationid,
    hospitalsid
  ) => {

    let statedata = await UpdateCip(
      cipid,
      cipname,
      areasid,
      cipaddress,
      country,
      state,
      city,
      pincode,
      number,
      email,
      cipdepartmentid,
      cipdesignationid,
      cipqualificationid,
      hospitalsid
    );
    if (statedata) {
      SetReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: "CIP Updated Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Updating CIP");
    }
  };

  let deleteCip = async (cipid) => {
    let statedata = await DeleteCip(cipid);
    if (statedata) {
      SetReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: "Cip Deleted !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Deleting CIP");
    }
  };

  let handleSave = (event) => {
    if (iscipname === false) {
      createCip(
        cipname,
        areasid,
        cipaddress,
        country,
        state,
        city,
        pincode,
        number,
        email,
        cipdepartmentid,
        cipdesignationid,
        cipqualificationid,
        hospitalsid
      );
    }
  };

  let handleUpdate = (event) => {
    updateCip(
      cipid,
      cipname,
      areasid,
      cipaddress,
      country,
      state,
      city,
      pincode,
      number,
      email,
      cipdepartmentid,
      cipdesignationid,
      cipqualificationid,
      hospitalsid
    );
  };

  let handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete the Cip?",

      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteCip(id);
      }
    });
  };

  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => <span >{row.id}</span>,
    //   width: '3%',
     
    //   sortable: true,
    // },
    {
      name: "CIP's",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.cipname === "" || row.cipname === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.cipname === "" || row.cipname === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.cipname === "" || row.cipname === null ? "#fff": "#000", margin:"3px", }}>{row.cipname ? row.cipname : "N/A"}</div>,
      width: '9%',
      sortable: true,
      fontSize: 10,
      fontWeight:'bold'
     
    },
    {
      name: "Hospitals",
      selector: (row) => <div style={{ whiteSpace: 'normal' }}>{row.hospitals.length > 0 ? row.hospitals.map((hobby) => (
        <Button style={{padding:1,fontSize:'11px',fontWeight:'bold',margin:3,padding:2,backgroundColor:"#D9E1FF",borderRadius:"10px",border: "2px solid #28B3D2",color:'#000'}} key={hobby.id}>
        <Badge style={{color:"#fff", borderRadius:"5px",backgroundColor:"#2457C5",padding:"3px",}} >{hobby.id}</Badge> {hobby.hospitalname}
    </Button>

      )) : <div style={{ whiteSpace: 'normal',backgroundColor:"red", borderRadius:"10px",padding:"3px", display:"flex", flexDirection:"row",color:"#fff", margin:"3px", }}>N/A</div>}</div>,
      width: '18%',
    },
    {
      name: "Designation",
      selector: (row) => <div style={{ whiteSpace: 'normal' }}>{row.desginationname}</div>,
      sortable: true,
      width: '10%',
      
     
      
    },
    {
      name: "Department",
      selector: (row) => row.departmentname,
      sortable: true,
      width: '7%',
    },
    {
      name: "Qualification",
      selector: (row) => row.qualificationname,
      sortable: true,
      width: '8%',
    },
    {
      name: "Created By",
      selector: (row) =>
      <div style={{ whiteSpace: 'normal',backgroundColor: row.createdbyuser === "" || row.createdbyuser === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.createdbyuser === "" || row.createdbyuser === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.createdbyuser === "" || row.createdbyuser === null ? "#fff": "#000", margin:"3px", }}>{row.createdbyuser ? row.createdbyuser : "N/A"}</div>
      ,
      width: '8%',
    },

    {
      name: "Territories",
      selector: (row) =>
      <div style={{ whiteSpace: 'normal' }}>{row.areas.map((hobby) => (
        <Button style={{padding:1,fontSize:'11px',fontWeight:'bold',margin:3,padding:2,backgroundColor:"#D9E1FF",borderRadius:"10px",border: "2px solid #28B3D2",color:'#000'}} key={hobby.id}>
        <Badge style={{color:"#fff", borderRadius:"5px",backgroundColor:"#2457C5",padding:"3px",}} >{hobby.id}</Badge> {hobby.areaname}
    </Button>
     ))}</div>,
   sortable: true,
   width: "200px"
 },

  ];
  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  if (Op_per.get().MASTERS_CIP.ALLOW_DELETE || Op_per.get().MASTERS_CIP.ALLOW_EDIT) {
    columns.push(
      {
        name: "Actions",
        selector: (row) => (
          <div key={row.id}>
            <AiFillDelete
              onClick={() => handleDelete(row.id)}
              style={{ color: "red", fontSize: 20,display:Op_per.get().MASTERS_CIP.ALLOW_DELETE ? '' : 'none' }}
            />
            &nbsp;&nbsp;&nbsp;
            <AiTwotoneEdit
              style={{ color: "black", fontSize: 20,display:Op_per.get().MASTERS_CIP.ALLOW_EDIT ? '' : 'none' }}
              onClick={() => handleEditShow(row)}
            />
          </div>
        ),
      }
    )
  }

  // A super simple expandable component.
  const ExpandedComponent = ({ data }) => (
    <div
      
      style={{
      backgroundColor: data.disabled ? "red" : "#D9E1FF",
      color: "#fff",
      padding: 10,
      fontSize: 12,
      margin:20,
      borderRadius:20,
      border:"2px solid #28B3D2",
      width:"95%"
      
    }}
    >
    <Table>
    <tr>
    <th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >CIP's</th>
    <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}> <span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}>{data.cipname}</span> </td>
                       <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%" }}>Territories </th>
                       <td style={{ width:"10%"}}>:</td>
                       <td style={{ padding:10,width:"30%" }}>{data.areas != null ? (
                  data.areas.map((are) => (
                    <span
                       style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold",margin:"3px"
                       }}
                    >
                      {are.areaname}
                    </span>
                  ))
                ) : (
                  <span
                   style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold",margin:"3px"
                       }}
                  >
                    N/A
                  </span>
                )}</td>
       
                </tr>

                <tr>
        <th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >Address   </th>
        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}> <span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}>{data.address === "" || data.address === null
                ? "N/A"
                : data.address}</span> </td>
                        <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%" }}>Country </th>
                        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}><span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}> {data.countryname === "" || data.countryname === null
                ? "N/A"
                : data.countryname}</span></td>
    </tr>
    <tr>
        <th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >City  </th>
        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}> <span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}> {data.cityname === "" || data.cityname === null
                ? "N/A"
                : data.cityname}</span> </td>
                <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%" }}>State </th>
                <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}><span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}> {data.statename === "" || data.statename === null
                ? "N/A"
                : data.statename}</span></td>
    </tr>
    <tr>
        <th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >Phone   </th>
        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}><span
                     style={{
                     backgroundColor: data.phone === "" || data.phone === null ? "red":"#2457C5", borderRadius:"10px", border: data.phone === "" || data.phone === null ? "2px solid red": "2px solid #28B3D2",padding:"5px",margin:"3px",color:"#fff", fontWeight:"bold"
                       }}
                    > {data.phone === "" || data.phone === null ? "N/A" : data.phone}</span></td>
                         <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%" }}>Pincode </th>
                         <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}><span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}> {data.pincode === "" || data.pincode === null
                ? "N/A"
                : data.pincode}</span></td>
    </tr>
    <tr>
    <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%" }}>Email </th>
    <td style={{ width:"10%"}}>:</td>
    <td style={{ padding:10,width:"30%" }}> <span
                     style={{
                     backgroundColor: data.email === "" || data.email === null || data.email === "NA" ? "red":"#2457C5", borderRadius:"10px", border: data.email === "" || data.email === null || data.email === "NA" ? "2px solid red": "2px solid #28B3D2",padding:"5px",margin:"3px",color:"#fff", fontWeight:"bold"
                       }}
                    >{data.email === "" || data.email === null ? "N/A" : data.email}</span></td>
                        <th  style={{fontWeight:"bold", color:"#000" , padding:10, }}>Created By </th>
                        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10 }}><span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}> {data.createdbyuser === "" || data.createdbyuser === null
                ? "N/A"
                : data.createdbyuser}</span></td>
    </tr>
    </Table>
      {/* <div className="row">
        <div className="col-3">
          <table>
            <tr>
              <td>
                <b>CIP Name</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.cipname}</td>
            </tr>
            <tr>
              <td>
                <b>Teritories</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>
                {data.areas.map((are) => (
                  <span>{are.areaname}</span>
                ))}
              </td>
            </tr>
          </table>
        </div>
        <div className="col-3">
          <table>
            <tr>
              <td>
                <b>Address</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.address}</td>
            </tr>
            <tr>
              <td>
                <b>Country</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.countryname}</td>
            </tr>
          </table>
        </div>
        <div className="col-3">
          <table>
            <tr>
              <td>
                <b>State</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.statename}</td>
            </tr>
            <tr>
              <td>
                <b>City</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.cityname}</td>
            </tr>
          </table>
        </div>
        <div className="col-3">
          <table>
            <tr>
              <td>
                <b>Pincode</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.pincode}</td>
            </tr>
            <tr>
              <td>
                <b>Phone</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.phone}</td>
            </tr>
          </table>
        </div>
        <div className="col-3">
          <table>
            <tr>
              <td>
                <b>Email</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.email}</td>
            </tr>
            <tr>
              <td>
                <b>Created By</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.createdby}</td>
            </tr>
          </table>
        </div>
      </div> */}
    </div>
  );

  //upload excels
  let createExcelCip = async (jsonData) => {
    let statedata = await CreateExcelCip(jsonData);
    if (statedata) {
      SetReload(true);
      setShowUploader(false);

      Swal.fire({
        title: "Success!",
        text: "CIP Added Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Adding CIP");
    }
  };

  // Upload excel
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      setExcelData(sheetData);
      setFileUploaded(true);
    };

    reader.readAsArrayBuffer(file);
  };

  // export excel
  const handleExcelExport = () => {
    Swal.fire({
      title: "Exporting!",
      text: "Exporting Excel !!",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading()
        let d = await GetAllCips();
        if (d) {
          Swal.hideLoading()
          Swal.close()
          downloadCSV(d);
        }

      }
    });
  }

  const handleTitleChange = (event) => {
    const { name, value } = event.target;
    setColumnTitles({ ...columnTitles, [name]: value });
  };

  const handleAddData = () => {
    const newData = [];
    excelData.forEach((row) => {
      const newRow = {
        cipname: row[columnTitles.title1],
        address: row[columnTitles.title2],
        pincode: row[columnTitles.title3],
        phone: row[columnTitles.title4],
        email: row[columnTitles.title5],
      };
      newData.push(newRow);
    });
    createExcelCip(newData);
  };

  async function downloadCSV(cips) {
    let csv;
    try {
      const opts = {
        fields: [
          "cipname",
          "areas_areaname",
          "address",
          "countryname",
          "statename",
          "pincode",
          "phone",
          "email",
          "disabled",
          "createdby",
        ],
        transforms: [
          unwind({
            paths: ["areas"],
          }),
          flatten({ object: false, array: true, separator: "_" }),
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(cips);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(cips)} />,
    [cips]
  );

  const handleBulkUpdateSourceData = () => {
    let newArr = [];
    selectedRows.map((obj) => {
      let option = {

        // ...obj,
        id: obj.id,
        cipname: obj.cipname,
        areas: Helper.SetTableDataInDropdown(obj.areas, "id", "areaname"),
        users: Helper.SetTableDataInDropdown(obj.users, "id", "username"),
        hospitals: Helper.SetTableDataInDropdown(obj.hospitals, "id", "hospitalname"),
        address: obj.address,
        country: { id: obj.countryid, name: obj.countryname },
        state: { id: obj.stateid, statename: obj.statename },
        city: { id: obj.cityid, cityname: obj.cityname },
        designation:{id: obj.designationid, designation: obj.desginationname},
        department:{id: obj.departmentid, department: obj.departmentname},
        qualification:{id: obj.qualificationid, qualification: obj.qualificationname},
        pincode: obj.pincode,
        phone: obj.phone,
        email: obj.email,
      };
      newArr.push(option);
      return true;
    });
    setBulkSourceData(newArr);
    setShowBulkAreaEdit(true);
  };

  let handleBulkAreaUpdate = async () => {
    let newData = bulkSourceData.map((data) => {
      return {
        id: data.id,
        areas: Helper.GetIdsFromObj(data.areas),
        cipname: data.cipname,
        users: Helper.GetIdsFromObj(data.users),
        hospitals: Helper.GetIdsFromObj(data.hospitals),
        address: data.address,
        countryid: data.country.id,
        stateid: data.state.id,
        cityid: data.city.id,
        pincode: data.pincode,
        designationid:data.designation.id,
        departmentid:data.department.id,
        qualificationid:data.qualification.id,
        phone: data.phone,
        email: data.email,
      };
    });

    let response = await CipBulkUpdateArea(newData);
    if (response) {
      SetReload(true);
      setShowBulkAreaEdit(false);

      Swal.fire({
        title: "Success!",
        text: "CIP Bulk Updated !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Updating Data");
    }
  };

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding:0,
          backgroundColor:'#3887BE'
        }}
      >
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
          /></a></li>
  <li><a href="/cip-master"><span class="icon icon-beaker"> </span>Masters</a></li>
  <li><a href="/cip-master"><span class="icon icon-beaker"> </span>CIP's</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Form.Control
            style={{height: 46,
            width:250,
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
              className="glass"
              placeholder="Search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            {selectedRows.length > 0 && 
            Op_per.get().MASTERS_CIP.ALLOW_EDIT ? (

              <div class="dropdown" style={{ marginRight: 10 }}>
                <button
                  class="btn btn-secondary"
                  type="button"
                  variant="warning"
                  title="Bulk Menu"
                  id="dropdown-basic"
                  style={{ backgroundColor: " #FFCA2C" }}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                <SiDatabricks color="#000" />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={handleBulkUpdateSourceData}>
                    <a class="dropdown-item"> Update</a>
                  </li>
                 
                 
                </ul>
              </div>
              
              
            ) : (
              ""
            )}
            {Op_per.get().MASTERS_CIP.ALLOW_ADD || Op_per.get().MASTERS_CIP.ALLOW_EXPORT_XLSX || Op_per.get().MASTERS_CIP.ALLOW_IMPORT_XLSX ? (
              <div class="dropdown">
                <button
                  class="btn btn-secondary"
                  type="button"
                  style={{ backgroundColor: "#28B3D2" }}
                  title="Options"
                  id="dropdown-basic"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <HiDotsVertical />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={handleShow} style={{display:Op_per.get().MASTERS_CIP.ALLOW_ADD ? '' : 'none'}}>
                    <a class="dropdown-item">Add</a>
                  </li>
                  <li onClick={handleUploaderShow} style={{display:Op_per.get().MASTERS_CIP.ALLOW_IMPORT_XLSX ? '' : 'none'}}>
                    <a class="dropdown-item">Import XLSX</a>
                  </li>
                  <li onClick={handleExcelExport} style={{display:Op_per.get().MASTERS_CIP.ALLOW_EXPORT_XLSX ? '' : 'none'}}>
                    <a class="dropdown-item">Export XLSX</a>
                  </li>
                </ul>
              </div>
            ) : ("")}

            {/* <Export onExport={() => downloadCSV(cips)} /> */}
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            columns={columns}
            data={cips}
            selectableRows
            onSelectedRowsChange={({ selectedRows }) =>
              setSelectedRows(selectedRows)
            }
            highlightOnHover
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            progressPending={pending}
            progressComponent={<LoadingComponent />}
            style={{ marginTop: 10 }}
            // actions={actionsMemo}
            dense
          />
        </div>
      </div>
      <Modal show={show} backdrop="static" size="md" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add CIP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>CIP's <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
                style={{
                  backgroundColor: iscipname ? "red" : "",
                  color: iscipname ? "#fff" : "",
                  height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,
                }}
                type="text"
                onChange={handleCipNameChange}
                placeholder="Enter Cip Name"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Hospitals <span style={{color:"red"}} >*</span></Form.Label>
              <Select
               className="selectdoctor"
                closeMenuOnSelect={false}
                options={hospitalsoptions}
                value={selectedHospitals}
                onChange={handleSelectHospitalsChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Territories <span style={{color:"red"}} >*</span></Form.Label>
              <Select
               className="selectdoctor"
                closeMenuOnSelect={false}
                options={options}
                value={selectedAreas}
                onChange={handleSelectChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Address</Form.Label>
              <Form.Control
              style={{height: 46,
            
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                type="text"
                onChange={handleCipAddressChange}
                placeholder="Enter Address"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Country <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select  className="selectdoctor" onChange={handleCountryCodeChange} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select Country---</option>
                {countries.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>State <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select  className="selectdoctor" onChange={handleStateChange} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select State---</option>
                {states.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.statename}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select   className="selectdoctor" onChange={handleCityChange} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select City---</option>
                {citys.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.cityname}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Department <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select  className="selectdoctor" onChange={handleDepartmentChange} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select Department---</option>
                {cipdepartment.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.department}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Designation <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select  className="selectdoctor" onChange={handleDesignationChange} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select Designation---</option>
                {cipdesignation.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.designation}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Qualification <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select  className="selectdoctor" onChange={handleQualificationChange} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select Qualification---</option>
                {cipqualification.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.qualification}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Pincode <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="text"
                onChange={handlePincodeChange}
                placeholder="Enter Pincode"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Phone <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="number"
                onChange={handleNumberChange}
                placeholder="Enter Phone"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Email <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="email"
                onChange={handleEmailChange}
                placeholder="Enter Email"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={iscipname ? true : false}
          >
            Add Cip
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showuploader} onHide={handleUploaderClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
            />
          </div>

          {fileUploaded && (
            <div>
              <hr />
              <div>
                <label htmlFor="title1">CIP's <span style={{color:"red"}} >*</span></label>
                <Form.Select
                className="selectdoctor"
                  id="title1"
                  name="title1"
                  value={columnTitles.title1}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label htmlFor="title2">Address</label>
                <Form.Select
                className="selectdoctor"
                  id="title2"
                  name="title2"
                  value={columnTitles.title2}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label htmlFor="title3">Pincode <span style={{color:"red"}} >*</span></label>
                <Form.Select
                className="selectdoctor"
                  id="title3"
                  name="title3"
                  value={columnTitles.title3}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label htmlFor="title4">Phone <span style={{color:"red"}} >*</span></label>
                <Form.Select
                className="selectdoctor"
                  id="title4"
                  name="title4"
                  value={columnTitles.title4}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label htmlFor="title5">Email <span style={{color:"red"}} >*</span></label>
                <Form.Select
                className="selectdoctor"
                  id="title5"
                  name="title5"
                  value={columnTitles.title5}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUploaderClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddData}>
            Add Data
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showedit} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit CIP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>CIP's <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="text"
                onChange={handleCipNameChange}
                placeholder="Enter Cip Name"
                value={cipname}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Hospitals <span style={{color:"red"}} >*</span></Form.Label>
              <Select
              className="selectdoctor"
                closeMenuOnSelect={false}
                options={hospitalsoptions}
                value={selectedHospitals}
                onChange={handleSelectHospitalsChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Territories <span style={{color:"red"}} >*</span></Form.Label>
              <Select
              className="selectdoctor"
                closeMenuOnSelect={false}
                options={options}
                value={selectedAreas}
                onChange={handleSelectChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Address</Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="text"
                onChange={handleCipAddressChange}
                placeholder="Enter Address"
                required
                value={cipaddress}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Country <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select
              className="selectdoctor"
                onChange={handleCountryCodeChange}
                required
                value={country}
                style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
              >
                <option>---Select Country---</option>
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>State <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={handleStateChange} required value={state}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select State---</option>
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.statename}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={handleCityChange} required value={city}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select City---</option>
                {citys.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.cityname}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Department <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={handleDepartmentChange} value={cipdepartmentid} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select Department---</option>
                {cipdepartment.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.department}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Designation <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={handleDesignationChange} value={cipdesignationid} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select Designation---</option>
                {cipdesignation.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.designation}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Qualification <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={handleQualificationChange} value={cipqualificationid} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select Qualification---</option>
                {cipqualification.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.qualification}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Pincode <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="text"
                onChange={handlePincodeChange}
                placeholder="Enter Pincode"
                required
                value={pincode}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Phone <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="number"
                onChange={handleNumberChange}
                placeholder="Enter Phone"
                required
                value={number}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Email <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="email"
                onChange={handleEmailChange}
                placeholder="Enter Email"
                value={email}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update Cip
          </Button>
        </Modal.Footer>
      </Modal>
      {showbulkareaedit && (
        <Modal
          fullscreen={true}
          size="lg"
          show={true}
          onHide={() => setShowBulkAreaEdit(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Bulk Update CIPs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="scrollable-div">
              <Table
                style={{ tableLayout: "auto", width: "100%" }}
                className="blazetable"
                striped
                bordered
                hover
                size="sm"
              >
                <thead className="sticky">
                  <tr style={{ width: "auto", whiteSpace: "nowrap" }}>
                    {Object.keys(bulkSourceData[0]).map((row, index) => {
                      return <th className={index === 0 ? "sticky-col1" : index === 1 ? "sticky-col2" : ""}>{row}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {bulkSourceData.map((row, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        {Object.keys(row).map((cell, cellIndex) => {
                          return (
                            <td
                              style={{ width: "auto", whiteSpace: "nowrap" }}
                              key={cellIndex}
                              className={cellIndex === 0 ? "sticky-col1" : cellIndex === 1 ? "sticky-col2" : ""}
                            >
                              {cell === "areas" || cell === "users" || cell === "country" || cell == "state" || cell == "city" || cell === "hospitals" || cell === "designation" || cell === "department" || cell === "qualification" ? (
                                <div style={{ minWidth: "200px" }}>
                                  <Select
                                    closeMenuOnSelect={false}
                                    options={
                                      cell === "areas" ? options : cell === "country" ? countries : cell == "state" ? states : cell === "city" ? citys : cell === "hospitals" ? hospitalsoptions : cell === "designation" ? cipdesignation : cell === "department" ? cipdepartment : cell === "qualification" ? cipqualification :  userOptions
                                    }
                                    value={bulkSourceData[rowIndex][cell]}
                                    getOptionLabel={(option) => cell === "country" ? option.name : cell === "state" ? option.statename : cell === "city" ? option.cityname : cell === "designation" ? option.designation : cell === "department" ? option.department : cell === "qualification" ? option.qualification : option.label}
                                    getOptionValue={(option) => cell === "country" || cell == "state" || cell == "city" ? option.id :  cell === "designation" ? option.id :  cell === "department" ? option.id : cell === "qualification" ? option.id : option.value}
                                    onChange={(selectedOption) => {
                                      const newValue = selectedOption;
                                      setBulkSourceData((prevData) => {
                                        const newData = [...prevData];
                                        newData[rowIndex][cell] = newValue;
                                        return newData;
                                      });
                                    }}
                                    isMulti={cell !== 'country' && cell !== 'state' && cell !== 'city' && cell !== 'designation' && cell !== 'department' && cell !== 'qualification'}
                                  />
                                </div>
                              ) : (
                                <input
                                  type="text"
                                  name={cell}
                                  value={
                                    bulkSourceData[rowIndex][cell] !== null &&
                                      bulkSourceData[rowIndex][cell] !== undefined
                                      ? bulkSourceData[rowIndex][cell]
                                      : ""
                                  }
                                  disabled={cell === "id"}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setBulkSourceData((prevData) => {
                                      const newData = [...prevData];
                                      newData[rowIndex][cell] = newValue;
                                      return newData;
                                    });
                                  }}
                                />
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowBulkAreaEdit(false)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleBulkAreaUpdate}>
              Update CIPs
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
}

export default CipMaster;
