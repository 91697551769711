import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

// Doctro

export const GetAllManagersProxy = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/manageruser/bymanager/managerdetail", null);
};


export const UpdateManagersProxy = async (managerid,Citiesid,userid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();

  let data = JSON.stringify({
    "managerid":managerid,
    "cities":Citiesid,
    "users":userid
});
  return await Restapi("put", REPORTING_URL + "/apiv1/manageruser/bymanager/managerdetail", data);
};

export const CreateManagersProxy = async (managerid,Citiesid,userid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();

  let data = JSON.stringify({
    "managerid":managerid,
    "cities":Citiesid,
    "users":userid
});
  return await Restapi("post", REPORTING_URL + "/apiv1/manageruser/bymanager/managerdetail", data);
};