import React, { Component, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Row } from 'react-bootstrap';
import { TbChartInfographic } from 'react-icons/tb';
import { getPerDayByMonth } from '../../utils/proxy/ReportsProxy';

function BarChart(selectedUserId,selectedMonth,selectedYear) {

   
    const [dates,setDates] = useState([]);
    const [counts,setCounts] = useState([]);


    useEffect(() => {
        getTasks();
    }, [selectedUserId,selectedMonth,selectedYear])



    async function getTasks() {
        let data = await getPerDayByMonth(selectedUserId.selectedYear.value,selectedUserId.selectedMonth.value,selectedUserId.selectedUserId);
        if (data) {
            let d = [];
            let c = [];
            data.forEach(element => {
                d.push(element.date.split('-')[2]);
                c.push(element.count);
            });
            setDates(d);
            setCounts(c);
        }
    }

    const options = {

        chart: {
            id: 'basic-bar'
        },
        
        xaxis: {
            categories: dates
        }
    }
    const series = [
        {
            name: 'Tasks',
            data: counts
        }
    ]

        return (
            <>
            <Row>
                    <div style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex', flexDirection: 'row', backgroundColor:'#FF8B13', borderRadius:20, padding:0,marginBottom:0,color:"white"     }}>
                        <div md={4}>
                            <div style={{ backgroundColor: '#f5dd4b', padding: '10px',marginRight:10, borderRadius: 50, height: 40, width: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <TbChartInfographic color='#000' size={30} />
                            </div>
                        </div>
                        <div md={4}>
                        <h6 style={{textAlign:'center', marginBottom:0}}>User Tasks Completed ({selectedUserId.selectedMonth.value}-{selectedUserId.selectedYear.value})</h6>
                        </div>
                        <div md={4}>
                            {/* <Button size='sm' variant="primary">+</Button> */}
                        </div>
                    </div>
                </Row> 
            <Row>
                        <Chart
                            options={options}
                            series={series}
                            type="bar"
                            height="300"
                            width="100%"
                        />
                </Row>
            </>
        );
    
}

export default BarChart;
