import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import { GetUserDetails } from '../../utils/proxy/ReportsProxy';
import { FaUserPen } from "react-icons/fa6";

function UserDetail(selectedUserId) {

    const [userinfo,setUserInfo] = useState({});

    useEffect(() => {

        const getUserDetail = async (prams) => {
            let res = await GetUserDetails(prams);
            if (res) {
                setUserInfo(res);
            }
        }

        if (selectedUserId.selectedUserId !=null || selectedUserId.selectedUserId != undefined) {
          getUserDetail(selectedUserId.selectedUserId)
        }

    },[selectedUserId])

  return (
    <>
      <Row>
                    <div style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex', flexDirection: 'row', backgroundColor:'#FF8B13', borderRadius:20, padding:0,marginBottom:10,color:"white"     }}>
                        <div md={4}>
                            <div style={{ backgroundColor: '#f5dd4b', padding: '10px',marginRight:10, borderRadius: 50, height: 40, width: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <FaUserPen color='#000' size={30} />
                            </div>
                        </div>
                        <div   md={4}>
                            <h6  style={{textAlign:'center', marginBottom:0, paddingTop:"10px !important"}}>User Detail</h6>
                        </div>
                        <div md={4}>
                            {/* <Button size='sm' variant="primary">+</Button> */}
                        </div>
                    </div>
                </Row> 
            <Row style={{paddingBottom:10}}>
                <Col md={6} style={{justifyContent:"flex-start", width:"35%"}}>
                <div style={{display:'flex',justifyContent:'center'}}><img src={userinfo.image} style={{borderRadius:"50px"}} height={70} width={70} /></div>
                </Col>
                <Col className='mt-2'  md={6}>
                    <h6>{userinfo.firstname} {userinfo.lastname}</h6>
                    <p>{userinfo.role}</p>
                </Col>
            </Row>
            {/* <Row className='pt-2' style={{textAlign:'center'}}>
                <Col>
                    <h6>{userinfo.firstname} {userinfo.lastname}</h6>
                    <p>{userinfo.role}</p>
                </Col>
            </Row> */}
       <Row>
       <Col md={12}>
       <Table className='jmn-table' size='xs' responsive>
       <tr>
          <td ><b>Username</b></td>
          <td className='pr-table-seprator'>:</td>
          <td>{userinfo.username ? userinfo.username : "N/A"}</td>
        </tr>
        <tr>
          <td ><b>Email</b></td>
          <td className='pr-table-seprator'>:</td>
          <td>{userinfo.email ? userinfo.email : "N/A"}</td>
        </tr>
        <tr>
          <td ><b>Phone</b></td>
          <td className='pr-table-seprator'>:</td>
          <td>{userinfo.phone ? userinfo.phone : "N/A"}</td>
        </tr>
        <tr>
          <td ><b>Address</b></td>
          <td className='pr-table-seprator'>:</td>
          <td>{userinfo.address ? userinfo.address : "N/A"}</td>
        </tr>
    </Table>
       </Col>
       </Row>
</>
  )
}

export default UserDetail