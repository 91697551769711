import Restapi from "../api/Restapi";
import { constats } from "../constant";

export const GetAllProductsCategory = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/productscategory',null);
}

export const CreateProductsCategory = async (name,description,parentcategoryid) => {
    const data = {
        name,
        description,
        parentcategoryid
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/productscategory',data);
}

export const UpdateProductsCategory = async (id,name,description,parentcategoryid) => {
    const data = {
        name,
        description,
        parentcategoryid
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/productscategory/'+id,data);
}

export const DeleteProductsCategory = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/productscategory/'+id,null);
}

export const GetProductCategoryById = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/productscategory/'+id,null);
}

export const ProductCategoryAvailableByName = async (name) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/productscategory/available/'+name,null);
}

export const GetAllProducts = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/products',null);
}

export const CreateProduct = async (name,description,categoryid,currencyid,price,saleprice,quantity,image) => {
    const data = {
        name,
        description,
        categoryid,
        currencyid,
        price,
        saleprice,
        quantity,
        image
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/products',data);
}

export const UpdateProduct = async (id,name,description,categoryid,currencyid,price,saleprice,quantity,image) => {
    const data = {
        name,
        description,
        categoryid,
        currencyid,
        price,
        saleprice,
        quantity,
        image
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/products/'+id,data);
}

export const DeleteProduct = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/products/'+id,null);
}

export const ProductAvailableByName = async (name) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/products/available/'+name,null);
}