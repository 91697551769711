import React, { useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import { createTheme } from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import Select from "react-select";
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Helper from "../../utils/helperFunctions";
import { GetAllDcr } from "../../utils/proxy/Dcrproxy";
import {
  CreateLeaveRequest,
  DeleteLeaveRequest,
  GetAllLeaveRequestsByUserAndStatus,
  GetAllLeaveRequestsByUserAndStatusCopy,
  UpdateLeaveRequest,
} from "../../utils/proxy/LeaveRequestProxy";
import Swal from "sweetalert2";
import { Parser } from "@json2csv/plainjs";
import { IoHomeOutline } from "react-icons/io5";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaPlus } from "react-icons/fa6";
import { GetAllLeaveType } from "../../utils/proxy/LeaveTypeMasterProxy";
import { Op_per, Permissions } from "../../utils/Permissions";
import { IoIosClose } from "react-icons/io";

let statusOption = [
  { value: "PENDING", label: "PENDING" },
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "REJECTED", label: "REJECTED" },
];

// createTheme(
//   "solarized",
//   {
//     text: {
//       primary: "#268bd2",
//       secondary: "#2aa198",
//     },
//     background: {
//       default: "#002b36",
//     },
//     context: {
//       background: "#cb4b16",
//       text: "#FFFFFF",
//     },
//     divider: {
//       default: "#073642",
//     },
//     button: {
//       default: "#2aa198",
//       hover: "rgba(0,0,0,.08)",
//       focus: "rgba(255,255,255,.12)",
//       disabled: "rgba(255, 255, 255, .34)",
//     },
//     sortFocus: {
//       default: "#2aa198",
//     },
//   },
//   "dark"
// );

function LeaveRequest() {
  const [leaverequest, setLeaveRequest] = useState([]);
  const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : "");
  const [reload, setReload] = useState(false);
  const [selectedUser, setSelectedUser] = useState(localStorage.getItem('role')==='User' ? {value:localStorage.getItem('uuid'),label:localStorage.getItem('firstname')+" "+localStorage.getItem('lastname')+" (Self)"} : null);
  const [selectedUserId, setSelectedUserId] = useState();
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [selectedStatus,setSelectedStatus] = useState('');
  const [leaveType, setLeaveType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leaveReason, setLeaveReason] = useState("");
  const [editStatus, setEditStatus] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [leaveId, setLeaveId] = useState("");
  const [allLeaves, setAllLeaves] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState();
  const [selectedLeaveId, setSelectedLeaveId] = useState();

  const [showAdd, setShowAdd] = useState(false);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [totalRows,setTotalRows] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [date, setDate] = useState('');

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getAllLeaveByuserAndStatus = async () => {
      let managerid = localStorage.getItem('role') === "Manager" ? localStorage.getItem('uuid') : ''
      let response = await GetAllLeaveRequestsByUserAndStatusCopy(
        userid,page,size,
        selectedStatus,managerid,date
      );
      if (response) {
        setLeaveRequest(response.leaverequest);
        setTotalPages(response.totalpages)
        setTotalRows(response.totalrows)
        setCurrentPage(response.currentpage)
        
      } else {
        alert("Error", "Issue While Fetching Leaves");
      }
    };
    
    getAllLeaveByuserAndStatus();
    setReload(false);
  }, [reload, userid, showModal, selectedStatus,page,size,date]);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {
      if (localStorage.getItem('role') === 'Admin') {
        let allusers = await AllUsers();
        setUsers(allusers);
      } else if (localStorage.getItem('role') === 'Manager') {
        let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
        setUsers(allusers);
      }
    };
    getallusers();
  }, []);

  useEffect(() => {
    const getleavetypes = async () => {
      let d = await GetAllLeaveType();
      if (d) {
        setAllLeaves(d);
      } else {
        alert("Unable To Fetch")
      }
    }
    getleavetypes();
  }, [])

  const options = [];
  const leaveoptions = [];

  users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  allLeaves.map(item => {
    leaveoptions.push({
      value: item.id,
      label: item.name
    })
  })

  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
     
    //   width: "5%",
    // },
    {
      name: "Username",
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.username === "" || row.username === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.username === "" || row.username === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.username === "" || row.username === null ? "#fff" : "#000", margin: "3px", }}>{row.username ? row.username : "N/A"}</div>,
      width: "10%",

    },
    {
      name: "Leave Type",
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.leavetypename === "" || row.leavetypename === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.leavetypename === "" || row.leavetypename === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.leavetypename === "" || row.leavetypename === null ? "#fff" : "#000", margin: "3px", }}>{row.leavetypename ? row.leavetypename : "N/A"}</div>,
      width: "10%",

    },
    {
      name: "Start Date",
      selector: (row) =>
        Helper.ConvertUtcToLocalTime(row.startdate).slice(0, 10),
      width: "10%",
    },
    {
      name: "End Date",
      selector: (row) => Helper.ConvertUtcToLocalTime(row.enddate).slice(0, 10),
      width: "10%",
    },
    {
      name: "Status",
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.status === "" || row.status === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.status === "" || row.status === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.status === "" || row.status === null ? "#fff" : "#000", margin: "3px", }}>{row.status ? row.status : "N/A"}</div>,
      width: "10%",

    },
    {
      name: "Leave Reason",
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.leavereason === "" || row.leavereason === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.leavereason === "" || row.leavereason === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.status === "" || row.leavereason === null ? "#fff" : "#000", margin: "3px", }}>{row.status ? row.leavereason : "N/A"}</div>,
      width: "20%",
    },
    // {
    //   name: "Created At",
    //   selector: (row) => Helper.ConvertUtcToLocalTime(row.createdat),
    //   width: "200px",
    // },
    // {
    //   name: "Updated At",
    //   selector: (row) => Helper.ConvertUtcToLocalTime(row.updatedat),
    //   width: "200px",
    // },
    // {
    //   name: "Actions",
    //   selector: (row) => (
    //     <div key={row.id}>
    //       <AiTwotoneEdit
    //         style={{ color: "black", fontSize: 20, display:Op_per.get().LEAVE_REQUEST.ALLOW_EDIT ? '' : 'none' }}
    //         onClick={() => handleEditShow(row)}
    //       />
    //       &nbsp;&nbsp;&nbsp;
    //       <AiFillDelete
    //         onClick={() => handleDelete(row.id)}
    //         style={{ color: "red", fontSize: 20, display:Op_per.get().LEAVE_REQUEST.ALLOW_DELETE ? '' : 'none' }}
    //       />
    //     </div>
    //   ),
    //   width: "150px",
    // },
  ];

  if(Op_per.get().LEAVE_REQUEST.ALLOW_DELETE || Op_per.get().LEAVE_REQUEST.ALLOW_EDIT){
    columns.push({
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>
          <AiTwotoneEdit
            style={{ color: "black", fontSize: 20, display:Op_per.get().LEAVE_REQUEST.ALLOW_EDIT ? '' : 'none' }}
            onClick={() => handleEditShow(row)}
          />
          &nbsp;&nbsp;&nbsp;
          <AiFillDelete
            onClick={() => handleDelete(row.id)}
            style={{ color: "red", fontSize: 20, display:Op_per.get().LEAVE_REQUEST.ALLOW_DELETE ? '' : 'none' }}
          />
        </div>
      ),
      width: "150px",
    })
  }

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  const handleEditShow = (row) => {
    console.log(row);
    setShowModal(true);
    setSelectedUserId(row.userid)
    setLeaveId(row && row.id ? row.id : "");
    setLeaveType(row && row.leavetype ? row.leavetype : "");
    setSelectedLeave({value:row.leavetype,label:row.leavetypename})
    setSelectedLeaveId(row.leavetype)
    setStartDate(row && row.startdate ? row.startdate : "");
    setEndDate(row && row.enddate ? row.enddate : "");
    setLeaveReason(row && row.leavereason ? row.leavereason : "");
    setEditStatus(
      row && row.status ? { value: row.status, label: row.status } : ""
    );
    setCreatedAt(row && row.createdat ? row.createdat : "");
  };

  const handleDelete = async(row) => {
    let d = await DeleteLeaveRequest(row);
        if (d.status === "success") {
            setReload(true)
            Swal.fire({
                title: "Deleted!",
                text: "Leave Request Deleted !!",
                icon: "success",
                confirmButtonText: "OK",
            });
        }else{
          alert('Something Went Wrong');
        }
   };

  const handleSelectChange = (selected) => {
    if (selected == null) {
      setSelectedUser(null);
      setUserId('')
      setReload(true)
      return;
    }
    setSelectedUser(selected)
    setUserId(selected.value);
    setPage(0);
    setSize(10);
  };

  const handleStatusChange = (selected) =>{
    if (selected == null) {
      setStatus(null);
      setSelectedStatus('')
      return;
    }
    setStatus(selected);
    setSelectedStatus(selected.value);
    setReload(true)
  }

  const handleSelectedLeaveType = (selected) => {
    setSelectedLeave(selected);
    setSelectedLeaveId(selected.value);
  }

  const handlePageChangeTable = (page) =>{
    setPage(page-1);
  }

  const handlePerRowChange = (newPerPage, page) =>{
    setSize(newPerPage);
    setPage(page-1);
  }

  function dateToIsoStringWithoutChange(istDate) {
    if (!(istDate instanceof Date)) {
      throw new Error("Input must be a Date object");
    }

    istDate.setMinutes(istDate.getMinutes() - istDate.getTimezoneOffset());

    const isoString = istDate.toISOString();

    return isoString;
  }

  const handleDateFilter = (value) => {
    if (value == null ) {
      setDate('');
      setReload(true);
      return;
    }
    setDate(dateToIsoStringWithoutChange(value).split('T')[0]);
  };
  
  const clearDate = () => {
    setDate('');
  };

  const handleUpdate = async () => {
    let data = {
      userid: selectedUserId,
      leavetype: selectedLeaveId,
      startdate:
        startDate,
      enddate: endDate,
      leavereason: leaveReason,
      status: editStatus.value,
      createdat: createdAt,
      updatedAt: Helper.ConvertLocalTimeToUtc(new Date()),
    };
    let response = await UpdateLeaveRequest(leaveId, data);
    if (response) {
      setReload(true);
      setShowModal(false);

      Swal.fire({
        title: "Success!",
        text: "Leave Request Updated Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Updating Leave");
    }
  };

  const addhandlesubmit = async() => {
    if (userid == null || userid == '') {
      Swal.fire({
        title: "Warning!",
        text: "Select User !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (startDate == null || startDate == '') {
      Swal.fire({
        title: "Warning!",
        text: "Select Start Date !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (endDate == null || endDate == '') {
      Swal.fire({
        title: "Warning!",
        text: "Select End Date !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (selectedLeaveId == null) {
      Swal.fire({
        title: "Warning!",
        text: "Select Leave Type !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (leaveReason == null || leaveReason == '') {
      Swal.fire({
        title: "Warning!",
        text: "Enter Leave Reason !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else{
      let d = await CreateLeaveRequest(userid,startDate,endDate,selectedLeaveId,leaveReason);
      if(d.status == 'Success'){
        Swal.fire({
          title: "Success!",
          text: "Leave Applied !!",
          icon: "success",
          confirmButtonText: "OK",
        });
        setShowAdd(false);
        setReload(true);  
        setStartDate('')
        setEndDate('')
        setSelectedLeave(null)
        setSelectedLeaveId(null)
        setLeaveReason(null)
      }else{
        alert("Error", "Issue while Applying Leave");
      }
    }
  }

  async function downloadCSV(leaverequest) {
    let csv;
    try {
      const opts = {
        fields: [
          "id",
          "leavetype",
          "startdate",
          "enddate",
          "status",
          "leavereason",
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(leaverequest);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (

    <Button style={{ backgroundColor: "#28B3D2 ", display:Op_per.get().LEAVE_REQUEST.ALLOW_EXPORT_XLSX ? '' : 'none' }} onClick={(e) => onExport(e.target.value)}>
      <RiFileExcel2Fill />
    </Button>
    /* <Button style={{backgroundColor:"#28B3D2", borderTopLeftRadius:20, borderBottomRightRadius:20,color:"#fff", border:"none"}} onClick={(e) => onExport(e.target.value)}>Export All</Button> */
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(leaverequest)} />,
    [leaverequest]
  );

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 0,
          backgroundColor: '#3887BE'
        }}
      >

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ul id="breadcrumb">
            <li><a href="/"><IoHomeOutline
              style={{ color: "white", fontSize: 20 }}
              
            /></a></li>
            <li><a href="/user-doctor-master"><span class="icon icon-beaker"> </span>Leave Request</a></li>
            {/* <li><a href="/user-doctor-master"><span class="icon icon-beaker"> </span> User Doctor Master</a></li> */}
            {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

          </ul>

        </div>

      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap:5
            }}
          >
            <div style={{ width: "350px", paddingRight: "20px", display: Op_per.get().LEAVE_REQUEST.USER_DROPDOWN ? '' : 'none' }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Users</Form.Label>
                <div className="selectdoctor">
                  <Select

                    options={options}
                    value={selectedUser}
                    onChange={handleSelectChange}
                    isClearable
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>
            </div>
            <div style={{ width: "350px" }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Status</Form.Label>
                <div className="selectdoctor">
                  <Select

                    options={statusOption}
                    value={status}
                    onChange={handleStatusChange}
                    isClearable
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>
            </div>
            <div style={{ width: "350px" }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Date</Form.Label>
                <div className="mt-2">
                  <DatePicker
                    clearIcon={<IoIosClose onClick={()=>clearDate()} />}
                    onChange={(date) => handleDateFilter(date)}
                    value={date}
                    format="dd-MM-yy"
                  />
                </div>
              </Form.Group>
            </div>
          </div>
          <div style={{ float: 'right' }}>
            <Button style={{ backgroundColor: "#28B3D2", display:Op_per.get().LEAVE_REQUEST.ALLOW_ADD ? '' : 'none' }} onClick={() => { setShowAdd(true) }}>
              <FaPlus />
            </Button>
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            columns={columns}
            data={leaverequest}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
            style={{ marginTop: 10 }}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChangeTable}
            highlightOnHover
            actions={actionsMemo}
            dense
          />
        </div>
      </div>

      <Modal show={showAdd} onHide={() => setShowAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Leave Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>User <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select

                    options={options}
                    value={selectedUser}
                    onChange={handleSelectChange}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Start Date <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='date'
                placeholder="Select Start Date"
                onChange={(e) => { setStartDate(new Date(e.target.value).toISOString()) }}
              />
              <Form.Label>End Date <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='date'
                placeholder="Select End Date"
                onChange={(e) => { setEndDate(new Date(e.target.value).toISOString()) }}
              />
              <Form.Label>Leave Type <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select

                    options={leaveoptions}
                    value={selectedLeave}
                    onChange={handleSelectedLeaveType}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Leave Reason <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 80,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                as="textarea"
                placeholder="Enter Leave Reason"
                onChange={(e) => { setLeaveReason(e.target.value) }}
              />
            </Form.Group>

          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAdd(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={addhandlesubmit}
          >
            Apply Leave
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Leave Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group className="mb-3 d-flex gap-5 selectdoctor">
              <Form.Label style={{ margin: 6 }} >Id <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Label style={{ margin: 6 }}>
                <strong>{leaveId}</strong>
              </Form.Label>
            </Form.Group> */}
            <Form.Group className="mb-3">
              <Form.Label >Start Date <span style={{color:"red"}}>*</span></Form.Label>
              <div >
                <DatePicker style={{ margin: 5 }}
                  onChange={setStartDate}
                  value={startDate}
                  format="dd-MM-yy"
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>End Date <span style={{color:"red"}}>*</span></Form.Label>
              <div>
                <DatePicker style={{ margin: 5 }}
                  onChange={setEndDate}
                  value={endDate}
                  format="dd-MM-yy"
                />
              </div>
            </Form.Group>
            <Form.Label>Leave Type <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select

                    options={leaveoptions}
                    value={selectedLeave}
                    onChange={handleSelectedLeaveType}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Leave Reason <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 80,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                onChange={(e) => setLeaveReason(e.target.value)}
                placeholder="Enter Address"
                required
                value={leaveReason}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>status <span style={{color:"red"}}>*</span></Form.Label>
              <div className="selectdoctor">
                <Select

                  options={statusOption}
                  value={editStatus}
                  onChange={(selOp) => setEditStatus(selOp)}
                  isDisabled={Permissions.LEAVE_REQUEST_UPDATE_STATUS}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update Leave Request
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default LeaveRequest;
