import { Button, Container, Form, Table } from "react-bootstrap";
import { IoHomeOutline } from "react-icons/io5";
import Select from "react-select";
import { useEffect, useState } from "react";
import Helper from "../../utils/helperFunctions";
import { GetAttendanceSheet } from "../../utils/proxy/AttendanceProxy";
import Swal from "sweetalert2";
import React from 'react';
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy";
import { RiFileExcel2Fill } from "react-icons/ri";

const AttendanceSheet = () => {

    const months = [
        { label: "January", value: "01" },
        { label: "February", value: "02" },
        { label: "March", value: "03" },
        { label: "April", value: "04" },
        { label: "May", value: "05" },
        { label: "June", value: "06" },
        { label: "July", value: "07" },
        { label: "August", value: "08" },
        { label: "September", value: "09" },
        { label: "October", value: "10" },
        { label: "November", value: "11" },
        { label: "December", value: "12" },
    ];

    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = currentYear; i >= currentYear - 100; i--) {
        years.push({ value: i.toString(), label: i.toString() });
    }

    const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
    const [selectedYear, setSelectedYear] = useState(years[0]);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState();

    const [users, setusers] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedUserID, setSelectedUserID] = useState('');

    useEffect(() => {
        //fetchin all users
        const getAllUsersData = async () => {
            if (localStorage.getItem('role') === 'Admin') {
                let allusers = await AllUsers();
                allusers.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
                setusers(allusers);
            } else if (localStorage.getItem('role') === 'Manager') {
                let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
                setusers(allusers);
            }
        };
        getAllUsersData();
    }, [])

    useEffect(() => {
        const getAttendanceSheet = async () => {
            let dt = new Date(selectedYear.value + '-' + selectedMonth.value + '-01');
            dt.setMonth(dt.getMonth() + 1);

            let d = await GetAttendanceSheet(selectedYear.value + '-' + selectedMonth.value + '-01', dt.getFullYear() + '-' + (Helper.addLeadingZero(dt.getMonth() + 1)) + '-01', selectedUserID);
            if (d) {
                setData(d);

            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Something Went Wrong',
                    icon: 'error'
                })
            }
        }

        getAttendanceSheet();
    }, [selectedYear, selectedMonth, selectedUserID])

    const handleSelectChangeMOnth = (selected) => {
        setSelectedMonth(selected);
    };

    const handleSelectChangeYear = (selected) => {
        setSelectedYear(selected);
    };

    const handleChange = (selected) => {
        if (selected == null) {
            setSelectedOption(null)
            setSelectedUserID('');
        }
        else {
            setSelectedOption(selected);
            setSelectedUserID(selected.value);
        }

    };

    const options = []

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });


    async function downloadCSV2() {
        // Collect all unique dates from the attendance data
        const datesSet = new Set();
        data.forEach(user => {
            Object.keys(user.attendance).forEach(date => datesSet.add(date));
        });
        const dates = Array.from(datesSet).sort(); // Convert to array and sort dates

        // Create CSV content
        const fields = ["username", ...dates];
        let csv = fields.join(",") + "\n";

        // Iterate over each user to build CSV rows
        data.forEach(user => {
            const { username, attendance } = user;
            const row = [username, ...dates.map(date => attendance[date].split('-')[0] == 'L' ? attendance[date].split('-')[0] : attendance[date])];
            csv += row.join(",") + "\n";
        });

        // Create a download link and trigger the download
        const link = document.createElement("a");
        const filename = `${selectedMonth.label}_${selectedYear.value}_attendance.csv`;

        // Create CSV data URI
        const csvData = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        link.setAttribute("href", csvData);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
    }

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}

                        /></a></li>
                        <li><a href="/attendance-sheet"><span class="icon icon-beaker"> </span>Attendance Sheet</a></li>

                    </ul>

                </div>

            </div>

            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                    }}
                >
                    <div style={{ width: "350px", zIndex: 3 }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Users</Form.Label>

                            <div className="w-50">
                                <Select
                                    className="selectdoctor"
                                    options={options}
                                    value={selectedOption}
                                    onChange={handleChange}
                                    isClearable

                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ width: "350px", zIndex: 3 }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Select Month</Form.Label>
                            <div className="w-50">
                                <Select
                                    className="selectdoctor"
                                    options={months}
                                    value={selectedMonth}
                                    onChange={handleSelectChangeMOnth}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ width: "450px", zIndex: 3 }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Select Year</Form.Label>
                            <div className="w-50">
                                <Select
                                    className="selectdoctor"
                                    options={years}
                                    value={selectedYear}
                                    onChange={handleSelectChangeYear}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div>
                        <Button style={{ backgroundColor: "#28B3D2" }} onClick={() => downloadCSV2()} >
                            <RiFileExcel2Fill />
                        </Button>
                    </div>
                </div>
                <div className="responsive-table-container">
                    <table bordered className="responsive-table">
                        <thead>
                            <tr>
                                <th style={{ backgroundColor: '#1ABC9C',color:'white', zIndex: 2 }}>Username</th>
                                {data && data.length > 0 && (
                                    <React.Fragment>
                                        {Object.keys(data[0].attendance).sort().map((key, index) => (
                                            <th style={{ backgroundColor: '#1ABC9C',color:'white' }} key={index}>{key.split('-')[2]}</th>
                                        ))}
                                    </React.Fragment>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((d, i) => (
                                <tr onClick={() => {if(selectedRow === i){setSelectedRow()}else{setSelectedRow(i)}}} style={{ border: selectedRow === i ? '2px solid' : '' }}>
                                    <td>{d.username}</td>
                                    {Object.keys(d.attendance)
                                        .sort()
                                        .map(key => (
                                            <td title={d.attendance[key] == 'A' ? 'Absent' : d.attendance[key] == 'P' ? 'Present' : d.attendance[key].split('-')[0] == 'L' ? d.attendance[key].split('-')[1] : d.attendance[key]} style={{ background: d.attendance[key] == 'A' ? '#FF4C4C' : d.attendance[key] == 'P' ? '#28A745' : d.attendance[key].split('-')[0] == 'L' ? '#FFC107' : '#5BC0DE' }} key={key}>{(d.attendance[key] == 'A' || d.attendance[key] == 'P' || d.attendance[key] == 'L' || d.attendance[key] == 'NA') ? d.attendance[key] : d.attendance[key].split('-')[0] == 'L' ? 'L' : 'H'}</td>
                                        ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Container>
    )
}

export default AttendanceSheet;