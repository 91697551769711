import Restapi from "../api/Restapi";
import { constats } from "../constant";

export const GetAllNotes = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/notes',null);
}

export const CreateNote = async (title,description) => {
    const data = {
        title:title,
        description:description,
        status:"new",
        userid:localStorage.getItem('uuid')
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/notes',data);
}

export const UpdateNote = async (id,title,description,status) => {
    const data = {
        title:title,
        description:description,
        status:status
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/notes/'+id,data);
}

export const DeleteNote = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/notes/'+id,null);
}

export const ChangeNoteStatus = async (id,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/notes/'+id+'/'+status,null);
}

export const GetNotesByUserid = async (userid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/notes/getbyuserid/'+userid,null);
}


export const GetNotesByUseridAndStatus = async (status,userid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/notes/getbystatus?status='+status+"&userid="+userid,null);
}