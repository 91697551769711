import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

export const GetUserDetails = async (userid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/reports/userdetails?userid='+userid,null);
}


export const GetUserRoiByTask = async (userid,month,year,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/reports/roi/task?userid='+userid+'&month='+month+'&year='+year+'&status='+status,null);
}

export const GetUserRoiByProject = async (userid,month,year) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/reports/roi/project?userid='+userid+'&month='+month+'&year='+year,null);
}


export const getAllUsersPobBelowUser = async (userid,startmonth,startyear,endmonth,endyear) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/reports/pobwisereport/${userid}/${startmonth}/${startyear}/${endmonth}/${endyear}`,null);
}

export const getLeadCountDataByMonth = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/reports/chart2/leads`,null);
}

export const getCompletedTaskDataByMonth = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/reports/chart2/task/completed`,null);
}

export const getLeadSourcData = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/reports/chart2/lead/source`,null);
}

export const getExpenseByMonth = async (date) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/reports/chart2/expense?date=${date}`,null);
}


export const getPerDayByMonth = async (year,month,userid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/dashboard/day/tasks?year=${year}&month=${month}&userid=${userid}`,null);
}