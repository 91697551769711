import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

// DCR

export const GetAllDcr = async (userId,date) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/dcr/user/${userId}/${date}`, null);
};

export const GetAllDcrReports = async (userId,start,end) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/dcr/user/report/${userId}/${start}/${end}`, null);
};
