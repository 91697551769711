import Restapi from "../api/Restapi";
import { constats } from "../constant";

export const GetAllSalutation = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/salutation',null);
}

export const CreateSalutation = async (name, description) => {
    const data = {
        name,
        description
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/salutation',data);
}

export const EditSalutation = async (id,name,description) => {
    const data = {
        name,
        description
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/salutation/'+id,data);
}

export const DeleteSalutation = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/salutation/'+id,null);
}

export const GetAllLeadStatus = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/leadStatus',null);
}

export const DeleteLeadStatus = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/leadStatus/'+id,null);
}

export const CreateLeadStatus = async (name, description,color,sortorder) => {
    const data = {
        name,
        description,
        color,
        sortorder
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/leadStatus',data);
}

export const EditLeadStatus = async (id,name, description,color,sortorder) => {
    const data = {
        name,
        description,
        color,
        sortorder
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/leadStatus/'+id,data);
}

export const GetAllLeadSource = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/leadSource',null);
}

export const CreateLeadSource = async (name, description) => {
    const data = {
        name,
        description
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/leadSource',data);
}

export const EditLeadSource = async (id,name,description) => {
    const data = {
        name,
        description
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/leadSource/'+id,data);
}

export const DeleteLeadSource = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/leadSource/'+id,null);
}

export const GetAllIndustry = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/industry',null);
}

export const CreateIndustry = async (name, description) => {
    const data = {
        name,
        description
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/industry',data);
}

export const EditIndustry = async (id,name,description) => {
    const data = {
        name,
        description
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/industry/'+id,data);
}

export const DeleteIndustry = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/industry/'+id,null);
}

export const GetAllLeads = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/leads',null);
}


export const GetLeadById = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/leads/'+id,null);
}

export const GetLeadByUserId = async (id,selectedleadstatusids) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/leads/user/'+id+'?status='+selectedleadstatusids,null);
}

export const GetLeadByUserIdCopy = async (userid,page,size,status,manager,date) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/leads/page/user?userid=${userid}&page=${page}&size=${size}&status=${status}&manager=${manager}&date=${date}`,null);
}

export const UpdateLead = async (id,lead) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/leads/'+id,lead);
}

export const UpdateLeadAchStatus = async (id,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/leads/leadachstatus/'+id+'/'+status,null);
}

export const CreateLead = async (lead) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/leads',lead);
}

export const DeleteLeadById = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/leads/'+id,null);
}

export const CreateLeadProduct = async (products,leadid) => {
    const data = {
        products,
        leadid
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/leadproduct',data);
}

export const getLeadProductByLeadId = async (leadid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/leadproduct/'+leadid,null);
}