import Restapi from "../api/Restapi";
import { constats } from "../constant";

export const GetAllAccounts = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/accounts`,null);
}

export const GetAccountById = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/accounts/`+id,null);
}

export const GetAllAccountsMini = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/accounts/mini`,null);
}

export const CreateAccount = async (account) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+`/apiv1/accounts`,account);
}

export const UpdateAccount = async (id,account) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+`/apiv1/accounts/${id}`,account);
}

export const DeleteAccount = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+`/apiv1/accounts/${id}`,null);
}

export const AccountAvailableByName = async (name) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/accounts/available/${name}`,null);
}

export const GetAllAccountType = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/accounttype`,null);
}

export const CreateAccountType = async (name,description) => {
    const data = {
        name,
        description
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+`/apiv1/accounttype`,data);
}

export const UpdateAccountType = async (id,name,description) => {
    const data = {
        name,
        description
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+`/apiv1/accounttype/${id}`,data);
}

export const DeleteAccountType = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+`/apiv1/accounttype/${id}`,null);
}

export const AccountTypeAvailableByName = async (name) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/accounttype/available/${name}`,null);
}

export const UploadOnAwsS3 = async (formData) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+`/apiv1/fileupload`,formData);
}

export const RemoveProfilePhoto = async (data) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+`/apiv1/fileupload/store`,data);
}