import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

// Doctro

export const GetAllLeaveRequests = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/leaverequest", null);
};

export const GetAllLeaveRequestsById = async (leaveId) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/leaverequest/${leaveId}`, null);
};

export const GetLeaveCount = async (month,year,userid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/leaverequest/leavecount?month=${month}&year=${year}&userid=${userid}`, null);
};

export const GetAllLeaveRequestsByUserAndStatus = async (userId, status) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/leaverequest/user/${userId}/${status}`, null);
};

export const GetAllLeaveRequestsByUserAndStatusCopy = async (userid,page,size,status,manager,date) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/leaverequest/page/user?userid=${userid}&page=${page}&size=${size}&status=${status}&manager=${manager}&date=${date}`, null);
};

export const GetAllLeaveRequestByMonth = async (month,year,userid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/leaverequest/user/getbymonth?month=${month}&year=${year}&userid=${userid}`, null);
};


export const CreateLeaveRequest = async (userid,startdate,enddate,leavetype,leavereason) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  const data = {
    userid,
    startdate,
    enddate,
    leavetype,
    leavereason
  }
  return await Restapi(
    "post",
    REPORTING_URL + `/apiv1/leaverequest`,
    data
  );
};
export const UpdateLeaveRequest = async (leaverequestid, data) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi(
    "put",
    REPORTING_URL + `/apiv1/leaverequest/${leaverequestid}`,
    JSON.stringify(data)
  );
};

export const DeleteLeaveRequest = async (id) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("delete", REPORTING_URL + `/apiv1/leaverequest/`+id, null);
};
