// Production Backend Service URLs

export async function constats() {
    const AUTH_URL = localStorage.getItem("AUTH_URL"); //Auth Service URL
  
    const REPORTING_URL = localStorage.getItem("REPORTING_URL"); //Reporting Service URL
  
    return [AUTH_URL,REPORTING_URL];
  }
  

export const CHECK_AUTH_SERVER = "https://getjnm2mfahc3n3llvnj7a4aha0dlevr.lambda-url.ap-south-1.on.aws";

export const GOOGLE_MAPS_API_KEY = "AIzaSyB2MD33OztJCd9eEfV-EGZwDfseVKgTIyg";

export const userRoles = ['manager', 'admin', 'user'];

export const UserMoods = [{mood:'😄',value:"Perfect"},{mood:'😀',value:"Good"},{mood:'😐',value:"Neutral"},{mood:'🙁',value:"Poor"},{mood:'😞',value:"Bad"}];

export const taskColor = {
  "newbackground":"#ADEAFE",
  "newtext":"black",
  "inprogressbackground":"#FDE767",
  "inprogresstext":"black",
  "completedbackground":"#6DEE7A",
  "completedtext":"black"
}