import React, { useEffect, useState } from 'react'
import { GetAllAreasByCity, CreateUserDoctor, GetAllCountries, GetAllStatesByCountry, GetAllCitiesByState } from '../../../utils/proxy/Countryproxy'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { AllUsers } from '../../../utils/proxy/Authproxy';
import { AddUserDoctorData, GetAllDoctorByCity } from '../../../utils/proxy/Userdoctorproxy';

function Adddoctor({ showModal, closeModal }) {

  const [areatype, setareatype] = React.useState();
  const [areaname, setareaName] = React.useState();
  const [areaCode, setareaCode] = React.useState();
  const [isFocus, setIsFocus] = useState(false);

  const [countries, setCountries] = useState([]);
  const [countryid, setCountryId] = useState();

  const [states, setStates] = useState([]);
  const [stateid, setStateId] = useState();

  const [cities, setCitys] = useState([]);
  const [cityid, setCityId] = useState();

  const [areas, setAreas] = useState([]);
  const [areaid, setAreaId] = React.useState();
  const [users, setUsers] = useState([]);
  const [user, setUserId] = useState('');

  const [doctor, setDoctor] = useState([]);
  const [doctorid, setDoctorId] = React.useState();


  useEffect(() => {
    if (stateid >  0) {
      // Fetch data from an API when the component mounts
    const getallareas = async (cityid) => {
      let areasdata = await GetAllDoctorByCity(cityid);
      if (areasdata) {
        setDoctor(areasdata);
      } else {
        alert('Error', 'Issue While Fetching Doctor');
      }
    }
    getallareas(cityid);
    }
  }, [cityid]);


  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {
        let allusers = await AllUsers();
        if (allusers) {
            setUsers(allusers);
        } else {
            alert('Error', 'Issue While Fetching Users');
        }
    }
    getallusers();

}, []);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallcountries = async () => {
      let countriesdata = await GetAllCountries();
      if (countriesdata) {
        setCountries(countriesdata);
      } else {
        alert('Error', 'Issue While Fetching Countries');
      }
    }
    getallcountries();

  }, []);

  useEffect(() => {

    if (countryid > 0) {

      // Fetch data from an API when the component mounts
      const getallstates = async (countryid) => {
        let statesdata = await GetAllStatesByCountry(countryid);
        if (statesdata) {
          setStates(statesdata);
        } else {
          alert('Error', 'Issue While Fetching States');
        }
      };
      getallstates(countryid);
    }
  }, [countryid]);

  useEffect(() => {
    if (stateid > 0) {
      // Fetch data from an API when the component mounts
      const getallcitys = async (stateid) => {
        let citysdata = await GetAllCitiesByState(stateid);
        if (citysdata) {
          setCitys(citysdata);
        } else {
          alert('Error', 'Issue While Fetching City');
        }
      }
      getallcitys(stateid);
    }
  }, [stateid]);

  useEffect(() => {
    if (stateid > 0) {
      // Fetch data from an API when the component mounts
      const getallareas = async (cityid) => {
        let areasdata = await GetAllAreasByCity(cityid);
        if (areasdata) {
          setAreas(areasdata);
        } else {
          alert('Error', 'Issue While Fetching Territories');
        }
      }
      getallareas(cityid);
    }
  }, [cityid]);


  const handleUserChange = (event) => {
    setUserId(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountryId(event.target.value);
  };

  const handleStateChange = (event) => {
    setStateId(event.target.value);
  };

  const handleCityChange = (event) => {
    setCityId(event.target.value);
  };

  const handleAreaChange = (event) => {
    setDoctorId(event.target.value);
  };

  const handleAreatypeChange = (event) => {
    setareatype(event.target.value);
  };

  const handleCodeChange = (event) => {
    setareaCode(event.target.value);
  };

  const createUseDoctor = async (user,doctorid) => {
    let countriesdata = await AddUserDoctorData(user,doctorid);
    if (countriesdata) {
      
      alert('Success', 'Doctor Added For Approval');
      closeModal();
    } else {
      alert('Error', 'Issue While Fetching Doctor');
    }
  }

  const handlesubmit = () => {
  
      createUseDoctor(user,doctorid);

 
  };



  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add User Doctor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div style={{paddingBottom:10}}>
          <label>Select User <span style={{color:"red"}}>*</span></label>
          <Form.Select  className="selectdoctor" value={user}
            onChange={handleUserChange}
            
            style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
              <option >Select</option>
            {users.map(user => (
              <option key={user.userid} value={user.userid} >{user.firstname} {user.lastname} ({user.username})</option>
            ))}
          </Form.Select>
        </div>
        <div style={{paddingBottom:10}}> 
          <label>Select Country <span style={{color:"red"}}>*</span></label>
          <Form.Select  className="selectdoctor" value={countryid}
            onChange={handleCountryChange}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
              <option >Select</option>
            {countries.map(country => (
              <option key={country.id} value={country.id} >{country.name}</option>
            ))}
          </Form.Select>
        </div>
        <div style={{paddingBottom:10}}>
          <label>Select State <span style={{color:"red"}}>*</span></label>
          <Form.Select  className="selectdoctor" value={stateid}
            onChange={handleStateChange}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
              <option >Select</option>
            {states.map(state => (
              <option key={state.id} value={state.id} >{state.name}</option>
            ))}
          </Form.Select>
        </div>

        <div style={{paddingBottom:10}}>
          <label>Select City <span style={{color:"red"}}>*</span></label>
          <Form.Select  className="selectdoctor" value={cityid}
            onChange={handleCityChange}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
              <option >Select</option>
            {cities.map(cty => (
              <option key={cty.id} value={cty.id} >{cty.city}</option>
            ))}
          </Form.Select>
        </div>

        <div style={{paddingBottom:10}}> 
          <label>Select Doctor <span style={{color:"red"}}>*</span></label>
          <Form.Select  className="selectdoctor" value={doctorid}
            onChange={handleAreaChange}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
              <option >Select</option>
            {doctor.map(cty => (
              <option key={cty.id} value={cty.id} >{cty.doctorname}</option>
            ))}
          </Form.Select>
        </div>
    
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handlesubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Adddoctor