import Restapi from "../api/Restapi";
import {AUTH_URL, CHECK_AUTH_SERVER, constats} from "../constant"


export const LoginProxy = async (usernameOrEmail,password) => {

  const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "usernameOrEmail": usernameOrEmail,
        "password": password
      });
    return await Restapi('post',AUTH_URL+'/apiv1/auth/signin',data);
}


export const AllUsers = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi('get',AUTH_URL+'/apiv1/auth/users',null);
}

export const GetAllManagerUsers = async (userid) =>{
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi('get', AUTH_URL+'/apiv1/auth/managerusers/'+userid,null);
}

export const getAuthServer = async (id) => {
  const [AUTH_URL, REPORTING_URL] = await constats();

  return await Restapi('get',CHECK_AUTH_SERVER+'/id/'+id,null);
}

export const ResetPassword = async (newPassword) => {
  const data = {
    password:newPassword
  }
  const [AUTH_URL, REPORTING_URL] = await constats();

  return await Restapi('put',AUTH_URL+'/apiv1/auth/passwordreset',data);
}

export const PasswordChangeByAdmin = async (userid,newPassword) => {
  const data = {
    password:newPassword
  }
  const [AUTH_URL, REPORTING_URL] = await constats();

  return await Restapi('put',AUTH_URL+'/apiv1/auth/admin/passwordchange/'+userid,data);
}

export const ForgotPassword = async (url,username) => {
  const [AUTH_URL, REPORTING_URL] = await constats();

  return await Restapi('post',url+'/apiv1/auth/forgotpassword/'+username,null);
}

export const GetUserDetailsByUserid = async (userid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();

  return await Restapi(
    "get",
    AUTH_URL + "/apiv1/auth/details/"+userid,
    null
  );
};