import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"


// Department
export const GetAllUsersDesignation = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/userdesignation',null);
}

export const CheckUserDesignationAvailable = async (designation) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/userdesignation/available/'+designation,null);
}

export const CreateUserDesignation = async (designation,description)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "designation": designation,
        "description": description
      });
    return await Restapi('post',REPORTING_URL+'/apiv1/userdesignation',data);
}

export const UpdateUserDesignation = async (id,designation,description)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "designation": designation,
        "description": description
      });
    return await Restapi('put',REPORTING_URL+'/apiv1/userdesignation/'+id,data);
}

export const DeleteUserDesignation = async (id)  => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/userdesignation/'+id,null);
}