import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { HiDotsVertical } from "react-icons/hi";
import {DeleteRole} from "../../utils/proxy/UserRoleProxy";
import { Parser } from "@json2csv/plainjs";
import { CreateUserType, DeleteUserType, GetAllUserType, UpdateUserType } from "../../utils/proxy/UsertypeProxy";
import { AllUsers } from "../../utils/proxy/Authproxy";
import { IoHomeOutline } from "react-icons/io5";
import { Op_per } from "../../utils/Permissions";

function UserRole() {
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [userid, setUserId] = useState();
  const [selectedUsers, setSelectedUsers] = useState();
  const [reload, setReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [show, setShow] = useState(false);
  const [role, setRole] = useState("");
  const [editOperation, setEditOperation] = useState(true);
  const [selectedType, setSelectedType] = useState();
  const [userType, setUserType] = useState();

  const [showedit, setShowEdit] = useState(false);

  const typeOptions = [];
  typeOptions.push({value:1,label:'Internal'});
  typeOptions.push({value:2,label:'External'});


  useEffect(() => {
    // Fetch data from an API when the component mounts
    if (show) {
      const getallteritories = async () => {
        let users = await AllUsers();
        if (users) {
          setUser(users);
        } else {
          alert("Error", "Issue While Fetching Users");
        }
      };
      getallteritories();
    }
  }, [show]);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getAllRoles = async () => {
      let response = await GetAllUserType();
      if (response) {
        setData(response);
      } else {
        alert("Error", "Issue While Fetching Roles");
      }
    };
    getAllRoles();
    setReload(false);
  }, [reload]);

  const handleSelectChange = (selected) => {
    setSelectedUsers(selected);
    setUserId(selected.value);
  };

  const handleTypeChange = (selected) =>{
    setUserType(selected.label)
    setSelectedType(selected)
  }

  const handleClose = () => {
    setShow(false);
    setRole("");
    setUserId("");
    setEditOperation(false);
  };

  const handleEditClose = () =>{
    setShowEdit(false);
  }

  const options = [];

  user.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.userid,
    //   sortable: true,
    // },
    {
      name: "Username",
      selector: (row) =>
      <div style={{ whiteSpace: 'normal',backgroundColor: row.username === "" || row.username === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.username === "" || row.username === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.username === "" || row.username === null ? "#fff": "#2457C5", margin:"3px", }}>{row.username ? row.username : "N/A"}</div>,
      width:"15%",
    },
    {
      name: "Firstname",
      selector: (row) =>
      <div style={{ whiteSpace: 'normal',backgroundColor: row.firstname === "" || row.firstname === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.firstname === "" || row.firstname === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.firstname === "" || row.firstname === null ? "#fff": "#2457C5", margin:"3px", }}>{row.firstname ? row.firstname : "N/A"}</div>,
      width:"15%",
      
    },
    {
      name: "Lastname",
      selector: (row) =><div style={{ whiteSpace: 'normal',backgroundColor: row.lastname === "" || row.lastname === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.lastname === "" || row.lastname === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.lastname === "" || row.lastname === null ? "#fff": "#2457C5", margin:"3px", }}>{row.lastname ? row.lastname : "N/A"}</div> ,
      width:"15%",
    },
    {
      name: "Type",
      selector: (row) =><div style={{ whiteSpace: 'normal',backgroundColor: row.type === "" || row.type === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.type === "" || row.type === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.type === "" || row.type === null ? "#fff": "#2457C5", margin:"3px", }}>{row.type ? row.type : "N/A"}</div> ,
      width:"15%",
    },
    // {
    //   name: "Actions",
    //   selector: (row) => (
    //     <div key={row.id}>
    //       <AiTwotoneEdit
    //         style={{ color: "black", fontSize: 20 }}
    //         onClick={() => handleEditShow(row)}
    //       />
    //       &nbsp;&nbsp;&nbsp;
    //       <AiFillDelete
    //         onClick={() => {handleDelete(row.userid)}}
    //         style={{ color: "red", fontSize: 20 }}
    //       />
    //     </div>
    //   ),
    // },
  ];

  if(Op_per.get().USERS_USER_TYPES.ALLOW_DELETE || Op_per.get().USERS_USER_TYPES.ALLOW_EDIT){
    columns.push({
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>
          <AiTwotoneEdit
            style={{ color: "black", fontSize: 20, display:Op_per.get().USERS_USER_TYPES.ALLOW_EDIT ? '' : 'none' }}
            onClick={() => handleEditShow(row)}
          />
          &nbsp;&nbsp;&nbsp;
          <AiFillDelete
            onClick={() => {handleDelete(row.userid)}}
            style={{ color: "red", fontSize: 20, display:Op_per.get().USERS_USER_TYPES.ALLOW_DELETE ? '' : 'none' }}
          />
        </div>
      ),
    })
  }

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  let deleteRole = async (roleId) => {
    let statedata = await DeleteRole(roleId);
    if (statedata) {
      setReload(true);

      Swal.fire({
        title: "Success!",
        text: "Role Deleted !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Updating Role");
    }
  };

  let handleDelete = async(id) => {
    let d = await DeleteUserType(id);
    if(d.status == 'success'){
        setReload(true);
        Swal.fire({
            title: "Success!",
            text: "User Type Deleted !!",
            icon: "success",
            confirmButtonText: "OK",
          });
    }else{
        alert("something went wrong");
    }
  };

  const handleEditShow = (row) => {
    setShowEdit(true);
    setUserId(row.userid);
    setSelectedType({value:row.userid,label:row.type})
    setSelectedUsers({value:row.userid,label:row.username})
  };

  let prepareData = () => {
    return {
      userid,
      role: role.value,
    };
  };

  const handleUpdate = async() =>{
    let d = await UpdateUserType(userid,userType);
    if(d.status == 'success'){
        setReload(true);
        setShowEdit(false);
        setUserId(null);
        setUserType(null);
        Swal.fire({
            title: "Success!",
            text: "Type Changed Successfully !!",
            icon: "success",
            confirmButtonText: "OK",
          });
    }else{
        alert("something went wrong");
    }
  }

  const handleSave = async () => {
    if(userid == null || userid == ''){
        Swal.fire({
            title: "Warning!",
            text: "Select User !!",
            icon: "warning",
            confirmButtonText: "OK",
        });
    }else if(userType == null || userType == ''){
        Swal.fire({
            title: "Warning!",
            text: "Select Type !!",
            icon: "warning",
            confirmButtonText: "OK",
        });
    }
    else{
        let d = await CreateUserType(userid,userType);
        if(d.status == 'success'){
            setReload(true);
            setShow(false);
            Swal.fire({
                title: "Success!",
                text: "Type Assigned Successfully !!",
                icon: "success",
                confirmButtonText: "OK",
              });
        }else{
            alert("Something went wrong");
        }
    }
  };

  async function downloadCSV(data) {
    let csv;
    try {
      const opts = {
        fields: [
          "userid",
          "username",
          "firstname",
          "lastname",
          "role",
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(data);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(data)} />,
    [data]
  );

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding:0,
          backgroundColor:'#3887BE'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/user-type"><span class="icon icon-beaker"> </span>Users</a></li>
  <li><a href="/user-type"><span class="icon icon-beaker"> </span> User Types</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Form.Control
             style={{height: 46,
            width:250,
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
              className="glass"
              placeholder="Search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div>
          <div class="dropdown">
                <button
                  class="btn btn-secondary"
                  type="button"
                  style={{ backgroundColor: "#28B3D2",display:Op_per.get().USERS_USER_TYPES.ALLOW_ADD ? '' : 'none' }}
                  title="Options"
                  id="dropdown-basic"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <HiDotsVertical />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={setShow} style={{display:Op_per.get().USERS_USER_TYPES.ALLOW_ADD ? '' : 'none'}}>
                    <a class="dropdown-item">Add</a>
                  </li>
                 
                </ul>
              </div>

            {/* <Export onExport={() => downloadCSV(user)} /> */}
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            columns={columns}
            data={data}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
            style={{ marginTop: 10 }}
            // actions={actionsMemo}
            dense
          />
        </div>
      </div>
      <Modal show={show} backdrop="static" size="md" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add User Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>User <span style={{color:"red"}} >*</span></Form.Label>
              <Select
               className="selectdoctor"
                options={options}
                value={selectedUsers}
                onChange={handleSelectChange}
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Type <span style={{color:"red"}} >*</span></Form.Label>
              <Select
               className="selectdoctor"
                options={typeOptions}
                value={selectedType}
                onChange={handleTypeChange}
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            // disabled={isclinicname ? true : false}
          >
            Assign Type
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showedit} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>User <span style={{color:"red"}} >*</span></Form.Label>
              <Select
               className="selectdoctor"
                options={options}
                value={selectedUsers}
                onChange={handleSelectChange}
                isDisabled={true}
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Type <span style={{color:"red"}} >*</span></Form.Label>
              <Select
               className="selectdoctor"
                options={typeOptions}
                value={selectedType}
                onChange={handleTypeChange}
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default UserRole;
