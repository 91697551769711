import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import BifurcationChart from './Includes/BifurcationChart';
import { GetAllBifurcationCounts } from '../utils/proxy/ChartsProxy';
import CardDash from './Common/CardDash';
import { EndDay, StartDay } from '../utils/proxy/Tourplanproxy';
import Swal from 'sweetalert2';
import WorkingUsers from './Includes/WorkingUsers';
import ActiveInactiveUsers from './Includes/ActiveInactiveUsers';
import PerfomanceChart from './Includes/PerfomanceChart';
import SubmissionTrendChart from './Includes/SubmissionTrendChart';
import InternalExternalChart from './Includes/InternalExternalChart';
import AdminDashBoard from './AdminDashboard';
import ManagerDashboard from './ManagerDashboard';
import UserDashboard from './UserDashboard';
import { UserDashboard2 } from './UserDashboard2';

function Dashboard() {

  const [chartData, setChartData] = useState([]);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [weatherlocation, setWeatherLocation] = useState();
  const [weatherlocationregion, setWeatherLocationRegion] = useState();
  const [currentweather, setCurrentWeather] = useState();
  const [currentweathercondition, setCurrentWeatherCondition] = useState();
  const [currentweathericon, setCurrentWeatherIcon] = useState();
  const [currentweatherwind, setCurrentWeatherWind] = useState();
  const [currentweatherhumidity, setCurrentWeatherHumidity] = useState();
  const [currentweatheruv, setCurrentWeatherUv] = useState();
  const [date, setDate] = useState(new Date());
  const [startDay, setStartDay] = useState();
  const [remark, setRemark] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    handleSearch();
  }, []);

  // useEffect(() => {
  //   if (latitude === undefined || longitude === undefined) {
  //     return;
  //   } else {
  //     getWeather();
  //   }

  // }, [latitude, longitude]);

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(function (position) {
  //     setLatitude(position.coords.latitude);
  //     setLongitude(position.coords.longitude);
  //   })
  // }, []);


  // async function getWeather() {
  //   var requestOptions = {
  //     method: 'GET',
  //     redirect: 'follow'
  //   };

  //   fetch("http://api.weatherapi.com/v1/current.json?key=1ec6fdeb9b684b3fb2d54931230911&q=" + latitude + "," + longitude, requestOptions)
  //     .then(response => response.json())
  //     .then(result => setWeatherData(result))
  //     .catch(error => console.log('error', error));
  // }

  // function setWeatherData(result) {
  //   setWeatherLocation(result.location.name);
  //   setWeatherLocationRegion(result.location.region);
  //   setCurrentWeather(result.current.temp_c);
  //   setCurrentWeatherCondition(result.current.condition.text);
  //   setCurrentWeatherIcon(result.current.condition.icon);
  //   setCurrentWeatherWind(result.current.wind_kph);
  //   setCurrentWeatherHumidity(result.current.humidity);
  //   setCurrentWeatherUv(result.current.uv);
  // }


  const handleSearch = async () => {
    let response = await GetAllBifurcationCounts();
    if (response) {
      setChartData(response);
    }
  };

  const handleStartDay = async () => {
    let res = await StartDay(localStorage.getItem('uuid'));
    if (res) {
      try {
        localStorage.setItem('startdayid', res.id.toString());
        localStorage.setItem('startday', res.startday);
        setStartDay(res.startday);
        Swal.fire({
          title: "Success!",
          text: "Day Started !!",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {

      }
    }
  }

  const handleEndDay = () => {
    if (!localStorage.getItem('startday')) {
      Swal.fire({
        title: "Warning!",
        text: "First Start the Day !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else {
      setShow(true);
    }
  }

  const submitEndDay = async () =>{
    let res = await EndDay(parseInt(localStorage.getItem('startdayid')),remark);
    if(res){
      setShow(false);
      localStorage.removeItem('startday');
      localStorage.removeItem('startdayid');
      setStartDay(null);
      Swal.fire({
        title: "Success!",
        text: "Day Ended !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    }
  }


  return (
    <>
      {localStorage.getItem('role') == 'Admin' ? <AdminDashBoard /> : localStorage.getItem('role') == 'Manager' ? <UserDashboard2 /> : <UserDashboard />} 
    </>
  );
}

export default Dashboard