import { Button, Container, Form } from "react-bootstrap"
import { IoHomeOutline } from "react-icons/io5"
import { Op_per } from "../../utils/Permissions"
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy";
import DataTable from "react-data-table-component";
import LoadingComponent from "../Includes/LoadingComponent";
import { GetPendingForAdditionDCR } from "../../utils/proxy/ChartsProxy";
import { Parser } from "@json2csv/plainjs";
import { RiFileExcel2Fill } from "react-icons/ri";

export const DcrPendingForAddition = () =>{

    const months = [
        { label: "January", value: "01" },
        { label: "February", value: "02" },
        { label: "March", value: "03" },
        { label: "April", value: "04" },
        { label: "May", value: "05" },
        { label: "June", value: "06" },
        { label: "July", value: "07" },
        { label: "August", value: "08" },
        { label: "September", value: "09" },
        { label: "October", value: "10" },
        { label: "November", value: "11" },
        { label: "December", value: "12" },
    ];

    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = currentYear; i >= currentYear - 100; i--) {
        years.push({ value: i.toString(), label: i.toString() });
    }

    const [data, setData] = useState([]);
    const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);
    const [selectedUser, setSelectedUser] = useState();
    const [users, setUsers] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]); // Set the default month to '01'
    const [selectedYear, setSelectedYear] = useState(years[0]);
    const [reload, setReload] = useState(false);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallusers = async () => {
            if (localStorage.getItem('role') === 'Admin') {
                let allusers = await AllUsers();
                allusers.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
                setUsers(allusers);
            } else if (localStorage.getItem('role') === 'Manager') {
                let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
                setUsers(allusers);
            }
        };
        getallusers();
    }, []);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallteritories = async (userid, selectedMonth, selectedYear) => {
            let userteritories = await GetPendingForAdditionDCR(
                userid,
                selectedMonth,
                selectedYear
            );

            if (userteritories) {
                setData(userteritories);
                setPending(false);
            } else {
                alert("Error", "Issue While Fetching Tour Plan");
            }
        };
        if (userid && selectedMonth.value && selectedYear.value) {
            setPending(true);
            getallteritories(userid, selectedMonth.value, selectedYear.value);
        }
        setReload(false);
    }, [reload, userid, selectedMonth, selectedYear]);

    const handleSelectChange = (selected) => {

        setUserId(selected.value);
    };

    const handleSelectChangeMOnth = (selected) => {
        setSelectedMonth(selected);
    };

    const handleSelectChangeYear = (selected) => {

        setSelectedYear(selected);
    };

    const options = [];

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });

    const columns = [
        {
            name: "Username",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.username === "" || row.username === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.username === "" || row.username === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.username === "" || row.username === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.username ? row.username : "N/A"}</div>,
            width: "12%",
            
        },
        {
            name: "Headquator",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.headquator === "" || row.headquator === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.headquator === "" || row.headquator === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.headquator === "" || row.headquator === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.headquator ? row.headquator : "N/A"}</div>,
            width: "12%",
            
        },
        {
            name: "Designation",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.designation == "" ? "N/A" : row.designation + " " + row.designation === "" || row.designation == "" ? "N/A" : row.designation + " " + row.designation === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.designation == "" ? "N/A" : row.designation + " " + row.designation === "" || row.designation == "" ? "N/A" : row.designation + " " + row.designation === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.designation == "" ? "N/A" : row.designation + " " + row.designation === "" || row.designation == "" ? "N/A" : row.designation + " " + row.designation === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.designation == null ? "N/A" : row.designation}</div>,
            width: "12%",
        },
        {
            name: "Reporting To",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.reportingto == "" ? "N/A" : row.reportingto + " " + row.reportingto === "" || row.reportingto == "" ? "N/A" : row.reportingto + " " + row.reportingto === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.reportingto == "" ? "N/A" : row.reportingto + " " + row.reportingto === "" || row.reportingto == "" ? "N/A" : row.reportingto + " " + row.reportingto === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.reportingto == "" ? "N/A" : row.reportingto + " " + row.reportingto === "" || row.reportingto == "" ? "N/A" : row.reportingto + " " + row.reportingto === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.reportingto == null ? "N/A" : row.reportingto}</div>,
            width: "12%",
        },
        {
            name: <p>Doctors DCR</p>,
            selector: (row) => <span className="cursor">{row.doctordp_count}</span>,
            // <div style={{ whiteSpace: 'normal',backgroundColor: row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.unplanned_doctor? row.unplanned_doctor: "N/A"}</div> ,
            width: "5%",
        },
        {
            name: <p>Clinics DCR</p>,
            selector: (row) => <span className="cursor">{row.clinicdp_count}</span>,

            // <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_clinic=== "" || row.planned_clinic=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.planned_clinic=== "" || row.planned_clinic=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_clinic=== "" || row.planned_clinic=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.planned_clinic? row.planned_clinic: "N/A"}</div>,
            width: "5%",
        },
        {
            name: <p>Chemist DCR</p>,
            selector: (row) => <span className="cursor">{row.chemistdp_count}</span>,
            // <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_chemist=== "" || row.planned_chemist=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.planned_chemist=== "" || row.planned_chemist=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_chemist=== "" || row.planned_chemist=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.planned_chemist? row.planned_chemist: "N/A"}</div> ,
            width: "5%",
        },
        {
            name: <p>Stockist DCR</p>,
            selector: (row) => <span className="cursor">{row.stockistdp_count}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_stockist=== "" || row.planned_stockist=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.planned_stockist=== "" || row.planned_stockist=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_stockist=== "" || row.planned_stockist=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.planned_stockist? row.planned_stockist: "N/A"}</div>  ,
            width: "5%",
        },
        {
            name: <p>Hospital DCR</p>,
            selector: (row) => <span className="cursor">{row.hospitaldp_count}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_hospital=== "" || row.planned_hospital=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.planned_hospital=== "" || row.planned_hospital=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_hospital=== "" || row.planned_hospital=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.planned_hospital? row.planned_hospital: "N/A"}</div> ,
            width: "5%",
        },
    ];

    async function downloadCSV(plans) {
        let csv;
        try {
            const opts = {
                fields: [
                    { label: "User Name", value: "username" },
                    { label: "Headquator", value: "headquator" },
                    { label: "Designation", value: "designation" },
                    { label: "Reporting To", value: "reportingto" },
                    { label: "Doctors Pending For Addition", value: "doctordp_count" },
                    { label: "Clinics Pending For Addition", value: "clinicdp_count" },
                    { label: "Chemists Pending For Addition", value: "chemistdp_count" },
                    { label: "Stockists Pending For Addition", value: "stockistdp_count" },
                    { label: "Hospitals Pending For Addition", value: "hospitaldp_count" },
                ],
            };
            const parser = new Parser(opts);
            csv = parser.parse(plans);
        } catch (err) {
            console.error(err);
        }

        const link = document.createElement("a");

        if (csv == null) return;

        const month = months.find(month => month.value === selectedMonth.value);

        const filename = `DcrPendingForAddition-${month.label}/${selectedYear.value}.csv`;

        csv = `DCR Pending For Addition Report for ${month.label} ${selectedYear.value} \n\n\n${csv}`;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    }

    const Export = ({ onExport }) => (
        <Button style={{ position: 'absolute', right: 40, backgroundColor: "#28B3D2 ",display:Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.ALLOW_EXPORT_XLSX ? '' : 'none' }} onClick={(e) => onExport(e.target.value)} disabled={!(userid && selectedMonth.value && selectedYear.value)}><RiFileExcel2Fill /></Button>
    );

    const actionsMemo = React.useMemo(
        () => <Export onExport={() => downloadCSV(data)} />,
        [data]
    );

    return(
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                        /></a></li>
                        <li><a href="/dcr-pendingforadd"><span class="icon icon-beaker"> </span>Reports</a></li>

                        <li><a href="#"><span class="icon icon-double-angle-right"></span>DCR Pending For Addition</a></li>

                    </ul>

                </div>

            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                    }}
                >
                    <div style={{ width: "320px", display: Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.USER_DROPDOWN ? '' : 'none' }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Users</Form.Label>
                            <div className="w-75">
                                <Select
                                    className="selectdoctor"
                                    options={options}
                                    value={selectedUser}
                                    onChange={handleSelectChange}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ width: "350px" }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Select Month</Form.Label>
                            <div className="w-50">
                                <Select
                                    className="selectdoctor"
                                    options={months}
                                    value={selectedMonth}
                                    onChange={handleSelectChangeMOnth}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ width: "450px" }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Select Year</Form.Label>
                            <div className="w-50">
                                <Select
                                    className="selectdoctor"
                                    options={years}
                                    value={selectedYear}
                                    onChange={handleSelectChangeYear}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="pt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        actions={actionsMemo}
                        pagination
                        customStyles={{ headRow: { style: { backgroundColor: '#E5E1DA', padding: 7 }, } }}
                        style={{ margin: '10px', padding: 10 }}
                        progressPending={pending}
                        progressComponent={<LoadingComponent />}
                        dense
                    />
                </div>
            </div>
        </Container>
    )
}