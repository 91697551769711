import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"


export const GetAllDoctorByCity = async (cityid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/doctor/city/'+cityid,null);
}

export const GetAllDoctorByCitiesMini = async (cities) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify(cities);
    return await Restapi('post',REPORTING_URL+'/apiv1/doctor/city/mini',data);
}

export const UpdateUserdoctorStatus = async (id,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/userdoctor/update/'+id+"/"+status,null);
}

export const DeleteUserdoctorStatus = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/userdoctor/'+id,null);
}

export const AddUserDoctorData = async (userid,doctorid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    let data = JSON.stringify({
        "userid":userid,
        "doctorid":doctorid,
        "status":"PENDING_FOR_ADDITION"
    });
    return await Restapi('post',REPORTING_URL+'/apiv1/userdoctor',data);
}


export const CreateUserDoctorData = async (userid,areaid,doctorcode,doctorname,doctoraadhar,address,cityid,pincode,phone,email,cipid,clinicid,doctordegreeid,doctorspecializationid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    var areas = [areaid];
    let data = JSON.stringify({
        "userid":userid,
        "areas":areas,
        "doctorcode":doctorcode,
        "doctorname":doctorname,
        "doctoraadhar":doctoraadhar,
        "address":address,
        "city":cityid,
        "pincode":pincode,
        "phone":phone,
        "email":email,
        "cips":[cipid],
        "clinics":[clinicid],
        "focusedproducts":[],
        "degrees":[doctordegreeid],
        "specializations":[doctorspecializationid]
    });
    return await Restapi('post',REPORTING_URL+'/apiv1/userdoctor/create',data);
}

export const GetAllDoctorbyUserStatus = async (user,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/userdoctor/user/'+user+'/'+status,null);
}