import { Button, Container, Form } from "react-bootstrap"
import { IoHomeOutline } from "react-icons/io5"
import { Op_per } from "../../utils/Permissions"
import { GetAllStatesDetail } from "../../utils/proxy/Countryproxy";
import React,{ useEffect, useState } from "react";
import Select from "react-select";
import { GetTerritoryStatus } from "../../utils/proxy/ChartsProxy";
import DataTable from "react-data-table-component";
import LoadingComponent from "../Includes/LoadingComponent";
import { Parser } from "@json2csv/plainjs";
import { RiFileExcel2Fill } from "react-icons/ri";

export const TerritoryStatusReport = () =>{

    const [data, setData] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState();
    const [stateid, setStateid] = useState();
    const [reload, setReload] = useState(false);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallstates = async () => {
                let allusers = await GetAllStatesDetail();
                if(allusers){
                    setStates(allusers);
                }
        };
        getallstates();
    }, []);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallteritories = async (state) => {
            let userteritories = await GetTerritoryStatus(state);

            if (userteritories) {
                setData(userteritories);
                setPending(false);
            } else {
                alert("Error", "Issue While Fetching Tour Plan");
            }
        };
        if (stateid) {
            setPending(true);
            getallteritories(stateid);
        }
        setReload(false);
    }, [reload, stateid]);

    const handleSelectChange = (selected) => {
        setSelectedState(selected);
        setStateid(selected.value);
    };

    const options = [];

    states.map((user) => {
        options.push({
            value: user.id,
            label: user.statename,
        });
    });

    const columns = [
        {
            name: "State",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.state === "" || row.state === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.state === "" || row.state === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.state === "" || row.state === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.state ? row.state : "N/A"}</div>,
            width: "12%",
            
        },
        {
            name: "City",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.city === "" || row.city === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.city === "" || row.city === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.city === "" || row.city === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.city ? row.city : "N/A"}</div>,
            width: "12%",
            
        },
        {
            name: "Territory",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.area == "" ? "N/A" : row.area + " " + row.area === "" || row.area == "" ? "N/A" : row.area + " " + row.area === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.area == "" ? "N/A" : row.area + " " + row.area === "" || row.area == "" ? "N/A" : row.area + " " + row.area === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.area == "" ? "N/A" : row.area + " " + row.area === "" || row.area == "" ? "N/A" : row.area + " " + row.area === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.area == null ? "N/A" : row.area}</div>,
            width: "12%",
        },
        {
            name: "Status",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.status === "" || row.status === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.status === "" || row.status === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.status === "" || row.status === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.status ? row.status : "N/A"}</div>,
            width: "12%",
            
        },
    ]

    async function downloadCSV(plans) {
        let csv;
        try {
            const opts = {
                fields: [
                    { label: "State", value: "state" },
                    { label: "City", value: "city" },
                    { label: "Territory", value: "area" },
                    { label: "Status", value: "status" },
                ],
            };
            const parser = new Parser(opts);
            csv = parser.parse(plans);
        } catch (err) {
            console.error(err);
        }

        const link = document.createElement("a");

        if (csv == null) return;

        const filename = `TerritoryStatus-(${selectedState.label}).csv`;

        csv = `Territory Status Of ${selectedState.label} \n\n\n${csv}`;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    }

    const Export = ({ onExport }) => (
        <Button style={{ position: 'absolute', right: 40, backgroundColor: "#28B3D2 ",display:Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.ALLOW_EXPORT_XLSX ? '' : 'none' }} onClick={(e) => onExport(e.target.value)} disabled={!(stateid)}><RiFileExcel2Fill /></Button>
    );

    const actionsMemo = React.useMemo(
        () => <Export onExport={() => downloadCSV(data)} />,
        [data]
    );

    return(
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                        /></a></li>
                        <li><a href="/territory-status"><span class="icon icon-beaker"> </span>Reports</a></li>

                        <li><a href="#"><span class="icon icon-double-angle-right"></span>Territory Status</a></li>

                    </ul>

                </div>

            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                    }}
                >
                    <div style={{ width: "320px", display: Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.USER_DROPDOWN ? '' : 'none' }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">States</Form.Label>
                            <div className="w-75">
                                <Select
                                    className="selectdoctor"
                                    options={options}
                                    value={selectedState}
                                    onChange={handleSelectChange}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="pt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        actions={actionsMemo}
                        pagination
                        customStyles={{ headRow: { style: { backgroundColor: '#E5E1DA', padding: 7 }, } }}
                        style={{ margin: '10px', padding: 10 }}
                        progressPending={pending}
                        progressComponent={<LoadingComponent />}
                        dense
                    />
                </div>
            </div>
        </Container>
    )
}