import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap"
import { FaPlus } from "react-icons/fa"
import { IoHomeOutline } from "react-icons/io5"
import { Op_per } from "../../utils/Permissions"
import DataTable from "react-data-table-component"
import { useState, useEffect } from "react"
import { CreateCompanyContact, DeleteCompanyContact, GetAllCompanyContacts, GetAllCompanyContactsMini, UpdateCompanyContact } from "../../utils/proxy/CompanyContactProxy"
import { GetAllSalutation } from "../../utils/proxy/LeadsProxy"
import { GetAllAccountsMini } from "../../utils/proxy/AccountsProxy"
import { GetAllCitiesByState, GetAllCountries, GetAllStatesByCountry } from "../../utils/proxy/Countryproxy"
import Swal from "sweetalert2"
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai"
import LoadingComponent from "../Includes/LoadingComponent"

export const CompanyContacts = () => {

    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [reload, setReload] = useState(false);
    const [operation, setOperation] = useState('Add');
    const [contact, setContact] = useState({"createdby": localStorage.getItem('uuid'), "updatedby": localStorage.getItem('uuid')});
    const [saluations, setSaluations] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [companyContact, setCompanyContact] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getall = async () => {
            let d = await GetAllCompanyContacts();
            if (d) {
                setData(d);
            } else {
                alert('Error', 'Issues while fetching data')
            }
        }
        getall()
        setReload(false);
    }, [reload])

    useEffect(() => {
        async function getSaluations() {
            let res = await GetAllSalutation();
            if (res) {
                setSaluations(res);

            }
        }

        async function getAccounts() {
            let res = await GetAllAccountsMini();
            if (res) {
                setAccounts(res);
            }
        }

        async function getCompanyContact() {
            let res = await GetAllCompanyContactsMini();
            if (res) {
                setCompanyContact(res);
            }
        }

        getSaluations();
        getAccounts();
        getCompanyContact();
        getCountries();
    }, [])

    useEffect(() => {
        getStates();
    }, [contact.mailing_address_countryid]);

    useEffect(() => {
        getCities();
    }, [contact.mailing_address_stateid]);

    async function getCountries() {
        let res = await GetAllCountries();
        if (res) {
            setCountries(res);

        }
    }

    async function getStates() {
        if(contact.mailing_address_countryid){
            let res = await GetAllStatesByCountry(contact.mailing_address_countryid);
            if (res) {
                setStates(res);
            }
        }
    }

    async function getCities() {
        if(contact.mailing_address_stateid){
            let res = await GetAllCitiesByState(contact.mailing_address_stateid);
            if (res) {
                setCities(res);

            }
        }
    }

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        if(operation == 'Update'){
            setContact({"createdby": localStorage.getItem('uuid'), "updatedby": localStorage.getItem('uuid')})
        }
        setShow(false);
        setOperation('Add');
    }

    function changesaluation(params) {
        setContact(prevLead => ({
            ...prevLead,
            salutationid: params
        }));
    }

    function changeaccountid(params) {
        setContact(prevLead => ({
            ...prevLead,
            accountid: params
        }));
    }

    function changeReportto(params) {
        setContact(prevLead => ({
            ...prevLead,
            reportstoid: params
        }));
    }

    function changeCountry(params) {
        setContact(prevLead => ({
            ...prevLead,
            mailing_address_countryid: params
        }));
    }

    function changeState(params) {
        setContact(prevLead => ({
            ...prevLead,
            mailing_address_stateid: params
        }));
    }

    function changeCity(params) {
        setContact(prevLead => ({
            ...prevLead,
            mailing_address_cityid: params
        }));
    }

    const handleSave = async() => {
        if(contact.salutationid == '' || contact.salutationid == null){
            Swal.fire({
                title: "Warning!",
                text: "Select Salutation !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }else if(contact.firstname == '' || contact.firstname == null){
            Swal.fire({
                title: "Warning!",
                text: "Enter First Name !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }else if(contact.middlename == '' || contact.middlename == null){
            Swal.fire({
                title: "Warning!",
                text: "Enter Middle Name !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }else if(contact.lastname == '' || contact.lastname == null){
            Swal.fire({
                title: "Warning!",
                text: "Enter Last Name !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }else if(contact.accountid == '' || contact.accountid == null){
            Swal.fire({
                title: "Warning!",
                text: "Select Account !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }else if(contact.title == '' || contact.title == null){
            Swal.fire({
                title: "Warning!",
                text: "Enter title !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }else if(contact.email == '' || contact.email == null){
            Swal.fire({
                title: "Warning!",
                text: "Enter Email !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }else if(contact.accountid == '' || contact.accountid == null){
            Swal.fire({
                title: "Warning!",
                text: "Select Account !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }else if(contact.mailing_address_countryid == '' || contact.mailing_address_countryid == null){
            Swal.fire({
                title: "Warning!",
                text: "Select Country !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }else if(contact.mailing_address_stateid == '' || contact.mailing_address_stateid == null){
            Swal.fire({
                title: "Warning!",
                text: "Select State !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }else if(contact.mailing_address_cityid == '' || contact.mailing_address_cityid == null){
            Swal.fire({
                title: "Warning!",
                text: "Select City !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }else if(contact.mailing_address_street == '' || contact.mailing_address_street == null){
            Swal.fire({
                title: "Warning!",
                text: "Enter Street Address !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }else if(contact.mailing_address_zip == '' || contact.mailing_address_zip == null){
            Swal.fire({
                title: "Warning!",
                text: "Enter Zip Code !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }else{
            setLoading(false);
            let res;
            if(operation === 'Add'){
                res = await CreateCompanyContact(contact);
            }else{
                res = await UpdateCompanyContact(contact.id,contact);
            }
            if(res.status == 'success'){
                setReload(true);
                setOperation('Add');
                setContact({"createdby": localStorage.getItem('uuid'), "updatedby": localStorage.getItem('uuid')});
                setShow(false);
                setLoading(true);
                Swal.fire({
                    title: "Success!",
                    text: res.message,
                    icon: "success"
                });
            }else{
                Swal.fire({
                    title: "error",
                    text: "Something Went Wrong !!",
                    icon: "warning",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const handleEditShow = (row) =>{
        setContact(row);
        setContact(prevLead => ({
            ...prevLead,
            updatedby: localStorage.getItem('uuid')
        }));
        setShow(true);
        setOperation('Update');
    }

    const handledelete = async(id) =>{
        let d = await DeleteCompanyContact(id);
        if(d.status == 'success'){
            setReload(true);
            Swal.fire({
                title: "Success!",
                text: d.message,
                icon: "success"
            });
        }else{
            Swal.fire({
                title: "error",
                text: "Something Went Wrong !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }
    }

    const CustomHeader = ({ children }) => (<div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    const columns = [
        // {
        //     name: "Id",
        //     selector: (row) => row.id,
        //   
        //     width:"5%",
        // },
        {
            name: "Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.firstname === "" || row.firstname === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.firstname === "" || row.firstname === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.firstname === "" || row.firstname === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.firstname ? row.firstname + ' ' + row.lastname : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Account Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.company === "" || row.company === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.company === "" || row.company === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.company === "" || row.company === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.company ? row.company : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Title",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.title === "" || row.title === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.title === "" || row.title === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.title === "" || row.title === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.title ? row.title : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Email",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.email === "" || row.email === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.email === "" || row.email === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.email === "" || row.email === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.email ? row.email : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Reports To",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.reportsto === "" || row.reportsto === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.reportsto === "" || row.reportsto === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.reportsto === "" || row.reportsto === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.reportsto ? row.reportsto : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Fax",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.fax === "" || row.fax === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.fax === "" || row.fax === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.fax === "" || row.fax === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.fax ? row.fax : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
    ];

    if (Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_DELETE || Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_EDIT) {
        columns.push({
            name: "Actions",
            selector: (row) => (
                <div key={row.id}>
                    <AiFillDelete
                        onClick={() => handledelete(row.id)}
                        style={{ color: "red", fontSize: 20, display: Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_DELETE ? '' : 'none' }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AiTwotoneEdit
                        onClick={() => handleEditShow(row)}
                        style={{ color: "black", fontSize: 20, display: Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_EDIT ? '' : 'none' }}
                    />
                </div>
            ),
        })
    }

    const ExpandedComponent = ({ data }) => (
        <div style={{
            backgroundColor: data.disabled ? "red" : "#D9E1FF",
            color: "#fff",
            padding: 10,
            fontSize: 12,
            margin: 20,
            borderRadius: 20,
            border: "2px solid #28B3D2",
            width: "95%"

        }}>
            <Table
                size="sm"
            >
                <tr>
                    <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }} >Street</th>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ padding: 10, width: "30%" }}> <span style={{
                        backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold"
                    }}>{data.mailing_address_street}</span> </td>
                    <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }} >City</th>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ padding: 10, width: "30%" }}> <span style={{
                        backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold"
                    }}>{data.cityname+ " (" + data.mailing_address_zip + ")"}</span> </td>
                </tr>

                <tr>

                    <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>State</th>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ padding: 10, width: "30%" }} > <span style={{
                        backgroundColor: data.statename === "" || data.statename === null ? "red" : "#2457C5", borderRadius: "10px", border: data.statename === "" || data.statename === null ? "2px solid red" : "2px solid #28B3D2", padding: "5px", margin: "3px", color: "#fff", fontWeight: "bold"
                    }}> {data.statename === "" || data.statename === null
                        ? "N/A"
                        : data.statename }</span></td>
                    <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Country</th>
                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ padding: 10, width: "30%" }} > <span style={{
                        backgroundColor: data.countryname === "" || data.countryname === null ? "red" : "#2457C5", borderRadius: "10px", border: data.countryname === "" || data.countryname === null ? "2px solid red" : "2px solid #28B3D2", padding: "5px", margin: "3px", color: "#fff", fontWeight: "bold"
                    }}> {data.countryname === "" || data.countryname === null
                        ? "N/A"
                        : data.countryname}</span></td>
                </tr>

                <tr>
                    <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Phone</th>

                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ padding: 10, width: "30%" }}> <span style={{
                        backgroundColor: data.phone === "" || data.phone === null ? "red" : "#2457C5", borderRadius: "10px", border: data.phone === "" || data.phone === null ? "2px solid red" : "2px solid #28B3D2", padding: "5px", margin: "3px", color: "#fff", fontWeight: "bold"
                    }}> {data.phone === "" || data.phone === null
                        ? "N/A"
                        : data.phone} </span></td>

                    <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Mobile</th>

                    <td style={{ width: "10%" }}>:</td>
                    <td style={{ padding: 10, width: "30%" }}> <span style={{
                        backgroundColor: data.mobile === "" || data.mobile === null ? "red" : "#2457C5", borderRadius: "10px", border: data.mobile === "" || data.mobile === null ? "2px solid red" : "2px solid #28B3D2", padding: "5px", margin: "3px", color: "#fff", fontWeight: "bold"
                    }}> {data.mobile === "" || data.mobile === null
                        ? "N/A"
                        : data.mobile} </span></td>
                </tr>
            </Table>
        </div>
    );

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                            
                        /></a></li>
                        <li><a href="/company-contacts"><span class="icon icon-beaker"> </span>Products</a></li>
                        <li><a href="/company-contacts"><span class="icon icon-beaker"> </span>Company Contacts</a></li>
                        {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

                    </ul>

                </div>
            </div>

            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <Form.Control
                            className="glass"
                            placeholder="Search"
                            type="text"
                            style={{ display: 'none' }}
                        //   value={searchQuery}
                        //   onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div>
                        {Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_ADD ? (
                            <Button style={{ backgroundColor: "#28B3D2 " }} onClick={handleShow}>
                                <FaPlus />
                            </Button>
                        ) : ("")}
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        customStyles={{ headRow: { style: { backgroundColor: '#E5E1DA', padding: 7 }, } }}
                        CustomHeader={<CustomHeader />}
                        style={{ marginTop: 10 }}
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        dense
                    />
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{operation} Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Saluation</Form.Label>
                                    <Form.Select className="selectdoctor" value={contact.salutationid} onChange={(e) => changesaluation(e.target.value)} aria-label="Default select example">
                                        <option value={''}>--Select Saluation--</option>
                                        {saluations.map(saluation => (
                                            <option value={saluation.id}>{saluation.name}</option>
                                        ))}


                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        style={{
                                            height: 46,

                                            borderColor: '#28B3D2',
                                            borderWidth: 2,
                                            paddingHorizontal: 8,
                                            marginBottom: 16,
                                            borderTopLeftRadius: 20,
                                            borderBottomRightRadius: 20,
                                        }}
                                        onChange={(e) => setContact(prevLead => ({ ...prevLead, firstname: e.target.value }))} value={contact.firstname} type="text" placeholder='Enter FirstName' />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Middle Name</Form.Label>
                                    <Form.Control
                                        style={{
                                            height: 46,

                                            borderColor: '#28B3D2',
                                            borderWidth: 2,
                                            paddingHorizontal: 8,
                                            marginBottom: 16,
                                            borderTopLeftRadius: 20,
                                            borderBottomRightRadius: 20,
                                        }}
                                        onChange={(e) => setContact(prevLead => ({ ...prevLead, middlename: e.target.value }))} value={contact.middlename} type="text" placeholder='Enter Middle Name' />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        style={{
                                            height: 46,

                                            borderColor: '#28B3D2',
                                            borderWidth: 2,
                                            paddingHorizontal: 8,
                                            marginBottom: 16,
                                            borderTopLeftRadius: 20,
                                            borderBottomRightRadius: 20,
                                        }}
                                        onChange={(e) => setContact(prevLead => ({ ...prevLead, lastname: e.target.value }))} value={contact.lastname} type="text" placeholder='Enter Last Name' />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Account</Form.Label>
                                    <Form.Select className="selectdoctor" value={contact.accountid} onChange={(e) => changeaccountid(e.target.value)} aria-label="Default select example">
                                        <option value={''}>--Select Account--</option>
                                        {accounts.map(saluation => (
                                            <option value={saluation.id}>{saluation.accountname}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        style={{
                                            height: 46,

                                            borderColor: '#28B3D2',
                                            borderWidth: 2,
                                            paddingHorizontal: 8,
                                            marginBottom: 16,
                                            borderTopLeftRadius: 20,
                                            borderBottomRightRadius: 20,
                                        }}
                                        onChange={(e) => setContact(prevLead => ({ ...prevLead, title: e.target.value }))} value={contact.title} type="text" placeholder='Enter Title' />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        style={{
                                            height: 46,

                                            borderColor: '#28B3D2',
                                            borderWidth: 2,
                                            paddingHorizontal: 8,
                                            marginBottom: 16,
                                            borderTopLeftRadius: 20,
                                            borderBottomRightRadius: 20,
                                        }}
                                        onChange={(e) => setContact(prevLead => ({ ...prevLead, email: e.target.value }))} value={contact.email} type="text" placeholder='Enter Email' />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        style={{
                                            height: 46,

                                            borderColor: '#28B3D2',
                                            borderWidth: 2,
                                            paddingHorizontal: 8,
                                            marginBottom: 16,
                                            borderTopLeftRadius: 20,
                                            borderBottomRightRadius: 20,
                                        }}
                                        onChange={(e) => setContact(prevLead => ({ ...prevLead, phone: e.target.value }))} value={contact.phone} type="text" placeholder='Enter Phone' />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Mobile</Form.Label>
                                    <Form.Control
                                        style={{
                                            height: 46,

                                            borderColor: '#28B3D2',
                                            borderWidth: 2,
                                            paddingHorizontal: 8,
                                            marginBottom: 16,
                                            borderTopLeftRadius: 20,
                                            borderBottomRightRadius: 20,
                                        }}
                                        onChange={(e) => setContact(prevLead => ({ ...prevLead, mobile: e.target.value }))} value={contact.mobile} type="text" placeholder='Enter Mobile Number' />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Fax</Form.Label>
                                    <Form.Control
                                        style={{
                                            height: 46,

                                            borderColor: '#28B3D2',
                                            borderWidth: 2,
                                            paddingHorizontal: 8,
                                            marginBottom: 16,
                                            borderTopLeftRadius: 20,
                                            borderBottomRightRadius: 20,
                                        }}
                                        onChange={(e) => setContact(prevLead => ({ ...prevLead, fax: e.target.value }))} value={contact.fax} type="text" placeholder='Enter Fax' />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Reports To</Form.Label>
                                    <Form.Select className="selectdoctor" value={contact.reportstoid} onChange={(e) => changeReportto(e.target.value)} aria-label="Default select example">
                                        <option value={''}>--Select Account--</option>
                                        {companyContact.map(saluation => (
                                            <option value={saluation.id}>{saluation.accountname}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select className="selectdoctor" value={contact.mailing_address_countryid} onChange={(e) => changeCountry(e.target.value)} aria-label="Default select example">
                                        <option value={''}>--Select Country--</option>
                                        {countries.map(saluation => (
                                            <option value={saluation.id}>{saluation.name}</option>
                                        ))}


                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select className="selectdoctor" value={contact.mailing_address_stateid} onChange={(e) => changeState(e.target.value)} aria-label="Default select example">
                                        <option value={''}>--Select State--</option>
                                        {states.map(saluation => (
                                            <option value={saluation.id}>{saluation.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>City</Form.Label>
                                    <Form.Select className="selectdoctor" value={contact.mailing_address_cityid} onChange={(e) => changeCity(e.target.value)} aria-label="Default select example">
                                        <option value={''}>--Select City--</option>
                                        {cities.map(saluation => (
                                            <option value={saluation.id}>{saluation.city}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Street Address</Form.Label>
                                    <Form.Control
                                        style={{
                                            height: 46,

                                            borderColor: '#28B3D2',
                                            borderWidth: 2,
                                            paddingHorizontal: 8,
                                            marginBottom: 16,
                                            borderTopLeftRadius: 20,
                                            borderBottomRightRadius: 20,
                                        }}
                                        onChange={(e) => setContact(prevLead => ({ ...prevLead, mailing_address_street: e.target.value }))} value={contact.mailing_address_street} type="text" placeholder='Enter Street Address' />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Zip Code</Form.Label>
                                    <Form.Control
                                        style={{
                                            height: 46,

                                            borderColor: '#28B3D2',
                                            borderWidth: 2,
                                            paddingHorizontal: 8,
                                            marginBottom: 16,
                                            borderTopLeftRadius: 20,
                                            borderBottomRightRadius: 20,
                                        }}
                                        onChange={(e) => setContact(prevLead => ({ ...prevLead, mailing_address_zip: e.target.value }))} value={contact.mailing_address_zip} type="number" placeholder='Enter Zip Code' />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>: <div style={{ display: 'grid', placeContent: 'center' }}><LoadingComponent /></div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                    >
                        {operation}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}