import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { createtcsSection, updatetcsSection } from '../../../utils/proxy/TaxProxy';
import Swal from 'sweetalert2';

function TcsSectionmodel({action,data,show,setShow,setReload}) {

    const [section,setSection] = useState(data ? data.section : "" );
    const [description,setDescription] = useState(data ? data.desc : "");

  const handleClose = () => setShow(false);

const handleSave = async () => {
    if (section && description) {
        if (action === 'create') {
            let res = await createtcsSection(section,description);
            if (res) {
                setShow(false)
                setReload(true)
                Swal.fire({
                    icon: res.status,
                    text: res.message,
                  });
            }
        }else{
            let res = await updatetcsSection(data.id,section,description);
            if (res) {
                setShow(false)
                setReload(true)
                Swal.fire({
                    icon: res.status,
                    text: res.message,
                  });
                }
        }
    }

}
  

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{action === 'create' ? 'Add Section' : 'Edit Section'}</Modal.Title>
        </Modal.Header>
        <div style={{margin:10}}>
        <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Section</Form.Label>
        <Form.Control type="text" value={section} onChange={(e) => setSection(e.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control as="textarea" rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
      </Form.Group>
     
    </Form>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
          {action === 'create' ? 'Save' : 'Update'} Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TcsSectionmodel