import { AiOutlineUserAdd } from "react-icons/ai"
import { FaTasks } from "react-icons/fa"
import { MdOutlineLeaderboard } from "react-icons/md"

export const CardDash2 = ({count,title}) =>{

  const cardArray = [
    { title: 'Total Tasks', icon: <FaTasks />},
    { title: 'Total Leads', icon: <MdOutlineLeaderboard/> },
    { title: 'Total Users', icon: <AiOutlineUserAdd/> },
]

    return(
        <div className="col-lg-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex flex-row">
                <div className="col-3 align-self-center">
                  <div className="round">
                    {cardArray.filter(k => k.title === title)[0].icon}
                  </div>
                </div>
                <div className="col-9 align-self-center text-right">
                  <div className="m-l-10">
                    <h5 className="mt-0">{count}</h5>
                    <p className="mb-0 text-muted">
                      {title}
                      {/* <span className="badge bg-soft-success">
                        <i className="mdi mdi-arrow-up" />
                        2.35%
                      </span> */}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="progress mt-3" style={{ height: 3 }}>
                <div
                  className="progress-bar  bg-success"
                  role="progressbar"
                  style={{ width: "35%" }}
                  aria-valuenow={35}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div> */}
            </div>
          </div>
        </div>
    )
}