import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { GetAllIndustry } from '../../utils/proxy/LeadsProxy';
import Form from 'react-bootstrap/Form';
import { GetAllCitiesByState, GetAllCountries, GetAllStatesByCountry } from '../../utils/proxy/Countryproxy';
import { AllUsers, GetAllManagerUsers } from '../../utils/proxy/Authproxy';
import Swal from 'sweetalert2';
import { AccountAvailableByName, CreateAccount, GetAccountById, GetAllAccountType, GetAllAccountsMini, UpdateAccount } from '../../utils/proxy/AccountsProxy';
import LoadingComponent from '../Includes/LoadingComponent';


function AccountModal({ fullscreen, show, setShow, accountid, setReload, opttype }) {

    const [lead, setLead] = useState({ "createdby": localStorage.getItem('uuid'), "updatedby": localStorage.getItem('uuid') });
    const [accounts, setAccounts] = useState([]);
    const [acccountTypes, setAccountsTypes] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([])
    const [shippingCountry, setShippingCountry] = useState([]);
    const [shippingState, setShippingState] = useState([]);
    const [shippingCity, setShippingCity] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [available, setAvailable] = useState(true);


    useEffect(() => {
        if (opttype == 'update') {
            getLeads();
        }
        getAccountTypes();
        getIndustries();
        getCountries();
        getSaluations();
    }, [accountid]);

    useEffect(() => {
        getStates();
    }, [lead.billing_address_countryid]);

    useEffect(() => {
        getCities();
    }, [lead.billing_address_stateid]);

    useEffect(() => {
        getShippingStates();
    }, [lead.shipping_address_countryid]);


    useEffect(() => {
        getShippingCities();
    }, [lead.shipping_address_stateid]);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallusers = async () => {

            if (localStorage.getItem('role') === 'Admin') {
                let allusers = await AllUsers();
                setUsers(allusers);
            } else if (localStorage.getItem('role') === 'Manager') {
                let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
                setUsers(allusers);
            }


        };
        getallusers();
    }, []);

    useEffect(() => {
        let debounceTimeout;

        if (lead.accountname !== "" && lead.accountname !== null && lead.accountname !== undefined) {
            debounceTimeout = setTimeout(async () => {
                // Make API call with name
                const a = await AccountAvailableByName(lead.accountname);
                setAvailable(a);
            }, 500); // Adjust debounce delay as needed
        } else {
            setAvailable(true);
        }

        // Clear timeout when name changes or becomes empty
        return () => clearTimeout(debounceTimeout);
    }, [lead.accountname]);

    async function getLeads() {
        setLoading(false);
        let res = await GetAccountById(accountid);
        if (res) {
            setLead(res);
            setLoading(true);
        }
    }

    async function getSaluations() {
        let res = await GetAllAccountsMini();
        if (res) {
            setAccounts(res);

        }
    }

    async function getAccountTypes() {
        let res = await GetAllAccountType();
        if (res) {
            setAccountsTypes(res);

        }
    }

    async function getIndustries() {
        let res = await GetAllIndustry();
        if (res) {
            setIndustries(res);

        }
    }


    async function getCountries() {
        let res = await GetAllCountries();
        if (res) {
            setCountries(res);
            setShippingCountry(res);
        }
    }

    async function getStates() {
        if (lead.billing_address_countryid) {
            let res = await GetAllStatesByCountry(lead.billing_address_countryid);
            if (res) {
                setStates(res);
            }
        }
    }

    async function getShippingStates() {
        if (lead.shipping_address_countryid) {
            let res = await GetAllStatesByCountry(lead.shipping_address_countryid);
            if (res) {
                setShippingState(res);

            }
        }
    }


    async function getCities() {
        if (lead.billing_address_stateid) {
            let res = await GetAllCitiesByState(lead.billing_address_stateid);
            if (res) {
                setCities(res);

            }
        }
    }

    async function getShippingCities() {
        if (lead.shipping_address_stateid) {
            let res = await GetAllCitiesByState(lead.shipping_address_stateid);
            if (res) {
                setShippingCity(res);

            }
        }
    }


    function changeParentAccount(params) {
        setLead(prevLead => ({
            ...prevLead,
            parentaccountid: params
        }));
    }

    function changeAccountOwner(params) {
        setLead(prevLead => ({
            ...prevLead,
            accountownerid: params
        }));
    }

    function changeAccountType(params) {
        setLead(prevLead => ({
            ...prevLead,
            typeid: params
        }));
    }

    function changeIndustry(params) {
        setLead(prevLead => ({
            ...prevLead,
            industryid: params
        }));
    }

    function changeCountry(params) {
        setLead(prevLead => ({
            ...prevLead,
            billing_address_countryid: params
        }));
    }

    function changeShippingCountry(params) {
        setLead(prevLead => ({
            ...prevLead,
            shipping_address_countryid: params
        }));
    }

    function changeState(params) {
        setLead(prevLead => ({
            ...prevLead,
            billing_address_stateid: params
        }));
    }

    function changeShippingState(params) {
        setLead(prevLead => ({
            ...prevLead,
            shipping_address_stateid: params
        }));
    }

    function changeCity(params) {
        setLead(prevLead => ({
            ...prevLead,
            billing_address_cityid: params
        }));
    }

    function changeShippingCity(params) {
        setLead(prevLead => ({
            ...prevLead,
            shipping_address_cityid: params
        }));
    }

    async function updateLead() {
        if (lead.accountname == null || lead.accountname == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Account Name !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (lead.accountownerid == null || lead.accountownerid == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Account Owner !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (lead.typeid == null || lead.typeid == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Account Type !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (lead.taxid == null || lead.taxid == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Tax id !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (lead.industryid == null || lead.industryid == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Industry !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (lead.billing_address_cityid == '' || lead.billing_address_countryid == '' || lead.billing_address_stateid == '' || lead.billing_address_street == '' || lead.billing_address_zip == '') {
            Swal.fire({
                title: "Warning!",
                text: "Fill All Billing Address Details !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (lead.shipping_address_cityid == '' || lead.shipping_address_countryid == '' || lead.shipping_address_stateid == '' || lead.shipping_address_street == '' || lead.shipping_address_zip == '') {
            Swal.fire({
                title: "Warning!",
                text: "Fill All Shipping Address Details !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let res;
            if (opttype === "update") {
                res = await UpdateAccount(lead.id, lead);
            } else {
                res = await CreateAccount(lead);
            }

            if (res.status == 'success') {
                Swal.fire({
                    title: opttype === "update" ? "Updated!" : "Created",
                    text: opttype === "update" ? "Account Has Been Updated Successfully" : "Account Has Been Created Successfully",
                    icon: "success"
                });
                setReload(true);
                setShow(false);
            } else if (res.status == 'error') {
                setLoading(true)
                Swal.fire({
                    title: "Warning!",
                    text: res.message,
                    icon: "warning",
                    confirmButtonText: "OK",
                });
            } else {
                Swal.fire({
                    title: "error",
                    text: "Something Went Wrong !!",
                    icon: "warning",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    function closeModal() {
        setLead({});
        setShow(false)
        setAvailable(true);
    }

    const handleCheckBox = (e) => {
        if (e.target.checked) {
            setLead(prevLead => ({
                ...prevLead,
                shipping_address_countryid: lead.billing_address_countryid,
                shipping_address_stateid: lead.billing_address_stateid,
                shipping_address_cityid: lead.billing_address_cityid,
                shipping_address_street: lead.billing_address_street,
                shipping_address_zip: lead.billing_address_zip
            }));
        } else {
            setLead(prevLead => ({
                ...prevLead,
                shipping_address_countryid: '',
                shipping_address_stateid: '',
                shipping_address_cityid: '',
                shipping_address_street: '',
                shipping_address_zip: '',
            }));
        }
    }

    return (
        <Modal show={show} fullscreen={fullscreen} onHide={() => closeModal()}>
            <Modal.Header closeButton>
                <Modal.Title><img src={process.env.PUBLIC_URL + "/images/nav.png"} alt="Team Insights" width={200} /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? <Row>
                    <Col md={12} style={{ height: "80vh" }}>
                        <Card style={{ height: "100%" }}>
                            <Card.Body style={{ overflowX: 'auto' }}>
                                <h4>Details</h4>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Account Name</Form.Label>
                                                <Form.Control
                                                    style={{
                                                        height: 46,

                                                        borderColor: available ? '#28B3D2' : 'red',
                                                        borderWidth: 2,
                                                        paddingHorizontal: 8,
                                                        marginBottom: 16,
                                                        borderTopLeftRadius: 20,
                                                        borderBottomRightRadius: 20,
                                                    }}
                                                    onChange={(e) => setLead(prevLead => ({ ...prevLead, accountname: e.target.value }))} value={lead.accountname} type="text" placeholder='Enter Account Name' />
                                            </Form.Group>
                                        </Col>


                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Parent Account</Form.Label>
                                                <Form.Select className='selectdoctor' value={lead.parentaccountid} onChange={(e) => changeParentAccount(e.target.value)} aria-label="Default select example">
                                                    <option value={''}>--Select Parent Account--</option>
                                                    {accounts.map(saluation => (
                                                        <option value={saluation.id}>{saluation.accountname}</option>
                                                    ))}


                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Account Owner</Form.Label>
                                                <Form.Select className='selectdoctor' value={lead.accountownerid} onChange={(e) => changeAccountOwner(e.target.value)} aria-label="Default select example">
                                                    <option value={''}>--Select Owner--</option>
                                                    {users.map(saluation => (
                                                        <option value={saluation.userid}>{saluation.firstname} {saluation.lastname} ({saluation.username})</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Account Type</Form.Label>
                                                <Form.Select className='selectdoctor' value={lead.typeid} onChange={(e) => changeAccountType(e.target.value)} aria-label="Default select example">
                                                    <option value={''}>--Select Type--</option>
                                                    {acccountTypes.map(saluation => (
                                                        <option value={saluation.id}>{saluation.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>



                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Industry</Form.Label>
                                                <Form.Select className='selectdoctor' value={lead.industryid} onChange={(e) => changeIndustry(e.target.value)} aria-label="Default select example">
                                                    <option value={''}>--Select Industry--</option>
                                                    {industries.map(saluation => (
                                                        <option value={saluation.id}>{saluation.name}</option>
                                                    ))}


                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Phone</Form.Label>
                                                <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, phone: e.target.value }))} value={lead.phone} type="text" placeholder="+91 0000000000" />
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, email: e.target.value }))} value={lead.email} type="text" placeholder="johnDoe@gmail.com" />
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Total Employees</Form.Label>
                                                <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, employees: e.target.value }))} value={lead.employees} type="number" placeholder="10000" />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Tax ID</Form.Label>
                                                <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, taxid: e.target.value }))} value={lead.taxid} type="text" placeholder="Tax Id" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Website</Form.Label>
                                                <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, website: e.target.value }))} value={lead.website} type="text" placeholder="www.example.com" />
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, description: e.target.value }))} value={lead.description} type="text" placeholder="Enter Description" />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <hr />
                                    <h4>Billing Address:</h4>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Billing Country</Form.Label>
                                                <Form.Select className='selectdoctor' value={lead.billing_address_countryid} onChange={(e) => changeCountry(e.target.value)} aria-label="Default select example">
                                                    <option value={''}>--Select Country--</option>
                                                    {countries.map(saluation => (
                                                        <option value={saluation.id}>{saluation.name}</option>
                                                    ))}


                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Billing State</Form.Label>
                                                <Form.Select className='selectdoctor' value={lead.billing_address_stateid} onChange={(e) => changeState(e.target.value)} aria-label="Default select example">
                                                    <option value={''}>--Select State--</option>
                                                    {states.map(saluation => (
                                                        <option value={saluation.id}>{saluation.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Billing City</Form.Label>
                                                <Form.Select className='selectdoctor' value={lead.billing_address_cityid} onChange={(e) => changeCity(e.target.value)} aria-label="Default select example">
                                                    <option value={''}>--Select City--</option>
                                                    {cities.map(saluation => (
                                                        <option value={saluation.id}>{saluation.city}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Billing Street</Form.Label>
                                                <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, billing_address_street: e.target.value }))} value={lead.billing_address_street} type="text" placeholder="ABC Banquite Street, Opp Benjiman Hall" />
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Zip Code</Form.Label>
                                                <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, billing_address_zip: e.target.value }))} value={lead.billing_address_zip} type="number" placeholder="Enter Zip Code" />
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    <hr />
                                    <h4>Shipping Address:</h4>
                                    <Form.Check
                                        type='checkbox'
                                        id={`default-checkbox`}
                                        label={`Same As Billing Address`}
                                        onChange={handleCheckBox}
                                    />
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Shipping Country</Form.Label>
                                                <Form.Select className='selectdoctor' value={lead.shipping_address_countryid} onChange={(e) => changeShippingCountry(e.target.value)} aria-label="Default select example">
                                                    <option value={''}>--Select Country--</option>
                                                    {shippingCountry.map(saluation => (
                                                        <option value={saluation.id}>{saluation.name}</option>
                                                    ))}


                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Shipping State</Form.Label>
                                                <Form.Select className='selectdoctor' value={lead.shipping_address_stateid} onChange={(e) => changeShippingState(e.target.value)} aria-label="Default select example">
                                                    <option value={''}>--Select State--</option>
                                                    {shippingState.map(saluation => (
                                                        <option value={saluation.id}>{saluation.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Shipping City</Form.Label>
                                                <Form.Select className='selectdoctor' value={lead.shipping_address_cityid} onChange={(e) => changeShippingCity(e.target.value)} aria-label="Default select example">
                                                    <option value={''}>--Select City--</option>
                                                    {shippingCity.map(saluation => (
                                                        <option value={saluation.id}>{saluation.city}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Shipping Street</Form.Label>
                                                <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, shipping_address_street: e.target.value }))} value={lead.shipping_address_street} type="text" placeholder="ABC Banquite Street, Opp Benjiman Hall" />
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Zip Code</Form.Label>
                                                <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, shipping_address_zip: e.target.value }))} value={lead.shipping_address_zip} type="number" placeholder="Enter Zip Code" />
                                            </Form.Group>
                                        </Col>

                                    </Row>


                                    <Button onClick={() => updateLead()} variant="primary" type="button">
                                        {opttype === "update" ? "Update Account" : "Create Account"}
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> : <div style={{ display: 'grid', placeContent: 'center' }}><LoadingComponent /></div>}
            </Modal.Body>
        </Modal>
    )
}

export default AccountModal