import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

// User Location

export const GetAllUserLocation = async (userId, date) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi(
    "get",
    REPORTING_URL + `/apiv1/userlocation/user/${userId}/${date}`,
    null
  );
};

export const GetUserDCRLocation = async (userId, date) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi(
    "get",
    REPORTING_URL + `/apiv1/reports/userdcrlocations/${userId}/${date}`,
    null
  );
};
