import { Button, Container, Dropdown, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Form from 'react-bootstrap/Form';
import { useState } from "react";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { CreateHoliday, DeleteHoliday, GetAllHoliday, GetAllHolidayPagination, GetAllHolidayType, UpdateHoliday } from "../../utils/proxy/AttendanceProxy";
import Select from "react-select";
import { HiDotsVertical } from "react-icons/hi";
import { Parser } from "@json2csv/plainjs";
import { IoHomeOutline } from "react-icons/io5";
import { Op_per } from "../../utils/Permissions";


function HolidayMaster() {

    const [show, setShow] = useState(false);
    const [showedit, setShowedit] = useState(false);
    const [reload, SetReload] = useState(false);
    const [data, setData] = useState([]);
    const [holidayType, setHolidayType] = useState([]);
    const [selectedHoliday, setSelectedHoliday] = useState();
    const [selectedHolidayId, setSelectedHolidayId] = useState();

    const [id, setId] = useState();
    const [name, setName] = useState();
    const [date, setDate] = useState('');
    const [description, setDescription] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [searchQuery, setSearchQuery] = useState('');

    const [perPage, setPerage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState();

    const getall = async (page) => {
        let d = await GetAllHolidayPagination(page, perPage, searchQuery);
        if (d) {
            setData(d[0].holidays);
            setTotalRows(d[0].totalrows);
            setCurrentPage(d[0].currentpage);
            setTotalPages(d[0].totalpages);
        } else {
            alert('Error', 'Issues while fetching data')
        }
    }

    useEffect(() => {

        getall(0)
        SetReload(false);
    }, [reload, searchQuery])

    useEffect(() => {
        const getallHolidayType = async () => {
            let d = await GetAllHolidayType();
            if (d) {
                setHolidayType(d);
            } else {
                alert('Error', 'Issues while fetching data')
            }
        }
        getallHolidayType()
        SetReload(true);
    }, [reload])

    const handlePageChange = page => {

        getall(page - 1);

    }

    const handlePerRowChange = async (newPerPage, page) => {
        setPerage(newPerPage);
        const areasdata = await GetAllHolidayPagination(page - 1, newPerPage, searchQuery);
        if (areasdata) {
            setData(areasdata[0].holidays);
            setTotalRows(areasdata[0].totalrows)
            setTotalPages(areasdata[0].totalpages);
        } else {
            alert("Error", "Issue While Fetching Doctors");
        };
    }

    const handleSave = async () => {
        if (name == null || name == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Holiday !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (date == null || date == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Date !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (selectedHolidayId == null) {
            Swal.fire({
                title: "Warning!",
                text: "Select Holiday Type !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await CreateHoliday(name, selectedHolidayId, date);
            if (d.status === 'success') {
                setShow(false);
                SetReload(true);
                setName(null);
                setDate('');
                Swal.fire({
                    title: "Success!",
                    text: "New Holiday Added !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const handledelete = async (id) => {
        let d = await DeleteHoliday(id);
        if (d.status === 'success') {
            SetReload(true);
            Swal.fire({
                title: "Success!",
                text: "Holiday Deleted !!",
                icon: "success",
                confirmButtonText: "OK",
            });
        }
    }

    const handleEditShow = (row) => {
        setShowedit(true);
        setId(row.id);
        setName(row.name);
        setDescription(row.description);
    }

    const handleEditSave = async () => {
        if (name == null || name == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Holiday !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (date == null || date == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Date !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (selectedHolidayId == null) {
            Swal.fire({
                title: "Warning!",
                text: "Select Holiday Type !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await UpdateHoliday(id, name, selectedHolidayId, date);
            if (d.status === 'success') {
                setShowedit(false);
                SetReload(true);
                setId(null);
                setName(null);
                setDescription(null);
                Swal.fire({
                    title: "Success!",
                    text: "Holiday Edited !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const edithandle = (row) => {
        setShowedit(true);
        setId(row.id);
        setName(row.name);
        setDate(row.date);
        setSelectedHoliday({ value: row.typeid, label: row.type });
        setSelectedHolidayId(row.typeid)
    }

    async function downloadCSV(areas) {
        let csv;
        try {
            const opts = {
                fields: [
                    "id",
                    "name",
                    "type",
                    "date",
                ],
            };
            const parser = new Parser(opts);
            csv = parser.parse(areas);
        } catch (err) {
            console.error(err);
        }

        const link = document.createElement("a");

        if (csv == null) return;

        const filename = "export.csv";

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    }

    // export excel
    const handleExcelExport = () => {
        Swal.fire({
            title: "Exporting!",
            text: "Exporting Excel !!",
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
                downloadCSV(data);
                Swal.hideLoading()
                Swal.close()

            }
        });
    }

    const columns = [
        // {
        //     name: "Id",
        //     selector: (row) => row.id,
        //     
        //     width:"5%",
        // },
        {
            name: "Holiday",
            selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.name === "" || row.name === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.name === "" || row.name === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.name === "" || row.name === null ? "#fff": "#2457C5", margin:"3px", }}>{row.name ? row.name : "N/A"}</div>,
      
            width:"15%",
            
            
            fontWeight:'bold',
            
        },
        {
            name: "Type",
            selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.type === "" || row.type === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.type === "" || row.type === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.type === "" || row.type === null ? "#fff": "#2457C5", margin:"3px", }}>{row.type ? row.type : "N/A"}</div>,
      
            width:"15%",
            
            fontSize: 10,
            fontWeight:'bold',
            
        },
        {
            name: "Date",
            selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.date.split('T')[0] === "" || row.date.split('T')[0] === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.date.split('T')[0] === "" || row.date.split('T')[0] === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.date.split('T')[0] === "" || row.date.split('T')[0] === null ? "#fff": "#2457C5", margin:"3px", }}>{row.date.split('T')[0] ? row.date.split('T')[0] : "N/A"}</div>,
      
            width:"15%",
            
            fontSize: 10,
            fontWeight:'bold'
            
        },
    ];
    const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
    const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    if (Op_per.get().MASTERS_HOLIDAYS.ALLOW_DELETE || Op_per.get().MASTERS_HOLIDAYS.ALLOW_EDIT) {
        columns.push({
            name: "Actions",
            selector: (row) => (
                <div key={row.id}>
                    <AiFillDelete
                        onClick={() => handledelete(row.id)}
                        style={{ color: "red", fontSize: 20, display:Op_per.get().MASTERS_HOLIDAYS.ALLOW_DELETE ? '' : 'none' }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AiTwotoneEdit
                        onClick={() => edithandle(row)}
                        style={{ color: "black", fontSize: 20, display:Op_per.get().MASTERS_HOLIDAYS.ALLOW_EDIT ? '' : 'none' }}
                    />
                </div>
            ),
        })
    }

    const typeoptions = [];

    holidayType.map((type) => {
        typeoptions.push({
            value: type.id,
            label: type.name
        });
    });

    const handleSelectHolidayType = (row) => {
        setSelectedHoliday(row);
        setSelectedHolidayId(row.value)
    }

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding:0,
          backgroundColor:'#3887BE'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
           
          /></a></li>
  <li><a href="/holiday-master"><span class="icon icon-beaker"> </span>Masters</a></li>
  <li><a href="/holiday-master"><span class="icon icon-beaker"> </span> Holidays</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <Form.Control
                         style={{height: 46,
            width:250,
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                            className="glass"
                            placeholder="Search"
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div>
                        {Op_per.get().MASTERS_HOLIDAYS.ALLOW_ADD || Op_per.get().MASTERS_HOLIDAYS.ALLOW_EXPORT_XLSX ? (
                            <div class="dropdown">
                <button
                  class="btn btn-secondary"
                  type="button"
                  style={{ backgroundColor: "#28B3D2" }}
                  title="Options"
                  id="dropdown-basic"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <HiDotsVertical />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={handleShow} style={{display:Op_per.get().MASTERS_HOLIDAYS.ALLOW_ADD ? '' : 'none'}}>
                    <a class="dropdown-item">Add</a>
                  </li>
                  
                  <li style={{display:Op_per.get().MASTERS_HOLIDAYS.ALLOW_EXPORT_XLSX ? '' : 'none'}}>
                    <a class="dropdown-item">Export XLSX</a>
                  </li>
                </ul>
              </div>
                        ) : ("")}
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <DataTable
                        columns={columns}
                        data={data}
                        // progressPending={pending}
                        // selectableRows
                        // onSelectedRowsChange={({ selectedRows }) =>
                        // setSelectedRows(selectedRows)
                        //   }
                        pagination
                        customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowChange}
                        onChangePage={handlePageChange}
                        // actions={actionsMemo}
                        style={{ marginTop: 10 }}
                        // progressPending={pending}
                        // progressComponent={<LoadingComponent/>}
                        dense
                    />
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Holiday Name <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                             style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter Holiday"
                                required
                            />
                        </Form.Group>
                        <Form.Label>Holiday Type <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <div  className="selectdoctor">
                                <Select
                               
                                    options={typeoptions}
                                    value={selectedHoliday}
                                    onChange={handleSelectHolidayType}
                                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                                />
                            </div>
                        </Form.Group>
                        <Form.Label>Date <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Control
                         style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                            type="date"
                            onChange={(e) => setDate(new Date(e.target.value).toISOString())}
                            placeholder="Enter Date"
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showedit} onHide={() => setShowedit(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Holiday </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Holiday Name <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                                type="text"
                                className="selectdoctor"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter Holiday Name"
                                required
                            />
                        </Form.Group>
                        <Form.Label>Holiday Type <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <div className="selectdoctor">
                                <Select
                                    options={typeoptions}
                                    value={selectedHoliday}
                                    onChange={handleSelectHolidayType}
                                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                                />
                            </div>
                        </Form.Group>
                        <Form.Label>Date <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Control
                            type="date"
                            className="selectdoctor"
                            onChange={(e) => setDate(new Date(e.target.value).toISOString())}
                            value={date.split('T')[0]}
                            placeholder="Select Date"
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowedit(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleEditSave}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container >
    )
}

export default HolidayMaster;