import React, { useEffect, useState } from "react";
import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import {
  GetAllDoctors,
  GetAllDoctorsSpecial,
  CheckDoctorNameAvailable,
  CheckDoctorCodeAvailable,
  GetAllDoctorsDegree,
  CreateDoctor,
  CreateExcelDoctor,
  UpdateDoctor,
  DeleteDoctor,
  DoctorBulkUpdateArea,
  BulkDeleteDoctor,
  GetAllDoctorsPagination,
} from "../../utils/proxy/Doctorproxy";
import { GetAllCips } from "../../utils/proxy/Cipproxy";
import {
  GetAllCountries,
  GetAllCitiesDetail,
  GetAllAreasDetail,
  GetAllStatesDetail,
  GetAllStatesByCountry,
  GetAllCitiesByState,
} from "../../utils/proxy/Countryproxy";
import {
  GetAllClinics,
  CreateClinic,
  UpdateClinic,
  CheckClinicAvailable,
  CreateExcelClinic,
  DeleteClinic,
} from "../../utils/proxy/Clinicproxy";
import { GetAllHospitalss } from "../../utils/proxy/Hospitalsproxy";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Badge from "react-bootstrap/Badge";
import DataTable from "react-data-table-component";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import ExportToExcel from "../../utils/ExportToExcel";
import { Parser } from "@json2csv/plainjs";
import { unwind, flatten } from "@json2csv/transforms";
import { AllUsers } from "../../utils/proxy/Authproxy";
import Helper from "../../utils/helperFunctions";
import LoadingComponent from "../Includes/LoadingComponent";
import { FaTruckField } from "react-icons/fa6";
import { HiDotsVertical } from "react-icons/hi";
import { SiDatabricks } from "react-icons/si";
import JSONToExcelConverter from "../../utils/JSONToExcelConverter";
import { IoHomeOutline } from "react-icons/io5";
import { Op_per } from "../../utils/Permissions";


function Doctormaster() {
  const [doctors, setDoctors] = useState([]);

  const [editdocid, setEditDocId] = useState();
  const [reload, SetReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [areas, setAreas] = useState([]);
  const [citys, setCitys] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const [doctorname, setDoctorName] = useState();
  const [doctorcode, setDoctorCode] = useState();
  const [doctoraadhar, setDoctorAadhar] = useState();

  const [selectedAreas, setSelectedAreas] = useState([]);

  const [areasid, setAreasid] = useState([]);
  const [doctoraddress, setDoctorAddress] = useState("");
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [pincode, setPincode] = useState();
  const [number, setNumber] = useState();
  const [email, setEmail] = useState();
  const [clinicid, setClinicId] = useState();

  const [doctordegree, setDoctorDegree] = useState([]);
  const [selectedDegrees, setSelectedDegrees] = useState([]);
  const [degreeid, setDegreeId] = useState([]);

  const [doctorspecialization, setDoctorSpecialization] = useState([]);
  const [selectedspecializations, setSelectedSpecializations] = useState([]);
  const [specializationid, setSpecializationId] = useState([]);

  const [cips, setCips] = useState([]);
  const [selectedcips, setSelectedCips] = useState([]);
  const [cipid, setCipId] = useState([]);

  const [clinics, setClinics] = useState([]);
  const [selectedclinics, setSelectedClinics] = useState([]);
  const [clinicsid, setClinicsId] = useState([]);

  const [hospitals, setHospitals] = useState([]);
  const [selectedhospital, setSelectedhospital] = useState([]);
  const [hospitalid, setHospitalId] = useState([]);

  const [show, setShow] = useState(false);
  const [showuploader, setShowUploader] = useState(false);
  const [showedit, setShowEdit] = useState(false);
  const [isdoctorname, setIsDoctorName] = useState();
  const [isdoctorcode, setIsDoctorCode] = useState();

  const [showbulkareaedit, setShowBulkAreaEdit] = useState(false);
  const [bulkSourceData, setBulkSourceData] = useState([]);

  const [pending, setPending] = useState(true);

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [users, setUsers] = useState([]);
  const [userid, setUserId] = useState("");

  const [isCountry, setIsCountry] = useState(false);
  const [isState, setIsState] = useState(false);
  const [isCity, setIsCity] = useState(false);
  const [isAreas, setIsAreas] = useState(false);

  const [perPage, setPerage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState();

  const [excelData, setExcelData] = useState([]);
  const [columnTitles, setColumnTitles] = useState({
    title1: excelData.length > 0 ? Object.keys(excelData[0])[0] : "",
    title2: excelData.length > 0 ? Object.keys(excelData[0])[1] : "",
    title3: excelData.length > 0 ? Object.keys(excelData[0])[2] : "",
    title4: excelData.length > 0 ? Object.keys(excelData[0])[3] : "",
    title5: excelData.length > 0 ? Object.keys(excelData[0])[4] : "",
  });
  const [fileUploaded, setFileUploaded] = useState(false);

  const getalldoctors = async (page) => {
    let areasdata = await GetAllDoctorsPagination(page, perPage, searchQuery);
    if (areasdata) {
      setDoctors(areasdata[0].doctors);
      setTotalRows(areasdata[0].totalrows);
      setCurrentPage(areasdata[0].currentpage);
      setTotalPages(areasdata[0].totalpages);
      setSelectedRows([]);
      setPending(false);
    } else {
      alert("Error", "Issue While Fetching Doctors");
    }
  };

  useEffect(() => {
    // Fetch data from an API when the component mounts
    getalldoctors(currentPage == undefined ? 0 : currentPage);
    SetReload(false);
  }, [reload, searchQuery]);

  const handlePageChange = page => {

    getalldoctors(page - 1);

  }

  const handlePerRowChange = async (newPerPage, page) => {
    setPerage(newPerPage);
    const areasdata = await GetAllDoctorsPagination(page - 1, newPerPage, searchQuery);
    if (areasdata) {
      setDoctors(areasdata[0].doctors);
      setTotalRows(areasdata[0].totalrows)
      setTotalPages(areasdata[0].totalpages);
      setPending(false);
    } else {
      alert("Error", "Issue While Fetching Doctors");
    };
  }

  //   const filteredData = doctors.filter((row) =>
  //   row.doctorname.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  // const filteredData = doctors.filter((row) => {
  //   // Check doctor name first.
  //   if (row.doctorname.toLowerCase().includes(searchQuery.toLowerCase())) {
  //     return true;
  //   }

  //   // If there are users associated with the doctor, loop through them.
  //   if (row.users && row.users.length > 0) {
  //     for (let user of row.users) {
  //       if (user.username.toLowerCase().includes(searchQuery.toLowerCase())) {
  //         return true;
  //       }
  //     }
  //   }

  //   return false;
  // });


  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getalldocdegree = async () => {
      let doctordegreedata = await GetAllDoctorsDegree();
      if (doctordegreedata) {
        setDoctorDegree(doctordegreedata);
      } else {
        alert("Error", "Issue While Fetching Doctors Degree");
      }
    };
    getalldocdegree();

    const getalldocspecialization = async () => {
      let doctorspecializationdata = await GetAllDoctorsSpecial();
      if (doctorspecializationdata) {
        setDoctorSpecialization(doctorspecializationdata);
      } else {
        alert("Error", "Issue While Fetching Doctors Specilization");
      }
    };
    getalldocspecialization();

    const getallcips = async () => {
      let cipsdata = await GetAllCips();
      if (cipsdata) {
        setCips(cipsdata);
      } else {
        alert("Error", "Issue While Fetching CIP's");
      }
    };
    getallcips();
    const getallclinics = async () => {
      let clinicsdata = await GetAllClinics();
      if (clinicsdata) {
        setClinics(clinicsdata);
      } else {
        alert("Error", "Issue While Fetching Clinics");
      }
    };
    getallclinics();
    const getallhospitals = async () => {
      let hospitaldata = await GetAllHospitalss();
      if (hospitaldata) {
        setHospitals(hospitaldata);
      } else {
        alert("Error", "Issue While Fetching Hospitals");
      }
    };
    getallhospitals();
  }, []);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {
      let allusers = await AllUsers();
      if (allusers) {
        setUsers(allusers);
      } else {
        alert("Error", "Issue While Fetching Users");
      }
    };
    getallusers();
  }, []);

  const userOptions = [];

  users.map((user) => {
    userOptions.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  const degreeoptions = [];
  doctordegree.map((degree) => {
    degreeoptions.push({ value: degree.id, label: degree.degree });
  });

  const cipoptions = [];
  cips.map((cip) => {
    cipoptions.push({ value: cip.id, label: cip.cipname });
  });

  const clinicsoptions = [];
  clinics.map((clinics) => {
    clinicsoptions.push({ value: clinics.id, label: clinics.clinicname });
  });

  const hospitalsoptions = [];
  hospitals.map((hospitals) => {
    hospitalsoptions.push({
      value: hospitals.id,
      label: hospitals.hospitalsname,
    });
  });

  const specializationsoptions = [];
  doctorspecialization.map((special) => {
    specializationsoptions.push({
      value: special.id,
      label: special.specialization,
    });
  });

  const options = [];

  areas.map((area) => {
    options.push({ value: area.id, label: area.areaname });
  });

  useEffect(() => {
    // Fetch data from an API when the component mounts


    const getallareas = async () => {
      let areas = await GetAllAreasDetail();
      if (areas) {
        setAreas(areas);
      } else {
        alert("Error", "Issue While Fetching Territories");
      }
    };
    getallareas();

    const getallcountries = async () => {
      let countriesdata = await GetAllCountries();
      if (countriesdata) {
        setCountries(countriesdata);

      } else {
        alert("Error", "Issue While Fetching Countries");
      }
    };
    getallcountries();


  }, [show]);

  useEffect(() => {
    if (country) {
      const getallstates = async () => {
        let statesdata = await GetAllStatesByCountry(country);
        if (statesdata) {
          setStates(statesdata);
          isState(true)
        } else {
          alert("Error", "Issue While Fetching State");
        }
      };
      getallstates();
    }



  }, [country])

  useEffect(() => {
    if (state) {
      const getallcitys = async () => {
        let citysdata = await GetAllCitiesByState(state);
        if (citysdata) {
          setCitys(citysdata);
        } else {
          alert("Error", "Issue While Fetching City");
        }
      };
      getallcitys();
    }
  }, [state])

  useEffect(() => {
    // const checkDoctorNameAvailable = async (doctorname) => {
    //   if (doctorname.length > 0) {
    //     let statedata = await CheckDoctorNameAvailable(doctorname);
    //     if (statedata) {
    //       setIsDoctorName(true);
    //     } else {
    //       setIsDoctorName(false);
    //     }
    //   }
    // };
    // checkDoctorNameAvailable(doctorname);
  }, [doctorname]);

  useEffect(() => {
    const checkDoctorCodeAvailable = async (doctorcode) => {
      if (doctorcode.length > 0) {
        let statedata = await CheckDoctorCodeAvailable(doctorcode);
        if (statedata) {
          setIsDoctorCode(true);
        } else {
          setIsDoctorCode(false);
        }
      }
    };
    checkDoctorCodeAvailable(doctorcode);
  }, [doctorcode]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setIsDoctorName(false);
    setShow(true);
  };

  const handleUploaderShow = () => setShowUploader(true);
  const handleUploaderClose = () => setShowUploader(false);

  const handleEditShow = (row) => {
    setDoctorName(row.doctorname);
    let ar = [];
    let arid = [];
    if (row.areas) {
      row.areas.map((area) => {
        ar.push({ value: area.id, label: area.areaname });
        arid.push(area.id);
      });
    }

    setSelectedAreas(ar);
    setDoctorCode(row.doctorcode);
    setDoctorAadhar(row.doctoraadhar);
    setAreasid(arid);

    let de = [];
    let deid = [];

    if (row.degrees) {
      row.degrees.map((degree) => {
        de.push({ value: degree.id, label: degree.degree });
        deid.push(degree.id);
      });
    }

    setSelectedDegrees(de);
    setDegreeId(deid);

    let spec = [];
    let specid = [];
    if (row.specializations) {
      row.specializations.map((special) => {
        spec.push({
          value: special.id,
          label: special.specialization,
        });
        specid.push(special.id);
      });
    }

    setSelectedSpecializations(spec);
    setSpecializationId(specid);

    const ciip = [];
    const ciipid = [];
    if (row.cips) {
      row.cips.map((cip) => {
        ciip.push({ value: cip.id, label: cip.name });
        ciipid.push(cip.id);
      });
    }

    setSelectedCips(ciip);
    setCipId(ciipid);

    const clnc = [];
    const clncid = [];
    if (row.clinics) {
      row.clinics.map((clinics) => {
        clnc.push({ value: clinics.id, label: clinics.name });
        clncid.push(clinics.id);
      });
    }

    setSelectedClinics(clnc);
    setClinicId(clncid);

    const hspt = [];
    const hsptid = [];
    if (row.hospitals) {
      row.hospitals.map((hospitals) => {
        hspt.push({ value: hospitals.id, label: hospitals.hospitalname });
        hsptid.push(hospitals.id);
      });
    }

    setSelectedhospital(hspt);
    setHospitalId(hsptid);

    setEditDocId(row.id);
    setDoctorAddress(row.address);
    setState(row.stateid);
    setCity(row.city);
    setPincode(row.pincode);
    setCountry(row.countryid);
    setNumber(row.phone);
    setEmail(row.email);
    setClinicId(row.id);
    setShowEdit(true);
  };
  const handleEditClose = () => {
    setDoctorName("");
    setSelectedAreas([]);
    setAreasid([]);
    setDoctorAddress("");
    setState("");
    setCity("");
    setPincode("");
    setCountry("");
    setNumber("");
    setEmail("");
    setShowEdit(false);
  };

  const handleCountryCodeChange = (event) => {
    setCountry(event.target.value);
    setIsCountry(true);
  };

  const handleDoctorNameChange = (event) => {
    setDoctorName(event.target.value);
  };

  const handleDoctorCodeChange = (event) => {
    setDoctorCode(event.target.value);
  };

  const handleDoctorAadharChange = (event) => {
    setDoctorAadhar(event.target.value);
  };

  const handleSelectChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedAreas(selected);
    setAreasid(values);
    setIsAreas(true);
  };

  const handleSelectDocDegreeChange = (selected) => {
    const values = selected.map((option) => option.value);
    setSelectedDegrees(selected);
    setDegreeId(values);
  };

  const handleSelectSpecializationChange = (selected) => {
    const values = selected.map((option) => option.value);
    setSelectedSpecializations(selected);
    setSpecializationId(values);
  };

  const handleSelectCipChange = (selected) => {
    const values = selected.map((option) => option.value);
    setSelectedCips(selected);
    setCipId(values);
  };
  const handleSelectClinicChange = (selected) => {
    const values = selected.map((option) => option.value);
    setSelectedClinics(selected);
    setClinicsId(values);
  };
  const handleSelectHospitalChange = (selected) => {
    const values = selected.map((option) => option.value);
    setSelectedhospital(selected);
    setHospitalId(values);
  };

  const handleClinicAddressChange = (event) => {
    setDoctorAddress(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
    setIsState(true)
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
    setIsCity(true)
  };

  const handlePincodeChange = (event) => {
    setPincode(event.target.value);
  };

  const handleNumberChange = (event) => {
    setNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  let createDoctor = async (
    areasid,
    doctorcode,
    doctorname,
    doctoraadhar,
    degreeid,
    specializationid,
    doctoraddress,
    city,
    pincode,
    cipid,
    clinicsid,
    hospitalid,
    number,
    email
  ) => {
    let statedata = await CreateDoctor(
      areasid,
      doctorcode,
      doctorname,
      doctoraadhar,
      degreeid,
      specializationid,
      doctoraddress,
      city,
      pincode,
      cipid,
      clinicsid,
      hospitalid,
      number,
      email
    );
    if (statedata) {
      SetReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: "Doctor Added Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Adding Doctor");
    }
  };

  let handleSave = (event) => {

    createDoctor(
      areasid,
      doctorcode,
      doctorname,
      doctoraadhar,
      degreeid,
      specializationid,
      doctoraddress,
      city,
      pincode,
      cipid,
      clinicsid,
      hospitalid,
      number,
      email
    );

  };

  let updateClinic = async (
    editdocid,
    areasid,
    doctorcode,
    doctorname,
    doctoraadhar,
    degreeid,
    specializationid,
    doctoraddress,
    city,
    pincode,
    cipid,
    clinicsid,
    hospitalid,
    number,
    email
  ) => {
    let statedata = await UpdateDoctor(
      editdocid,
      areasid,
      doctorcode,
      doctorname,
      doctoraadhar,
      degreeid,
      specializationid,
      doctoraddress,
      city,
      pincode,
      cipid,
      clinicsid,
      hospitalid,
      number,
      email
    );
    if (statedata) {
      SetReload(true);
      setShowEdit(false);

      Swal.fire({
        title: "Success!",
        text: "Doctor Updated Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Updating Doctor");
    }
  };

  let deleteClinic = async (clinicid) => {
    let statedata = await DeleteDoctor(clinicid);
    if (statedata) {
      SetReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: "Doctor Deleted !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Deleting Doctor");
    }
  };

  let handleUpdate = (event) => {
    updateClinic(
      editdocid,
      areasid,
      doctorcode,
      doctorname,
      doctoraadhar,
      degreeid,
      specializationid,
      doctoraddress,
      city,
      pincode,
      cipid,
      clinicsid,
      hospitalid,
      number,
      email
    );
  };

  let handleBulkAreaUpdate = async () => {

    let newData = bulkSourceData.map((data) => {

      return {
        id: data.id,
        areas: Helper.GetIdsFromObj(data.areas),
        doctorcode: data.doctorcode,
        doctorname: data.doctorname,
        doctoraadhar: data.doctoraadhar,
        degrees: Helper.GetIdsFromObj(data.degrees),
        users: Helper.GetIdsFromObj(data.users),
        specializations: Helper.GetIdsFromObj(data.specializations),
        address: data.address,
        city: data.city,
        pincode: data.pincode,
        dateofbirth: data.dateofbirth,
        aniversarydate: data.aniversarydate,
        cips: Helper.GetIdsFromObj(data.cips),
        hospitals: Helper.GetIdsFromObj(data.hospitals),
        clinics: Helper.GetIdsFromObj(data.clinics),
        focusedproducts: Helper.GetIdsFromObj(data.focusedproducts),
        deleted: data.deleted,
        disabled: data.disabled,
        createdby: data.createdby,
      };
    });




    let response = await DoctorBulkUpdateArea(newData);
    if (response) {
      SetReload(true);
      setPending(true);
      setShowBulkAreaEdit(false);
      handleClearRows();
      Swal.fire({
        title: "Success!",
        text: "Doctors Updated !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Updating Doctors");
    }
  };

  let handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete the Doctor?",

      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteClinic(id);
      }
    });
  };

  async function downloadCSV(doctors) {
    let csv;
    doctors.forEach(doctor => {
      if (doctor.cips && Array.isArray(doctor.cips)) {
        doctor.cips = doctor.cips.map(cip => `${cip.name}`).join(', ');
      }
      if (doctor.clinics && Array.isArray(doctor.clinics)) {
        doctor.clinics = doctor.clinics.map(cip => `${cip.name}`).join(', ');
      }
    });
    try {
      const opts = {
        fields: [
          "id",
          "doctorname",
          "areas_areaname",
          "users_username",
          "doctorcode",
          "doctoraadhar",
          "degrees_degree",
          "specializations_specialization",
          "address",
          "city",
          "pincode",
          "dateofbirth",
          "anniversary",
          "cips",
          "hospitals_hospitalname",
          "clinics",
          "phone",
          "email",
          "focusedproducts",
          "deleted",
          "disabled",
          "createdby",
          "createdat",
          "updatedat",
        ],
        transforms: [
          unwind({
            paths: [
              "areas",
              "degrees",
              "hospitals",
              "specializations",
              "users",
            ],
          }),
          flatten({ object: false, array: true, separator: "_" }),
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(doctors);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(doctors)} />,
    [doctors]
  );

  //upload excels
  let createExcelClinic = async (jsonData) => {
    let statedata = await CreateExcelDoctor(jsonData);
    if (statedata) {
      SetReload(true);
      setShowUploader(false);

      Swal.fire({
        title: "Success!",
        text: "Doctors Added Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Fetching Doctors");
    }
  };

  // Upload excel
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      setExcelData(sheetData);
      setFileUploaded(true);
    };

    reader.readAsArrayBuffer(file);
  };

  // export excel
  const handleExcelExport = () => {
    Swal.fire({
      title: "Exporting!",
      text: "Exporting Excel !!",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading()
        let d = await GetAllDoctors();
        if (d) {
          Swal.hideLoading()
          Swal.close()
          downloadCSV(d);
        }

      }
    });
  }

  const handleTitleChange = (event) => {
    const { name, value } = event.target;
    setColumnTitles({ ...columnTitles, [name]: value });
  };

  const handleAddData = () => {
    const newData = [];
    excelData.forEach((row) => {
      const newRow = {
        doctorname: row[columnTitles.title1],
        address: row[columnTitles.title2],
        pincode: row[columnTitles.title3],
        phone: row[columnTitles.title4],
        email: row[columnTitles.title5],
      };
      newData.push(newRow);
    });
    createExcelClinic(newData);
  };

  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => <span >{row.id}</span>,
    //   width:"3%",
     
    //   sortable: true,
    // },
    {
      name: "Doctors",
      selector: (row) => 
      <div style={{ whiteSpace: 'normal',backgroundColor: row.doctorname === "" || row.doctorname === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.doctorname === "" || row.doctorname === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.doctorname === "" || row.doctorname === null ? "#fff": "#2457C5", margin:"3px", }}>{row.doctorname ? row.doctorname : "N/A"}</div>
     ,
     width:"12%",
      sortable: true,
    
     
    },
    {
      name: "Clinics",
      selector: (row) => 
      
      <div style={{ whiteSpace: 'normal' }}>{row.clinics != null && row.clinics.length > 0 ? row.clinics.map((hobby) => (
         <Button style={{padding:1,fontSize:'11px',fontWeight:'bold',margin:3,padding:2,backgroundColor:"#D9E1FF",borderRadius:"10px",border: "2px solid #28B3D2",color:'#2457C5'}} >
         {hobby.name}
    </Button>
        

      )) : <div style={{ whiteSpace: 'normal',backgroundColor:"red", borderRadius:"10px", border: "2px solid red",padding:"3px", display:"flex", flexDirection:"row",color:"#fff", margin:"3px", }}>N/A</div>}</div>
      ,
      
      width:"12%",
      sortable: true,
    },
    {
      name: "Hospitals",
      selector: (row) => 
      <div style={{ whiteSpace: 'normal' }}>{row.hospitals.length > 0 ? row.hospitals.map((hobby) => (
 <Button style={{padding:1,fontSize:'11px',fontWeight:'bold',margin:3,padding:2,backgroundColor:"#D9E1FF",borderRadius:"10px",border: "2px solid #28B3D2",color:'#2457C5'}} key={hobby.id}>
        <Badge style={{color:"#fff", borderRadius:"5px",backgroundColor:"#2457C5",padding:"3px",}} >{hobby.id}</Badge> {hobby.hospitalname}
    </Button>

        /* <div style={{ whiteSpace: 'normal',backgroundColor:"#D9E1FF", borderRadius:"10px", border: "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:"#2457C5", margin:"3px", }}><div style={{color:"#fff", borderRadius:"5px",backgroundColor:"#2457C5",padding:"1px",}}>
         
          {hobby.id}
          </div>&nbsp;{ hobby.hospitalname}</div> */

      )) : <div style={{ whiteSpace: 'normal',backgroundColor:"red", borderRadius:"10px", border: "2px solid red",padding:"3px", display:"flex", flexDirection:"row",color:"#fff", margin:"3px", }}>N/A</div>}</div>,
      width: '18%',
      sortable: true,
    },
    {
      name: "Users",
      selector: (row) =>
      <div style={{ whiteSpace: 'normal' }}>{ row.users.map((hobby) => (
        <Button style={{padding:1,fontSize:'11px',fontWeight:'bold',margin:3,padding:2,backgroundColor:"#D9E1FF",borderRadius:"10px",border: "2px solid #28B3D2",color:'#2457C5'}} >{hobby.username}
        
    </Button>
     ))}</div>,
      
     
      sortable: true,
      width:"13%"
    },
    {
      name: "Created By",
      selector: (row) => 
      <div style={{ whiteSpace: 'normal',backgroundColor: row.createdbyuser === "" || row.createdbyuser === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.createdbyuser === "" || row.createdbyuser === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.createdbyuser === "" || row.createdbyuser === null ? "#fff": "#2457C5", margin:"3px", }}>{row.createdbyuser ? row.createdbyuser : "N/A"}</div>,
      width:"8%",
      sortable: true,
    },
    {
      name: "Territories",
      selector: (row) =>
<div style={{ whiteSpace: 'normal' }}>{row.areas.map((hobby) => (
        <Button style={{padding:1,fontSize:'11px',fontWeight:'bold',margin:3,padding:2,backgroundColor:"#D9E1FF",borderRadius:"10px",border: "2px solid #28B3D2",color:'#2457C5'}} key={hobby.id}>
        <Badge style={{color:"#fff", borderRadius:"5px",backgroundColor:"#2457C5",padding:"3px",}} >{hobby.id}</Badge> {hobby.areaname}
    </Button>
     ))}</div>,

        
      sortable: true,
      width:"10%",
    },

    {
      name: "Specialization",
      selector: (row) =>
        row.specializations &&
        row.specializations.map((hobby) => (
          <div style={{backgroundColor:"#D9E1FF", borderRadius:"10px", border: "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:"#2457C5", }}  > 
          <div style={{color:"#fff", borderRadius:"5px",backgroundColor:"#2457C5",padding:"1px",}}>
         
          {hobby.id}
          </div> &nbsp; {hobby.specialization}
        </div>
          
        )),
      sortable: true,
      width:"10%"
    },



  ];

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  if (Op_per.get().MASTERS_DOCTORS.ALLOW_DELETE || Op_per.get().MASTERS_DOCTORS.ALLOW_EDIT) {
    columns.push({
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>
          <AiFillDelete
            onClick={() => handleDelete(row.id)}
            style={{ color: "red", fontSize: 20,display:Op_per.get().MASTERS_DOCTORS.ALLOW_DELETE ? '' : 'none' }}
          />
          &nbsp;&nbsp;&nbsp;
          <AiTwotoneEdit
            onClick={() => handleEditShow(row)}
            style={{ color: "black", fontSize: 20,display:Op_per.get().MASTERS_DOCTORS.ALLOW_EDIT ? '' : 'none' }}
          />
        </div>
      ),
    })
  }


  // A super simple expandable component.
  const ExpandedComponent = ({ data }) => (
    <div style={{
      backgroundColor: data.disabled ? "red" : "#D9E1FF",
      color: "#fff",
      padding: 10,
      fontSize: 12,
      margin:20,
      borderRadius:20,
      border:"2px solid #28B3D2",
      width:"95%"
      
    }}>
      <Table
        // striped
        // hover
        
        size="sm"
        // style={{
        //   backgroundColor: data.disabled ? "red" : "#D9E1FF",
        //   color: "#fff",
        //   padding: 10,
        //   fontSize: 12,
        //   margin:20,
        //   borderRadius:20,
        //   border:"2px solid #28B3D2",
        //   width:"95%"
          
        // }}
      >
      <tr>
        <th style={{fontWeight:"bold", color:"#000", padding:10, width:"10%"}} >Doctors</th>
        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10, width:"30%" }}> <span  style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}>{data.doctorname}</span> </td>
                        <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%" }}>Users</th>
                        <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10 ,width:"30%"}}>{data.users != null ? (
                  data.users.map((user) => (
                    <span
                      style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",margin:"3px",color:"#fff", fontWeight:"bold"
                       }}
                    >
                      {user.username}
                    </span>
                  ))
                ) : (
                  <span
                    style={{
                        backgroundColor:"red", borderRadius:"10px", padding:"5px",color:"#fff", fontWeight:"bold",border:"2px solid red"
                       }}
                  >
                    N/A
                  </span>
                )}</td>
    </tr>

    <tr>
         
                <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%"}}>Doctor's Code</th>
               <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }} > <span  style={{
                       backgroundColor: data.doctorcode === "" || data.doctorcode === null ? "red":"#2457C5", borderRadius:"10px", border: data.doctorcode === "" || data.doctorcode === null ? "2px solid red": "2px solid #28B3D2",padding:"5px",margin:"3px",color:"#fff", fontWeight:"bold"
                       }}> {data.doctorcode === "" || data.doctorcode === null
                ? "N/A"
                : data.doctorcode}</span></td>

                <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%"}}>Degrees </th>
       <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}> {data.degrees != null && data.degrees.length > 0 ? (
                  data.degrees.map((are) => (
                    <span
                      style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold",margin:"3px"
                       }}
                    >
                      {are.degree}
                    </span>
                  ))
                ) : (
                  <span
                   style={{
                    backgroundColor:"red", borderRadius:"10px", padding:"5px",color:"#fff", fontWeight:"bold",margin:"3px", border:"2px solid red"
                       }}
                  >
                  
                    N/A
                  </span>
                )}</td>
    </tr>

    <tr>
        <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%"}}>Doctor Aadhar</th>

       <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}> <span style={{
                       backgroundColor: data.doctoraadhar === "" || data.doctoraadhar === null ? "red":"#2457C5", borderRadius:"10px", border: data.doctoraadhar === "" || data.doctoraadhar === null ? "2px solid red": "2px solid #28B3D2",padding:"5px",margin:"3px",color:"#fff", fontWeight:"bold"
                       }}> {data.doctoraadhar === "" || data.doctoraadhar === null
                ? "N/A"
                : data.doctoraadhar} </span></td>
                <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%"}}>Specializations </th>
               <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}>{data.specializations != null ? (
                  data.specializations.map((are) => (
                    <span
                     style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}>
                    
                      {are.specialization}
                    </span>
                  ))
                ) : (
                  <span
                   style={{
                    backgroundColor:"red", borderRadius:"10px", padding:"5px",color:"#fff", fontWeight:"bold",border:"2px solid red"
                       }}>
                    N/A
                  </span>
                )}</td>   </tr>

    <tr>
    
        <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%"}}>Pincode </th>
       <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}> <span style={{
                       backgroundColor:data.pincode === "" || data.pincode === null ? "red":"#2457C5", borderRadius:"10px", border: data.pincode === "" || data.pincode === null ? "2px solid red": "2px solid #28B3D2",padding:"5px",margin:"3px",color:"#fff", fontWeight:"bold"
                       }}>{data.pincode === "" || data.pincode === null
                ? "N/A"
                : data.pincode} </span></td>

                <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%"}}>City </th>
               <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}> <span style={{
                        backgroundColor:"#2457C5" === "" || data.city === null ? "red":"#2457C5", borderRadius:"10px", border: data.city === "" || data.city === null ? "2px solid red": "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold"
                       }}> {data.city === "" || data.city === null ? "N/A" : data.city} </span></td>
    </tr>
   
    <tr>
        <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%"}}>Date Of Birth</th>
       <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}> <span style={{
                       backgroundColor:data.dateofbirth === "" || data.dateofbirth === null ? "red":"#2457C5", borderRadius:"10px", border: data.dateofbirth === "" || data.dateofbirth === null ? "2px solid red": "2px solid #28B3D2",padding:"5px",margin:"3px",color:"#fff", fontWeight:"bold"
                       }}> {data.dateofbirth === "" || data.dateofbirth === null
                ? "N/A"
                : data.dateofbirth} </span></td>
                <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%"}}>Anniversary Date </th>
               <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}> <span style={{
                       backgroundColor:data.aniversarydate === "" || data.aniversarydate === null ? "red":"#2457C5", borderRadius:"10px", border: data.aniversarydate === "" || data.aniversarydate === null ? "2px solid red": "2px solid #28B3D2",padding:"5px",margin:"3px",color:"#fff", fontWeight:"bold"
                       }}>{data.aniversarydate === "" || data.aniversarydate === null
                ? "N/A"
                : data.aniversarydate}</span></td>
    </tr>
    
    
    <tr>
        <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%"}}>Clinics </th>
       <td style={{ width:"10%"}}>:</td>
        <td  style={{ padding:10,width:"30%" }}>{data.clinics != null && data.clinics.length > 0 ? (
                  data.clinics.map((are) => (
                    <span
                     style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold", margin: 3
                       }}
                    >
                     {are.name}
                    </span>
                  ))
                ) : (
                  <span
                    style={{
                      backgroundColor:"red", borderRadius:"10px", border:"2px solid red", padding:"5px",color:"#fff", fontWeight:"bold",margin:"3px"
                       }}
                  >
                    N/A
                  </span>
                )}</td>
                <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%"}}>Hospitals </th>
               <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}>{data.hospitals != null ? (
                  data.hospitals.map((are) => (
                    <span
                     style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold", margin: 3
                       }}
                    >
                      {are.hospitalname}
                    </span>
                  ))
                ) : (
                  <span
                    style={{
                      backgroundColor:"red", borderRadius:"10px", padding:"5px",color:"#fff", fontWeight:"bold", border:"2px solid red"
                       }}
                  >
                    N/A
                  </span>
                )}</td>
    </tr>
    
    <tr>
        <th  style={{fontWeight:"bold", color:"#000" , padding:10, width:"10%"}}>Email </th>
       <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}> <span
                     style={{
                     backgroundColor: data.email === "" || data.email === null ? "red":"#2457C5", borderRadius:"10px", border: data.email === "" || data.email === null ? "2px solid red": "2px solid #28B3D2",padding:"5px",margin:"3px",color:"#fff", fontWeight:"bold"
                       }}
                    >{data.email === "" || data.email === null ? "N/A" : data.email}</span></td>


                    <th  style={{fontWeight:"bold", color:"#000" , padding:10,width:"10%"}}>Phone </th>
                   <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}><span
                     style={{
                     backgroundColor: data.phone === "" || data.phone === null ? "red":"#2457C5", borderRadius:"10px", border: data.phone === "" || data.phone === null ? "2px solid red": "2px solid #28B3D2",padding:"5px",margin:"3px",color:"#fff", fontWeight:"bold"
                       }}
                    > {data.phone === "" || data.phone === null ? "N/A" : data.phone}</span></td>
    </tr>
   
    {/* <tr  >
        <th  style={{fontWeight:"bold", color:"#000" , padding:10}}>Degrees </th>
        <td>:</td>
        <td style={{ padding:10 }}> {data.degrees != null && data.degrees.length > 0 ? (
                  data.degrees.map((are) => (
                    <span
                      style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold",margin:"3px"
                       }}
                    >
                      {are.degree}
                    </span>
                  ))
                ) : (
                  <span
                   style={{
                    backgroundColor:"red", borderRadius:"10px", padding:"5px",color:"#fff", fontWeight:"bold",margin:"3px", border:"2px solid red"
                       }}
                  >
                  
                    N/A
                  </span>
                )}</td>
    </tr> */}
        {/* <tbody >
          <tr>
            <td style={{ color: "#000", fontWeight: "bold" }}>Doctor Name :</td>
           
            <td style={{ color: "#fff" }}>{data.doctorname}</td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Users :</td>
           
            <td style={{ color: "#fff" }}>
              <ul style={{ listStyle: "none" }}>
                {data.users != null ? (
                  data.users.map((user) => (
                    <li
                      style={{
                        backgroundColor: "#000",
                        margin: 2,
                        padding: 2,
                        borderRadius: 10,
                      }}
                    >
                      {user.username}
                    </li>
                  ))
                ) : (
                  <li
                    style={{
                      backgroundColor: "#000",
                      margin: 2,
                      padding: 2,
                      borderRadius: 10,
                    }}
                  >
                    N/A
                  </li>
                )}
              </ul>
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Areas :</td>
           
            <td style={{ color: "#fff" }}>
              <ul style={{ listStyle: "none" }}>
                {data.areas != null ? (
                  data.areas.map((are) => (
                    <li
                      style={{
                        backgroundColor: "#000",
                        margin: 2,
                        padding: 2,
                        borderRadius: 10,
                      }}
                    >
                      {are.areaname}
                    </li>
                  ))
                ) : (
                  <li
                    style={{
                      backgroundColor: "#000",
                      margin: 2,
                      padding: 2,
                      borderRadius: 10,
                    }}
                  >
                    N/A
                  </li>
                )}
              </ul>
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Doctor Code :</td>
           
            <td style={{ color: "#fff" }}>
              {data.doctorcode === "" || data.doctorcode === null
                ? "N/A"
                : data.doctorcode}
            </td>
          </tr>
          <tr>
            <td style={{ color: "#000", fontWeight: "bold" }}>Doctor Aadhar :</td>
            
            <td style={{ color: "#fff" }}>
              {data.doctoraadhar === "" || data.doctoraadhar === null
                ? "N/A"
                : data.doctoraadhar}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>
              Specializations :
            </td>
            
            <td style={{ color: "#fff" }}>
              <ul style={{ listStyle: "none" }}>
                {data.specializations != null ? (
                  data.specializations.map((are) => (
                    <li
                      style={{
                        backgroundColor: "#000",
                        margin: 2,
                        padding: 2,
                        borderRadius: 10,
                      }}
                    >
                      {are.specialization}
                    </li>
                  ))
                ) : (
                  <li
                    style={{
                      backgroundColor: "#000",
                      margin: 2,
                      padding: 2,
                      borderRadius: 10,
                    }}
                  >
                    N/A
                  </li>
                )}
              </ul>
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Address :</td>
           
            <td style={{ color: "#fff" }}>
              {data.address === "" || data.address === null
                ? "N/A"
                : data.address}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Pincode :</td>
           
            <td style={{ color: "#fff" }}>
              {data.pincode === "" || data.pincode === null
                ? "N/A"
                : data.pincode}
            </td>
          </tr>
          <tr>
            <td style={{ color: "#000", fontWeight: "bold" }}>City :</td>
            
            <td style={{ color: "#fff" }}>
              {data.city === "" || data.city === null ? "N/A" : data.city}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Date Of Birth :</td>
           
            <td style={{ color: "#fff" }}>
              {data.dateofbirth === "" || data.dateofbirth === null
                ? "N/A"
                : data.dateofbirth}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>
              Aniversary Date :
            </td>
            
            <td style={{ color: "#fff" }}>
              {data.aniversarydate === "" || data.aniversarydate === null
                ? "N/A"
                : data.aniversarydate}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Cips :</td>
            
            <td style={{ color: "#fff" }}>
              <ul style={{ listStyle: "none" }}>
                {data.cips != null ? (
                  data.cips.map((are) => (
                    <li
                      style={{
                        backgroundColor: "#000",
                        margin: 2,
                        padding: 2,
                        borderRadius: 10,
                      }}
                    >
                      {are.name}
                    </li>
                  ))
                ) : (
                  <li
                    style={{
                      backgroundColor: "#000",
                      margin: 2,
                      padding: 2,
                      borderRadius: 10,
                    }}
                  >
                    N/A
                  </li>
                )}
              </ul>
            </td>
          </tr>

          <tr>
            <td style={{ color: "#000", fontWeight: "bold" }}>Clinics :</td>
            
            <td style={{ color: "#fff" }}>
              <ul style={{ listStyle: "none" }}>
                {data.clinics != null ? (
                  data.clinics.map((are) => (
                    <li
                      style={{
                        backgroundColor: "#000",
                        margin: 2,
                        padding: 2,
                        borderRadius: 10,
                      }}
                    >
                      {are.name}
                    </li>
                  ))
                ) : (
                  <li
                    style={{
                      backgroundColor: "#000",
                      margin: 2,
                      padding: 2,
                      borderRadius: 10,
                    }}
                  >
                    N/A
                  </li>
                )}
              </ul>
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Hospitals :</td>
           
            <td style={{ color: "#fff" }}>
              <ul style={{ listStyle: "none" }}>
                {data.hospitals != null ? (
                  data.hospitals.map((are) => (
                    <li
                      style={{
                        backgroundColor: "#000",
                        margin: 2,
                        padding: 2,
                        borderRadius: 10,
                      }}
                    >
                      {are.hospitalname}
                    </li>
                  ))
                ) : (
                  <li
                    style={{
                      backgroundColor: "#000",
                      margin: 2,
                      padding: 2,
                      borderRadius: 10,
                    }}
                  >
                    N/A
                  </li>
                )}
              </ul>
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Email :</td>
           
            <td style={{ color: "#fff" }}>
              {data.email === "" || data.email === null ? "N/A" : data.email}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Phone :</td>
           
            <td style={{ color: "#fff" }}>
              {data.phone === "" || data.phone === null ? "N/A" : data.phone}
            </td>
          </tr>
          <tr>
            <td style={{ color: "#000", fontWeight: "bold" }}>Degrees :</td>
           
            <td style={{ color: "#fff" }}>
              <ul style={{ listStyle: "none" }}>
                {data.degrees != null ? (
                  data.degrees.map((are) => (
                    <li
                      style={{
                        backgroundColor: "#000",
                        margin: 2,
                        padding: 2,
                        borderRadius: 10,
                      }}
                    >
                      {are.degree}
                    </li>
                  ))
                ) : (
                  <li
                    style={{
                      backgroundColor: "#000",
                      margin: 2,
                      padding: 2,
                      borderRadius: 10,
                    }}
                  >
                    N/A
                  </li>
                )}
              </ul>
            </td>
          </tr>
        </tbody> */}
      </Table>
    </div>
  );

  const selectedRowChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  // Toggle the state so React Data Table changes to clearSelectedRows are triggered
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  }

  const conditionalRowStyles = [
    {
      when: (row) => row.disabled === true,
      style: {
        backgroundColor: "red",
        color: "white",
      },
    },
  ];

  const handleBulkUpdateSourceData = () => {
    let newArr = [];

    selectedRows.map((obj) => {
      let option = {
        id: obj.id,
        doctorname: obj.doctorname,
        doctorcode: obj.doctorcode,
        doctoraadhar: obj.doctoraadhar,
        degrees: Helper.SetTableDataInDropdown(obj.degrees, "id", "degree"),
        specializations: Helper.SetTableDataInDropdown(
          obj.specializations,
          "id",
          "specialization"
        ),
        areas: Helper.SetTableDataInDropdown(obj.areas, "id", "areaname"),
        clinics: Helper.SetTableDataInDropdown(obj.clinics, "id", "name"),
        hospitals: Helper.SetTableDataInDropdown(
          obj.hospitals,
          "id",
          "hospitalname"
        ),
        address: obj.address,
        city: obj.city,
        pincode: obj.pincode,
        phone: obj.phone,
        email: obj.email,
        cips: Helper.SetTableDataInDropdown(obj.cips, "id", "name"), //TODO CIP LABEL
        users: Helper.SetTableDataInDropdown(obj.users, "id", "username"),
      };
      newArr.push(option);
      return true;
    });
    setBulkSourceData(newArr);
    setShowBulkAreaEdit(true);
  };

  const handleBulkDataDelete = async () => {

    let newIds = [];

    selectedRows.map((obj) => {
      newIds.push(obj.id)
    })

    let response = await BulkDeleteDoctor(newIds);
    if (response) {
      SetReload(true);

      Swal.fire({
        title: "Success!",
        text: "Doctor Area Deleted !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Deleting Data");
    }
  }

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 0,
          backgroundColor: "#3887BE",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ul id="breadcrumb">
            <li>
              <a href="/">
                <IoHomeOutline
                  style={{ color: "white", fontSize: 20 }}
                  
                />
              </a>
            </li>
            <li>
              <a href="/doctor-master">
                <span class="icon icon-beaker"> </span>Masters
              </a>
            </li>
            <li>
              <a href="/doctor-master">
                <span class="icon icon-beaker"> </span> Doctors
              </a>
            </li>
            {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
          </ul>
        </div>
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Form.Control
              style={{
                height: 46,
                width: 250,
                borderColor: "#28B3D2",
                borderWidth: 2,
                paddingHorizontal: 8,
                marginBottom: 16,
                borderTopLeftRadius: 20,
                borderBottomRightRadius: 20,
              }}
              placeholder="Search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {selectedRows.length > 0 &&
            Op_per.get().MASTERS_DOCTORS.ALLOW_EDIT ? (

              <div class="dropdown" style={{ marginRight: 10 }}>
                <button
                  class="btn btn-secondary"
                  type="button"
                  variant="warning"
                  title="Bulk Menu"
                  id="dropdown-basic"
                  style={{ backgroundColor: " #FFCA2C" }}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                <SiDatabricks color="#000" />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={handleBulkUpdateSourceData}>
                    <a class="dropdown-item"> Update</a>
                  </li>
                  <li onClick={handleBulkDataDelete}>
                    <a class="dropdown-item"> Delete</a>
                  </li>
                 
                </ul>
              </div>
   
            ) : (
              ""
            )}
            {Op_per.get().MASTERS_DOCTORS.ALLOW_ADD ||
            Op_per.get().MASTERS_DOCTORS.ALLOW_IMPORT_XLSX || Op_per.get().MASTERS_DOCTORS.ALLOW_EXPORT_XLSX ? (
              <div class="dropdown">
                <button
                  class="btn btn-secondary"
                  type="button"
                  style={{ backgroundColor: "#28B3D2" }}
                  title="Options"
                  id="dropdown-basic"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <HiDotsVertical />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={handleShow} style={{display:Op_per.get().MASTERS_DOCTORS.ALLOW_ADD ? '' : 'none'}}>
                    <a class="dropdown-item">Add</a>
                  </li>
                  <li onClick={handleUploaderShow} style={{display:Op_per.get().MASTERS_DOCTORS.ALLOW_IMPORT_XLSX ? '' : 'none'}}>
                    <a class="dropdown-item">Import XLSX</a>
                  </li>
                  <li onClick={handleExcelExport} style={{display:Op_per.get().MASTERS_DOCTORS.ALLOW_EXPORT_XLSX ? '' : 'none'}}>
                    <a class="dropdown-item">Export XLSX</a>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <DataTable
            columns={columns}
            data={doctors}
            selectableRows
            onSelectedRowsChange={selectedRowChange}
            clearSelectedRows={toggledClearRows}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            conditionalRowStyles={conditionalRowStyles}
            progressPending={pending}
            progressComponent={<LoadingComponent />}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            highlightOnHover
            // actions={actionsMemo}
            style={{ marginTop: 10, fontSize: 10 }}
            dense
          />
        </div>
      </div>

      <Modal show={show} backdrop="static" size="md" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Doctor </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Doctors <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: "#28B3D2",
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                onChange={handleDoctorNameChange}
                placeholder="Enter Doctor Name"
                value={doctorname}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Doctor's Code <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  backgroundColor: isdoctorcode ? "red" : "",
                  color: isdoctorcode ? "#fff" : "",
                  height: 46,

                  borderColor: "#28B3D2",
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                value={doctorcode}
                type="text"
                onChange={handleDoctorCodeChange}
                placeholder="Enter Doctor Code"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Doctor's Aadhar <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: "#28B3D2",
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                value={doctoraadhar}
                onChange={handleDoctorAadharChange}
                placeholder="Enter Doctor Aadhar"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Degrees <span style={{color:"red"}}>*</span></Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={degreeoptions}
                value={selectedDegrees}
                onChange={handleSelectDocDegreeChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Specializations <span style={{color:"red"}}>*</span></Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={specializationsoptions}
                value={selectedspecializations}
                onChange={handleSelectSpecializationChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Address </Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: "#28B3D2",
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                value={doctoraddress}
                onChange={handleClinicAddressChange}
                placeholder="Enter Address"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Country <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select
                className="selectdoctor"
                onChange={handleCountryCodeChange}
                required
                style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
              >
                <option>---Select Country---</option>
                {countries.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>State <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select
                className="selectdoctor"
                onChange={handleStateChange}
                disabled={isCountry ? false : true}
                required
                style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
              >
                <option>---Select State---</option>
                {states.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select
                className="selectdoctor"
                onChange={handleCityChange}
                disabled={isState ? false : true}
                required
                style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
              >
                <option>---Select City---</option>
                {citys.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.city}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Pincode <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,
                  backgroundColor: "#fff",
                  borderColor: "#28B3D2",
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                onChange={handlePincodeChange}
                placeholder="Enter Pincode"
                value={pincode}
                required
                disabled={isCity ? false : true}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Territories <span style={{color:"red"}}>*</span></Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={options}
                value={selectedAreas}
                onChange={handleSelectChange}
                isMulti
                isDisabled={isCity ? false : true}
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>CIP's <span style={{color:"red"}}>*</span></Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={cipoptions}
                value={selectedcips}
                onChange={handleSelectCipChange}
                isMulti
                isDisabled={isAreas ? false : true}
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Clinics <span style={{color:"red"}}>*</span></Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={clinicsoptions}
                value={selectedclinics}
                onChange={handleSelectClinicChange}
                isMulti
                isDisabled={isAreas ? false : true}
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Hospitals <span style={{color:"red"}}>*</span></Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={hospitalsoptions}
                value={selectedhospital}
                onChange={handleSelectHospitalChange}
                isMulti
                isDisabled={isAreas ? false : true}
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Phone <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: "#28B3D2",
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="number"
                onChange={handleNumberChange}
                placeholder="Enter Phone"
                value={number}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: "#28B3D2",
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="email"
                onChange={handleEmailChange}
                value={email}
                placeholder="Enter Email"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Add Doctor
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showuploader} onHide={handleUploaderClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
            />
          </div>

          {fileUploaded && (
            <div>
              <hr />
              <div>
                <label style={{ paddingTop: 10 }} htmlFor="title1">
                  Doctor Name <span style={{color:"red"}}>*</span>
                </label>
                <Form.Select
                  className="selectdoctor"
                  id="title1"
                  name="title1"
                  value={columnTitles.title1}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label style={{ paddingTop: 10 }} htmlFor="title2">
                  Address <span style={{color:"red"}}>*</span>
                </label>
                <Form.Select
                  className="selectdoctor"
                  id="title2"
                  name="title2"
                  value={columnTitles.title2}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label style={{ paddingTop: 10 }} htmlFor="title3">
                  Pincode <span style={{color:"red"}}>*</span>
                </label>
                <Form.Select
                  className="selectdoctor"
                  id="title3"
                  name="title3"
                  value={columnTitles.title3}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label style={{ paddingTop: 10 }} htmlFor="title4">
                  Phone <span style={{color:"red"}}>*</span>
                </label>
                <Form.Select
                  className="selectdoctor"
                  id="title4"
                  name="title4"
                  value={columnTitles.title4}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label style={{ paddingTop: 10 }} htmlFor="title5">
                  Email <span style={{color:"red"}}>*</span>
                </label>
                <Form.Select
                  className="selectdoctor"
                  id="title5"
                  name="title5"
                  value={columnTitles.title5}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUploaderClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddData}>
            Add Data
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showedit} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Doctor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Doctor's ID: <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Label
                style={{
                  marginLeft: 10,
                }}
              >
                {editdocid}
              </Form.Label>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Doctors <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: "#28B3D2",
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                onChange={handleDoctorNameChange}
                placeholder="Enter Doctor Name"
                value={doctorname}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Doctor's Code <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: "#28B3D2",
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                value={doctorcode}
                type="text"
                onChange={handleDoctorCodeChange}
                placeholder="Enter Doctor Code"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Doctor's Aadhar <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: "#28B3D2",
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                value={doctoraadhar}
                onChange={handleDoctorAadharChange}
                placeholder="Enter Doctor Aadhar"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Degrees <span style={{color:"red"}}>*</span></Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={degreeoptions}
                value={selectedDegrees}
                onChange={handleSelectDocDegreeChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Specializations <span style={{color:"red"}}>*</span></Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={specializationsoptions}
                value={selectedspecializations}
                onChange={handleSelectSpecializationChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Areas <span style={{color:"red"}}>*</span></Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={options}
                value={selectedAreas}
                onChange={handleSelectChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>CIP's <span style={{color:"red"}}>*</span></Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={cipoptions}
                value={selectedcips}
                onChange={handleSelectCipChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Clinics <span style={{color:"red"}}>*</span></Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={clinicsoptions}
                value={selectedclinics}
                onChange={handleSelectClinicChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Hospitals <span style={{color:"red"}}>*</span></Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={hospitalsoptions}
                value={selectedhospital}
                onChange={handleSelectHospitalChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Address <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: "#28B3D2",
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                value={doctoraddress}
                onChange={handleClinicAddressChange}
                placeholder="Enter Address"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Country <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select
                className="selectdoctor"
                onChange={handleCountryCodeChange}
                required
                style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
              >
                <option>---Select Country---</option>
                {countries.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>State <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select
                className="selectdoctor"
                onChange={handleStateChange}
                required
                style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
              >
                <option>---Select State---</option>
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select
                className="selectdoctor"
                onChange={handleCityChange}
                required
                style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
              >
                <option>---Select City---</option>
                {citys.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.city}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Pincode <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: "#28B3D2",
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                onChange={handlePincodeChange}
                placeholder="Enter Pincode"
                value={pincode}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Phone <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: "#28B3D2",
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="number"
                onChange={handleNumberChange}
                placeholder="Enter Phone"
                value={number}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: "#28B3D2",
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="email"
                onChange={handleEmailChange}
                value={email}
                placeholder="Enter Email"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update Doctor
          </Button>
        </Modal.Footer>
      </Modal>

      {showbulkareaedit && (
        <Modal
          fullscreen={true}
          size="lg"
          show={true}
          onHide={() => setShowBulkAreaEdit(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Bulk Edit Doctor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="scrollable-div">
              <Table
                style={{ tableLayout: "auto", width: "100%" }}
                className="blazetable"
                striped
                bordered
                hover
                size="sm"
              >
                <thead className="sticky">
                  <tr style={{ width: "auto", whiteSpace: "nowrap" }}>
                    {Object.keys(bulkSourceData[0]).map((row, index) => {
                      return (
                        <th
                          className={
                            index === 0
                              ? "sticky-col1"
                              : index === 1
                              ? "sticky-col2"
                              : ""
                          }
                        >
                          {row}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {bulkSourceData.map((row, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        {Object.keys(row).map((cell, cellIndex) => {
                          return (
                            <td
                              style={{ width: "auto", whiteSpace: "nowrap" }}
                              key={cellIndex}
                              className={
                                cellIndex === 0
                                  ? "sticky-col1"
                                  : cellIndex === 1
                                  ? "sticky-col2"
                                  : ""
                              }
                            >
                              {cell === "areas" ||
                              cell === "cips" ||
                              cell === "clinics" ||
                              cell === "degrees" ||
                              cell === "hospitals" ||
                              cell === "specializations" ||
                              cell === "users" ? (
                                <div style={{ minWidth: "200px" }}>
                                  <Select
                                    closeMenuOnSelect={false}
                                    options={
                                      cell === "areas"
                                        ? options
                                        : cell === "cips"
                                        ? cipoptions
                                        : cell === "clinics"
                                        ? clinicsoptions
                                        : cell === "degrees"
                                        ? degreeoptions
                                        : cell === "hospitals"
                                        ? hospitalsoptions
                                        : cell === "users"
                                        ? userOptions
                                        : specializationsoptions
                                    }
                                    value={bulkSourceData[rowIndex][cell]}
                                    onChange={(selectedOption) => {
                                      const newValue = selectedOption;
                                      setBulkSourceData((prevData) => {
                                        const newData = [...prevData];
                                        newData[rowIndex][cell] = newValue;
                                        return newData;
                                      });
                                    }}
                                    isMulti
                                  />
                                </div>
                              ) : (
                                <input
                                  type="text"
                                  name={cell}
                                  value={
                                    bulkSourceData[rowIndex][cell] !== null &&
                                    bulkSourceData[rowIndex][cell] !== undefined
                                      ? bulkSourceData[rowIndex][cell]
                                      : ""
                                  }
                                  disabled={cell === "id"}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setBulkSourceData((prevData) => {
                                      const newData = [...prevData];
                                      newData[rowIndex][cell] = newValue;
                                      return newData;
                                    });
                                  }}
                                />
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowBulkAreaEdit(false)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleBulkAreaUpdate}>
              Update Doctors
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
}

export default Doctormaster;
