import React from 'react';
import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { GetUserTypes } from '../../utils/proxy/DashboardProxy';
import { useState } from 'react';
import { Row } from 'react-bootstrap';
import { VscGraph } from "react-icons/vsc";

const InternalExternalChart = () => {

    const [userCount, setUserCount] = useState([]);
    const [types, setTypes] = useState([]);

    useEffect(()=>{
        const getUserTypes = async() =>{
            let d = await GetUserTypes();
            let usercount = [];
            let types = [];
            d && d.map((data)=>{
                usercount.push(data.usercount);
                types.push(data.type);
            })
            setUserCount(usercount);
            setTypes(types);
        }
        getUserTypes();
    },[])

  const chartOptions = {
    chart: {
        id: 'bar-chart',
        stacked: true,
        // marginTop:10,
        offsetY:4,
    },
    colors: ['#38419D', '#200E3A'],
    xaxis: {
        categories: types,
        labels: {
            style: {
                fontSize: '10px', // Change the font size here
            },
        },
    },
    plotOptions: {
        bar: {
            horizontal: true,
        },
        dataLabels: {
            position: 'top', // Set the position of the data labels to display above the bars
            style: {
                fontSize: '12px', // Change the font size of the data labels
                colors: ['#ff0000'], // Change the color of the labels if needed (for data points)
            },
        },
    },
    stroke: {
        show: true,
        width: 1,
        colors: ['#fff'],
    },
    // title: {
    //     text: 'Internal/External User Summary',
    //     style:{
    //         fontSize:'13px',
    //     }
    // },
    legend:{
        show:true,
        
        position:'bottom', // to set series position above chart or below chart
    }
  };
  
  const series = [
    {
        name: 'External',
        data: [userCount[0],0],
    },
    {
        name: 'Internal',
        data:[0,userCount[1]],
    },
];

  return (

    <>
    <Row>
<div style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex', flexDirection: 'row', backgroundColor:'#FF8B13', borderRadius:20, padding:0,marginBottom:10, color:"white"    }}>
    <div md={4}>
        <div style={{ backgroundColor: '#f5dd4b', padding: '10px',marginRight:10, borderRadius: 50, height: 40, width: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <VscGraph color='#000' size={30} />
        </div>
    </div>
    <div md={4}>
    <h6 style={{textAlign:'center', marginBottom:0}}>Internal/External User</h6>
    </div>
    <div md={4}>
  
    </div>
    
</div>
</Row> 
      <div style={{backgroundColor:'white',padding:10}}>
      <Chart options={chartOptions} series={series} type="bar" width='100%' height={201} />
    </div>
    </>


  
   
  );
};

export default InternalExternalChart;
