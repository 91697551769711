import Restapi from "../api/Restapi";
import { constats } from "../constant";

export const GetAllNotificationByUserid = async (userid,status,limit) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/notification/userid/'+userid+'?status='+status+'&limit='+limit,null);
}

export const DeleteNotification = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/notification/delete/'+id,null);
}

// export const GetAllNotificationByUseridStatusAndLimit = async (userid) => {
//     const [AUTH_URL, REPORTING_URL] = await constats();
//     return await Restapi('get',REPORTING_URL+'/apiv1/notification/userid/'+userid,null);
// }

export const ReadAllNotification = async(userID)=>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/notification/deleteall/'+userID,null);    
}