import React, { useState,useEffect } from 'react';
import { Form ,Button  } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { CreateTaxConfiguration, GetAllTaxConfiguration } from '../../utils/proxy/TaxProxy';


function Taxconfiguration() {

  const [taxSupport, setTaxSupport] = useState(false);
  const handleTaxSupportChange = (e) => {
    setTaxSupport(e.target.checked);
  }

  const [taxNumber, setTaxNumber] = useState("");
  const handleTaxNumberChange = (e) => {
    setTaxNumber(e.target.value);
  }

  const [taxType, setTaxType] = useState("");
  const handleTaxTypeChange = (e) => {
    setTaxType(e.target.value);
  }

  const [nextInvoiceNumber, setnextInvoiceNumber] = useState(0);
  const handlenextInvoiceNumberChange = (e) => {
    setnextInvoiceNumber(e.target.value);
  }

  const [autoResetNumbering, setAutoResetNumbering] = useState("");
  const handleAutoResetNumberingChange = (e) => {
    setAutoResetNumbering(e.target.value);
  }
  
  const [customerFormat, setCustomerFormat] = useState("");
  const handleCustomerFormatChange = (e) => {
    setCustomerFormat(e.target.value);
  }

  const [customNumber, setCustomNumber] = useState(false);
  const handleCustomNumberChange = (e) => {
    setCustomNumber(e.target.checked);
  }

  

  useEffect(() => {
    const getAllTaxConfig = async () => {
    let allTaxConfiguration = await GetAllTaxConfiguration();
    if(allTaxConfiguration){
      setTaxSupport(allTaxConfiguration[0].taxsupport);
      setTaxNumber(allTaxConfiguration[0].taxnumber);
      setTaxType(allTaxConfiguration[0].taxtype);
      setnextInvoiceNumber(allTaxConfiguration[0].nextinvoicenumber);
      setAutoResetNumbering(allTaxConfiguration[0].autoresetnumbering);
      setCustomerFormat(allTaxConfiguration[0].customerinvoicenumberformat);
      setCustomNumber(allTaxConfiguration[0].custominvoicenumber)
    }
    
  }
  getAllTaxConfig();
  },[])
  
  const handleSave = async() => {
      
      if(taxNumber == null || taxNumber == ""){
        Swal.fire({
          title: "Warning!",
          text: "Enter the tax number",
          icon: "warning",
          confirmButtonText: "OK",
        });
      } else if(nextInvoiceNumber == null || nextInvoiceNumber == ""){
        Swal.fire({
          title: "Warning!",
          text: "Enter next Invoice Number",
          icon: "warning",
          confirmButtonText: "OK",
        });
      } else if(customerFormat == null || customerFormat == ""){
        Swal.fire({
          title: "Warning!",
          text: "Enter the customer Invoice Number Format",
          icon: "warning",
          confirmButtonText: "OK",
        });
      } else{
        let d = await CreateTaxConfiguration(taxSupport ,taxNumber, taxType, customNumber, customerFormat, nextInvoiceNumber,autoResetNumbering)

        if(d.status == "success"){
          Swal.fire({
            title: "Success",
            text: "Tax configuration created successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
        } else{
          Swal.fire({
            title: "error",
            text: "Something went wrong",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
        
      }
      
  }

  return (
    <div className='container'>
      <Form>
        <Form.Group style={{marginBottom: "10px", marginTop: "10px"}}>
          
          <Form.Check
            type='checkbox'
            label='Tax Support'
            checked= {taxSupport}
            onChange={handleTaxSupportChange} 
           />
        </Form.Group>

        <Form.Group style={{marginBottom: "10px"}}>
           <Form.Label>Tax Number</Form.Label>
            <Form.Control type="text" value={taxNumber} onChange={handleTaxNumberChange} />
          </Form.Group>

          <Form.Group style={{marginBottom: "10px"}}>
          <Form.Label>Tax type</Form.Label>
          <Form.Select value={taxType} onChange={handleTaxTypeChange}>
        <option value={"INCLUSIVE"}>INCLUSIVE</option>
        <option value={"EXCLUSIVE"}>EXCLUSIVE</option>
      </Form.Select>
    </Form.Group>

    <Form.Group style={{marginBottom: "10px"}}>
           <Form.Label>Next Invoice Number</Form.Label>
            <Form.Control type="number" value={nextInvoiceNumber} onChange={handlenextInvoiceNumberChange}/>
          </Form.Group>

      <Form.Group style={{marginBottom: "10px"}}>
      <Form.Label>Auto Reset Numbering</Form.Label>
      <Form.Select value={autoResetNumbering} onChange={handleAutoResetNumberingChange}>
        <option>YEARLY</option>
        <option>MONTHLY</option>
        <option>NEVER</option>
      </Form.Select>
    </Form.Group>


    <Form.Group style={{marginBottom: "10px"}}>
           <Form.Label>Customer Invoice Number Format</Form.Label>
            <Form.Control type="text" value={customerFormat} onChange={handleCustomerFormatChange} />
          </Form.Group>

          <Form.Group style={{marginBottom: "10px"}}>
          
          <Form.Check
            type='checkbox'
            id='customer-invoice-number'
            label='Custom Invoice Number'
            checked= {customNumber}
            onChange={handleCustomNumberChange}
          />
        </Form.Group>     

      <Form.Group style={{marginBottom: "10px"}}>
        <Button  onClick={handleSave}>SAVE</Button>
        </Form.Group>
</Form>
</div>
  );
}

export default Taxconfiguration;
