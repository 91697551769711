import React from 'react'
import { Container } from 'react-bootstrap'
import FloatingActionButton from '../Common/FloatingActionButton'

function Footer({ per }) {
  return (
    <Container fluid>
      <FloatingActionButton per={per}/>
      <div className='glass-body text-center'>
      &copy; Team Insights 2023 - 2024
    </div>
    </Container>
  )
}

export default Footer
