export default class Helper {
  static ConvertNewLineToComma = (inString) => {
    return inString ? inString.replace(/(?:\r\n|\r|\n)/g, ",") : "";
  };

  static ConvertLocalTimeToUtc = (date) => {
    return new Date(date).toISOString();
  };

  static ConvertUtcToLocalTime = (cellValue) => {
    if (!cellValue || cellValue === null) return "";

    let theDate = new Date(Date.parse(cellValue));
    return (
      theDate.toLocaleDateString("en-IN") + " " + theDate.toLocaleTimeString()
    );
  }; 

  static dateToIsoStringWithoutChange = (istDate) => {
    if (!(istDate instanceof Date)) {
      throw new Error("Input must be a Date object");
    }

    istDate.setMinutes(istDate.getMinutes() - istDate.getTimezoneOffset());

    const isoString = istDate.toISOString();

    return isoString;
  }

  static CheckIsEmail = (value) => {
    let validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return value.match(validEmailRegex);
  };

  static CheckIsAlphaNumeric = (value) => {
    let validRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;
    return value.match(validRegex);
  };

  static CheckIsNumber = (value) => {
    return !isNaN(value); // isNaN will check if it is not a number so when it return true we return false to identify it as a number
  };

  static CheckIsString = (value) => {
    return isNaN(value);
  };

  static CheckIsPhone = (input_str) => {
    let validPhoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return validPhoneRegex.test(input_str);
  };

  static CheckisNumberGreaterThanZero = (value) => {
    return this.CheckIsNumber(value) && value > 0;
  };

  static CheckisNumberPositive = (value) => {
    return this.CheckIsNumber(value) && value >= 0;
  };

  static CheckIsPresent = (value) => {
    return !(value === null || value === undefined || value.trim() === "");
  };

  static CheckIsNumberAndHyphen = (value) => {
    let validRegex = /^[0-9-]+$/;
    return validRegex.test(value);
  };

  static SetTableDataInDropdown = (arr, value, label) => {
    if (!arr || arr.length === 0) return;

    let optionArr = [];

    arr.map((opt) => {
      let option = { value: opt[value], label: opt[label] };
      optionArr.push(option);
    });
    return optionArr;
  };

  static GetIdsFromObj = (array) => {
    if (!array || array.length === 0) return [];

    return array.map((obj) => obj.value);
  };

  static AdjustTimezone = (date) => {
    return new Date(
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
    );
  };

  static toHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    return { hours, minutes };
  }

  static addLeadingZero(number){
    return number < 10 ? '0' + number : number.toString();
  }
}
