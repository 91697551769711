import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import Select from "react-select";
import { AllUsers, GetAllManagerUsers } from "../../../src/utils/proxy/Authproxy";
import { DeleteTourPlan, GetAllTourPlan, UpdateStatusTourPlan } from "../../../src/utils/proxy/Tourplanproxy";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from "sweetalert2";
import { Parser } from "@json2csv/plainjs";
import { unwind, flatten } from "@json2csv/transforms";
import { GetAllDcrYearlyMonthly } from "../../utils/proxy/ChartsProxy";
import Helper from "../../utils/helperFunctions";
import { IoHomeOutline } from "react-icons/io5";
import { RiFileExcel2Fill } from "react-icons/ri";
import { Op_per } from "../../utils/Permissions";

function Dcrmonthly() {

  const months = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = currentYear; i >= currentYear - 100; i--) {
    years.push({ value: i.toString(), label: i.toString() });
  }

  const [userstockit, setUserStockit] = useState([]);
  const [userid, setUserId] = useState(localStorage.getItem('role')==='User' ? localStorage.getItem('uuid') : "all");
  const [areatype, setAreaType] = useState("all");
  const [status, setStatus] = useState("PENDING_FOR_ADDITION,ACTIVE");
  const [reload, setReload] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [users, setUsers] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [plans, setPlans] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]); // Set the default month to '01'
  const [selectedYear, setSelectedYear] = useState(years[0]); // Set the default year to the current year

  const [currentid, setCurrentId] = useState();
  const [upstatus, setUpStatus] = useState("ACTIVE");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleStatusChange = (event) => {
    setUpStatus(event.target.value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallteritories = async (userid, selectedMonth, selectedYear) => {
      let userteritories = await GetAllDcrYearlyMonthly(
        userid,
        selectedMonth,
        selectedYear
      );

      if (userteritories) {
        setPlans(userteritories);
      } else {
        alert("Error", "Issue While Fetching Tour Plan");
      }
    };
    if (userid && selectedMonth.value && selectedYear.value) {
      getallteritories(userid, selectedMonth.value, selectedYear.value);
    }
    setReload(false);
  }, [reload, userid, showModal, selectedMonth, selectedYear]);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {
      if (localStorage.getItem('role') === 'Admin') {
        let allusers = await AllUsers();
        setUsers(allusers);
      } else if (localStorage.getItem('role') === 'Manager') {
        let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
        setUsers(allusers);
      }
    };
    getallusers();
  }, []);

  const options = [];

  users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      margin: 30,
      width: "8%",
    },
    {
      name: "Territories",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.teritories === "" || row.teritories === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.teritories === "" || row.teritories === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.teritories === "" || row.teritories === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.teritories ? row.teritories : "N/A"}</div>,
      width: "12%",
    },

    {
      name: "Work Type",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.worktype === "" || row.worktype === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.worktype === "" || row.worktype === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.worktype === "" || row.worktype === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.worktype ? row.worktype : "N/A"}</div>,
      width: "8%",

    },
    {
      name: "Start Day",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.startday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] === "" || row.startday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.startday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] === "" || row.startday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.startday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] === "" || row.startday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.startday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] ? row.startday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] : "N/A"}</div>,
      width: "8%",
    },
    {
      name: "End Day",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.endday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.endday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.endday).split(" ")[2] === "" || row.endday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.endday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.endday).split(" ")[2] === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.endday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.endday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.endday).split(" ")[2] === "" || row.endday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.endday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.endday).split(" ")[2] === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.endday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.endday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.endday).split(" ")[2] === "" || row.endday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.endday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.endday).split(" ")[2] === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.endday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.endday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.endday).split(" ")[2] ? row.endday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.endday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.endday).split(" ")[2] : "N/A"}</div>,
      width: "8%",
    },
    {
      name: <p>Planned<br />Doctor</p>,
      selector: (row) => row.planned_doctor,
      // <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_doctor=== "" || row.planned_doctor=== null || row.planned_doctor=== "N/A" ? "red":"#D9E1FF", borderRadius:"10px", border: row.planned_doctor=== "" || row.planned_doctor=== null || row.planned_doctor=== "N/A" ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_doctor=== "" || row.planned_doctor=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.planned_doctor? row.planned_doctor: "N/A"}</div>,
      width: "5%",
    },
    {
      name: <p>Unplanned<br />Doctor</p>,
      selector: (row) => row.unplanned_doctor,
      // <div style={{ whiteSpace: 'normal',backgroundColor: row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.unplanned_doctor? row.unplanned_doctor: "N/A"}</div> ,
      width: "5%",
    },
    {
      name: <p>Planned<br />Clinic</p>,
      selector: (row) => row.planned_clinic,

      // <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_clinic=== "" || row.planned_clinic=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.planned_clinic=== "" || row.planned_clinic=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_clinic=== "" || row.planned_clinic=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.planned_clinic? row.planned_clinic: "N/A"}</div>,
      width: "5%",
    },
    {
      name: <p>Unplanned<br />Clinic</p>,
      selector: (row) => row.unplanned_clinic,
      // <div style={{ whiteSpace: 'normal',backgroundColor: row.unplanned_clinic=== "" || row.unplanned_clinic=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.unplanned_clinic=== "" || row.unplanned_clinic=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.unplanned_clinic=== "" || row.unplanned_clinic=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.unplanned_clinic? row.unplanned_clinic: "N/A"}</div>,
      width: "5%",
    },
    {
      name: <p>Planned<br />Chemist</p>,
      selector: (row) => row.planned_chemist,
      // <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_chemist=== "" || row.planned_chemist=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.planned_chemist=== "" || row.planned_chemist=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_chemist=== "" || row.planned_chemist=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.planned_chemist? row.planned_chemist: "N/A"}</div> ,
      width: "5%",
    },
    {
      name: <p>Unplanned<br />Chemist</p>,
      selector: (row) => row.unplanned_chemist,
      // <div style={{ whiteSpace: 'normal',backgroundColor: row.unplanned_chemist=== "" || row.unplanned_chemist=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.unplanned_chemist=== "" || row.unplanned_chemist=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.unplanned_chemist=== "" || row.unplanned_chemist=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.unplanned_chemist? row.unplanned_chemist: "N/A"}</div> ,
      width: "5%",
    },
    {
      name: <p>Planned<br />Stockist</p>,
      selector: (row) => row.planned_stockist,
      //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_stockist=== "" || row.planned_stockist=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.planned_stockist=== "" || row.planned_stockist=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_stockist=== "" || row.planned_stockist=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.planned_stockist? row.planned_stockist: "N/A"}</div>  ,
      width: "5%",
    },
    {
      name: <p>Unplanned<br />Stockist</p>,
      selector: (row) => row.unplanned_stockist,
      // <div style={{ whiteSpace: 'normal',backgroundColor: row.unplanned_stockist=== "" || row.unplanned_stockist=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.unplanned_stockist=== "" || row.unplanned_stockist=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.unplanned_stockist=== "" || row.unplanned_stockist=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.unplanned_stockist? row.unplanned_stockist: "N/A"}</div> ,
      width: "5%",
    },
    {
      name: <p>Planned<br />Hospital</p>,
      selector: (row) => row.planned_hospital,
      //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_hospital=== "" || row.planned_hospital=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.planned_hospital=== "" || row.planned_hospital=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_hospital=== "" || row.planned_hospital=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.planned_hospital? row.planned_hospital: "N/A"}</div> ,
      width: "5%",
    },
    {
      name: <p>CIP<br />Met</p>,
      selector: (row) => row.cipmet,
      //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_hospital=== "" || row.planned_hospital=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.planned_hospital=== "" || row.planned_hospital=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_hospital=== "" || row.planned_hospital=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.planned_hospital? row.planned_hospital: "N/A"}</div> ,
      width: "5%",
    },
    {
      name: <p>Unplanned<br />Hospital</p>,
      selector: (row) => row.unplanned_hospital,
      //  <div style={{ whiteSpace: 'normal',backgroundColor: row.unplanned_hospital=== "" || row.unplanned_hospital=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.unplanned_hospital=== "" || row.unplanned_hospital=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.unplanned_hospital=== "" || row.unplanned_hospital=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.unplanned_hospital? row.unplanned_hospital: "N/A"}</div>,
      width: "5%",
    },
  ];

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  const handleEditShow = (row) => {
    setCurrentId(row.id);
    setShow(true)
  };

  let deleteTourProgram = async (row) => {


    let degreedata = await DeleteTourPlan(row);
    if (degreedata) {
      setReload(true);
      setShow(false)


      Swal.fire({
        title: 'Success!',
        text: 'Tour Program Deleted Successfully !!',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } else {
      alert('Error', 'Issue While Fetching Tour Program');
    }
  }

  const handleDelete = (row) => {
    deleteTourProgram(row);
  };

  const handleSelectChange = (selected) => {
    setSelectedUser(selected);
    setUserId(selected.value);
  };

  const handleSelectChangeMOnth = (selected) => {
    setSelectedMonth(selected);
  };

  const handleSelectChangeYear = (selected) => {

    setSelectedYear(selected);
  };

  let updateTourProgram = async (currentid, upstatus) => {

    let degreedata = await UpdateStatusTourPlan(currentid, upstatus);
    if (degreedata) {
      setReload(true);
      setShow(false)


      Swal.fire({
        title: 'Success!',
        text: 'Tour Program Update Successfully !!',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } else {
      alert('Error', 'Issue While Fetching Tour Program');
    }
  }

  const handleSave = () => {
    updateTourProgram(currentid, upstatus);
  }

  async function downloadCSV(plans) {
    let csv;
    try {
      const opts = {
        fields: [
          { label: "UserName", value: "username" },
          { label: "Date", value: "date" },
          { label: "Terotories", value: "teritories" },
          { label: "Work Type", value: "worktype" },
          { label: "Start Day", value: "startday" },
          { label: "End Day", value: "endday" },
          { label: "Planned Doctor", value: "planned_doctor" },
          { label: "Unplanned Doctor", value: "unplanned_doctor" },
          { label: "Planned Clinic", value: "planned_clinic" },
          { label: "Unplanned Clinic", value: "unplanned_clinic" },
          { label: "Planned Chemist", value: "planned_chemist" },
          { label: "Unplanned Chemist", value: "unplanned_chemist" },
          { label: "Planned Stockist", value: "planned_stockist" },
          { label: "Unplanned Stockist", value: "unplanned_stockist" },
          { label: "Planned Hospital", value: "planned_hospital" },
          { label: "Cip Met", value: "cipmet" },
          { label: "Unplanned Hospital", value: "unplanned_hospital" },
        ],
        transforms: [
          (row) => {
            if (row.startday) {
              row.startday = row.startday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2]; // Split and get the first part
            }
            if (row.endday) {
              row.endday = row.endday == "" ? "N/A" : Helper.ConvertUtcToLocalTime(row.endday).split(" ")[1] + " " + Helper.ConvertUtcToLocalTime(row.endday).split(" ")[2]; // Split and get the first part
            }
   
              row.username = row.username  ? "N/A" : selectedUser.label // Split and get the first part
           

            return row;
          },
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(plans);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = `DCRMonthly-${selectedMonth.value}/${selectedYear.value}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button style={{ position: 'absolute', right: 40, backgroundColor: "#28B3D2 ", display:Op_per.get().REPORTS_DCR_MONTHLY.ALLOW_EXPORT_XLSX ? '' : 'none' }} onClick={(e) => onExport(e.target.value)}><RiFileExcel2Fill /></Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(plans)} />,
    [plans]
  );

  const conditionalRowStyles = [
    {
      when: row => row.worktype == "Holiday",
      style: {
        backgroundColor: 'rgb(245, 185, 0,0.9)',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ]

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 0,
          backgroundColor: '#3887BE'
        }}
      >

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ul id="breadcrumb">
            <li><a href="/"><IoHomeOutline
              style={{ color: "white", fontSize: 20 }}
              
            /></a></li>
            <li><a href="/dailycallrecordmonthly"><span class="icon icon-beaker"> </span>Reports</a></li>

            <li><a href="#"><span class="icon icon-double-angle-right"></span>DCR (Monthly)</a></li>

          </ul>

        </div>

      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
          }}
        >
          <div style={{ width: "320px",display:Op_per.get().REPORTS_DCR_MONTHLY.USER_DROPDOWN ? '' : 'none' }}>
            <Form.Group
              className="mb-3 d-flex w-100"
              style={{ gap: "15px" }}
              controlId="formBasicPassword"
            >
              <Form.Label className="mt-2">Users</Form.Label>
              <div className="w-75">
                <Select
                  className="selectdoctor"
                  options={options}
                  value={selectedUser}
                  onChange={handleSelectChange}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                />
              </div>
            </Form.Group>
          </div>
          <div style={{ width: "350px" }}>
            <Form.Group
              className="mb-3 d-flex w-100"
              style={{ gap: "15px" }}
              controlId="formBasicPassword"
            >
              <Form.Label className="mt-2">Select Month</Form.Label>
              <div className="w-50">
                <Select
                  className="selectdoctor"
                  options={months}
                  value={selectedMonth}
                  onChange={handleSelectChangeMOnth}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                />
              </div>
            </Form.Group>
          </div>
          <div style={{ width: "450px" }}>
            <Form.Group
              className="mb-3 d-flex w-100"
              style={{ gap: "15px" }}
              controlId="formBasicPassword"
            >
              <Form.Label className="mt-2">Select Year</Form.Label>
              <div className="w-50">
                <Select
                  className="selectdoctor"
                  options={years}
                  value={selectedYear}
                  onChange={handleSelectChangeYear}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                />
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            columns={columns}
            data={plans}
            conditionalRowStyles={conditionalRowStyles}
            actions={actionsMemo}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
            style={{ margin: '10px', padding: 10 }}
            dense
          />
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Select
            className="selectdoctor"
            onChange={handleStatusChange}
            aria-label="Default select example"
          >
            <option value="ACTIVE">ACTIVE</option>
            <option value="PENDING_FOR_ADDITION">PENDING_FOR_ADDITION</option>
            <option value="PENDING_FOR_DELETION">PENDING_FOR_DELETION </option>
            <option value="REJECTED">REJECTED</option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Dcrmonthly;
