
import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

// Charts

export const GetAllDoctorMonths = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/monthlydoccounts", null);
};

export const GetAllClinicMonths = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/monthlycliniccounts", null);
};

export const GetAllHospitalMonths = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/monthlyhospitalcounts", null);
};

export const GetAllChemistMonths = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/monthlychemistcounts", null);
};

export const GetTopPerformer = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/top", null);
};

export const GetTopPerformerUnderManager = async (managerid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/manager/top?managerid="+managerid, null);
};

export const GetPreviousMonthsDCR = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/records", null);
};

export const GetPreviousMonthsDCRManager = async (managerid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/manager/records?managerid="+managerid, null);
};

export const GetPreviousMonthsDCRUser = async (userid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/user/records?userid="+userid, null);
};

export const GetDisabledUsers = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/disabled", null);
};

export const GetUserTypes = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/usertype", null);
};

export const GetWorkingUsers = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/user/working", null);
};

export const GetTaskProgression = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/taskprogression", null);
};

export const GetUserTaskProgression = async (userid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/user/taskprogression?userid="+userid, null);
};

export const GetDataForCardDash = async (userid,type) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/dashboard/card/${userid}/${type}`, null);
};