import { data } from "jquery";
import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

export const GetAttendance = async (id,start,end) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/reports/attendance?userid='+id+`&start=${start}&end=${end}`,null);
}

export const GetAttendanceSheet = async (start,end,userid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/reports/attendancesheet?start=${start}&end=${end}&userid=${userid}`,null);
}

export const GetDcrTask = async (id,date) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi('get',REPORTING_URL+'/apiv1/reports/attendancedcr?userid='+id+`&date=${date}`,null);
}

export const GetAllHoliday = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi('get',REPORTING_URL+'/apiv1/holiday',null);
}

export const GetAllHolidayPagination = async (page,size,search) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi('get',REPORTING_URL+'/apiv1/holiday/pagination?page='+page+'&size='+size+'&search='+search,null);
}

export const CreateHoliday = async (name,type,date) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  data = {
    name:name,
    type:type,
    date:date
  }
  return await Restapi('post',REPORTING_URL+'/apiv1/holiday',data);
}

export const UpdateHoliday = async (id,name,type,date) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  data = {
    name:name,
    type:type,
    date:date
  }
  return await Restapi('put',REPORTING_URL+'/apiv1/holiday/'+id,data);
}

export const DeleteHoliday = async (id) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi('delete',REPORTING_URL+'/apiv1/holiday/'+id,null);
}

export const GetAllHolidayType = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi('get',REPORTING_URL+'/apiv1/holidaytype',null);
}

export const CreateHolidayType = async (name, description) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  const data ={
    name : name,
    description : description
  }
  return await Restapi('post',REPORTING_URL+'/apiv1/holidaytype',data);
}

export const EditHolidayType = async (id,name, description) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  const data ={
    name : name,
    description : description
  }
  return await Restapi('put',REPORTING_URL+'/apiv1/holidaytype/'+id,data);
}

export const DeleteHolidayType = async (id) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi('delete',REPORTING_URL+'/apiv1/holidaytype/'+id,null);
}

export const GetAllTaskForCalendar = async (userid, date) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi('get',REPORTING_URL+'/apiv1/task/calendar?userid='+userid+"&date="+date,null);
}