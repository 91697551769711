import { Container } from "react-bootstrap"
import Chart from 'react-apexcharts';
import { getLeadSourcData } from "../../utils/proxy/ReportsProxy";
import { useEffect, useState } from "react";

export const DonutDemo = () => {

    const [names, setNames] = useState([]);
    const [count, setCount] = useState([]);

    useEffect(()=>{
        const getLeadData = async() =>{
            let d = await getLeadSourcData();
            let names = [];
            let series = [];
            if (d) {
              d && d.map(k =>{
                series.push(k.count);
                names.push(k.date)
              })
            setNames(names.reverse());
            setCount(series.reverse());
            }
          }
          getLeadData();
    },[])

    const options = {
        chart: {
            type: 'donut'
        },
        labels: names,
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: true,
                    }
                }
            }
        },
        // title: {
        //     text: "Leads By Sources",
        //     style: {
        //         fontSize: '13px',
        //     }
        // },
        legend: {
            show: true,
            position: 'bottom',
            markers: {
                width: 12,
                height: 12,
                radius: 2 // To make the legend marker square, set radius to 0
            }
        }
    }

    const series = count;

    return (
        <Container fluid>
            <div className="donut">
                <Chart options={options} width={420} series={series} type="donut" />
            </div>
        </Container>
    )
}