import {React ,  useEffect, useState} from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import { IoHomeOutline } from 'react-icons/io5'
import { AllUsers, GetAllManagerUsers } from '../../utils/proxy/Authproxy';
import Select from "react-select";
import DataTable from 'react-data-table-component';
import { getAllUsersPobBelowUser } from '../../utils/proxy/ReportsProxy';
import { Parser } from '@json2csv/plainjs';
import * as XLSX from 'xlsx';
import { RiFileExcel2Fill } from 'react-icons/ri';

function PobWiseReportForMonths() {

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);
    const [start,setStart] = useState();
    const [end,setEnd] = useState();
    const [data,setData] = useState([]);
    const [monthlength,setMonthLength] = useState(0);

    const handleSelectChange = (selected) => {

        setSelectedUser(selected);
        setUserId(selected.value);
    };


    const handleStartMonth = (value) => {
        setStart(value);
    }

    async function downloadCSV(plans) {
        const headers = [
            'Username',
            'Name',
            'Designation',
            'Headquarter',
            ...data[0].months.flatMap(monthData => ([
                `${monthData.month} - Call AVG`,
                `${monthData.month} - Doctor POB`,
                `${monthData.month} - Chemist POB`,
                `${monthData.month} - Clinic POB`,
                `${monthData.month} - Stockist POB`,
                `${monthData.month} - CIP POB`,
                `${monthData.month} - Hospital POB`
            ]))
        ];

        const rows = data.map(dd => ([
            dd.username,
            `${dd.firstname} ${dd.lastname}`,
            dd.designation,
            dd.headquator,
            ...dd.months.flatMap(monthData => ([
                monthData.callaverageperday,
                monthData.drspob,
                monthData.chemistpob,
                monthData.clinicpob,
                monthData.stockistpob,
                monthData.cippob,
                monthData.hospitalpob
            ]))
        ]));

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "data.xlsx");
    }



    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallusers = async () => {
            if (localStorage.getItem('role') === 'Admin') {
                let allusers = await AllUsers();
                setUsers(allusers);
            } else if (localStorage.getItem('role') === 'Manager') {
                let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
                setUsers(allusers);
            }
        };
        getallusers();
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            setMonthLength(data[0].months.length *7)
        }
    }, [data]);

    useEffect(() => {
       async function name() {
        let starts = start.split("-");
        let ends = end.split("-");

            let res = await getAllUsersPobBelowUser(userid,starts[1],starts[0],ends[1],ends[0]);
            if (res) {
                setData(res);
            }
        }

            name()
    
        
    },[userid,start,end])


    const options = [];

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });




// Function to generate dynamic columns for months
const generateMonthColumns = (row) => {
    if (row && row.months && row.months.length > 0) {
        return row.months.map(month => ({
            name: month.month,
            selector: () => month.month ? month.month : "N/A",
            width: "8%",
        }));
    }
    return [];
};

// Define the static columns
const staticColumns = [
    {
        name: "Username",
        selector: (row) => row.username,
        width: "8%",
    },
    {
        name: "Name",
        selector: (row) => row.firstname + " " + row.lastname,
        width: "15%",
    },
    {
        name: "Designation",
        selector: (row) => row.designation ? row.designation : "N/A",
        width: "8%",
    },
    {
        name: "HQ",
        selector: (row) => row.headquator ? row.headquator : "N/A",
        width: "8%",
    },
    {
        name: "MOnths",
        selector: (row) => row.headquator ? row.headquator : "N/A",
        width: "8%",
    }
];

// Concatenate static columns with dynamic columns
const doctorColumns = (row) => staticColumns.concat(generateMonthColumns(row));




  return (
    <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                            
                        /></a></li>
                        <li><a href="/dailycallrecordmonthly"><span class="icon icon-beaker"> </span>Reports</a></li>

                        <li><a href="#"><span class="icon icon-double-angle-right"></span>POB Wise Report For months</a></li>

                    </ul>

                </div>

            </div>

            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                    }}
                >
                    <div style={{ width: "320px" }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Users</Form.Label>
                            <div className="w-75">
                                <Select
                                    className="selectdoctor"
                                    options={options}
                                    value={selectedUser}
                                    onChange={handleSelectChange}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ width: "350px" }}>
                    <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">From</Form.Label>
                            <div className="w-75">
                            <input className="selectdoctor" type="month" id="start" name="start"  onChange={(e) => handleStartMonth(e.target.value)} value={start} />
                            </div>
                        </Form.Group>
                   
                    </div>
                    <div style={{ width: "450px" }}>
                    <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">To</Form.Label>
                            <div className="w-75">
                            <input className="selectdoctor" type="month" id="start" name="end"  onChange={(e) => setEnd(e.target.value)} value={end} />
                            </div>
                        </Form.Group>
                    </div>
                    <div><Button onClick={() => downloadCSV(data)}><RiFileExcel2Fill /></Button></div>
                </div>
                <div className="pt-3 div1">
                    {data.length > 0  ?       <table className='table table-bordered'>
                    <thead>
                                    <tr>
                                        <td>Username</td>
                                        <td>Name</td>
                                        <td>Designation</td>
                                        <td>headquator</td>
                                        <td colSpan={monthlength}>Months</td>
                                 
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        {data.length > 0 && data[0].months.map((monthData, index) => (
                  
                        <td colSpan={7}>{monthData.month}</td>
                   
                ))}
                                   
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        {data.length > 0 && data[0].months.map((monthData, index) => (
                  
                        <>
                        <td>Call AVG</td>
                        <td>Doctor POB</td>
                        <td>Chemist. POB</td>
                        <td>Clinic POB</td>
                        <td>Stockist POB</td>
                        <td>CIP POB</td>
                        <td>Hospital POB</td>
                        </>
                   
                ))}
                                    </tr>
                    </thead>
                    <tbody>
                        {data.map(dd => (
                            <tr>
                            <td>{dd.username}</td>
                            <td>{dd.firstname}<br/>{dd.lastname}</td>
                            <td>{dd.designation}</td>
                            <td>{dd.headquator}</td>
                            {dd.months.length > 0 && dd.months.map((monthData, index) => (
                  
                  <>
                  <td>{monthData.callaverageperday}</td>
                  <td>{monthData.drspob}</td>
                  <td>{monthData.chemistpob}</td>
                  <td>{monthData.clinicpob}</td>
                  <td>{monthData.stockistpob}</td>
                  <td>{monthData.cippob}</td>
                  <td>{monthData.hospitalpob}</td></>
             
          ))}

                        </tr>
                        ))}
                        
                    </tbody>
                </table>  : ""}
          
                </div>
            </div>

        </Container>
  )
}

export default PobWiseReportForMonths